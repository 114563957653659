import { Directive,ElementRef, HostListener } from '@angular/core';

const MAX_PLATE_LENGTH = 8

@Directive({
 selector: '[licensePlate]'
})
export class LicensePlateDirective {
    constructor(public ref: ElementRef) { }

    @HostListener('input', ['$event']) onInput(event) {
  
        let regex = /^[a-zA-Z0-9]*$/
        let replaceRegex = /[^a-zA-Z0-9/-]+/
        let str = event.target.value

        // allow letters and numbers only.
        if(!regex.test(event.key)){
            str = str.replace(replaceRegex, "") 
            this.ref.nativeElement.value = str
            event.preventDefault()
        }

    }

    @HostListener('input', ['$event']) onBlur(event) {

        let replaceRegex = /[^a-zA-Z0-9/-]+/
        let str = event.target.value.replace(replaceRegex, "")
        event.target.value = str.toUpperCase()

    }

}