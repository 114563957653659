import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function ValidatePropertyAddress(controlName: string) {
    return (formGroup: FormGroup) => {

        const control = formGroup.controls[controlName];

        if (control.errors && !control.errors.invalid) {
            // return if another validator has already found an error on the control
            return;
        }

        /*
        // set error if API key is not found
        if (control.value !== 'HILLS')
            control.setErrors({ invalid: true });
        else
            control.setErrors(null);
        */
        control.setErrors(null);
    }
}
