<h2 mat-dialog-title>{{ 'ACCOUNT_NOTICES.INFO_TITLE' | translate }}</h2>

<mat-dialog-content>
  
   <!-- <div>
      {{ 'ACCOUNT_NOTICES.NOTICES_INFO' | translate }}
   </div> -->
   
   <div class='vehicleBox' *ngFor="let vehicle of vehiclesWithWarning">

      <h6>
         {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.color }} 
         {{ 'ACCOUNT_NOTICES.NOTICE_MSG' | translate }}
      </h6>

      <ol>
        <li *ngIf="vehicle.driverlics_comment !== '' && vehicle.driverlics_comment !== null">{{ vehicle.driverlics_comment }}</li>
        <li *ngIf="vehicle.insuranceproofs_comment !== '' && vehicle.insuranceproofs_comment !== null">{{ vehicle.insuranceproofs_comment }}</li>
        <li *ngIf="vehicle.registeration_proofs_comment !== '' && vehicle.registeration_proofs_comment !== null">{{ vehicle.registeration_proofs_comment }}</li>
        <li *ngIf="vehicle.status_reason !== '' && vehicle.status_reason !== null">{{ vehicle.status_reason }}</li>
      </ol>

   </div>

</mat-dialog-content>

<button class="btn btn-primary parkingpass-nav" (click)="close()">{{ 'BUTTONS.CLOSE' | translate }}</button>