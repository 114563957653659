import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-account-warnings-dialog',
  templateUrl: './account-warnings-dialog.component.html',
  styleUrls: ['./account-warnings-dialog.component.css']
})
export class AccountWarningsDialogComponent implements OnInit {

  public vehicleList: Array<any> = []
  public vehiclesWithWarning: Array<any> = []

  constructor(private dialogRef: MatDialogRef<AccountWarningsDialogComponent>, @Inject(MAT_DIALOG_DATA) private dat) {}

  close() {
    this.dialogRef.close()
  }

  public populateVehicleWarnings(){

    this.vehicleList.forEach(vehicle => {
      
      if((vehicle.driverlics_comment !== '' && vehicle.driverlics_comment !== null) ||
         (vehicle.insuranceproofs_comment !== '' && vehicle.insuranceproofs_comment !== null) ||
         (vehicle.registeration_proofs_comment !== '' && vehicle.registeration_proofs_comment !== null) ||
         (vehicle.status_reason !== '' && vehicle.status_reason !== null)
        )
        this.vehiclesWithWarning.push(vehicle)

    });

  }

  ngOnInit(): void {

    this.vehicleList = this.dat.vehicleList
    console.log("vehicle List", this.vehicleList)
    this.populateVehicleWarnings()

  }

}
