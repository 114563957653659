import { AbstractControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { retryWithBackOff } from 'src/app/helpers/retry.operators';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';

export class LpMustBeUniqueInProperty {

    static valid(vehicleService: VehicleService, system: string, location: string, alreadySetTag: string) {

        return (control: AbstractControl) => {

          return vehicleService.checkIfLpIsUniqueInProperty(control.value, system, location).pipe(
            retryWithBackOff(1000),
            map(res => {
            
            if (control.value == alreadySetTag) return null

            return res.is_unique ? null : { notUniqueInProperty: true };

          }));

        };

    }
    
    static validate(vehicleService: VehicleService, controlName: string, system: string, location: string, alreadySetTag: string) {

      return (formGroup: FormGroup) => {
  
          const control = formGroup.controls[controlName];

          if ((control.errors && !control.errors.invalid) || 
              (control.value == alreadySetTag)) return

          vehicleService.checkIfLpIsUniqueInProperty(control.value, system, location).pipe(
            retryWithBackOff(1000),
            map(res => {

            if(res.is_unique){
              control.setErrors(null);
            } else {
              control.setErrors({ notUniqueInProperty: true });
            }

          }));
      }

    }

}