<div class="parkingpass-el-100 parkingpass-menu-fixed" id="parkingpassMainMenu" #parkingpassMainMenu>

    <mat-toolbar class="parkingpass-top-bar">

      <button mat-icon-button aria-label="icon-button with menu icon" (click)="toggleSideBar()" tabindex="0">
        <mat-icon>menu</mat-icon>
      </button>

      <span class='parkingpass-systemName'>{{ systemName }}</span>

    </mat-toolbar>

    <div class="parkingpass-side-bar animated fadeInLeft" *ngIf="sideBar">

      <button class="parkingpass-sideNavButton" (click)="logOut()" tabindex="0">
        {{ 'DASHBOARD.LOG_OUT' | translate }} <fa-icon class="fa-power-off" [icon]="faPowerOff"></fa-icon>
      </button>

    </div>

    <div class="parkingpass-contentHolder">

      <app-sign-up></app-sign-up>

    </div>

</div>

<div class="parkingpass-menu-page-padding-top-not-logged"></div>
