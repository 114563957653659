<h2 mat-dialog-title>{{ 'KEYWORD.INFO_TITLE' | translate }}</h2>

<mat-dialog-content>
  
   <div>
      {{ 'KEYWORD.KEYWORD_INFO' | translate }}
   </div>
 
</mat-dialog-content>

<button class="btn btn-primary parkingpass-nav" (click)="close()">{{ 'BUTTONS.CLOSE' | translate }}</button>