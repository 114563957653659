<div class='card-title'>
  {{ 'KEYWORD.INSTRUCTIONS' | translate }}
</div>

<form [formGroup]="keywordForm">

  <div *ngIf="submitted && f.parkingpassKeyword.errors" class="signUpBoxInstructions">

    <mat-error *ngIf="f.parkingpassKeyword.errors.required">
      {{ 'KEYWORD.KEYWORD_REQUIRED' | translate }}
    </mat-error>

  </div>

    <mat-form-field id='propertyKeyword'>

      <input matInput
              data-clarity-unmask='true'
              type='text'
              id='parkingpass_property_keyword'
              formControlName="parkingpassKeyword"
              autocomplete=off
              placeholder="{{ 'KEYWORD.INPUT_PLACEHOLDER' | translate }}"
              propertyKeyword
              uppercase
              (keyup)="getKeywordPropertyName()"/>

    </mat-form-field>

    <mat-error class='parkingpass-long-error' *ngIf="f.parkingpassKeyword.errors && f.parkingpassKeyword.errors.invalid">
      {{ 'KEYWORD.KEYWORD_INVALID' | translate }}
    </mat-error>

    <mat-error class='parkingpass-long-success' *ngIf="f.parkingpassKeyword.errors && f.parkingpassKeyword.errors.isValid">
      {{ 'KEYWORD.KEYWORD_INVALID' | translate }}
    </mat-error>

</form>

<button class='text-primary parkingpass-help-info' (click)="enableInfo()" tabindex="0">
  {{ 'KEYWORD.WHATS_THIS' | translate }}<fa-icon class='fa-info' [icon]="faInfoCircle"></fa-icon>
</button>

<br/><br/>

<div class='card-title' *ngIf="(propertyName !== undefined && propertyName !== null) && !f.parkingpassKeyword.errors">
  {{ 'KEYWORD.PROPERTY_NAME' | translate }}: {{ propertyName }}
</div>
