import { FormGroup } from '@angular/forms'

// custom validator to check that two fields match
export function MustNotMatchLpVin(lpControlName: string, vinControlName: string) {

    return (formGroup: FormGroup) => {

        const lpControl = formGroup.controls[lpControlName]
        const vinControl = formGroup.controls[vinControlName]

        // return if another validator has already found an error on the name
        if (lpControl.errors && !lpControl.errors.invalidInput) { return }

        // set error on matchingControl if validation fails
        if (lpControl.value === vinControl.value) {
            lpControl.setErrors({ mustNotMatchLpVin : true })
        } else {
            lpControl.setErrors(null)
        }

    }
    
}
