<h2 mat-dialog-title class="helperTitle desktop">{{ 'VEHICLE_INFO.VIN_PLATE_HELPER_TITLE_DESKTOP' | translate }}</h2>

<div class="vinPlateHelper desktop" [ngStyle]="{ 'background' : 'url(' + vinPlateHelperImageMobile + ')' }"></div>

<h2 mat-dialog-title class="helperTitle mobile">{{ 'VEHICLE_INFO.VIN_PLATE_HELPER_TITLE' | translate }}</h2>

<div class="vinPlateHelper mobile" [ngStyle]="{ 'background' : 'url(' + vinPlateHelperImageMobile + ')' }"></div>

<button class="btn btn-primary parkingpass-nav" (click)="close()">
   {{ 'BUTTONS.CLOSE' | translate }}
</button>