import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { catchError, retry, tap } from 'rxjs/operators'
import { handleError } from '../helpers/error-helper'
import { environment } from 'src/environments/environment'
import { retryWithBackOff } from '../helpers/retry.operators'

const LOGIN_API = `${environment.api_endpoint}/v2/users`

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public userLogin: string
  public userPassword: string
  public userRememberMe: boolean
  public userRememberMeToken: string
  public userTimezone: string

  constructor(private http: HttpClient,
              private router: Router) { }

  public async checkIfLoggedIn(): Promise<any>{

    let system = localStorage.getItem('parkingpass_system')

    if(system == undefined || system == null || system == 'null'){
      return new Promise((resolve, reject) => { resolve({message: 'null_system'}) })
    }

    let userId = localStorage.getItem('parkingpass_userId')
    let v1Token = localStorage.getItem('parkingpass_v1Token')
    let userIdToLogin = localStorage.getItem('parkingpass_userId')

    let checkLoginObject = {
      api_key: environment.api_key,
      system: system,
      v1_token: v1Token,
      uuid_requesting_login : userId,
      uuid_to_login : userIdToLogin
    }

    let loginApi = `${LOGIN_API}/auth/check-user-auth`

    return new Promise((resolve, reject) => {

      this.http.post<any>(loginApi, checkLoginObject)
      .subscribe( resp => {
        resolve(resp)
      }, error => {
        reject()
      })

    })
      
  }

  private reRouteHome(){
    this.router.navigate(['/home'])
  }

  public logOut(): boolean{

    let system = localStorage.getItem('parkingpass_system')

    localStorage.clear()

    localStorage.setItem('parkingpass_system', system) 

    return true

  }

  public initLogin(loginObj: any): Observable<any>{

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const params = {
      'api_key': loginObj.api_key,
      'login' : loginObj.login,
      'password' : loginObj.password,
      'remember_me' : loginObj.remember_me,
      'system': loginObj.system,
      'timezone' : userTimezone
    }

    const login_api = `${LOGIN_API}/login/user-login`

    return this.http.post<any>(login_api, params).pipe(
      retryWithBackOff(1000),
    )

  }

  public tokenLogin(loginObj: any): Observable<any>{

    this.userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const login_api = `${LOGIN_API}/login/token-login`

    return this.http.post<any>(login_api, loginObj).pipe(
      retryWithBackOff(1000)
    )

  }

  public initRegister(signUpObj: any): Observable<any> {
    
    const registerApi = `${LOGIN_API}/register/new-user`

    signUpObj.api_key = environment.api_key

    return this.http.post<any>(registerApi, signUpObj).pipe(
      retryWithBackOff(1000)
    )  

  }

  public saveApplication(signUpObj: any): Observable<any> {
    
    const registerApi = `${LOGIN_API}/edit/save-application`

    signUpObj.api_key = environment.api_key

    return this.http.post<any>(registerApi, signUpObj).pipe(
      retryWithBackOff(1000)
    )  

  }

}
