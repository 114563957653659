import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { titleCase } from 'src/app/helpers/string-formatting.helper';
import { VehiclesInfoComponent } from '../vehicles-info/vehicles-info.component';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css', '../sign-up.component.css']
})
export class ReviewComponent implements OnInit {

  @Output() goToCard = new EventEmitter()

  public keywordForm: FormGroup
  public residentForm: FormGroup
  public propertyInfoForm: FormGroup
  public ownerInfoForm: FormGroup
  public vehiclesInCommunity: string
  public vehicles: Array<VehiclesInfoComponent> = []
  public rentOrOwn: boolean = false

  constructor() {}

  public goToCardx(card_index: number): void{
    this.goToCard.emit(card_index)
  }
  
  public titleCaseThis(str: string): string{
    return titleCase(str)
  }

  ngOnInit() {}

  ngAfterViewInit(){}

}
