import { AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { EmailValidationError } from 'src/app/errors/user-errors/user.errors';
import { logErrorMessage, retryWithBackOff } from 'src/app/helpers/retry.operators';
import { EmailUniqueCheckService } from 'src/app/services/sign-up/email-unique-check.service';

export class ValidateUniqueEmail {

    static valid(checkEmailUniqueService: EmailUniqueCheckService, system: string, userEmail: string) {
        return (control: AbstractControl) => {
          return checkEmailUniqueService.checkIfEmailUnique(control.value, system).pipe(
          retryWithBackOff(1000),
          map(res => {

            if(res === undefined) return null

            if(userEmail === control.value || res.is_valid)
              return null 
            else
              return { emailTaken: true }
                    
          }, error => {

            let sentryContext: any = {
              errorName: 'Error Validating E-mail.',
              userEmail,
              errorMessage: "There was an error validating this e-mail.",
              error: null
            }
          
            sentryContext.error = new EmailValidationError(sentryContext.errorName)
            
            logErrorMessage(3, error, sentryContext, null)    

          }))
        
        }
    }
    
}