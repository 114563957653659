<h2 class="helperTitle desktop">{{ 'SERVER_ERROR_TITLE' | translate }}</h2>

<h2 class="helperTitle mobile">{{ 'SERVER_ERROR_TITLE' | translate }}</h2>

<div class='mat-dialog-content'>
  {{ displayMessage }}
</div>

<button class="btn btn-primary parkingpass-nav" (click)="close()">
   {{ 'BUTTONS.CLOSE' | translate }}
</button>