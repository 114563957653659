import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HomeComponent } from './home.component'
import { Routes, RouterModule } from '@angular/router'
import { MenuLoggedOutRoutingModule } from '../parkingpass/logged-out/menu-logged-out-routing.module'
import { MenuModule } from '../parkingpass/menu.module'
import { ParkingPassPipesModule } from '../pipes/parkingpass-pipes.module'

const routes : Routes = [
  { path : '', component : HomeComponent }
]

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    MenuLoggedOutRoutingModule,
    MenuModule,
    ParkingPassPipesModule,
    RouterModule.forChild(routes)     
  ],
  exports : [HomeComponent],
})
export class HomeModule { }
