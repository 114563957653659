import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { MenuLoggedInRoutingModule } from '../parkingpass/logged-in/menu-logged-in-routing.module';
import { RouterModule, Routes } from '@angular/router';

const routes : Routes = [
  { path : '', component : DashboardComponent }
];

@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    MenuLoggedInRoutingModule,
    RouterModule.forChild(routes)
  ],
  exports: [ DashboardComponent ]
})
export class DashboardModule { }