import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { Router } from '@angular/router'
import { Location } from '@angular/common'
import { UserService } from 'src/app/services/user.service'
import { systemLogo, systemName } from 'src/app/helpers/system.helper'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { LanguageRouterComponent } from 'src/app/language-router/language-router.component'
import { TranslateService } from '@ngx-translate/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { AccountWarningsDialogComponent } from './account-warnings-dialog/account-warnings-dialog.component'

@Component({
  selector: 'app-menu-logged-in',
  templateUrl: './menu-logged-in.component.html',
  styleUrls: ['../menu.component.css', './menu-logged-in.component.css']
})
export class MenuLoggedInComponent implements OnInit {
  
  @ViewChild('parkingpassMainMenu') parkingpassMainMenu: ElementRef

  public home_route: boolean = true
  
  public prevScrollpos

  public systemName: string
  public systemLogo: string
  public system: string

  public sideBar: boolean = false

  public faPowerOff = faPowerOff

  constructor(private router: Router,
              private location: Location,
              private languageService: TranslateService,
              private userService: UserService,
              private matDialog: MatDialog) { 

  }

  public openWindow(window: any): void {
    window.open = true
  }

  public closeWindow(window: any): void {
    window.open = false
  }

  public scrollTo(el: string): void {
    const element = document.getElementById(el)
    element.scrollIntoView()
  }

  public toggleSideBar(): void {
    this.sideBar = !this.sideBar
  }

  public logOut(): void {

    let language = this.languageService.currentLang

    if( this.userService.logOut() ){
      this.router.navigate([`${language}/home`])
    }

  }

  public showWarnings(): void{

    let vehicleList: Array<any> = JSON.parse(localStorage.getItem('parkingpass_vehicleList'))

    let vehicleWarnings = 0

    vehicleList.forEach(vehicle => {
      
      if(vehicle.driverlics_comment === undefined) return

      if((vehicle.driverlics_comment !== '' && vehicle.driverlics_comment !== null) ||
          (vehicle.insuranceproofs_comment !== '' && vehicle.insuranceproofs_comment !== null) ||
          (vehicle.registeration_proofs_comment !== '' && vehicle.registeration_proofs_comment !== null) ||
          (vehicle.status_reason !== '' && vehicle.status_reason !== null)
        )         
          vehicleWarnings++
        

    });

    if(vehicleWarnings > 0){

      const dialogConfig = new MatDialogConfig()

      dialogConfig.autoFocus = true

      dialogConfig.data = {
        vehicleList : vehicleList,
      }

      let dialogRef = this.matDialog.open(
        AccountWarningsDialogComponent,
        dialogConfig
      )

    }

  }

  ngOnInit(): void {

    this.system = localStorage.getItem('parkingpass_system')
    this.systemName = systemName(this.system)
    this.systemLogo = systemLogo(this.system)

    this.showWarnings()

  }

}