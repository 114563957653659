<app-loading-screen *ngIf="loading"></app-loading-screen>

<h2 mat-dialog-title>{{ 'PHONE_CONFIRMATION.INFO_TITLE' | translate }}</h2>

<div *ngIf="affirmPhoneNumber">

  <mat-dialog-content>
  
    <div>
 
      <p>
        {{ 'PHONE_CONFIRMATION.INFO' | translate }}
      </p>
      
      <form [formGroup]="confirmPhoneForm">

        <div class='parkingpass-formFieldWrapper'>
    
          <div *ngIf="d.parkingpassPhoneNumber.touched && d.parkingpassPhoneNumber.errors" class="signUpBoxInstructions">

            <mat-error *ngIf="d.parkingpassPhoneNumber.errors.invalid">
                {{ 'RESIDENT_INFO.PHONE_NUMBER_INVALID' | translate }}
            </mat-error>

            <mat-error *ngIf="d.parkingpassPhoneNumber.errors.unavailable">
              {{ 'RESIDENT_INFO.PHONE_NUMBER_UNAVAILABLE' | translate }}
            </mat-error>

          </div> 

          <mat-form-field>
    
            <input matInput type='text' 
                            id='user_phone' 
                            mask="(000) 000-0000" 
                            (keyup)="changeParentPhone()"
                            autocomplete="tel" 
                            placeholder="{{ 'RESIDENT_INFO.PHONE_NUMBER_PLACEHOLDER' | translate }}"
                            formControlName="parkingpassPhoneNumber" />       
              
          </mat-form-field>
        
        </div>
     
            <mat-checkbox
              labelPosition="after"
              [checked]="value"
              [(ngModel)]="disabled"
              [ngModelOptions]="{standalone: true}"
              id="check">
              {{ 'PHONE_CONFIRMATION.OPT_IN' | translate }}
          </mat-checkbox>
      
      </form>

      <p>
        {{ 'PHONE_CONFIRMATION.INFO_OPT_IN' | translate }}
      </p>

    </div>
  
  </mat-dialog-content>
  
  <div style="text-align: center;">

    <button disabled="true" style="border-color: black; background-color:black" [disabled]="!disabled" class="btn btn-primary parkingpass-nav" (click)="sendCodeWithCall()">{{ 'PHONE_CONFIRMATION.CALL' | translate }}</button>

    <button  disabled="true" style="border-color: black; background-color:black" [disabled]="!disabled" class="btn btn-primary parkingpass-nav" (click)="sendCode()">{{ 'PHONE_CONFIRMATION.SEND_SMS' | translate }}</button>

  </div>
  <div style="text-align: center;">
    <button style="border-color: red; background-color:red" class="btn btn-primary parkingpass-nav" (click)="optOut()">{{ 'PHONE_CONFIRMATION.CANCEL' | translate }}</button>
  </div>

</div>

<div *ngIf="confirmPhoneNumber">

  <mat-dialog-content>
  
    <div>
 
      <p>
        {{ 'PHONE_CONFIRMATION.INPUT_CODE' | translate }}
      </p>
 
      <div class='parkingpass-formFieldWrapper'>
      
        <form [formGroup]="pinForm">

          <div *ngIf="f.confirmationCode.touched && f.confirmationCode.errors" class="signUpBoxInstructions">
            
            <mat-error *ngIf="f.confirmationCode.errors.required">
                {{ 'RESIDENT_INFO.CUSTOMER_PIN_REQUIRED' | translate }}  
            </mat-error>

            <mat-error *ngIf="f.confirmationCode.errors.invalid">
                {{ 'RESIDENT_INFO.CUSTOMER_PIN_WRONG_LENGTH' | translate }}
            </mat-error>

          </div> 

          <mat-form-field>
  
            <input matInput 
                  type="text"
                  formControlName="confirmationCode"
                  placeholder="{{ 'PHONE_CONFIRMATION.CODE_PLACEHOLDER' | translate }}" 
                  mask="000000"/> 
            
          </mat-form-field>

        </form>
     
      </div>
 
    </div>
  
  </mat-dialog-content>

  <div>

    <button class="btn btn-primary parkingpass-nav parkingpass-confirm-button" (click)="checkCode()">
      {{ 'PHONE_CONFIRMATION.CONFIRM_BTN' | translate }}
    </button>

  </div>

  <button class='text-primary parkingpass-help-info' (click)="backToFirst()">
    {{ 'PHONE_CONFIRMATION.CODE_NOT_RECEIVED' | translate }}
  </button>

  <span class='parkingpass-sub-text'>
    <small>{{ 'PHONE_CONFIRMATION.TRY_AGAIN_WAIT' | translate }}</small>
  </span>

</div>


<div *ngIf="codeCheckTried">

  <div *ngIf="phoneNumberConfirmationPassed">
    
    <p>
      {{ 'PHONE_CONFIRMATION.PHONE_CONFIRMED' | translate }}
    </p>
    
    <div style="text-align: center;">
      <button class="btn btn-primary parkingpass-nav" (click)="close()">{{ 'PHONE_CONFIRMATION.DONE' | translate }}</button>
    </div>

  </div>

  <div *ngIf="!phoneNumberConfirmationPassed">
    
    <p>
      {{ 'PHONE_CONFIRMATION.CODE_INVALID' | translate }}
    </p>

    <button class='text-primary parkingpass-help-info' (click)="backToFirst()">
      {{ 'PHONE_CONFIRMATION.TRY_AGAIN' | translate }}
    </button>

  </div>

</div>