<div class="col" *ngIf="lang == 'en-us'">

    <h3>
      Terms & Conditions
    </h3>

    <p>
        Last Modified: November 2, 2020
    </p>
    <p>
        <u>Acceptance of the Terms of Use</u>
    </p>
    <p>
        These terms of use are entered into by and between your property management
        company, on behalf of itself, its officers, directors, employees,
        contractors, agents, and affiliates (collectively, <strong>"You"</strong>),
        and
    </p>
    <p>
    Union Rich Technologies, LLC d/b/a ParkingPass.com (<strong>"Company"</strong>, <strong>"we"</strong> or <strong>"us"</strong>).
    The following terms and conditions, together with any documents they
    expressly incorporate by reference (collectively, these <strong>"Terms of Use"</strong>), govern your access to and use of
        <a href="https://www.parkingpass.com/" target="_blank">
            <u><strong>https://www.parkingpass.com/</strong></u>
        </a>
        , including any content, functionality and services offered on or through
        <a href="https://www.parkingpass.com/" target="_blank">
            <u><strong>https://www.parkingpass.com/</strong></u>
        </a>
        (the <strong>"Website"</strong>), whether as a guest or a registered user.
    </p>
    <p>
        Please read the Terms of Use carefully before you start to use the Website.
        <strong>
            By using the Website, signing below, or by clicking to accept or agree
            to the Terms of Use when this option is made available to you, you
            accept and agree to be bound and abide by these Terms of Use and our
        </strong>
        <a href="https://www.parkingpass.com/privacy-policy" target="_blank">
            <strong>Privacy Policy</strong>
        </a>
        <strong>
            , found at https://www.parkingpass.com/privacy-policy/, incorporated
            herein by reference
        </strong>
        . If you do not want to agree to these Terms of Use or the
        <a href="https://www.parkingpass.com/privacy-policy" target="_blank">
            <strong>Privacy Policy</strong>
        </a>
        , you must not access or use the Website. This Website is offered and
        available to users who are 18 years of age or older and reside in the
        United States or any of its territories or possessions. By using this
        Website, you represent and warrant that you are of legal age to form a
        binding contract with the Company and meet all of the foregoing eligibility
        requirements. If you do not meet all of these requirements, you must not
        access or use the Website.
    </p>
    <p>
        <u>Changes to the Terms of Use</u>
    </p>
    <p>
        We may revise and update these Terms of Use from time to time in our sole
        discretion. All changes are effective immediately when we post them and
        apply to all access to and use of the Website thereafter.
    </p>
    <p>
        Your continued use of the Website following the posting of revised Terms of
        Use means that you accept and agree to the changes. You are expected to
        check this page from time to time when you access this Website so you are
        aware of any changes, as they are binding on you.
    </p>
    <p>
        <u>Accessing the Website and Account Security</u>
    </p>
    <p>
        We reserve the right to withdraw or amend this Website, and any service or
        material we provide on the Website, in our sole discretion without notice.
        We will not be liable if for any reason all or any part of the Website is
        unavailable at any time or for any period. From time to time, we may
        restrict access to some parts of the Website, or the entire Website, to
        users, including registered users.
    </p>
    <p>
        You are responsible for:
    </p>
    <ul>
        <li>
            <p>
                Making all arrangements necessary for you to have access to the
                Website.
            </p>
        </li>
        <li>
            <p>
                Ensuring that all persons who access the Website through your
                internet connection are aware of these Terms of Use and comply with
                them.
            </p>
        </li>
    </ul>
    <p>
        To access the Website or some of the resources it offers, you may be asked
        to provide certain registration details or other information. It is a
        condition of your use of the Website that all the information you provide
        on the Website is correct, current and complete. You agree that all
        information you provide to register with this Website or otherwise,
        including but not limited to through the use of any interactive features on
        the Website, is governed by our
        <a href="https://www.parkingpass.com/privacy-policy" target="_blank">
            <u>Privacy Policy</u>
        </a>
        and you consent to all actions we take with respect to your information
        consistent with our Privacy Policy.
    </p>
    <p>
        If you choose, or are provided with, a user name, password or any other
        piece of information as part of our security procedures, you must treat
        such information as confidential, and you must not disclose it to any other
        person or entity. You also acknowledge that your account is personal to you
        and agree not to provide any other person with access to this Website or
        portions of it using your user name, password or other security
        information. You agree to notify us immediately of any unauthorized access
        to or use of your user name or password or any other breach of security.
        You also agree to ensure that you exit from your account at the end of each
        session. You should use particular caution when accessing your account from
        a public or shared computer so that others are not able to view or record
        your password or other personal information.
    </p>
    <p>
        We have the right to disable any user name, password or other identifier,
        whether chosen by you or provided by us, at any time in our sole discretion
        for any or no reason, including if, in our opinion, you have violated any
        provision of these Terms of Use.
    </p>
    <p>
        <u>Intellectual Property Rights</u>
    </p>
    <p>
        The Website and its entire contents, features and functionality (including
        but not limited to all information, software, text, displays, images, video
        and audio, and the design, selection and arrangement thereof), are owned by
        the Company, its licensors or other providers of such material and are
        protected by United States and international copyright, trademark, patent,
        trade secret and other intellectual property or proprietary rights laws.
    </p>
    <p>
        These Terms of Use permit you to use the Website for your personal,
        non-commercial, non-competitive, and confidential use only. The Website and
        its underlying code constitute trade secrets. You must not reproduce,
        distribute, modify, create derivative works of, publicly display, publicly
        perform, republish, download, share with unauthorized persons/parties,
        store or transmit (i) any of the material on our Website, (ii) any of the
        underlying code for the Website, except as follows:
    </p>
    <ul>
        <li>
            <p>
                Your computer may temporarily store copies of such materials in RAM
                incidental to your accessing and viewing those materials.
            </p>
        </li>
        <li>
            <p>
                You may store files that are automatically cached by your Web
                browser for display enhancement purposes.
            </p>
        </li>
        <li>
            <p>
                You may print or download one copy of a reasonable number of pages
                of the Website for your own personal, non-commercial use and not
                for further reproduction, publication or distribution.
            </p>
        </li>
        <li>
            <p>
                If we provide desktop, mobile or other applications for download,
                you may download a single copy to your computer or mobile device
                solely for your own personal, non-commercial use, provided you
                agree to be bound by our end user license agreement for such
                applications.
            </p>
        </li>
    </ul>
    <p>
        You must not:
    </p>
    <ul>
        <li>
            <p>
                Modify copies of any materials from this site.
            </p>
        </li>
        <li>
            <p>
                Use any illustrations, photographs, video or audio sequences or any
                graphics separately from the accompanying text.
            </p>
        </li>
        <li>
            <p>
                Delete or alter any copyright, trademark or other proprietary
                rights notices from copies of materials from this site.
            </p>
        </li>
    </ul>
    <p>
        You must not access or use for any commercial purposes any part of the
        Website or any services or materials available through the Website.
    </p>
    <p>
        If you wish to make any use of material on the Website other than that set
        out in this section, please address your request to:
    </p>
    <p>
        If you print, copy, modify, download or otherwise use or provide any other
        person with access to any part of the Website in breach of the Terms of
        Use, your right to use the Website will cease immediately and you must, at
        our option, return or destroy any copies of the materials you have made. No
        right, title or interest in or to the Website or any content on the Website
        is transferred to you, and all rights not expressly granted are reserved by
        the Company. Any use of the Website not expressly permitted by these Terms
        of Use is a breach of these Terms of Use and may violate copyright,
        trademark and other laws.
    </p>
    <p>
        <u>Trademarks</u>
    </p>
    <p>
        The Company name, the Company logo and all related names, logos, product
        and service names, designs and slogans are trademarks of the Company or its
        affiliates or licensors. You must not use such marks without the prior
        written permission of the Company. All other names, logos, product and
        service names, designs and slogans on this Website are the trademarks of
        their respective owners.
    </p>
    <p>
        <u>Prohibited Uses</u>
    </p>
    <p>
        You may use the Website only for lawful purposes and in accordance with
        these Terms of Use. You agree not to use the Website:
    </p>
    <ul>
        <li>
            <p>
                In any way that violates any applicable federal, state, local or
                international law or regulation (including, without limitation, any
                laws regarding the export of data or software to and from the US or
                other countries).
            </p>
        </li>
        <li>
            <p>
                For the purpose of exploiting, harming or attempting to exploit or
                harm minors in any way by exposing them to inappropriate content,
                asking for personally identifiable information or otherwise.
            </p>
        </li>
        <li>
            <p>
                To transmit, or procure the sending of, any advertising or
                promotional material [without our prior written consent], including
                any "junk mail", "chain letter" or "spam" or any other similar
                solicitation.
            </p>
        </li>
        <li>
            <p>
                To impersonate or attempt to impersonate the Company, a Company
                employee, another user or any other person or entity.
            </p>
        </li>
        <li>
            <p>
                To engage in any other conduct that restricts or inhibits anyone's
                use or enjoyment of the Website, or which, as determined by us, may
                harm the Company or users of the Website or expose them to
                liability.
            </p>
        </li>
    </ul>
    <p>
        Additionally, you agree not to:
    </p>
    <ul>
        <li>
            <p>
                Use the Website in any manner that could disable, overburden,
                damage, or impair the site or interfere with any other party's use
                of the Website, including their ability to engage in real time
                activities through the Website.
            </p>
        </li>
        <li>
            <p>
                Use any robot, spider or other automatic device, process or means
                to access the Website for any purpose, including monitoring or
                copying any of the material on the Website.
            </p>
        </li>
        <li>
            <p>
                Use any manual process to monitor or copy any of the material on
                the Website or for any other unauthorized purpose without our prior
                written consent.
            </p>
        </li>
        <li>
            <p>
                Use any device, software or routine that interferes with the proper
                working of the Website.
            </p>
        </li>
        <li>
            <p>
                Introduce any viruses, trojan horses, worms, logic bombs or other
                material which is malicious or technologically harmful.
            </p>
        </li>
        <li>
            <p>
                Attempt to gain unauthorized access to, interfere with, damage or
                disrupt any parts of the Website, the server on which the Website
                is stored, or any server, computer or database connected to the
                Website.
            </p>
        </li>
        <li>
            <p>
                Attack the Website via a denial-of-service attack or a distributed
                denial-of-service attack.
            </p>
        </li>
        <li>
            <p>
                Otherwise attempt to interfere with the proper working of the
                Website.
            </p>
        </li>
    </ul>
    <p>
        <u>Monitoring and Enforcement; Termination</u>
    </p>
    <p>
        We have the right to:
    </p>
    <ul>
        <li>
            <p>
                Take appropriate legal action, including without limitation,
                referral to law enforcement, for any illegal or unauthorized use of
                the Website.
            </p>
        </li>
        <li>
            <p>
                Terminate or suspend your access to all or part of the Website for
                any violation of these Terms of Use.
            </p>
        </li>
    </ul>
    <p>
        Without limiting the foregoing, we have the right to fully cooperate with
        any law enforcement authorities or court order requesting or directing us
        to disclose the identity or other information of anyone posting any
        materials on or through the Website. YOU WAIVE AND HOLD HARMLESS THE
        COMPANY FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY
        DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A
        CONSEQUENCE OF INVESTIGATIONS BY EITHER THE COMPANY OR LAW ENFORCEMENT
        AUTHORITIES.
    </p>
    <p>
        <u>Changes to the Website</u>
    </p>
    <p>
        We may update the content on this Website from time to time, but its
        content is not necessarily complete or up-to-date. Any of the material on
        the Website may be out of date at any given time, and we are under no
        obligation to update such material.
    </p>
    <p>
        <u>Information About You and Your Visits to the Website</u>
    </p>
    <p>
        All information we collect on this Website is subject to our
        <a href="https://www.parkingpass.com/privacy-policy" target="_blank">
            <u>Privacy Policy</u>
        </a>
        . By using the Website, you consent to all actions taken by us with respect
        to your information in compliance with the Privacy Policy.
    </p>
    <p>
        <u>Links from the Website</u>
    </p>
    <p>
        If the Website contains links to other sites and resources provided by
        third parties, these links are provided for your convenience only. This
        includes links contained in advertisements, including banner advertisements
        and sponsored links. We have no control over the contents of those sites or
        resources, and accept no responsibility for them or for any loss or damage
        that may arise from your use of them. If you decide to access any of the
        third-party websites linked to this Website, you do so entirely at your own
        risk and subject to the terms and conditions of use for such websites.
    </p>
    <p>
        <u>Geographic Restrictions</u>
    </p>
    <p>
        The owner of the Website is based in the state of Florida in the United
        States. We provide this Website for use only by persons located in the
        United States. We make no claims that the Website or any of its content is
        accessible or appropriate outside of the United States. Access to the
        Website may not be legal by certain persons or in certain countries. If you
        access the Website from outside the United States, you do so on your own
        initiative and are responsible for compliance with local laws.
    </p>
    <p>
        <u>Disclaimer of Warranties</u>
    </p>
    <p>
        You understand that we cannot and do not guarantee or warrant that files
        available for downloading from the internet or the Website will be free of
        viruses or other destructive code. You are responsible for implementing
        sufficient procedures and checkpoints to satisfy your particular
        requirements for anti-virus protection and accuracy of data input and
        output, and for maintaining a means external to our site for any
        reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR
        DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER
        TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
        COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF
        THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO
        YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO
        IT.
    </p>
    <p>
        YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED
        THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY
        SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS"
        AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
        EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE
        COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
        COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF
        THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
        ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS
        CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE
        ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE
        CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
        VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR
        ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR
        EXPECTATIONS.
    </p>
    <p>
        THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
        IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
        WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR
        PURPOSE.
    </p>
    <p>
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
    </p>
    <p>
        <u>Limitation on Liability</u>
    </p>
    <p>
        IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE
        PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES
        OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH
        YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY
        CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS
        CONTRACTED FOR OR OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES,
        INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
        PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
        SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF
        BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
        DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT
        OR OTHERWISE, EVEN IF FORESEEABLE.
    </p>
    <p>
        THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
    </p>
    <p>
        <u>Indemnification</u>
    </p>
    <p>
        You agree to defend, indemnify and hold harmless the Company, its
        affiliates, licensors and service providers, and its and their respective
        officers, directors, employees, contractors, agents, licensors, suppliers,
        successors and assigns from and against any claims, liabilities, damages,
        judgments, awards, losses, costs, expenses or fees (including reasonable
        attorneys' fees) arising out of or relating to your violation of these
        Terms of Use, the use of the Company’s services by you, your invitees,
        guests or affiliates, or your use of the Website, including, but not
        limited to any use of the Website's content, services and products other
        than as expressly authorized in these Terms of Use or your use of any
        information obtained from the Website.
    </p>
    <p>
        <u>Governing Law and Jurisdiction</u>
    </p>
    <p>
        All matters relating to the Website and these Terms of Use and any dispute
        or claim arising therefrom or related thereto (in each case, including
        non-contractual disputes or claims), shall be governed by and construed in
        accordance with the internal laws of the State of Florida without giving
        effect to any choice or conflict of law provision or rule (whether of the
        State of Florida or any other jurisdiction).
    </p>
    <p>
        Any legal suit, action or proceeding arising out of, or related to, these
        Terms of Use or the Website shall be instituted exclusively in the federal
        courts of the United States or the courts of the State of Florida in each
        case located in the City of Fort Lauderdale and County of Broward. You
        waive any and all objections to the exercise of jurisdiction over you by
        such courts and to venue in such courts.
    </p>
    <p>
        <u>Limitation on Time to File Claims</u>
    </p>
    <p>
        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF SERVICES PROVIDED
        BY THE COMPANY OR RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE
        COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE,
        YOU AGREE THAT SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
    </p>
    <p>
        <u>Waiver and Severability</u>
    </p>
    <p>
        No waiver of by the Company of any term or condition set forth in these
        Terms of Use shall be deemed a further or continuing waiver of such term or
        condition or a waiver of any other term or condition, and any failure of
        the Company to assert a right or provision under these Terms of Use shall
        not constitute a waiver of such right or provision.
    </p>
    <p>
        If any provision of these Terms of Use is held by a court or other tribunal
        of competent jurisdiction to be invalid, illegal or unenforceable for any
        reason, such provision shall be eliminated or limited to the minimum extent
        such that the remaining provisions of the Terms of Use will continue in
        full force and effect.
    </p>
    <p>
        <u>Entire Agreement</u>
    </p>
    <p>
        The Terms of Use and our Privacy Policy constitute the sole and entire
        agreement between you and the Company with respect to the Website and
        supersede all prior and contemporaneous understandings, agreements,
        representations and warranties, both written and oral, with respect to the
        Website.
    </p>
    <p>
        <u>Your Comments and Concerns</u>
    </p>
    <p>
        All feedback, comments, requests for technical support and other
    communications relating to the Website should be directed to:    <a href="mailto:support@parkingpass.com">support@parkingpass.com</a>
    </p>
    <p>
        By agreeing to the terms of service, you are acknowledging your agreement
        to be bound by and acceptance of the Terms of Use.
    </p>

</div>

<div class="col" *ngIf="lang == 'es-mx'">

    <h3>
      Términos y Condiciones
    </h3>

    <p>
        <u>Última modificación</u>
        2 de noviembre de 2020
    </p>
    <p>
        <u>Aceptación de las Condiciones de uso</u>
    </p>
    <p>
        Estas condiciones de uso son celebradas entre su empresa de administración
        de propiedades, en nombre propio, sus funcionarios, directores, empleados,
    contratistas, agentes y afiliados (colectivamente,    <strong>"Usted")</strong>y
    </p>
    <p>
        Union Rich Technologies, LLC d/b/a ParkingPass.com (
        <strong>
            en lo sucesivo la "Empresa", o "nosotros"). Las siguientes condiciones
            de uso, junto con cualquier documento que incorporen expresamente por
            referencia (colectivamente, las "Condiciones de uso"), rigen su acceso
            y uso de https://www.parkingpass.com/, incluyendo cualquier contenido,
            funcionalidad y servicios ofrecidos a través de
            https://www.parkingpass.com/ (el "Sitio Web"), ya sea como invitado o
            como usuario registrado.
        </strong>
    </p>
    <p>
        Por favor lea atentamente las Condiciones de uso antes de utilizar el Sitio
        web.
        <strong>
            Al utilizar el Sitio web, firmar a continuación o al hacer clic para
            aceptar las Condiciones de uso cuando esta
        </strong>
        opción se pone a su disposición, usted acepta estar vinculado y cumplir con
        estas Condiciones de uso y nuestra Política de
        <strong>
            , privacidad, que se encuentra en
            https://www.parkingpass.com/privacy-policy/, incorporado aquí por
            referencia.
        </strong>
        Si no desea aceptar estas condiciones de uso o la    <strong> Política de privacidad</strong>, no debe acceder ni utilizar el
        sitio web. Este sitio web se ofrece y está a disposición de los usuarios
        mayores de 18 años que residan en los Estados Unidos o en cualquiera de sus
        territorios o posesiones. Al utilizar este sitio web, usted declara y
        garantiza que es mayor de edad para celebrar un contrato vinculante con la
        Empresa y cumplir con todos los requisitos de elegibilidad anteriores. Si
        no cumple con todos estos requisitos, no debe acceder ni utilizar el Sitio
        Web.
    </p>
    <p>
        <u>Cambios en los Términos de uso</u>
    </p>
    <p>
        Podemos revisar y actualizar estas condiciones de uso de vez en cuando a
        nuestra entera discreción. Todos los cambios son efectivos inmediatamente
        una vez publicados y se aplican a todo acceso y uso del Sitio Web.
    </p>
    <p>
        El uso continuo del sitio web después de la publicación de las condiciones
        de uso revisadas significa que usted acepta y está de acuerdo con los
        cambios. Se espera que revise esta página de vez en cuando al acceder a
        este sitio web para que esté al tanto de cualquier cambio, ya que son
        vinculantes para usted.
    </p>
    <p>
        <u>Acceso al sitio web y a la seguridad de la cuenta</u>
    </p>
    <p>
        Nos reservamos el derecho de retirar o modificar este sitio web, y
        cualquier servicio o material que proporcionemos en el sitio web, a nuestra
        entera discreción y sin previo aviso. No seremos responsables si por alguna
        razón todo o parte del sitio web no está disponible en cualquier momento o
        por cualquier período. De vez en cuando, podemos restringir el acceso a
        algunas partes del Sitio web, o a todo el Sitio Web, a los usuarios,
        incluidos los usuarios registrados.
    </p>
    <p>
        Usted es responsable de:
    </p>
    <ul>
        <li>
            <p>
                Realizar todos los acomodos necesarios para que tenga acceso al
                Sitio Web.
            </p>
        </li>
        <li>
            <p>
                Asegurarse que todas las personas que acceden al Sitio Web a través
                de su conexión a Internet son conscientes de estos Términos de Uso
                y cumplen con ellos.
            </p>
        </li>
    </ul>
    <p>
        Para acceder al sitio web o a algunos de los recursos que ofrece, puede que
        se le pida proporcionar ciertos detalles de registro u otra información. Es
        una condición para el uso de la página web que toda la información que
        proporcione en la página web sea correcta, actual y completa. Usted acepta
        que toda la información que proporcione para registrarse en este Sitio web
        o de otro modo, incluyendo pero no limitado a través del uso de cualquier
        característica interactiva en el Sitio web, se rige por nuestra
        <a href="https://www.parkingpass.com/privacy-policy" target="_blank">
            <u>Política de Privacidad</u>
        </a>
        y acepta todas las acciones que tomamos con respecto a su información de
        acuerdo con nuestra Política de Privacidad.
    </p>
    <p>
        Si elige o se le proporciona un nombre de usuario, una contraseña o
        cualquier otra información como parte de nuestros procedimientos de
        seguridad, debe tratar dicha información como confidencial y no debe
        revelarla a ninguna otra persona o entidad. También reconoce que su cuenta
        es personal y se compromete a no proporcionar a ninguna otra persona el
        acceso a este sitio web o a partes del mismo utilizando su nombre de
        usuario, contraseña u otra información de seguridad. Usted se compromete a
        notificarnos inmediatamente cualquier acceso o uso no autorizado de su
        nombre de usuario o contraseña o cualquier otra violación de seguridad.
        También acepta asegurarse de salir de su cuenta al final de cada sesión.
        Debe tener especial cuidado al acceder a su cuenta desde una computadora
        pública o compartida para que otros no puedan ver o registrar su contraseña
        u otra información personal.
    </p>
    <p>
        Tenemos el derecho de inhabilitar cualquier nombre de usuario, contraseña u
        otro identificador, ya sea elegido por usted o proporcionado por nosotros,
        en cualquier momento y a nuestra entera discreción, por cualquier razón o
        sin ella, incluso si, en nuestra opinión, usted ha violado alguna
        disposición de estas Condiciones de uso.
    </p>
    <p>
        <u>Derechos de propiedad intelectual</u>
    </p>
    <p>
        El sitio web y todo su contenido y funcionalidades (incluyendo pero no
        limitándose a toda la información, software, texto, pantallas, imágenes,
        vídeo y audio, y el diseño, selección y disposición de los mismos), son
        propiedad de la Empresa, sus licenciatarios u otros proveedores de dicho
        material y están protegidos por las leyes de derechos de autor, marcas
        comerciales, patentes, secretos comerciales y otras leyes de propiedad
        intelectual o derechos de propiedad de los Estados Unidos e
        internacionales.
    </p>
    <p>
        Estas Condiciones de uso le permiten utilizar el Sitio web para su uso
        personal, no comercial, no competitivo y confidencial solamente. El Sitio
        Web y su código subyacente constituyen secretos comerciales. No debe
        reproducir, distribuir, modificar, crear trabajos derivados, exhibir
        públicamente, ejecutar públicamente, volver a publicar, descargar,
        compartir con personas/partes no autorizadas, almacenar o transmitir (i)
        ningún material de nuestro sitio web, (ii) ningún código subyacente del
        sitio web, excepto en los casos siguientes:
    </p>
    <ul>
        <li>
            <p>
                Su computadora puede almacenar temporalmente copias de esos
                materiales en la memoria RAM, de forma que pueda acceder a ellos y
                verlos.
            </p>
        </li>
        <li>
            <p>
                Puede almacenar archivos que son automáticamente almacenados en la
                memoria caché de su navegador de Internet con el fin de mejorar la
                visualización.
            </p>
        </li>
        <li>
            <p>
                Puede imprimir o descargar una copia de un número razonable de
                páginas del Sitio Web para su propio uso personal y no comercial y
                no para su posterior reproducción, publicación o distribución.
            </p>
        </li>
        <li>
            <p>
                Si proporcionamos aplicaciones de escritorio, móviles o de otro
                tipo para su descarga, podrá descargar una única copia a su
                ordenador o dispositivo móvil únicamente para su uso personal y no
                comercial, siempre y cuando acepte regirse por nuestro acuerdo de
                licencia de usuario final para dichas aplicaciones.
            </p>
        </li>
    </ul>
    <p>
        Usted no debe:
    </p>
    <ul>
        <li>
            <p>
                Modificar copias de cualquier material de este sitio.
            </p>
        </li>
        <li>
            <p>
                Utilizar las ilustraciones, fotografías, secuencias de vídeo o
                audio o cualquier gráfico por separado del texto que lo acompaña.
            </p>
        </li>
        <li>
            <p>
                Eliminar o alterar cualquier aviso de derechos de autor, marca
                comercial u otros derechos de propiedad de copias de materiales de
                este sitio.
            </p>
        </li>
    </ul>
    <p>
        Acceder ni utilizar con fines comerciales ninguna parte del Sitio Web ni
        ningún servicio o material disponible a través del Sitio Web.
    </p>
    <p>
        Si desea hacer cualquier uso de material en el Sitio Web que no sea el
        establecido en esta sección, por favor dirija su solicitud a:
    </p>
    <p>
        Si imprime, copia, modifica, descarga o utiliza o proporciona a cualquier
        otra persona acceso a cualquier parte del sitio web en violación de las
        condiciones de uso, su derecho a utilizar el sitio web cesará
        inmediatamente y deberá, a nuestra opción, devolver o destruir cualquier
        copia de los materiales que haya hecho. No se le transfiere ningún derecho,
        título o interés sobre el sitio web o cualquier contenido del mismo, y la
        Empresa se reserva todos los derechos no concedidos expresamente. Cualquier
        uso del sitio web que no esté expresamente permitido por estas Condiciones
        de uso constituye una infracción de las mismas y puede violar las leyes de
        derechos de autor, marcas comerciales y otras leyes.
    </p>
    <p>
        <u>Marcas</u>
    </p>
    <p>
        El nombre de la Empresa, el logotipo de la Empresa y todos los nombres,
        logotipos, nombres de productos y servicios, diseños y eslóganes
        relacionados son marcas comerciales de la Compañía o de sus filiales o
        licenciatarios. No debe utilizar dichas marcas sin el permiso previo por
        escrito de la Empresa. Todos los demás nombres, logotipos, nombres de
        productos y servicios, diseños y eslóganes de este sitio web son marcas
        comerciales de sus respectivos propietarios.
    </p>
    <p>
        <u>Prohibiciones de uso</u>
    </p>
    <p>
        Usted puede utilizar el sitio web sólo para fines lícitos y de acuerdo con
        estas condiciones de uso. Usted acepta no utilizar el Sitio Web:
    </p>
    <ul>
        <li>
            <p>
                De cualquier forma que viole cualquier ley o reglamento federal,
                estatal, local o internacional aplicable (incluidas, entre otras,
                las leyes relativas a la exportación de datos o programas
                informáticos hacia y desde los Estados Unidos u otros países).
            </p>
        </li>
        <li>
            <p>
                Con el propósito de explotar, dañar o intentar explotar o dañar a
                menores de cualquier forma exponiéndolos a contenido inapropiado,
                solicitando información de identificación personal o de otra
                manera.
            </p>
        </li>
        <li>
            <p>
                Transmitir o procurar el envío de cualquier material publicitario o
                promocional [sin nuestro consentimiento previo por escrito],
                incluyendo cualquier "correo basura", "carta en cadena" o "spam" o
                cualquier otra solicitud similar.
            </p>
        </li>
        <li>
            <p>
                Suplantar o hacerse pasar por la Empresa, un empleado de la
                Compañía, otro usuario o cualquier otra persona o entidad.
            </p>
        </li>
        <li>
            <p>
                Participar en cualquier otra conducta que restrinja o inhiba el uso
                o disfrute del Sitio Web por parte de cualquier persona, o que,
                según lo determinemos, pueda perjudicar a la Empresa o a los
                usuarios del Sitio Web o exponerlos a responsabilidad.
            </p>
        </li>
    </ul>
    <p>
        Además, usted acepta no:
    </p>
    <ul>
        <li>
            <p>
                Utilizar el sitio web de cualquier forma que pueda desactivar,
                sobrecargar, dañar o perjudicar el sitio o interferir con el uso
                del sitio web por parte de terceros, incluida su capacidad de
                participar en actividades en tiempo real a través del sitio web.
            </p>
        </li>
        <li>
            <p>
                Usar cualquier robot, araña u otro dispositivo, proceso o medio
                automático para acceder al sitio web para cualquier propósito,
                incluyendo el control o la copia de cualquier material del sitio
                web.
            </p>
        </li>
        <li>
            <p>
                Utilizar cualquier proceso manual para monitorear o copiar
                cualquier material en el Sitio Web o para cualquier otro propósito
                no autorizado sin nuestro consentimiento previo por escrito.
            </p>
        </li>
        <li>
            <p>
                Utilizar cualquier dispositivo, software o rutina que interfiera
                con el correcto funcionamiento del Sitio Web.
            </p>
        </li>
        <li>
            <p>
                Introducir cualquier virus, caballo de Troya, gusano, bomba lógica
                u otro material que sea malicioso o tecnológicamente dañino.
            </p>
        </li>
        <li>
            <p>
                Intentar obtener acceso no autorizado, interferir, dañar o
                interrumpir cualquier parte del Sitio Web, el servidor en el que se
                almacena el Sitio Web, o cualquier servidor, computadora o base de
                datos conectada al Sitio Web.
            </p>
        </li>
        <li>
            <p>
                Atacar el sitio web a través de un ataque de denegación de servicio
                o un ataque de denegación de servicio distribuido.
            </p>
        </li>
        <li>
            <p>
                De lo contrario, intente interferir con el correcto funcionamiento
                del Sitio Web.
            </p>
        </li>
    </ul>
    <p>
        <u>Vigilancia y aplicación de la ley; Terminación</u>
    </p>
    <p>
        Tenemos derecho a:
    </p>
    <ul>
        <li>
            <p>
                Tomar las medidas legales correspondientes, incluyendo, sin
                limitación, la remisión a la aplicación de la ley, para cualquier
                uso ilegal o no autorizado del Sitio Web.
            </p>
        </li>
        <li>
            <p>
                Terminar o suspender su acceso a todo o parte del Sitio Web por
                cualquier violación de estas Condiciones de uso.
            </p>
        </li>
    </ul>
    <p>
        Sin limitar lo anterior, tenemos el derecho de cooperar plenamente con
        cualquier autoridad encargada de hacer cumplir la ley u orden judicial que
        nos solicite u ordene revelar la identidad u otra información de cualquier
        persona que publique cualquier material en o a través del sitio web.
        RENUNCIA Y EXIME DE RESPONSABILIDAD A LA EMPRESA DE CUALQUIER DEMANDA
        RESULTANTE DE CUALQUIER ACCIÓN EMPRENDIDA POR LA EMPRESA DURANTE O COMO
        RESULTADO DE SUS INVESTIGACIONES Y DE CUALQUIER ACCIÓN EMPRENDIDA COMO
        CONSECUENCIA DE LAS INVESTIGACIONES DE LA EMPRESA O DE LAS AUTORIDADES
        ENCARGADAS DE HACER CUMPLIR LA LEY.
    </p>
    <p>
        <u>Cambios en el Sitio Web</u>
    </p>
    <p>
        Podemos actualizar el contenido de este sitio web de vez en cuando, pero su
        contenido no es necesariamente completo o actualizado. Cualquiera de los
        materiales del Sitio Web puede estar desactualizado en un momento dado, y
        no estamos obligados a actualizar dicho material.
    </p>
    <p>
        <u>Información sobre usted y sus visitas al sitio web</u>
    </p>
    <p>
        Toda la información que recopilamos en este sitio web está sujeta a nuestra
        <a href="https://www.parkingpass.com/privacy-policy" target="_blank">
            <u>Política de privacidad.</u>
        </a>
        Al utilizar el Sitio web, usted acepta todas las acciones que tomemos con
        respecto a su información de conformidad con la Política de privacidad.
    </p>
    <p>
        <u>Enlaces desde el Sitio Web</u>
    </p>
    <p>
        Si el sitio web contiene enlaces a otros sitios y recursos proporcionados
        por terceros, estos enlaces se proporcionan únicamente para su
        conveniencia. Esto incluye enlaces contenidos en anuncios, incluyendo
        banners y enlaces patrocinados. No tenemos ningún control sobre el
        contenido de esos sitios o recursos, y no aceptamos ninguna responsabilidad
        por ellos o por cualquier pérdida o daño que pueda surgir del uso que usted
        haga de ellos. Si decide acceder a cualquiera de los sitios web de terceros
        vinculados a este sitio web, lo hará bajo su propio riesgo y sujeto a los
        términos y condiciones de uso de dichos sitios web.
    </p>
    <p>
        <u>Restricciones geográficas</u>
    </p>
    <p>
        El propietario del sitio web tiene su sede en el estado de Florida en los
        Estados Unidos. Proporcionamos este sitio web para el uso exclusivo de
        personas ubicadas en los Estados Unidos. No afirmamos que el Sitio web o
        cualquiera de sus contenidos sea accesible o apropiado fuera de los Estados
        Unidos. Es posible que el acceso al sitio web no sea legal para ciertas
        personas o en ciertos países. Si accede al Sitio Web desde fuera de los
        Estados Unidos, lo hace por iniciativa propia y será responsable del
        cumplimiento de las leyes locales.
    </p>
    <p>
        <u>Descargo de responsabilidad de garantías.</u>
    </p>
    <p>
        Usted entiende que no podemos y no garantizamos ni aseguramos que los
        archivos disponibles para ser descargados de Internet o del sitio web estén
        libres de virus u otro código destructivo. Usted es responsable de
        implementar suficientes procedimientos y puntos de control para satisfacer
        sus requisitos particulares de protección antivirus y la precisión de la
        entrada y salida de datos, y de mantener un medio externo a nuestro sitio
        para cualquier reconstrucción de cualquier dato perdido. NO SEREMOS
        RESPONSABLES DE NINGUNA PÉRDIDA O DAÑO CAUSADO POR UN ATAQUE DE DENEGACIÓN
        DE SERVICIO DISTRIBUIDO, VIRUS U OTRO MATERIAL TECNOLÓGICAMENTE DAÑINO QUE
        PUEDA INFECTAR SU EQUIPO DE CÓMPUTO, PROGRAMAS DE COMPUTACIÓN, DATOS U OTRO
        MATERIAL DE PROPIEDAD DEBIDO AL USO QUE USTED HAGA DEL SITIO WEB O DE
        CUALQUIER SERVICIO O ARTÍCULO OBTENIDO A TRAVÉS DEL SITIO WEB O A LA
        DESCARGA DE CUALQUIER MATERIAL PUBLICADO EN ÉL, O EN CUALQUIER SITIO WEB
        VINCULADO A ÉL.
    </p>
    <p>
        EL USO QUE HAGA DEL SITIO WEB, SU CONTENIDO Y CUALQUIER SERVICIO O ARTÍCULO
        OBTENIDO A TRAVÉS DEL SITIO WEB ES A SU PROPIO RIESGO. EL SITIO WEB, SU
        CONTENIDO Y CUALQUIER SERVICIO O ARTÍCULO OBTENIDO A TRAVÉS DEL SITIO WEB
        SE PROPORCIONAN "TAL CUAL" Y "SEGÚN DISPONIBILIDAD", SIN GARANTÍAS DE
        NINGÚN TIPO, YA SEAN EXPRESAS O IMPLÍCITAS. NI LA EMPRESA NI NINGUNA
        PERSONA ASOCIADA A LA EMPRESA OFRECE NINGUNA GARANTÍA O REPRESENTACIÓN CON
        RESPECTO A LA INTEGRIDAD, SEGURIDAD, FIABILIDAD, CALIDAD, EXACTITUD O
        DISPONIBILIDAD DEL SITIO WEB. SIN LIMITAR LO ANTERIOR, NI LA EMPRESA NI
        NADIE ASOCIADO A LA EMPRESA DECLARA O GARANTIZA QUE EL SITIO WEB, SU
        CONTENIDO O CUALQUIER SERVICIO O ARTÍCULO OBTENIDO A TRAVÉS DEL SITIO WEB
        SEA PRECISO, FIABLE, LIBRE DE ERRORES O ININTERRUMPIDO, QUE LOS DEFECTOS
        SEAN CORREGIDOS, QUE NUESTRO SITIO O EL SERVIDOR QUE LO PONE A DISPOSICIÓN
        ESTÉN LIBRES DE VIRUS U OTROS COMPONENTES DAÑINOS O QUE EL SITIO WEB O
        CUALQUIER SERVICIO O ARTÍCULO OBTENIDO A TRAVÉS DEL SITIO WEB SATISFAGA DE
        OTRA FORMA SUS NECESIDADES O EXPECTATIVAS.
    </p>
    <p>
        LA COMPAÑÍA RENUNCIA POR LA PRESENTE A TODA GARANTÍA DE CUALQUIER TIPO, YA
        SEA EXPRESA O IMPLÍCITA, ESTATUTARIA O DE OTRO TIPO, INCLUYENDO PERO NO
        LIMITÁNDOSE A CUALQUIER GARANTÍA DE COMERCIABILIDAD, NO INFRACCIÓN Y
        APTITUD PARA UN PROPÓSITO PARTICULAR.
    </p>
    <p>
        LO ANTERIOR NO AFECTA NINGUNA GARANTÍA QUE NO PUEDE EXCLUIRSE O LIMITARSE
        BAJO LA LEY APLICABLE.
    </p>
    <p>
        Limitación de responsabilidad.
    </p>
    <p>
        EN NINGÚN CASO LA EMPRESA, SUS FILIALES O SUS LICENCIATARIOS, PROVEEDORES
        DE SERVICIOS, EMPLEADOS, AGENTES, FUNCIONARIOS O DIRECTORES SERÁN
        RESPONSABLES POR DAÑOS DE NINGÚN TIPO, BAJO NINGUNA TEORÍA LEGAL, QUE
        SURJAN DE SU USO, O INCAPACIDAD DE USAR, EL SITIO WEB, CUALQUIER SITIO WEB
        VINCULADO A ÉL, CUALQUIER CONTENIDO DEL SITIO WEB O DE ESOS OTROS SITIOS
        WEB O CUALQUIER SERVICIO O ARTÍCULO CONTRATADO U OBTENIDO A TRAVÉS DEL
        SITIO WEB O DE ESOS OTROS SITIOS WEB, INCLUYENDO CUALQUIER DAÑO DIRECTO,
        INDIRECTO, ESPECIAL, INCIDENTAL, CONSECUENTE O PUNITIVO, INCLUYENDO PERO NO
        LIMITADO A, LESIONES PERSONALES, DOLOR Y SUFRIMIENTO, ANGUSTIA EMOCIONAL,
        PÉRDIDA DE INGRESOS, PÉRDIDA DE BENEFICIOS, PÉRDIDA DE NEGOCIOS O AHORROS
        ANTICIPADOS, PÉRDIDA DE USO, PÉRDIDA DE BUENA VOLUNTAD, PÉRDIDA DE DATOS, Y
        YA SEA CAUSADO POR AGRAVIO (INCLUYENDO NEGLIGENCIA), INCUMPLIMIENTO DE
        CONTRATO O DE OTRA FORMA, INCLUSO SI ES PREVISIBLE.
    </p>
    <p>
        LO ANTERIOR NO AFECTA A NINGUNA RESPONSABILIDAD QUE NO PUEDA SER EXCLUIDA O
        LIMITADA EN VIRTUD DEL DERECHO APLICABLE.
    </p>
    <p>
        <u>Indemnización</u>
    </p>
    <p>
        Usted acepta defender, indemnizar y eximir de responsabilidad a la Empresa,
        sus afiliados, licenciantes y proveedores de servicios, y sus respectivos
        funcionarios, directores, empleados, contratistas, agentes, licenciantes,
        proveedores, sucesores y cesionarios de y contra cualquier demanda,
        responsabilidad, daño, juicio, premios, pérdidas, costos, gastos o
        honorarios (incluidos los honorarios razonables de abogados) que surjan de
        o estén relacionados con su violación de estas Condiciones de uso, el uso
        de los servicios de la Compañía por usted, sus invitados o afiliados, o su
        uso del Sitio Web, incluyendo, pero no limitado a cualquier uso del
        contenido, servicios y productos del Sitio Web que no sea el expresamente
        autorizado en estos Términos de uso o su uso de cualquier información
        obtenida del Sitio Web.
    </p>
    <p>
        <u>Ley aplicable y jurisdicción</u>
    </p>
    <p>
        Todos los asuntos relacionados con el sitio web y estas condiciones de uso
        y cualquier disputa o demanda que surja de ellos o que esté relacionada (en
        cada caso, incluidas las disputas o reclamaciones no contractuales), se
        regirán e interpretarán de acuerdo con las leyes internas del Estado de
        Florida sin dar efecto a ninguna elección o conflicto de disposiciones o
        normas legales (ya sea del Estado de Florida o de cualquier otra
        jurisdicción).
    </p>
    <p>
        Cualquier demanda, acción o procedimiento legal que surja o esté
        relacionado con estas condiciones de uso o con el sitio web se iniciará
        exclusivamente en los tribunales federales de los Estados Unidos o en los
        tribunales del Estado de Florida, en cada caso ubicados en la ciudad de
        Fort Lauderdale y el condado de Broward. Usted renuncia a cualquier
        objeción al ejercicio de la jurisdicción sobre usted por esos tribunales y
        a la jurisdicción en esos tribunales.
    </p>
    <p>
        <u>Limitación del tiempo para presentar reclamos</u>
    </p>
    <p>
        CUALQUIER CAUSA DE ACCIÓN O RECLAMO QUE USTED PUEDA TENER DERIVADA DE LOS
        SERVICIOS PRESTADOS POR LA EMPRESA O RELACIONADA CON ESTAS CONDICIONES DE
        USO O EL SITIO WEB DEBE INICIARSE EN EL PLAZO DE UN (1) AÑO DESPUÉS DE QUE
        SE ACUMULE LA CAUSA DE ACCIÓN, DE LO CONTRARIO, USTED ACEPTA QUE DICHA
        CAUSA DE ACCIÓN O RECLAMO PRESCRIBA DE FORMA PERMANENTE.
    </p>
    <p>
        <u>Exención y divisibilidad</u>
    </p>
    <p>
        Ninguna renuncia por parte de la Compañía a ninguno de los términos o
        condiciones establecidos en estas Condiciones de uso se considerará como
        una renuncia adicional o continua a dicho término o condición o una
        renuncia a cualquier otro término o condición, y cualquier incumplimiento
        por parte de la Compañía de la afirmación de un derecho o disposición en
        virtud de estas Condiciones de uso no constituirá una renuncia a dicho
        derecho o disposición.
    </p>
    <p>
        Si una corte u otro tribunal de jurisdicción competente considera que
        alguna disposición de las presentes condiciones de uso es inválida, ilegal
        o inaplicable por cualquier motivo, dicha disposición se eliminará o
        limitará al mínimo necesario para que las restantes disposiciones de las
        condiciones de uso continúen en pleno vigor y efecto.
    </p>
    <p>
        <u>Acuerdo completo</u>
    </p>
    <p>
        Las Condiciones de Uso y nuestra Política de Privacidad constituyen el
        único y completo acuerdo entre usted y la Compañía con respecto al Sitio
        Web y reemplazan todos los entendimientos, acuerdos, representaciones y
        garantías previas y contemporáneas, tanto escritas como orales, con
        respecto al Sitio Web.
    </p>
    <p>
        <u>Sus comentarios e inquietudes</u>
    </p>
    <p>
        Todas las retroalimentaciones, comentarios, solicitudes de apoyo técnico y
        otras comunicaciones relacionadas con el sitio web deben dirigirse a:
        support@parkingpass.com
    </p>
    <p>
        Al aceptar los términos de servicio, usted reconoce que está de acuerdo en
        estar obligado y aceptar las condiciones de uso.
    </p>

</div>

<div class="col" *ngIf="lang  == 'ht-ht'">

  <h3>
    Tèm ak Kondisyon
  </h3>

    <p>
        Dènye modifikasyon: 2 novanm 2020
    </p>
    <p>
        Akseptasyon Regleman pou Itilizasyon yo
    </p>
    <p>
        Kondisyon pou itilizasyon sa yo konkli avèk epi ant konpayi jesyon
        pwopriyete ou a, sou pwòp non li, ofisye li yo, direktè, anplwaye,
        kontraktè, ajan, ak afilye (kolektivman, "<strong>Ou</strong>"), ak Union
        Rich Technologies, LLC d / b / a ParkingPass.com ("<strong>Konpayi</strong>
        ", "<strong>nou</strong>" oswa "<strong>nou</strong> <strong>menm</strong>
        "). Tèm ak kondisyon sa yo, ansanm ak nenpòt ki dokiman yo ekspreseman
    enkòpore pa referans (kolektivman, "    <strong>Regleman pou Itilizasyon</strong>" sa yo), ap dirije aksè ou a ak
        itilizasyon ou pou
        <a href="https://www.parkingpass.com/" target="_blank">
            <u><strong>https://www.parkingpass.com/</strong></u>
        </a>
        , ki gen ladan tou nenpòt kontni, fonksyonalite ak sèvis yo ofri sou oswa
        atravè
        <a href="https://www.parkingpass.com/" target="_blank">
            <u><strong>https://www.parkingpass.com/</strong></u>
        </a>
        ("<strong>Sit wèb la</strong>"), ke ou se yon envite oswa yon itilizatè ki
        anrejistre.
    </p>
    <p>
        Tanpri, li Regleman pou Itilizasyon yo ak anpil atansyon anvan ou kòmanse
    sèvi ak Sit wèb la. <strong>Lè w’ sèvi avèk sit wèb la, l</strong><strong>èw</strong><strong> siyen anba a, oswa l</strong>    <strong>èw</strong><strong> klike sou aksepte oswa dakò ak </strong>
        <strong>
            <a href="https://www.parkingpass.com/privacy-policy" target="_blank">
                Privacy Policy
            </a>
        </strong>
        <strong>nou l</strong>
        <strong>è</strong>
        <strong>
            yo mete opsyon sa a disponib pou ou, ou aksepte epiw dakò pou koube
            anba epi respekte Regleman pou Itilizasyon sa yo ak
        </strong>
        <strong>
            <a href="https://www.parkingpass.com/privacy-policy" target="_blank">
                Privacy Policy
            </a>
        </strong>
        <strong>nou yo, ke wap jwenn nan adr</strong>
        <strong>è</strong>
        <strong>
            s https://www.parkingpass.com/privacy-policy/, enkòpore isit la pa
            referans.
        </strong>
        Si ou pa dakò pou koube anba Regleman pou Itilizasyon sa yo oswa ou
        respekte politik enfòmasyon prive a, ou pa dwe antre sit wèb la oswa
        itilize l’. Sit wèb sa a se pou itilizatè ki gen 18 lane oswa plis epi ki
        abite Ozetazini oswa nenpòt nan teritwa li yo osinon byen li yo. Lè w
        itilize sit entènèt sa a, ou deklare epi ou garanti ke ou gen laj legal yo
        fòme yon kontra obligatwa ak Konpayi an epi ranpli tout kondisyon
        kalifikasyon ki sot site yo pi wo a. Si ou pa ranpli tout kondisyon sa yo,
        ou pa dwe rantre sou sit wèb la oswa itilize l’.
    </p>
    <p>
        <u>Chanjman nan Regleman pou Itilizasyon yo</u>
    </p>
    <p>
        Nou ka revize ak mete ajou kondisyon pou Itilizasyon sa yo de tan zan tan
        nan fason pa nou. Tout modifikasyon yo ap antre nan fonksyon imedyatman nou
        pibliye yo epi yap aplike nan tout aksè ak itilizasyon Sit wèb la aprè sa.
    </p>
    <p>
        Si ou kontinye ap Itilize sit wèb la prè Regleman pou Itilizasyon revize yo
        fin pibliye, sa vle di ke ou aksepte epi ou dakò ak chanjman yo. Ou dwe
        tcheke paj sa a de tan zan tan lè ou antre sou sit entènèt sa a pou ou
        okouran de nenpòt ki chanjman, paske yo <u>angajew</u><u>.</u>
    </p>
    <p>
        <u>Aksè sou sit wèb la ak sekirite kont lan</u>
    </p>
    <p>
        Nou rezève nou dwa pou retire oswa amande sit entènèt sa a, ak nenpòt sèvis
        oswa materyèl nou ofri sou sit entènèt la, nan fason pa nou epi san
        avètisman. Nou pa responsab si pou nenpòt ki rezon tout osinon nenpòt ki
        pati nan sit wèb la pa disponib nan nenpòt ki lè oswa nan nenpòt ki peryòd.
        De tan zan tan, nou ka mete restriksyon sou aksè a kèk pati nan sit wèb la,
        osinon sou tout sit wèb la, sou itilizatè yo, ki ka gen ladan itilizatè ki
        te anrejistre yo tou.
    </p>
    <p>
        Ou responsab pou:
    </p>
    <p>
        • Pran tout dispozisyon ki nesesè pou ou gen aksè a Sit wèb la.
    </p>
    <p>
        • Asire w’ ke tout moun ki itilize koneksyon entènèt ou pou antre sou sit
        wèb la okouran de Regleman pou Itilizasyon sa yo epi respekte yo.
    </p>
    <p>
        Pou jwenn aksè a sit wèb la osinon kèk nan resous li ofri yo, yo ka mande w
        bay sèten detay enskripsyon oubyen lòt enfòmasyon. Kondisyon pou ou itilize
        sit wèb la koube anba regleman ke tout enfòmasyon ou bay sou sit wèb la dwe
        kòrèk, aktyèl epi konplè. Ou dakò ke tout enfòmasyon ou bay pou enskri sou
        sit entènèt sa a oswa otreman, ki gen ladan men pa limite a sa sèlman,
        itilizasyon nan nenpòt ki karakteristik entèraktif sou sit entènèt la, ki
        reji pa
        <a href="https://www.parkingpass.com/privacy-policy" target="_blank">
            <u>Privacy Policy</u>
        </a>
        nou yo epi ou aksepte tout mezi nou pran ki gen rapò ak enfòmasyon ou yo
        jan sa ye nan Règleman sou enfòmasyon prive nou<u> yo.</u>
    </p>
    <p>
        Si ou chwazi, oswa yo baw, yon non itilizatè, yon modpas oswa nenpòt lòt
        enfòmasyon kòm yon pati nan pwosedi sekirite nou an, ou dwe kenbe
        enfòmasyon sa yo konfidansyèl, epi ou pa dwe divilge yo bay nenpòt lòt moun
        oswa antite. Ou rekonèt tou ke kont ou a pèsonèl ou epi ou dakò pou pa bay
        nenpòt lòt moun aksè sèvi avèk non itilizatè ou a, modpas oswa lòt
        enfòmasyon sekirite ou pou antre sou sit entènèt sa a oswa yon pòsyon
        ladan. Ou dakò avèti nou imedyatman sou nenpòt ki aksè san otorizasyon de
        non itilizatè ou a oswa modpas oswa nenpòt lòt vyolasyon sekirite. Ou dakò
        tou pou asire ke ou sòti sou kont ou aprè chak sesyon. Ou dwe pran
        prekosyon patikilye lè ou antre sou kont ou nan yon òdinatè piblik oswa ki
        pataje pou lòt moun yo pa kapab wè oswa anrejistre modpas ou oswa lòt
        enfòmasyon pèsonèl ou.
    </p>
    <p>
        Nou gen dwa dezaktive nenpòt ki non itilizatè, modpas oswa lòt idantifyan,
        ke ou menm osinin nou menm te chwazi, nan nenpòt ki lè nan fason pa nou pou
        nenpòt ki rezon oswa san rezon, ki gen ladan si, daprè nou menm, ou te
        vyole nenpòt ki dispozisyon nan Regleman pou Itilizasyon sa yo.
    </p>
    <ul>
        <li>
            <p>
                Dwa pwopriyete entelektyèl
            </p>
        </li>
    </ul>
    <p>
        Sit wèb la ak tout kontni li yo, karakteristik ak fonksyonalite li yo (ki
        gen ladan men ki pa limite a sa sèlman, tout enfòmasyon, lojisyèl, tèks,
        afichaj, imaj, videyo ak odyo, ak konsepsyon, seleksyon an ak aranjman), se
        pou Konpayi a, lisansye li yo oswa lòt founisè nan materyèl li yo epi yo
        pwoteje pa lwa Etazini ak entènasyonal sou dwa otè, mak, patant, sekrè
        komès ak lòt pwopriyete entèlektyèl oswa lwa sou dwa pwopriyetè.
    </p>
    <p>
        Regleman pou Itilizasyon sa yo pèmèt ou itilize sit wèb la pou rezon
        pèsonèl ou, ki pa komèsyal, ki pa konkiransyèl, ki sèlman konfidansyèl. Sit
        wèb la ak kòd kache li yo konstitye sekrè yon komès. Ou pa dwe repwodui,
        distribye, modifye, kreye travay derive, montre piblikman, egzekite
        piblikman, repibliye, telechaje, pataje ak moun/pati ki pa otorize, estoke
        oswa transmèt (i) nenpòt materyèl la sou sit entènèt nou an, (ii) nenpòt
        nan kòd ki kache pou Sit wèb la, eksepte nan fason sa yo:
    </p>
    <p>
        • Odinatè ou a ka estoke tanporèman kopi materyèl sa yo nan RAM nan aksè ak
        vizyalizasyon materyèl sa.
    </p>
    <p>
        • Ou ka estoke dosye ki otomatikman mete an kachèt pa navigatè entènèt ou
        pou rezon amelyorasyon afichaj.
    </p>
    <p>
        • Ou ka enprime oswa telechaje yon kopi yon kantite rezonab nan paj sit wèb
        la pou itilizasyon pèsonèl ou, ki pa komèsyal itilize epi yo pa pou
        repwodiksyon, piblikasyon oswa distribisyon.
    </p>
    <p>
        • Si nou ofri aplikasyon biwo, mobil oswa lòt aplikasyon pou telechaje, ou
        ka telechaje yon kopi sèl nan òdinatè w lan oswa aparèy mobil sèlman pou
        itilizasyon pèsonèl ou, ki pa komèsyal itilize, ak kondisyon ke ou dakò pou
        koube anba akò lisans itilizatè final nou an pou aplikasyon sa yo.
    </p>
    <p>
        Ou pa dwe:
    </p>
    <p>
        • Modifye kopi nenpòt materyèl nan sit sa a.
    </p>
    <p>
        • Sèvi ak nenpòt ilistrasyon, foto, sekans videyo oswa odyo oswa nenpòt
        grafik separeman ak tèks ki akonpaye li a.
    </p>
    <p>
        • Efase oswa chanje nenpòt avi dwa otè, mak oswa lòt avi dwa pwopriyetè ki
        soti nan kopi materyèl nan sit sa a.
    </p>
    <p>
        Ou pa dwe itilize pou nenpòt rezon komèsyal nenpòt pati nan sit wèb la oswa
        nenpòt sèvis osinon materyèl ki disponib sou sit wèb la.
    </p>
    <p>
        Si ou vle fè nenpòt ki sèvi ak materyèl sou sit wèb la pou lòt rezon kip a
        nan sa ki tabli nan seksyon sa a, tanpri adrese demann ou an nan:
    </p>
    <p>
        Si ou enprime, kopye, modifye, telechaje oswa otreman itilize oswa bay
        nenpòt lòt moun aksè a nenpòt ki pati nan sit wèb la nan vyolasyon
        kondisyon pou Itilizasyon yo, dwa ou genyen pou sèvi ak sit entènèt la ap
        sispann imedyatman epi ou dwe, nan fason pa nou, retounen oswa detwi nenpòt
        kopi materyèl ou te fè yo. Yo pap transferew okenn dwa, tit oswa enterè sou
        sit wèb la oswa nenpòt ki kontni sou sit wèb la, epi tout dwa ki
        ekspreseman akòde yo rezève a Konpayi a. Nenpòt itilizasyon sit wèb la ki
        pa ekspreseman otorize pa Regleman pou Itilizasyon sa yo se yon vyolasyon
        Kondisyon pou Itilizasyon yo epi yo ka vyole dwa otè, mak ak lòt lwa yo.
    </p>
    <p>
        <u>Mak</u>
    </p>
    <p>
        Non Konpayi an, logo Konpayi a ak tout lòt non, logo, non pwodwi ak sèvis,
        desen ak slogan ki gen rapò ak Konpayi a se mak komèsyal Konpayi an oswa
        afilye li yo oswa lisansye yo. Ou pa dwe itilize mak sa yo san pèmisyon
        ekri alavans nan konpayi a. Tout lòt non, logo, pwodwi ak non sèvis, desen
        ak slogan sou sit entènèt sa a se mak komèsyal mèt pwopriyete respektif yo.
    </p>
    <p>
        <u>Itilizasyon Entèdi</u>
    </p>
    <p>
        Ou ka itilize Sit wèb la sèlman pou rezon legal nan akò avèk Regleman pou
        Itilizasyon sa yo. Ou dakò pa sèvi ak sit wèb la:
    </p>
    <p>
        • Nan nenpòt fason ki vyole nenpòt ki lwa federal aplikab, eta, lokal oswa
        entènasyonal oswa règleman (ki gen ladan, men ki pa limite ak sa sèlman,
        nenpòt lwa konsènan ekspòtasyon done oswa lojisyèl pou ale ak pou soti US
        oswa lòt peyi yo).
    </p>
    <p>
        • Nan bi pou yo eksplwate, nwi oswa eseye mal eksplwate oswa minè nan
        nenpòt fason lè yo ekspoze a kontni ki pa apwopriye, mande yo pou
        enfòmasyon pèsonèl ki idantifyab oswa otreman.
    </p>
    <p>
        • Pou transmèt, oswa voye nan nenpòt ki piblisite oswa materyèl pwomosyonèl
        [san konsantman ekri nou an avan], ki gen ladan nenpòt "kourye ki
        endezyrab", "chèn lèt " oswa "Spam" osinon nenpòt lòt solitasyon menm jan
        an.
    </p>
    <p>
        • Pou imite oswa eseye imite Konpayi an, yon anplwaye Konpayi, yon lòt
        itilizatè oswa nenpòt lòt moun oswa antite.
    </p>
    <p>
        • Pou angaje nan nenpòt lòt kondwit ki gen restriksyon oswa ki anpeche
        nenpòt moun itilize oswa jwi Sit wèb la, oswa ki, selon nou, ka nwi Konpayi
        a oswa itilizatè sit wèb la oswa ekspoze yo a kèk responsablite.
    </p>
    <p>
        Anplis de sa, ou dakò pa:
    </p>
    <p>
        • Sèvi ak sit wèb la nan nenpòt fason ki ta ka dezaktive, twò chaje,
        domaje, oswa deteryore oswa entèfere l’ ak itilizasyon Sit la pa nenpòt lòt
        pati, ki gen ladan kapasite pou angaje yo nan aktivite tan reyèl nan sit
        wèb la.
    </p>
    <p>
        • Sèvi ak nenpòt ki robo, areye oswa lòt aparèy, pwosesis oswa mwayen
        otomatik pou rantre sou sit wèb la pou nenpòt ki rezon, ki gen ladan
        siveyans oswa kopye nenpòt nan materyèl la sou sit entènèt la.
    </p>
    <p>
        • Sèvi ak nenpòt pwosesis manyèl pou kontwole oswa kopye nenpòt nan
        materyèl ki sou sit wèb la oswa pou nenpòt lòt rezon san otorizasyon san
        konsantman ekri nou anvan.
    </p>
    <p>
        • Sèvi ak nenpòt aparèy, lojisyèl oswa woutin ki entèfere ak bon
        fonksyonman sit wèb la.
    </p>
    <p>
        • Entwodwi nenpòt viris, chwal Trojan, vè, bonm lojik oswa lòt move
        materyèl oswa ki danjere teknolojikman.
    </p>
    <p>
        • Eseye jwenn aksè san otorizasyon nan, entèfere ak, domaj oswa deranje
        nenpòt pati nan sit wèb la, sèvè a ki te sou sit wèb la ki estoke, oswa
        nenpòt sèvè, òdinatè osinon baz done ki konekte nan sit wèb la.
    </p>
    <p>
        • Atake sit wèb la atravè yon atak refi-sèvis oswa yon atak distribye
        refi-sèvis.
    </p>
    <p>
        • Sinon eseye entèfere bon fonktyonman Sit wèb la.
    </p>
    <p>
        <u>Siveyans ak Ranfòsman; Revokasyon</u>
    </p>
    <p>
        Nou gen dwa pou:
    </p>
    <p>
        • Pran aksyon legal ki apwopriye, ki gen ladan, men ki pa limite a sa
        sèlman, referans a sèvis ki fè respekte lalwa, pou nenpòt ki itilizasyon
        ilegal oswa san otorizasyon nan sit wèb la.
    </p>
    <p>
        • Mete fen oswa sispann aksè ou nan tout oswa yon pati nan sit wèb la pou
        nenpòt nan vyolasyon Regleman pou Itilizasyon sa yo.
    </p>
    <p>
        San yo pa limite ak sa ki ekri yo, nou gen dwa pou kolabore konplètman avèk
        nenpòt otorite ki la pou fè respekte lalwa oswa lòd tribinal ki mande oswa
        dirije nou divilge idantite a oswa lòt enfòmasyon sou nenpòt moun ki afiche
        nenpòt materyèl sou oswa atravè sit wèb la. OU RENONSE AK TOUT REKLAMASYON
        KI SOTI NAN AKSYON KE KONPAYI A PRAN AVAN OUBYEN APRÈ ANKÈT LI SOU TOUT
        ASKSYON KE KONPAYI A OSINON OTORITE KI LA POU FÈ APLIKE LALWA YO TE PRAN.
    </p>
    <p>
        <u>Chanjman nan sit wèb la</u>
    </p>
    <p>
        Nou ka mete ajou kontni ki sou sit entènèt sa a de tan zan tan, men kontni
        li yo pa nesesèman konplè oswa ajou. Nenpòt nan materyèl ki sou sit wèb la
        ka ekpire nenpòt ki lè, epi nou pa gen okenn obligasyon mete materyèl sa yo
        ajou.
    </p>
    <p>
        <u>Enfòmasyon sou ou ak sou vizitè Sit web la</u>
    </p>
    <p>
        Tout enfòmasyon nou kolekte sou sit wèb sa a sijè a Règleman sou
        <u>
            <a href="https://www.parkingpass.com/privacy-policy" target="_blank">
                Privacy Policy
            </a>
        </u>
        nou yo. Lè w sèvi avèk Sit wèb la, ou konsanti a tout mezi nou pran ki gen
        rapò ak enfòmasyon ou an konfòmite avèk Règleman sou enfòmasyon prive nou
        yo.
    </p>
    <p>
        <u>Lyen pou Site web la</u>
    </p>
    <p>
        Si Sit wèb la gen lyen ki mennen nan lòt sit ak resous ki ofri pa twazyèm
        pati, lyen sa yo yo bay pou konvenyans ou sèlman. Sa gen ladan lyen ki
        genyen nan piblisite yo, ki gen ladan banyè piblisite ak lyen patwone yo.
        Nou pa gen okenn kontwòl sou sa ki nan sit sa yo oswa resous, epi nou pa
        aksepte okenn responsablite pou yo oswa pou nenpòt ki pèt oswa domaj ki ka
        soti nan sèvi ak yo. Si ou deside jwenn aksè nan nenpòt nan sit entènèt
        twazyèm-pati yo ki lye nan sit entènèt sa a, ou fè sa antyèman sou pwòp
        risk ou jan sa ye nan tèm ak kondisyon pou itilizasyon pou sit entènèt sa
        yo.
    </p>
    <p>
        <u>Restriksyon jewografik</u>
    </p>
    <p>
        Pwopriyetè sit entènèt la baze nan eta Florid Ozetazini. Nou bay sit
        entènèt sa a pou itilize sèlman pa moun kap viv Ozetazini. Nou pa fè okenn
        reklamasyon ke Sit wèb la oswa nenpòt nan kontni li yo aksesib oswa
        apwopriye deyò Etazini. Aksè sou sit wèb la pa kapab legal pou sèten moun
        oswa nan sèten peyi. Si ou jwenn aksè nan sit wèb la pandan ou deyò
        Etazini, ou fè sa sou pwòp inisyativ ou epi ou responsab pou konfòmite avèk
        lwa lokal yo.
    </p>
    <p>
        <u>Limit responsabilite nou nan garanti</u>
    </p>
    <p>
        Ou konprann ke nou pa ka epi nou pa garanti ke dosye ki disponib pou
        telechaje sou entènèt la oswa sou sit wèb la pap gen viris oswa lòt kòd
        destriktif. Ou responsab pou aplike tout pwosedi ak kontwòl sifizan pou
        reponn ak kondisyon patikilye ou pou pwoteksyon anti-viris ak presizyon nan
        done kap rantre ak sòti, ak pou kenbe yon mwayen ekstèn sou sit nou an pou
        rekonstriksyon nan nenpòt ki done pèdi. NOU PAP RESPONSAB POU OKENN PÈT
        OSWA DOMAJ KI SOTI NAN YON ATAK KI DISTRIBUYE, VIRIS OUBYEN NENPÒT LÒT
        MATERYÈL KI NWIZIB TEKNOLOJIKMAN KI KA ENFEKTE EKIPMAN KONPITÈ OU, PWOGRAM
        KONPITÈ, DONE OSWA LÒT MATERYÈL, PASKE OU TAP ITILIZE SIT LA OUBYEN TOUT
        SÈVIS OSINON TOUT ELEMAN PA ENTÈMEDYÈ DE SIT LA OUBYEN TELECHAJMAN NENPÒT
        MATERYÈL KI SOU SIT LA OSINON SOU NENPÒT SIT KI LYE AK LI.
    </p>
    <p>
        ITILIZASYON OU SOU SIT WÈB LA, KONTNI LI AK NENPOT KI SÈVIS OSWA ATIK KI TE
        JWENN ATRAVÈ SIT WÈB LA SE SOU PWOP RISK OU. SIT ENTÈNÈT LA, KONTNI LI YO
        AK NENPÒT SÈVIS OUBYEN ATIK OUBYEN TRAVAY OU JWENN SOU SIT ENTÈNÈT LA YO
        OFRI SOU YON BAZ "KI KONSA" AK "SELON DISPONIBILITE", SAN PA GEN GARANTI
        SOU NENPÒT FÒM. NI KONPAYI A NI OKENN MOUN KI ASOSYE AK KONPAYI A PA FÈ
        OKENN GARANTI OUBYEN REPREZANTASYON AK RESPÈ POU ENTÈNÈT, SEKIRITE,
        FYABITE, KALITE, PRESIZASYON OSWA DISPONIBILITE SIT ENTÈNÈT LA. SAN YO PA
        LIMITE AK SA KI EKRI LA A SÈLMAN, NI KONPAYI AN NI OKENN LÒT MOUN KI ASOSYE
        AK KONPAYI A REPREZANTE OSWA GARANTI KE SIT ENTENET LA, KONTNI LI OSWA
        NENPOT SÈVIS OSWA ATIK KI TE JWENN ATRAVE SIT WÈB LA PRAL EGZAT, FYAB, SAN
        ERÈ OSWA SAN ENTÈRIPSYON, KE DEFO YO PRAL KORIJE, KE SIT NOU OSWA SERVEUR
        KI FÈ LI DISPONIB YO PA GEN VIRIS OSWA ELEMAN NWIZIB OUBYEN SIT ENTÈNÈT LA
        OUBYEN SÈVIS LI OFRI YO OSWA KI GEN SOU SIT ENTÈNÈT LA AP REPONN A BEZWEN
        OU ATANT OU.
    </p>
    <p>
        KONPAYI A DECHAJE LI DE TOUT GARANTI NENPÒT KALITE LI, KI ESPRÈS LI OSWA SA
        LI ENPLIKE, KIT LI LEGAL OUBYÈ LÒT NATI, KI GEN LADAN MEN KI PA LIMITE A SA
        SÈLMAN OKENN GARANTI SOU FÒM KOMÈSYAL, KI PA ENFRAKSYON AK ANFOM POU BI
        PATIKILYE.
    </p>
    <p>
        SA KI EKRI LA A PA AFEKTE OKENN GARANTI KI PA KA EKSKLI OSWA LIMITE SELON
        LWA KI APLIKAB YO.
    </p>
    <p>
        <u>Limit sou Responsablite</u>
    </p>
    <p>
        NAN OKENN KA, KONPAYI AN, AFILYE LI YO OSWA LISANSYE YO, FOUNISE SÈVIS YO,
        ANPLWAYE YO, AJAN YO, OFISYE YO OSWA DIREKTE YO PA PRAL SIBI POU DOMAJ
        NENPOT KALITE LI, ANBA NENPOT TEYORI LEGAL, KI SOTI NAN KONEKSYON OSWA
        ENKAPASITEPOU ITILIZE SIT ENTÈNÈT LA, NENPÒT LOT SIT ENTÈNÈT KI LIYE AVÈK
        LI, NENPÒT KONTNI KI SOU SIT ENTÈNÈT LA OUBYEN LÒT SIT ENTÈNÈT KI LYE AK LI
        YO OUBYEN NENPÒT SÈVIS OSWA ATIK KI KONTRAYE ATRAVÈ SIT ENTÈNÈT LA OUBYEN
        LÒT SIT ENTÈNÈT, ENKLI NENPÒT DIRÈK, ENDIRÈK, ESPESYAL, ENSIDAN, KONSÈNAN
        OU KONSESYAN , KI GEN LADAN MEN KI PA LIMITE A SA SELMAN, DOMAJ DIRÈK,
        ENDIRÈK, ESPESYAL, DOULE AK SOUFRANS, EMOSYONEL DEZAVANTAJ, PÈT NAN REVNI,
        PET NAN PWOFI, PÈDI NAN BIZNIS OSWA EKONOMI KI TE PREVWA, PÈT NAN
        ITILIZASYON, PÈT KLIYAN, PÈT DONE, KIT LI TE KOZE PA TÒ ( KI GEN LADAN
        NEGLIJANS), VYOLASYON KONTRA OUBYEN LÒT MENM, MENM SI YO PREVIZIB.
    </p>
    <p>
        SA KI EKRI A PA AFEKTE OKENN RESPONSABLITE KI PA KA EKSKLI OSWA LIMITE
        SELON LWA KI APLIKAB.
    </p>
    <p>
        <u>Konpansasyon</u>
    </p>
    <p>
        Ou dakò defann, dedomaje epi kenbe inonsan Konpayi an, afilye li yo,
        lisansye ak founisè sèvis, ak ofisye respektif li yo, direktè, anplwaye,
        kontraktè, ajan, lisansye, founisè, siksesè ak asiyen li yo kont nenpòt
        reklamasyon, réskonsablité, domaj, jijman, prim, pèt, depans, oswa frè (ki
        gen ladan frè avoka rezonab) ki rive soti nan oswa ki gen rapò ak vyolasyon
        ou de Regleman pou Itilizasyon sa yo, itilizasyon w de sèvis konpayi a,
        envite ou, oswa afilye, oswa itilizasyon ou de sit wèb la, ki gen ladan,
        men ki pa limite a sa sèlman nenpòt ki itilizasyon kontni sit wèb la, sèvis
        ak pwodwi yon lòt fason ke jan li otorize nan Regleman pou Itilizasyon sa
        yo oswa itilizasyon ou nan nenpòt enfòmasyon ki gen nan sit wèb la.
    </p>
    <p>
        <u>Gouvènman Lwa ak jiridiksyon</u>
    </p>
    <p>
        Tout zafè ki gen rapò ak Sit wèb la ak Regleman pou Itilizasyon sa yo ak
        nenpòt dispit oswa reklamasyon ki rive ladan yo oswa ki gen rapò ak li (nan
        chak ka, ki gen ladan diskisyon ki pa nan kontra oswa reklamasyon), dwe
        dirije ak entèprete an akò ak lwa entèn yo nan Eta nan Florid la san yo pa
        bay okenn chwa oswa konfli nan dispozisyon lalwa oswa règ yo(si nan Eta
        Florid oswa nenpòt lòt jiridiksyon).
    </p>
    <p>
        Nenpòt pwosè legal, aksyon oswa pwosedi ki rive soti nan, oswa ki gen rapò
        ak, Regleman pou Itilizasyon sa yo oswa sit entènèt la dwe angaje sèlman
        nan tribinal federal yo nan Etazini oswa tribinal yo nan Eta Florid nan
        chak ka ki sitye nan vil la nan Fort Lauderdale ak Konte Broward. Ou
        renonse ak nenpòt ak tout objeksyon nan fè egzèsis la nan jiridiksyon sou
        ou pa tribinal sa yo ak pidevan nan tribinal sa yo.
    </p>
    <p>
        <u>Limitasyon sou tan pou ou fè reklamasyon yo</u>
    </p>
    <p>
        <a name="_GoBack"></a>
        NENPÒT KOZ DE AKSYON OUBYEN REKLAMASYON OU KA GENYEN KI GEN RAPÒ AK SÈVIS
        KONPAYI A BAY OUBYEN RELASYON AK TÈM ITILIZASYON SA OUBYEN SIT ENTÈNÈT LA
        DWE KÒMANSE NAN YON (1) ANE APRÈ KOZ AKSYON AN, SINON OU DAKO KE KOZ AKSYON
        OSWA REVANDIKASYON SA AP PRESKRI PÈMANAN.
    </p>
    <p>
        <u>Esepsyon ak divizibilite</u>
    </p>
    <p>
        Pa gen okenn egzansyon nan Konpayi a nenpòt tèm oswa kondisyon ki tabli nan
        Regleman pou ItilizASYON sa yo pa dwe konsidere kòm yon egzansyon plis oswa
        kontinyèl nan tèm sa yo oswa kondisyon osinon yon egzansyon nan nenpòt ki
        lòt tèm oubyen kondisyon, ak nenpòt echèk nan Konpayi a revandike yon dwa
        oswa dispozisyon anba kondisyon sa yo nan Itilize pa dwe konstitye yon
        egzansyon pou dwa sa yo oswa dispozisyon.
    </p>
    <p>
        Si yon tribinal oubyen yon lòt tribinal ki gen jiridiksyon konpetan kenbe
        nenpòt dispozisyon nan kondisyon itilizasyon sa yo pou yo valab, ilegal
        oswa enposib pou nenpòt ki rezon, dispozisyon sa yo dwe elimine oswa limite
        nan limit minimòm sa yo ke dispozisyon Regleman pou itilizasyon ki rete yo
        ka kontinye aplike nan tout fòs ak efè yo.
    </p>
    <p>
        <u>Tout Akò</u>
    </p>
    <p>
        Regleman pou Itilizasyon ak Règleman sou Konfidansyalite nou an konstitye
        sèl ak inik akò ant ou menm ak konpayi an ki gen rapò ak sit entènèt la epi
        ranplase tout akò anvan ak kontanporen, deklarasyon, reprezantasyon ak
        garanti, ni ekri ni pale, ki gen rapò ak sit entènèt la.
    </p>
    <p>
        <u>Kòmantè ou ak enkyetid</u>
    </p>
    <p>
        Tout fidbak, kòmantè, demann pou sipò teknik ak lòt kominikasyon ki gen
    rapò ak Sit wèb la ta dwe dirije nan:    <a href="mailto:support@parkingpass.com">support@parkingpass.com</a>
    </p>
    <p>
        Lè ou dakò ak kondisyon ki sèvis yo, w ap rekonèt epi ou aksepte pou
        fonksyone selon kondisyon pou Itilizasyon ki nan dokiman sa.
    </p>

</div>

<div class="col" *ngIf="lang  == 'pt-pt'">

  <h3>
    Termos e Condições
  </h3>

    <p>
        Última modificação: 2 de Novembro de 2020
    </p>
    <p>
        <u>Aceitação dos Termos de Utilização</u>
    </p>
    <p>
        <a name="OLE_LINK11"></a>
        <a name="OLE_LINK12"></a>
        <a name="OLE_LINK9"></a>
        <a name="OLE_LINK10"></a>
        Estes termos de utilização foram estabelecidos pela sua empresa de gestão
        imobiliária, em seu nome, pelos seus administradores, diretores,
    empregados, contratantes, agentes e afiliados (coletivamente, "    <strong>Você</strong>"), e Union Rich Technologies, LLC d/b/a
        ParkingPass.com ("<strong>Empresa</strong>" ou "<strong>nós</strong>"). Os
        termos e condições que se seguem, juntamente com quaisquer documentos que
        estejam expressamente incorporados através de referência (coletivamente,
        estes "<strong>Termos de Utilização</strong>"), regem o seu acesso e
        utilização de <u>https://www.parkingpass.com/,</u> incluindo quaisquer
    conteúdos, funcionalidades ou serviços oferecidos em ou através de    <u>https://www.parkingpass.com/</u> (o "<strong>Website</strong>"), seja
        como convidado ou como utilizador registado.
    </p>
    <p>
        Por favor, leia atentamente os Termos de Utilização antes de começar a
        utilizar o Website.
        <strong>
            Ao utilizar o Website, assinando abaixo, ou ao clicar em aceitar ou
            concordar com os Termos de Utilização assim que esta opção lhe for
            disponibilizada, o utilizador aceita e concorda em estar vinculado e
            respeitar estes Termos de Utilização e a nossa
        </strong>
        <strong>Política de Privacidade</strong>
        <strong>
            , encontrada em https://www.parkingpass.com/privacy-policy/, aqui
            incorporada através de referência.
        </strong>
        Se não concordar com estes Termos de Utilização ou com a Política de
        Privacidade, não deverá aceder ou utilizar o Website. Este Website está
        disponível para utilizadores com 18 anos ou mais e que residam nos Estados
        Unidos ou em qualquer um dos seus territórios ou possessões. Ao utilizar
        este Website, declara e garante que tem idade legal para formar um contrato
        vinculativo com a Empresa e cumprir todos os requisitos de elegibilidade
        anteriores. Se não cumprir todos estes requisitos, não deverá aceder ou
        utilizar o Website.
    </p>
    <p>
        <u>Alterações aos Termos de Utilização</u>
    </p>
    <p>
        Podemos rever e atualizar estes Termos de Utilização periodicamente, a
        nosso exclusivo critério. Todas as alterações entram imediatamente em vigor
        assim que as publicamos e aplicam-se a todo o acesso e utilização do
        Website a partir daí.
    </p>
    <p>
        O uso contínuo do Website após a publicação dos Termos de Utilização
        alterados significa que aceita e concorda com as alterações. Espera-se que
        verifique esta página esporadicamente quando aceder a este Website, de modo
        a estar ciente de quaisquer alterações, uma vez que estas são vinculativas
        para si.
    </p>
    <p>
        <u>Acesso ao Website e Segurança da Conta</u>
    </p>
    <p>
        Reservamo-nos o direito de remover ou alterar este Website, e qualquer
        serviço ou material que forneçamos no Website, a nosso exclusivo critério,
        sem aviso prévio. Não seremos responsáveis se, por qualquer razão, a
        totalidade ou qualquer parte do Website estiver indisponível a qualquer
        momento ou por qualquer período. Periodicamente, podemos restringir o
        acesso a algumas partes do Website, ou a todo o Website, a utilizadores,
        incluindo utilizadores registados.
    </p>
    <p>
        Você é responsável por:
    </p>
    <ul>
        <li>
            <p>
                Tomar todas as providências necessárias para que tenha acesso ao
                Website.
            </p>
        </li>
        <li>
            <p>
                Assegurar que todas as pessoas que acedem ao Website através da sua
                ligação à Internet estejam cientes destes Termos de Utilização e os
                respeitem.
            </p>
        </li>
    </ul>
    <p>
        Para aceder ao Website ou a alguns dos recursos que este oferece, poderá
        ser-lhe solicitado que forneça certos detalhes de registo ou outras
        informações. Para que o utilizador utilize o Website, é necessário que
        todas as informações que forneça no Website sejam corretas, atuais e
        completas. O utilizador concorda com o facto de todas as informações que
        fornecer para se registar neste Website ou não, incluindo mas não se
        limitando à utilização de quaisquer funcionalidades interativas no Website,
        serem regidas pela nossa <u>Política de Privacidade</u> e consente com
        todas as medidas que tomarmos em relação às suas informações em
        conformidade com a nossa Política de Privacidade.
    </p>
    <p>
        Se escolher, ou lhe forem fornecidos um nome de utilizador, palavra-passe
        ou qualquer outra informação como parte dos nossos procedimentos de
        segurança, deve tratar tais informações como sendo confidenciais e não as
        deve divulgar a qualquer outra pessoa ou entidade. Reconhece também que a
        sua conta é pessoal e concorda em não facultar a qualquer outra pessoa o
        acesso a este Website ou a partes do mesmo utilizando o seu nome de
        utilizador, palavra-passe ou outras informações de segurança. O utilizador
        concorda em notificar-nos imediatamente sobre qualquer acesso ou utilização
        não autorizada do seu nome de utilizador ou palavra-passe ou qualquer outra
        violação de segurança. Concorda também em assegurar-se de que desconecta a
        sua conta no final de cada sessão. Deve ter especial cuidado ao aceder à
        sua conta a partir de um computador público ou partilhado, para que outros
        não consigam ver ou registar a sua palavra-passe ou outras informações
        pessoais.
    </p>
    <p>
        Temos o direito de desativar qualquer nome de utilizador, palavra-passe ou
        outro identificador, escolhido pelo utilizador ou fornecido por nós, a
        qualquer momento, a nosso exclusivo critério, por qualquer ou nenhuma
        razão, incluindo se, na nossa opinião, o utilizador tiver violado qualquer
        disposição dos presentes Termos de Utilização.
    </p>
    <p>
        <u>Direitos de Propriedade Intelectual</u>
    </p>
    <p>
        O Website e a totalidade dos seus conteúdos, características e
        funcionalidades (incluindo mas não se limitando a toda a informação,
        software, texto, ecrãs, imagens, vídeo e áudio e o respetivo design,
        seleção e disposição), são propriedade da Empresa, dos seus licenciadores
        ou de outros fornecedores de tal material e são protegidos por leis
        norte-americanas e internacionais de direitos de autor, marcas registadas,
        patentes, segredos comerciais e outras leis de propriedade intelectual ou
        de direitos de propriedade intelectual.
    </p>
    <p>
        Estes Termos de Utilização permitem-lhe utilizar o Website apenas para seu
        uso pessoal, não comercial, não competitivo e confidencial. O Website e o
        seu código subjacente constituem segredos comerciais. Não deve reproduzir,
        distribuir, modificar, criar projetos derivados de, exibir publicamente,
        executar publicamente, republicar, descarregar, partilhar com
        pessoas/partes não autorizadas, armazenar ou transmitir (i) qualquer
        material do nosso Website, (ii) qualquer código subjacente ao Website,
        exceto nos seguintes casos:
    </p>
    <ul>
        <li>
            <p>
                O seu computador pode armazenar temporariamente cópias de tais
                materiais na RAM, de forma incidental ao seu acesso e visualização
                desses materiais.
            </p>
        </li>
        <li>
            <p>
                Pode guardar ficheiros que são automaticamente armazenados em cache
                pelo seu navegador da Web para fins de melhoramento da
                visualização.
            </p>
        </li>
        <li>
            <p>
                Pode imprimir ou descarregar uma cópia de um número razoável de
                páginas do Website para seu uso pessoal, não comercial e não para
                reprodução, publicação ou distribuição posterior.
            </p>
        </li>
        <li>
            <p>
                Se disponibilizarmos aplicações desktop, móveis ou outras
                aplicações para download, poderá descarregar uma única cópia para o
                seu computador ou dispositivo móvel apenas para o seu uso pessoal e
                não comercial, desde que aceite estar vinculado pelo nosso contrato
                de licença de utilizador final para tais aplicações.
            </p>
        </li>
    </ul>
    <p>
        Não deve:
    </p>
    <ul>
        <li>
            <p>
                <a name="OLE_LINK91"></a>
                <a name="OLE_LINK92"></a>
                Modificar cópias de quaisquer materiais deste site.
            </p>
        </li>
        <li>
            <p>
                Utilizar quaisquer ilustrações, fotografias, sequências de vídeo ou
                áudio ou quaisquer gráficos separadamente do texto que os
                acompanha.
            </p>
        </li>
        <li>
            <p>
                Eliminar ou alterar quaisquer avisos de direitos de autor, marcas
                registadas ou outros direitos de propriedade de cópias de materiais
                deste site.
            </p>
        </li>
    </ul>
    <p>
        Não deverá aceder ou utilizar para quaisquer fins comerciais nenhuma parte
        do Website ou quaisquer serviços ou materiais disponíveis através do
        Website.
    </p>
    <p>
        Se desejar fazer qualquer outra utilização do material do Website para além
        da estabelecida nesta secção, queira ter em conta o seguinte:
    </p>
    <p>
        Se imprimir, copiar, modificar, descarregar ou de outra forma utilizar ou
        fornecer a qualquer outra pessoa acesso a qualquer parte do Website em
        violação dos Termos de Utilização, o seu direito a utilizar o Website
        cessará imediatamente e deverá, ao nosso critério, devolver ou destruir
        quaisquer cópias dos materiais que tenha feito. Nenhum direito, título ou
        interesse no ou para com o Website ou qualquer conteúdo do Website é
        transferido para si, e todos os direitos não expressamente concedidos são
        reservados pela Empresa. Qualquer tipo de utilização do Website que não
        esteja expressamente permitida nestes Termos de Utilização constitui uma
        violação dos mesmos e pode violar direitos de autor, marcas registadas e
        outras leis.
    </p>
    <p>
        <u>Marcas registadas</u>
    </p>
    <p>
        O nome da Empresa, o logótipo da Empresa e todos os nomes, logótipos, nomes
        de produtos e serviços relacionados, desenhos e slogans são marcas
        registadas da Empresa ou dos seus afiliados ou licenciadores. Não deve
        utilizar tais marcas sem a autorização prévia por escrito da Empresa. Todos
        os outros nomes, logótipos, nomes de produtos e serviços, desenhos e
        slogans contidos neste Website são marcas registadas dos seus respetivos
        proprietários.
    </p>
    <p>
        <u>Utilizações Proibidas</u>
    </p>
    <ul>
        <li>
            <p>
                Apenas poderá utilizar o Website para fins lícitos e de acordo com
                estes Termos de Utilização. O utilizador concorda em não utilizar o
                Website:
            </p>
        </li>
        <li>
        </li>
        <li>
            <p>
                De qualquer forma que viole as leis ou regulamentos federais,
                estaduais, locais ou internacionais aplicáveis (incluindo, sem
                limitação, quaisquer leis relativas à exportação de dados ou
                software de e para os EUA ou outros países).
            </p>
        </li>
        <li>
            <p>
                Com o objetivo de explorar, prejudicar ou tentar explorar ou
                prejudicar menores de alguma forma, expondo-os a conteúdos
                impróprios, pedindo informações pessoalmente identificáveis ou de
                qualquer outra forma.
            </p>
        </li>
        <li>
            <p>
                Para transmitir, ou procurar o envio de qualquer material
                publicitário ou promocional [sem o nosso consentimento prévio por
                escrito], incluindo qualquer "junk mail", "chain letter" ou "spam"
                ou qualquer outra solicitação semelhante.
            </p>
        </li>
        <li>
            <p>
                Para se fazer passar ou tentar fazer-se passar pela Empresa, um
                funcionário da Empresa, outro utilizador ou qualquer outra pessoa
                ou entidade.
            </p>
        </li>
        <li>
            <p>
                Para se envolver em qualquer outra conduta que restrinja ou iniba o
                uso ou usufruto do Website por qualquer pessoa, ou que, conforme
                determinado por nós, possa prejudicar a Empresa ou os utilizadores
                do Website ou expô-los a qualquer tipo de risco.
            </p>
        </li>
    </ul>
    <p>
        Além disso, concorda em não:
    </p>
    <ul>
        <li>
            <p>
                Utilizar o Website de qualquer forma que possa desativar,
                sobrecarregar, danificar ou comprometer o Website ou interferir com
                a utilização do Website por qualquer outra parte, incluindo a sua
                capacidade de se envolverem em atividades em tempo real através do
                Website.
            </p>
        </li>
        <li>
            <p>
                Utilizar qualquer robô, spider ou qualquer outro dispositivo
                automático, processo ou meio para aceder ao Website para qualquer
                finalidade, incluindo a monitorização ou cópia de qualquer material
                do Website.
            </p>
        </li>
        <li>
            <p>
                Utilizar qualquer processo manual para monitorizar ou copiar
                qualquer material no Website ou para qualquer outro fim não
                autorizado sem o nosso consentimento prévio por escrito.
            </p>
        </li>
    </ul>
    <p>
        Utilizar qualquer dispositivo, software ou procedimento de rotina que
        interfira com o bom funcionamento do Website.
    </p>
    <p>
        Introduzir quaisquer vírus, cavalos de Tróia, worms, bombas lógicas ou
        outro material que seja malicioso ou tecnologicamente nocivo.
    </p>
    <ul>
        <li>
            <p>
                Tentar obter acesso não autorizado, interferir, danificar ou
                perturbar quaisquer partes do Website, o servidor em que o Website
                está alojado, ou qualquer servidor, computador ou base de dados
                ligada ao Website.
            </p>
        </li>
    </ul>
    <p>
        Atacar o Website através de um ataque de negação de serviço ou de um ataque
        distribuído de negação de serviço.
    </p>
    <p>
        De outra forma, tentar interferir com o bom funcionamento do Website.
    </p>
    <p>
        <u>Controlo e Execução; Rescisão</u>
    </p>
    <p>
        Temos o direito de:
    </p>
    <ul>
        <li>
            <p>
                Tomar as providências legais apropriadas, incluindo, sem limitação,
                o recurso às forças de segurança, para qualquer utilização ilegal
                ou não autorizada do Website.
            </p>
        </li>
        <li>
            <p>
                Rescindir ou suspender o seu acesso a todo ou parte do Website por
                qualquer violação destes Termos de Utilização.
            </p>
        </li>
    </ul>
    <p>
        Sem limitar o supracitado, temos o direito de cooperar plenamente com
        qualquer força de segurança ou ordem judicial que nos solicite ou nos
        ordene a revelar a identidade ou outras informações de qualquer pessoa que
        publique qualquer material no Website ou através dele. O UTILIZADOR
        RENUNCIA E ISENTA A EMPRESA DE QUAISQUER RECLAMAÇÕES RESULTANTES DE
        QUALQUER AÇÃO TOMADA PELA EMPRESA DURANTE OU EM RESULTADO DAS SUAS
        INVESTIGAÇÕES E DE QUAISQUER AÇÕES TOMADAS EM CONSEQUÊNCIA DE INVESTIGAÇÕES
        QUER PELA EMPRESA QUER PELAS FORÇAS DE SEGURANÇA.
    </p>
    <p>
        <u>Alterações ao Website</u>
    </p>
    <p>
        Podemos atualizar o conteúdo deste Website periodicamente, mas o seu
        conteúdo não está necessariamente completo ou atualizado. Qualquer material
        do Website pode estar desatualizado a qualquer momento, e não temos a
        obrigação de atualizar tal material.
    </p>
    <p>
        <u>Informação sobre si e as suas visitas ao Website</u>
    </p>
    <p>
    Toda a informação que recolhemos neste Website está sujeita à nossa    <u>Política de Privacidade</u>. Ao utilizar o Website, consente com todas
        as medidas por nós tomadas relativamente às suas informações em
        conformidade com a nossa Política de Privacidade.
    </p>
    <p>
        <u>Links a partir do Website</u>
    </p>
    <p>
        Se o Website contiver links para outros sites e recursos fornecidos por
        terceiros, estes links são fornecidos apenas para sua conveniência. Isto
        abrange links contidos em anúncios, incluindo banners publicitários e links
        patrocinados. Não temos qualquer controlo sobre o conteúdo desses sites ou
        recursos, e não assumimos qualquer responsabilidade por eles ou por
        quaisquer perdas ou danos que possam advir da utilização dos mesmos. Se
        decidir aceder a qualquer um dos websites de terceiros com link neste este
        website, fá-lo-á inteiramente por sua conta e risco e sujeito aos termos e
        condições de utilização de tais websites.
    </p>
    <p>
        <u>Restrições Geográficas</u>
    </p>
    <p>
        O proprietário do Website está sediado no estado da Florida, nos Estados
        Unidos. Este Website é apenas disponibilizado para utilização a pessoas
        localizadas nos Estados Unidos da América. Não alegamos que o Website ou
        qualquer um dos seus conteúdos seja acessível ou apropriado fora dos
        Estados Unidos da América. O acesso ao Website pode não ser legal por
        certas pessoas ou em certos países. Se aceder ao Website a partir de países
        fora dos Estados Unidos, fá-lo por sua própria iniciativa e é responsável
        pelo cumprimento das leis locais.
    </p>
    <p>
        <u>Renúncia de Garantias</u>
    </p>
    <p>
        O utilizador compreende que não podemos e não garantimos nem asseguramos
        que os ficheiros disponíveis para descarga a partir da Internet ou do
        Website estejam livres de vírus ou outro código destrutivo. É da sua
        responsabilidade a implementação de procedimentos e pontos de controlo
        suficientes para satisfazer os seus requisitos particulares de proteção
        antivírus e precisão de entrada e saída de dados, e a manutenção de um meio
        externo ao nosso site para qualquer reconstituição de quaisquer dados
        perdidos. NÃO SEREMOS RESPONSÁVEIS POR QUALQUER PERDA OU DANO CAUSADO POR
        UM ATAQUE DISTRIBUÍDO DE NEGAÇÃO DE SERVIÇO, VÍRUS OU OUTRO MATERIAL
        TECNOLOGICAMENTE NOCIVO QUE POSSA INFETAR O SEU EQUIPAMENTO INFORMÁTICO,
        PROGRAMAS INFORMÁTICOS, DADOS OU OUTRO MATERIAL PRÓPRIO DEVIDO À UTILIZAÇÃO
        DO WEBSITE OU DE QUAISQUER SERVIÇOS OU ARTIGOS OBTIDOS ATRAVÉS DO WEBSITE
        OU AO DESCARREGAMENTO DE QUALQUER MATERIAL NELE PUBLICADO, OU EM QUALQUER
        WEBSITE A ELE LIGADO.
    </p>
    <p>
        A UTILIZAÇÃO DO WEBSITE, DO SEU CONTEÚDO E DE QUAISQUER SERVIÇOS OU ARTIGOS
        OBTIDOS ATRAVÉS DO PRÓPRIO É FEITA POR SUA CONTA E RISCO. O WEBSITE, O SEU
        CONTEÚDO E QUAISQUER SERVIÇOS OU ARTIGOS OBTIDOS ATRAVÉS DO WEBSITE SÃO
        DISPONIBILIZADOS NUMA BASE DE "TAL COMO ESTÁ" E "TAL COMO DISPONÍVEL", SEM
        QUAISQUER GARANTIAS DE QUALQUER TIPO, EXPRESSAS OU IMPLÍCITAS. NEM A
        EMPRESA NEM QUALQUER PESSOA ASSOCIADA À EMPRESA FAZ QUALQUER GARANTIA OU
        REPRESENTAÇÃO NO QUE DIZ RESPEITO À INTEGRIDADE, SEGURANÇA, FIABILIDADE,
        QUALIDADE, PRECISÃO OU DISPONIBILIDADE DO WEBSITE. SEM LIMITAR O
        PRECEDENTE, NEM A EMPRESA NEM NINGUÉM ASSOCIADO À EMPRESA REPRESENTA OU
        GARANTE QUE O WEBSITE, O SEU CONTEÚDO OU QUAISQUER SERVIÇOS OU ARTIGOS
        OBTIDOS ATRAVÉS DO WEBSITE SERÃO PRECISOS, FIÁVEIS, ISENTOS DE ERROS OU
        ININTERRUPTOS, QUE OS DEFEITOS SERÃO CORRIGIDOS, QUE O NOSSO WEBSITE OU O
        SERVIDOR QUE O DISPONIBILIZA ESTÃO LIVRES DE VÍRUS OU OUTROS COMPONENTES
        NOCIVOS OU QUE O WEBSITE OU QUAISQUER SERVIÇOS OU ARTIGOS OBTIDOS ATRAVÉS
        DO WEBSITE IRÃO DE OUTRA FORMA SATISFAZER AS SUAS NECESSIDADES OU
        EXPECTATIVAS.
    </p>
    <p>
        A EMPRESA RENUNCIA A TODAS AS GARANTIAS DE QUALQUER TIPO, EXPRESSAS OU
        IMPLÍCITAS, ESTATUTÁRIAS OU OUTRAS, INCLUINDO MAS NÃO LIMITADAS A QUAISQUER
        GARANTIAS DE COMERCIALIZAÇÃO, NÃO-INFRAÇÃO E ADEQUAÇÃO A UM DETERMINADO
        FIM.
    </p>
    <p>
        O PRECEDENTE NÃO AFETA QUAISQUER GARANTIAS QUE NÃO POSSAM SER EXCLUÍDAS OU
        LIMITADAS AO ABRIGO DA LEI APLICÁVEL.
    </p>
    <p>
        <u>Limitação de responsabilidade</u>
    </p>
    <p>
        A EMPRESA, OS SEUS AFILIADOS OU LICENCIADORES, PRESTADORES DE SERVIÇOS,
        EMPREGADOS, AGENTES, FUNCIONÁRIOS OU DIRETORES NÃO SERÃO, EM CASO ALGUM,
        RESPONSÁVEIS POR DANOS DE QUALQUER TIPO, SOB QUALQUER TEORIA JURÍDICA,
        DECORRENTES OU RELACIONADOS COM A SUA UTILIZAÇÃO OU INCAPACIDADE DE
        UTILIZAÇÃO DO WEBSITE, DE QUAISQUER WEBSITES A ELE LIGADOS, DE QUALQUER
        CONTEÚDO DO WEBSITE OU DE TAIS OUTROS WEBSITES OU DE QUAISQUER SERVIÇOS OU
        ARTIGOS CONTRATADOS OBTIDOS ATRAVÉS DO WEBSITE OU DE TAIS OUTROS WEBSITES,
        INCLUINDO QUAISQUER DANOS DIRETOS, INDIRETOS, ESPECIAIS, INCIDENTAIS,
        CONSEQUENCIAIS OU PUNITIVOS, INCLUINDO MAS NÃO LIMITADOS A, DANOS PESSOAIS,
        DOR E SOFRIMENTO, ANGÚSTIA EMOCIONAL, PERDA DE RECEITAS, PERDA DE LUCROS,
        PERDA DE NEGÓCIOS OU POUPANÇA ANTECIPADA, PERDA DE UTILIZAÇÃO, PERDA DE BOA
        VONTADE, PERDA DE DADOS, E SE CAUSADOS POR DELITO (INCLUINDO NEGLIGÊNCIA),
        RUPTURA DE CONTRATO OU OUTROS, MESMO QUE PREVISÍVEIS.
    </p>
    <p>
        O PRECEDENTE NÃO AFETA QUALQUER RESPONSABILIDADE QUE NÃO POSSA SER EXCLUÍDA
        OU LIMITADA AO ABRIGO DA LEI APLICÁVEL.
    </p>
    <p>
        <u>Indemnização</u>
    </p>
    <p>
        O utilizador concorda em defender, indemnizar e isentar a Empresa, os seus
        afiliados, licenciadores e prestadores de serviços e os seus respetivos
        funcionários, diretores, empregados, contratantes, agentes, licenciadores,
        fornecedores, sucessores e cessionários de e contra quaisquer reclamações,
        responsabilidades, danos, sentenças, prémios, perdas, custos, despesas ou
        honorários (incluindo honorários de advogados) decorrentes ou relacionados
        com a violação destes Termos de Utilização, a utilização dos serviços da
        Empresa por si mesmo, pelos seus convidados, hóspedes ou afiliados, ou a
        utilização do Website, incluindo, mas não se limitando a qualquer
        utilização do conteúdo do Website, serviços e produtos para além dos
        expressamente autorizados nestes Termos de Utilização ou a utilização de
        qualquer informação obtida a partir do Website.
    </p>
    <p>
        <u>Legislação e Jurisdição Aplicável</u>
    </p>
    <p>
        Todas as questões relacionadas com o Website e estes Termos de Utilização e
        qualquer disputa ou reclamação dele decorrente ou relacionada (em todos os
        casos, incluindo disputas ou reclamações extracontratuais), serão regidas e
        interpretadas de acordo com as leis internas do Estado da Florida sem dar
        efeito a qualquer escolha ou conflito de disposição legal ou regra (seja do
        Estado da Florida ou de qualquer outra jurisdição).
    </p>
    <p>
        Qualquer processo legal, ação ou procedimento decorrente ou relacionado com
        estes Termos de Utilização ou com o Website será instaurado exclusivamente
        nos tribunais federais dos Estados Unidos ou nos tribunais do Estado da
        Florida localizados, respetivamente, na Cidade de Fort Lauderdale e no
        Condado de Broward. O utilizador renuncia a toda e qualquer objeção
        relativamente ao exercício da jurisdição exercida sobre si por tais
        tribunais e ao foro em tais tribunais.
    </p>
    <p>
        <a name="OLE_LINK234"></a>
        <a name="OLE_LINK235"></a>
        <u>Limitação do prazo para apresentação de reclamações</u>
    </p>
    <p>
        QUAISQUER CONDIÇÕES DE AÇÃO OU RECLAMAÇÕES QUE POSSAM TER SURGIDO DE
        SERVIÇOS FORNECIDOS PELA EMPRESA OU RELACIONADOS COM ESTES TERMOS DE
        UTILIZAÇÃO OU O WEBSITE DEVE SER INICIADO NO PRAZO DE UM (1) ANO APÓS O
        SURGIMENTO DAS CONDIÇÕES DE AÇÃO, CASO CONTRÁRIO, O CLIENTE CONCORDA QUE
        TAL CAUSA DE AÇÃO OU RECLAMAÇÃO É PERMANENTEMENTE INTERDITA.
    </p>
    <p>
        <u>Renúncia e Divisibilidade</u>
    </p>
    <p>
        Qualquer renúncia da Empresa a qualquer termo ou condição estabelecida
        nestes Termos de Utilização não será considerada uma renúncia adicional ou
        contínua a tal termo ou condição, ou uma renúncia a qualquer outro termo ou
        condição, e qualquer falha da Empresa quanto ao exercício de um direito ou
        disposição ao abrigo destes Termos de Utilização não constituirá uma
        renúncia a tal direito ou disposição.
    </p>
    <p>
        Se qualquer disposição destes Termos de Utilização for considerada
        inválida, ilegal ou inaplicável por qualquer razão por um tribunal ou outro
        órgão jurisdicional competente, tal disposição deverá ser eliminada ou
        limitada ao mínimo necessário para que as restantes disposições dos Termos
        de Utilização continuem em pleno vigor e efeito.
    </p>
    <p>
        <u>Acordo completo</u>
    </p>
    <p>
        Os Termos de Utilização e a nossa Política de Privacidade constituem o
        único e completo acordo entre o utilizador e a Empresa relativamente ao
        Website e substituem todos os entendimentos, acordos, representações e
        garantias anteriores e contemporâneos, tanto escritos como orais, no que
        diz respeito ao Website.
    </p>
    <p>
        <u>Opiniões e Questões</u>
    </p>
    <p>
        Todas as opiniões, comentários, pedidos de apoio técnico e outras
    comunicações relacionadas com o Website devem ser encaminhadas para:    <a href="mailto:support@parkingpass.com">support@parkingpass.com</a>
    </p>
    <p>
        Ao concordar com os termos de serviço, está a confirmar o seu consentimento
        e a aceitar os Termos de Utilização.
    </p>

</div>
