<div class='card-title'>
    {{ 'PROPERTY_INFO.CARD_TITLE' | translate }}
</div>

<form [formGroup]="propertyInfoForm">

    <div>

        <div class='parkingpass-formFieldWrapper' *ngIf="showAddress">

            <div *ngIf="submitted && g.parkingpassStreetAddress.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="g.parkingpassStreetAddress.errors.required">
                    {{ 'PROPERTY_INFO.ADDRESS_SELECT_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="g.parkingpassStreetAddress.errors.invalid">
                    {{ 'PROPERTY_INFO.ADDRESS_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field>

                <mat-select placeholder="{{ 'PROPERTY_INFO.ADDRESS_PLACEHOLDER' | translate }}"
                            id="street_address"
                            data-clarity-unmask="true"
                            formControlName="parkingpassStreetAddress"
                            (selectionChange)="getBuildingNumbers()"
                            required
                            [errorStateMatcher]="errorMatcher">

                    <mat-option *ngFor="let propertyAddress of propertyAddressList" [(value)]="propertyAddress.address">
                        {{ propertyAddress.address }}
                    </mat-option>

                </mat-select>

            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper' *ngIf="showBuildingNumber">

            <div *ngIf="submitted && g.parkingpassBuildingNumber.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="g.parkingpassBuildingNumber.errors.required">
                    {{ 'PROPERTY_INFO.BUILDING_NUMBER_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="g.parkingpassBuildingNumber.errors.invalid">
                    {{ 'PROPERTY_INFO.BUILDING_NUMBER_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field>

                <mat-select placeholder="{{ 'PROPERTY_INFO.BUILDING_NUMBER_PLACEHOLDER' | translate }}"
                            id="building_number"
                            data-clarity-unmask="true"
                            formControlName="parkingpassBuildingNumber"
                            (selectionChange)="getUnitNumbers()"
                            required
                            [errorStateMatcher]="errorMatcher">

                    <mat-option *ngFor="let building of buildingNumberList" [(value)]="building.building_number">
                        {{ building.building_number }}
                    </mat-option>

                </mat-select>

            </mat-form-field>

        </div>

    </div>

    <div>

        <div class='parkingpass-formFieldWrapper' *ngIf="showUnitNumber">

            <div *ngIf="submitted && g.parkingpassApartmentUnit.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="g.parkingpassApartmentUnit.errors.required">
                    {{ 'PROPERTY_INFO.UNIT_NUMBER_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="g.parkingpassApartmentUnit.errors.invalid">
                    {{ 'PROPERTY_INFO.UNIT_NUMBER_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field>

                <mat-select placeholder="{{ 'PROPERTY_INFO.UNIT_NUMBER_PLACEHOLDER' | translate }}"
                            id="unit_number"
                            data-clarity-unmask="true"
                            formControlName="parkingpassApartmentUnit"
                            required
                            [errorStateMatcher]="errorMatcher">

                    <mat-option *ngFor="let unit of unitNumberList" [(value)]="unit.unit_number">
                        {{ unit.unit_number }}
                    </mat-option>

                </mat-select>

            </mat-form-field>

        </div>


        <div [style.display]=" ( propertyInfo !== undefined && propertyInfo.community_type ) === 'Manufactured Homes' ? 'none' : 'block'">
            <div class='parkingpass-formFieldWrapper'
                 style="position: relative; margin: 0 auto;"
                 *ngIf="propertyInfo !== undefined && propertyInfo.questions.own_or_rent">

              <mat-label>
                {{ 'PROPERTY_INFO.OWN_OR_RENT' | translate }}
              </mat-label>

              <div *ngIf="submitted && g.parkingpassResidentType.errors">
                <mat-error *ngIf="g.parkingpassResidentType.errors.required">
                    {{ 'PROPERTY_INFO.OWN_OR_RENT_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="g.parkingpassResidentType.errors.invalid">
                  {{ 'PROPERTY_INFO.OWN_OR_RENT_INVALID' | translate }}
                </mat-error>
              </div>

              <mat-radio-group class="parkingpass-singup-resident-type"
                                aria-label="Select an option"
                                (change)="residentTypeChange()"
                                formControlName="parkingpassResidentType">
                <mat-radio-button value="owner" [checked]="propertyInfo.community_type === 'Manufactured Homes' || false">{{ 'PROPERTY_INFO.OWN' | translate }}</mat-radio-button>
                <mat-radio-button value="renter">{{ 'PROPERTY_INFO.RENT' | translate }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

    </div>

    <input type="hidden" formControlName="parkingpassOwnerInfoNeeded" value=false/>

</form>

<div class='parkingpass-ownerFormWrapper'
        *ngIf="parkingpassResidentType === 'renter' &&
                propertyInfo !== undefined &&
                propertyInfo.questions.own_or_rent &&
                canInitialize">

    <div class='card-title'>
        {{ 'OWNER_INFO.CARD_TITLE' | translate }}
    </div>

    <form [formGroup]="ownerInfoForm">

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && h.parkingpassOwnerFirstName.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="h.parkingpassOwnerFirstName.errors.required">
                    {{ 'OWNER_INFO.FIRST_NAME_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="h.parkingpassOwnerFirstName.errors.wrongLength">
                    {{ 'OWNER_INFO.FIRST_NAME_LENGTH' | translate }}
                </mat-error>

                <mat-error *ngIf="h.parkingpassOwnerFirstName.errors.mustNotMatch">
                    {{ 'OWNER_INFO.FULL_NAME_MUST_NOT_MATCH' | translate }}
                </mat-error>

            </div>

            <mat-form-field>
                <input matInput type='text'
                        formControlName="parkingpassOwnerFirstName"
                        id='owner_first_name'
                        titlecase
                        personName
                        whiteSpace
                        placeholder="{{ 'OWNER_INFO.FIRST_NAME_PLACEHOLDER' | translate }}"
                        [errorStateMatcher]="errorMatcher" />
            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && h.parkingpassOwnerLastName.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="h.parkingpassOwnerLastName.errors.required">
                    {{ 'OWNER_INFO.LAST_NAME_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="h.parkingpassOwnerLastName.errors.wrongLength">
                    {{ 'OWNER_INFO.LAST_NAME_LENGTH' | translate }}
                </mat-error>

            </div>

            <mat-form-field>
                <input matInput type='text'
                        formControlName="parkingpassOwnerLastName"
                        id='owner_last_name'
                        titlecase
                        personName
                        whiteSpace
                        placeholder="{{ 'OWNER_INFO.LAST_NAME_PLACEHOLDER' | translate }}"
                        [errorStateMatcher]="errorMatcher" />
            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && h.parkingpassOwnerEmail.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="h.parkingpassOwnerEmail.errors.required">
                    {{ 'OWNER_INFO.EMAIL_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="h.parkingpassOwnerEmail.errors.email">
                    {{ 'OWNER_INFO.EMAIL_INVALID' | translate }}
                </mat-error>

                <mat-error *ngIf="h.parkingpassOwnerEmail.errors.mustNotMatch">
                    {{ 'OWNER_INFO.EMAIL_MUST_NOT_MATCH' | translate }}
                </mat-error>

            </div>

            <mat-form-field>
                <input matInput (keyup)="removeWhiteSpace('parkingpassOwnerEmail')"
                                type='text'
                                id='user_email'
                                formControlName="parkingpassOwnerEmail"
                                placeholder="{{ 'OWNER_INFO.EMAIL_PLACEHOLDER' | translate }}"
                                autocomplete="email"
                                [errorStateMatcher]="errorMatcher" />
            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && h.parkingpassOwnerConfirmEmail.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="h.parkingpassOwnerConfirmEmail.errors.required">
                    {{ 'OWNER_INFO.C_EMAIL_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="h.parkingpassOwnerConfirmEmail.errors.mustMatch">
                    {{ 'OWNER_INFO.C_EMAIL_MATCH' | translate }}
                </mat-error>

            </div>

            <mat-form-field>
                <input matInput (keyup)="removeWhiteSpace('parkingpassOwnerConfirmEmail')"
                                type='text'
                                id='user_email_confirm'
                                formControlName="parkingpassOwnerConfirmEmail"
                                placeholder="{{ 'OWNER_INFO.C_EMAIL_PLACEHOLDER' | translate }}"
                                autocomplete="email"
                                [errorStateMatcher]="errorMatcher" />
            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && h.parkingpassOwnerPhoneNumber.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="h.parkingpassOwnerPhoneNumber.errors.required">
                    {{ 'OWNER_INFO.PHONE_NUMBER_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="h.parkingpassOwnerPhoneNumber.errors.invalid">
                    {{ 'OWNER_INFO.PHONE_NUMBER_INVALID' | translate }}
                </mat-error>

                <mat-error *ngIf="h.parkingpassOwnerPhoneNumber.errors.mustNotMatch">
                    {{ 'OWNER_INFO.PHONE_MUST_NOT_MATCH' | translate }}
                </mat-error>

            </div>

            <mat-form-field>
                <input matInput type='text'
                        formControlName="parkingpassOwnerPhoneNumber"
                        mask="(000) 000-0000"
                        id='owner_phone'
                        placeholder="{{ 'OWNER_INFO.PHONE_NUMBER_PLACEHOLDER' | translate }}"
                        [errorStateMatcher]="errorMatcher" />
            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && h.parkingpassOwnerAddress.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="h.parkingpassOwnerAddress.errors.required">
                    {{ 'OWNER_INFO.OWNER_ADDRESS_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="h.parkingpassOwnerAddress.errors.invalid">
                    {{ 'OWNER_INFO.OWNER_ADDRESS_INVALID' | translate }}
                </mat-error>

                <mat-error *ngIf="h.parkingpassOwnerAddress.errors.wrongLength">
                    {{ 'OWNER_INFO.OWNER_ADDRESS_WRONG_LENGTH' | translate }}
                </mat-error>

            </div>

            <mat-form-field>
                <input matInput type='text'
                        formControlName="parkingpassOwnerAddress"
                        id='owner_address'
                        placeholder="{{ 'OWNER_INFO.OWNER_ADDRESS_PLACEHOLDER' | translate }}"
                        [errorStateMatcher]="errorMatcher" />
            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && h.parkingpassOwnerCountry.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="h.parkingpassOwnerCountry.errors.required">
                    {{ 'OWNER_INFO.OWNER_COUNTRY_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="h.parkingpassOwnerCountry.errors.invalid">
                    {{ 'OWNER_INFO.OWNER_COUNTRY_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field>

                <mat-select placeholder="{{ 'OWNER_INFO.OWNER_COUNTRY_PLACEHOLDER' | translate }}"
                            id="owner_state"
                            formControlName="parkingpassOwnerCountry"
                            [errorStateMatcher]="errorMatcher"
                            [(ngModel)]="countrySelected"
                            (click)="changeCountry()">

                    <mat-option *ngFor="let country of country_list" [(value)]="country.abbreviation">
                        {{ country.name }}
                    </mat-option>

                </mat-select>

            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && h.parkingpassOwnerCity.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="h.parkingpassOwnerCity.errors.required">
                    {{ 'OWNER_INFO.OWNER_CITY_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="h.parkingpassOwnerCity.errors.invalid">
                    {{ 'OWNER_INFO.OWNER_CITY_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field>
                <input matInput type='text'
                        formControlName="parkingpassOwnerCity"
                        id='owner_city'
                        placeholder="{{ 'OWNER_INFO.OWNER_CITY_PLACEHOLDER' | translate }}"
                        [errorStateMatcher]="errorMatcher"/>
            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && h.parkingpassOwnerState.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="h.parkingpassOwnerState.errors.required">
                    {{ 'OWNER_INFO.OWNER_STATE_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="h.parkingpassOwnerState.errors.invalid">
                    {{ 'OWNER_INFO.OWNER_STATE_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field>

                <mat-select placeholder="{{ 'OWNER_INFO.OWNER_STATE_PLACEHOLDER' | translate }}"
                            id="owner_state"
                            formControlName="parkingpassOwnerState"
                            *ngIf="this.countrySelected === 'US'"
                            [errorStateMatcher]="errorMatcher">

                    <mat-option *ngFor="let state of state_list" [(value)]="state.abbreviation">
                        {{ state.abbreviation }}
                    </mat-option>

                </mat-select>
                <input *ngIf="this.countrySelected !== 'US'"
                matInput type='text'
                placeholder="{{ 'OWNER_INFO.OWNER_STATE_PLACEHOLDER_ENTER' | translate }}"
                id="owner_state"
                formControlName="parkingpassOwnerState"
                [errorStateMatcher]="errorMatcher">

            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && h.parkingpassOwnerZipCode.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="h.parkingpassOwnerZipCode.errors.required">
                    {{ 'OWNER_INFO.OWNER_ZIP_CODE_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="h.parkingpassOwnerZipCode.errors.invalid">
                    {{ 'OWNER_INFO.OWNER_ZIP_CODE_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field>
                <input matInput type='text'
                        formControlName="parkingpassOwnerZipCode"
                        mask="00000"
                        id='owner_zip_code'
                        placeholder="{{ 'OWNER_INFO.OWNER_ZIP_CODE_PLACEHOLDER' | translate }}"
                        [errorStateMatcher]="errorMatcher" />
            </mat-form-field>

        </div>

    </form>

</div>
