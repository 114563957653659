import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: environment.sentry_dsn,
  autoSessionTracking: true,
  ignoreErrors: [
    'Non-Error exception captured',
    /Http failure response for \/assets\/i18n\/.*: 0 Unknown Error/,
    'Http failure response for assets/locales.json: 0 Unknown Error',
    'Http failure response for https://api.parkingpass.com/v2/properties/details/keyword: 422 OK',
    'Load failed'
  ],

  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["https://register.parkingpass.com/"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: .5,
  release: environment.release
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
