import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { handleError } from 'src/app/helpers/error-helper';
import { retryWithBackOff } from 'src/app/helpers/retry.operators';
import { environment } from 'src/environments/environment';

const PROPERTY_ADDRESS_API = `${environment.api_endpoint}/v2/properties/abu/addresses`
const BUILDING_ADDRESS_API = `${environment.api_endpoint}/v2/properties/abu/address-buildings`
const UNIT_NUMBER_API = `${environment.api_endpoint}/v2/properties/abu/address-building-units`

const PROPERTY_KEYWORD_API = `${environment.api_endpoint}/v2/properties/details/keyword`

const VEHICLES_PER_UNIT_API = `${environment.api_endpoint}/v2/properties/details/max-resident-vehicles-per-unit`

const PARKING_POLICY_API = `${environment.api_endpoint}/v2/properties/parking-policy/details`

@Injectable({
  providedIn: 'root'
})
export class PropertyKeywordService {

  constructor(private http: HttpClient) { }

  public getPropertyDataset(propertyKeyword: string): Observable<any>{
    
    if(propertyKeyword.length < 3) return of({ invalid: true })

    const apiEndpoint = `${PROPERTY_KEYWORD_API}`

    let body = {
      api_key: environment.api_key,
      keyword: propertyKeyword
    }

    return this.http.post<any>(apiEndpoint, body).pipe(
      retryWithBackOff(1000)    
    )

  }

  public getPropertyAddressInfo(propertyKeyword: string, system: string): Observable<any>{

    const api_endpoint = `${PROPERTY_ADDRESS_API}`

    let body = {
      api_key: environment.api_key,
      keyword: propertyKeyword,
      system: system
    }

    return this.http.post<any>(api_endpoint, body).pipe(
      retryWithBackOff(1000)     
    )

  }

  public getBuildingNumbers(propertyKeyword: string, system: string, address: string){

    const api_endpoint = `${BUILDING_ADDRESS_API}`

    let body = {
      api_key: environment.api_key,
      keyword: propertyKeyword,
      system: system,
      address: address
    }

    return this.http.post<any>(api_endpoint, body).pipe(
      retryWithBackOff(1000)    
    )

  }

  public getUnitNumbers(propertyKeyword: string, system: string, address: string, building: string){

    const api_endpoint = `${UNIT_NUMBER_API}`

    let body = {
      api_key: environment.api_key,
      keyword: propertyKeyword,
      system: system,
      address: address,
      building: building
    }

    return this.http.post<any>(api_endpoint, body).pipe(
      retryWithBackOff(1000)     
    )

  }

  public getVehiclesPerUnit(addressObj: any){

    const api_endpoint = `${VEHICLES_PER_UNIT_API}`

    let body = {
      api_key: environment.api_key,
      property_uuid: addressObj.propertyUuid,
      location: addressObj.location,
      system: addressObj.system,
      address: addressObj.address,
      building_number: addressObj.buildingNumber,
      unit_number: addressObj.unitNumber
    }

    return this.http.post<any>(api_endpoint, body).pipe(
      retryWithBackOff(1000)      
    )

  }

  public getParkingPolicyDetails(data : any) {
    const api_endpoint = `${PARKING_POLICY_API}`

    let body = {
      api_key: environment.api_key,
      keyword: data.property_keyword,
      system: data.system,
    }

    return this.http.post<any>(api_endpoint, body).pipe(
      retryWithBackOff(1000)      
    )
  }

}
