import { Component, OnInit, ViewChild } from '@angular/core'
import {FormGroup, FormBuilder, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import { LogInError, TokenLogInError } from 'src/app/errors/user-errors/user.errors'
import { logErrorMessage } from 'src/app/helpers/retry.operators'
import { systemDomain } from 'src/app/helpers/system.helper'
import { UserService } from 'src/app/services/user.service'
import { ValidatePassword } from 'src/app/validators/password.validator'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['../sign-up/sign-up.component.css', './log-in.component.css']
})
export class LogInComponent implements OnInit {

  @ViewChild('parkingpassLogInIssues') parkingpassLogInIssues

  public faEye = faEye
  public faEyeSlash = faEyeSlash

  public loading: boolean = false

  public bg_color: string
  public current_login_photo: string
  public current_login_username: string

  public logInForm: FormGroup

  // will be used to know if the user clicked submit or not.
  public loggingIn: boolean = false
  public submitted: boolean = false
  public helpToggle: boolean = false

  public parkingpassRememberMe: boolean = false

  public rememberMeLight: string = 'red'
  public rememberMeTextOff: string = 'Remember Me is set to OFF.'
  public rememberMeTextOn: string = 'Remember Me is set to ON.'
  public rememberMeToggleStateText: string = this.rememberMeTextOff
  public rememberMeToken: string

  public lastLoggedWindow = { open : false }  

  public action: string
  public card: string
  public system: string
  public userUId: string
  public token: string

  public usingForm: boolean = false

  public passwordShow: boolean = false

  public systemIsSet: boolean = false

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private router: Router,
              private route: ActivatedRoute,
              private translateService: TranslateService,
              private matDialog: MatDialog) { }
  
  public togglePassword(){
    this.passwordShow = !this.passwordShow
  }

  public closeWindowX(): void{

  }

  public toggleRememberMe(): void{

    this.parkingpassRememberMe = !this.parkingpassRememberMe

  }

  public toggleRememberMeHelp(): void{
    this.helpToggle = !this.helpToggle
  }

  public initLogIn(): void{

    this.loading = true

    this.submitted = true

    if (this.logInForm.invalid) {
      this.loading = false
      return
    }

    this.system = localStorage.getItem('parkingpass_system')
    
    if(this.system == 'undefined') this.system = 'ocp'

    const params = {
      'api_key': environment.api_key,
      'login': this.parkingpassUsername,
      'password': this.parkingpassPassword,
      'remember_me': this.parkingpassRememberMe,
      'system': this.system
    }

    this.userService.initLogin(params).subscribe(
      resp => {
        this.loginCallback(resp)
      },
      err => {

        let sentryContext: any = {
          errorName: 'Login Error',
          loginEmail: this.parkingpassUsername,
          requestRetries: 3,
          errorMessage: this.translateService.instant('USER_SERVER_ERRORS.LOGIN'),
          system: this.system
        }

        sentryContext.error = new LogInError(sentryContext.errorName)
        
        logErrorMessage(3, err, sentryContext, this.matDialog)

        this.loading = false

      }
    )

  }

  public initTokenLogin(): void {

    this.usingForm = false
    this.loading = true

    const loginObj = {
      api_key: environment.api_key,
      token: this.token,
      system: this.system,
      uuid_to_login: this.userUId
    }

    this.userService.tokenLogin(loginObj).subscribe(
      resp => {
        this.loginCallback(resp)
      },
      err => {

        let sentryContext: any = {
          errorName: 'Token Login Error',
          uuid_to_login: this.userUId,
          tokenLogin: this.token,
          requestRetries: 3,
          errorMessage: this.translateService.instant('USER_SERVER_ERRORS.LOGIN'),
          system: this.system
        }

        sentryContext.error = new TokenLogInError(sentryContext.errorName)
        
        logErrorMessage(3, err, sentryContext, this.matDialog)

        this.loading = false

        setTimeout(() => {
          window.open(`${systemDomain(this.system)}/login`, "_self")
        }, 2500)
       
      }      
    )

  }

  private loginCallback(loginResponse: any): void {

    if(loginResponse == undefined){
      this.parkingpassLogInIssues.nativeElement.innerHTML = this.translateService.instant('SERVER_ERROR')
      return
    }

    if(loginResponse.success){

      localStorage.setItem('parkingpass_v1Token', loginResponse.v1_token)
      localStorage.setItem('parkingpass_userEmail', loginResponse.user_info.email)
      localStorage.setItem('parkingpass_loggedIn', '1')
      //localStorage.setItem('parkingpass_rememberMe', this.parkingpassRememberMe.toString())
      localStorage.setItem('parkingpass_userId', loginResponse.user_info.uuid)
      localStorage.setItem('parkingpass_userIdToLogin', loginResponse.user_id_to_login)

      localStorage.setItem('parkingpass_userInfo', JSON.stringify(loginResponse.user_info))
      localStorage.setItem('parkingpass_propertyInfo', JSON.stringify(loginResponse.property_info))
      localStorage.setItem('parkingpass_ownerInfo', JSON.stringify(loginResponse.owner_info))

      for(let i = 0; i < loginResponse.vehicle_list.length; i++){

        if(loginResponse.vehicle_list[i].tag == 'GUEST'){
          loginResponse.vehicle_list.splice(i, 1)
        }

      }

      localStorage.setItem('parkingpass_vehicleList', JSON.stringify(loginResponse.vehicle_list))
      
      this.router.navigate([`${this.translateService.currentLang}/dashboard`])

    } else {

      localStorage.setItem('parkingpass_userId', null)
      localStorage.setItem('parkingpass_loggedIn', '0')
      //localStorage.setItem('parkingpass_rememberMe', '0')
      //localStorage.setItem('parkingpass_rememberMeToken', null)

      this.parkingpassLogInIssues.nativeElement.style.display = 'none'

      if (loginResponse.message == 'invalid_cred')        
        this.parkingpassLogInIssues.nativeElement.innerHTML = this.translateService.instant('LOG_IN.INVALID_CREDENTIALS')
      else if(loginResponse.message == 'user_does_not_exist')
        this.parkingpassLogInIssues.nativeElement.innerHTML = this.translateService.instant('LOG_IN.EMAIL_DOES_NOT_EXIST')
      else if(loginResponse.message == "user_not_pending")
        window.open(`${systemDomain(this.system)}/login`, "_self")
      else
        this.parkingpassLogInIssues.nativeElement.innerHTML = this.translateService.instant('SERVER_ERROR')

      setTimeout(function() { 
        this.parkingpassLogInIssues.nativeElement.style.display = 'block' 
      }.bind(this), 40)

      this.parkingpassLogInIssues.nativeElement.className = 'card-title'      

    }

    this.loading = false

  }

  private initLogInForm(): void{

    const usernameValidators = [Validators.required, Validators.email]
    const passwordValidators = [Validators.required]
    //const parkingpassRememberMeFieldValidators = []

    this.logInForm = this.formBuilder.group({
      parkingpassUsername: ['', usernameValidators],
      parkingpassPassword: ['', passwordValidators],
      //parkingpassRememberMeField: ['', parkingpassRememberMeFieldValidators]
    }, {
      validators: [
        ValidatePassword('parkingpassPassword')
      ]
    })

    if(this.current_login_username != '')
      this.logInForm.get('parkingpassUsername').setValue(this.current_login_username)

    /*if(localStorage.getItem('parkingpass_rememberMe') == '1') 
      this.toggleRememberMe()
    */

    this.loading = false

  }

  get parkingpassUsername() { return this.logInForm.get('parkingpassUsername').value }
  get parkingpassPassword() { return this.logInForm.get('parkingpassPassword').value }
  get f() { return this.logInForm.controls }

  public closeWindow() {}

  public openWindow(window: any): void{
    window.open = true
  }

  public activateFeatures(): void{

    this.action = this.router.url

    if(this.action.indexOf('/token-login') > 0)
      this.initTokenLogin()
    else if(this.action.indexOf('/login') > 0){

      this.system = localStorage.getItem('parkingpass_system')
      
      this.usingForm = true

      if(this.system != 'null'){
        
        this.systemIsSet = true
        this.initLogInForm()

      } else {
        
        this.systemIsSet = false

      }

    } else
      this.router.navigate(['home'])
    
  }

  public navigateHome(){

    let route = `/${this.translateService.currentLang}/home` 
    this.router.navigate([route])
    
  }

  public goToForgotPassword(){

    let systemForgotPasswordLink = `${systemDomain(this.system)}/forgot-password`

    window.open(systemForgotPasswordLink, "_blank")

  }
  
  ngOnInit() {

    localStorage.setItem('parkingpass_v1Token', null)
    localStorage.setItem('parkingpass_userEmail', null)
    localStorage.setItem('parkingpass_loggedIn', null)

    localStorage.setItem('parkingpass_userId', null)
    localStorage.setItem('parkingpass_userIdToLogin', null)

    localStorage.setItem('parkingpass_userInfo', null)
    localStorage.setItem('parkingpass_propertyInfo', null)
    localStorage.setItem('parkingpass_ownerInfo', null)
    localStorage.setItem('parkingpass_vehicleList', null)

    const activatedRoute = this.route.params.subscribe(

      params => {
        this.card = params.card
        this.system = params.system
        
        if(this.system !== undefined)
          localStorage.setItem('parkingpass_system', this.system)
        
        this.userUId = params.userUId
        this.token = params.token
        this.activateFeatures()
      }

    )

  }

}
