import { FormGroup, ValidationErrors } from "@angular/forms"

export function getFormValidationErrors(form: FormGroup): boolean {

    let errors = false

    Object.keys(form.controls).forEach(key => {

      form.get(key).markAsTouched()
      form.get(key).updateValueAndValidity()

      const controlErrors: ValidationErrors = form.get(key).errors

      if (controlErrors != null) errors = true
    
    })

    return errors

  }