export function passwordHelper(password: string, formControlError: string): any{

    if(password == '') return {'color' : '#f44336'}

    let re

    switch(formControlError){
      case 'oneUpperCase':
        re = /[A-Z]/
        break
      case 'oneLowerCase':
        re = /[a-z]/
        break
      case 'oneNumber':
        re = /[0-9]/
        break
      case 'oneSpecialCharacter':
        re = /(?=.*[~!@#$%^&*_\-+=`|\(){}[\]:;"'<>,\.\?\/])/
        break
    }
    
    if(formControlError == 'wrongLength'){
      if((password.length < 8) || (password.length > 135))
        return {'color' : '#f44336'}
      else
        return {'color' : 'var(--parkingpass-success-green)'}
    } else if (!re.test(password)) { 
      return {'color' : '#f44336'}
    } else {
      return {'color' : 'var(--parkingpass-success-green)'}
    }

}