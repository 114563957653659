import { HttpClient, HttpEventType } from '@angular/common/http'
import { Component, OnInit, ViewChild, Input, ElementRef, EventEmitter, Output } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { faFolder, faEye, faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { STATE_LIST } from 'src/app/lists/state.list'
import { VEHICLE_COLOR_LIST } from 'src/app/lists/color.list'
import { vehicleYearList } from 'src/app/lists/vehicle-year.list'
import { VehicleService } from 'src/app/services/vehicle/vehicle.service'
import { MustMatch } from 'src/app/validators/must-match.validator'
import { environment } from 'src/environments/environment'
import { LpMustBeUniqueInProperty } from 'src/app/validators/sign-up/lp-must-be-unique-in-property.validator'
import { LpMustNotBeBannedInProperty } from 'src/app/validators/sign-up/lp-must-not-be-banned-in-property.validator'
import { TranslateService } from '@ngx-translate/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { VinInfoComponent } from './vin-info/vin-info.component'
import { MyErrorStateMatcher } from 'src/app/helpers/default.error-matcher'
import { ReplaySubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { LicensePlateInfoComponent } from './license-plate-info/license-plate-info.component'
import { MustNotMatchLpVin } from 'src/app/validators/sign-up/must-not-match-lp-and-vin'
import { logErrorMessage, retryWithBackOff } from 'src/app/helpers/retry.operators'
import { formatBytes } from 'src/app/helpers/string-formatting.helper'
import { VehicleDocumentUploadError, VehicleMakesError } from 'src/app/errors/vehicle-errors/vehicle.errors'

const REGISTRATION_UPLOAD_API_URL = `${environment.api_endpoint}/v2/vehicles/vehicle-documents/upload-document`
const DEFAULT_MAX_UPLOAD_SIZE = 5000000

@Component({
  selector: 'app-vehicles-info',
  templateUrl: './vehicles-info.component.html',
  styleUrls: ['./vehicles-info.component.css', '../sign-up.component.css']
})
export class VehiclesInfoComponent implements OnInit {

  @Input() propertyInfo: any

  @Input() vehicles: Array<VehiclesInfoComponent>
  @Input() vehicleIndex: number

  @ViewChild('parkingpassSignUpIssues')                parkingpassSignUpIssues

  @ViewChild('vehicleRegistrationFileUploaderInput')   vehicleRegistrationFileUploaderInput
  @ViewChild('vehicleInsuranceFileUploaderInput')      vehicleInsuranceFileUploaderInput
  @ViewChild('vehicleDriversLicenseFileUploaderInput') vehicleDriversLicenseFileUploaderInput

  @ViewChild('vehicleRegistrationFileDisplay')         vehicleRegistrationFileDisplayTitle
  @ViewChild('vehicleInsuranceFileDisplay')            vehicleInsuranceFileDisplayTitle
  @ViewChild('vehicleDriversLicenseFileDisplay')       vehicleDriversLicenseFileDisplayTitle

  @ViewChild('registrationExpDatePickerInput')         registrationExpDatePickerInput
  @ViewChild('registrationExpDatePicker')              registrationExpDatePicker
  @ViewChild('registrationExpDatePickerToggle')        registrationExpDatePickerToggle

  @ViewChild('parkingpassVehicleRegistration')         parkingpassVehicleRegistration
  @ViewChild('parkingpassVehicleInsurance')            parkingpassVehicleInsurance
  @ViewChild('parkingpassVehicleDriversLicense')       parkingpassVehicleDriversLicense

  @Output('vehicleLoading')                            vehicleLoading = new EventEmitter()
  @Output('primaryVehicleChange')                      primaryVehicleChange = new EventEmitter()

  public vehicleInfo: any

  public faFolder = faFolder
  public faEye = faEye
  public faSpinner = faSpinner
  public faInfoCircle = faInfoCircle

  public vehicleInfoForm: FormGroup

  public submittingForm: boolean = false
  public submitted: boolean = false

  public vehicleRegistrationFile: any
  public vehicleDriversLicenseFile: any
  public vehicleInsuranceFile: any

  public vehicleRegistrationFilePreview: any
  public vehicleDriversLicenseFilePreview: any
  public vehicleInsuranceFilePreview: any

  public uploadingVehicleDocument: boolean = false

  public parkingpassVehicleDriversLicenseMediaMessage: string = ""
  public parkingpassVehicleRegistrationMediaMessage: string = ""
  public parkingpassVehicleInsuranceMediaMessage: string = ""

  public loading: boolean = false

  public mediaUploadProgress: number = 0

  public stateList = STATE_LIST

  public vehicleYears: Array<any>
  public showVehicleYears: boolean = false

  public vehicleMakes: Array<any>
  public showVehicleMakes: boolean = false

  public vehicleModels: Array<any>
  public showVehicleModels: boolean = false

  public vehicleColors: Array<any> = VEHICLE_COLOR_LIST
  public showVehicleColors: boolean = false

  public uniqueUserDirectory: string = null

  public minDate: Date = new Date()

  public vehicleRegistrationUploadingMessage: string = null
  public vehicleInsuranceUploadingMessage: string = null
  public vehicleDriversLicenseUploadingMessage: string = null

  public language: string

  public isLoggedIn: string = '0'

  public vehicleInfoLoggedIn: any

  public uuid: string = null

  public driversLicenseUpdated: boolean = false
  public registrationUpdated: boolean = false
  public insuranceUpdated: boolean = false

  public vehicleProvided: boolean = false

  public vehicleInsuranceFileType: string
  public vehicleRegistrationFileType: string
  public vehicleLicenseFileType: string

  public errorMatcher = new MyErrorStateMatcher()

  public parkingpassVehicleModelFilter: FormControl = new FormControl()

  public filteredModels: ReplaySubject<any[]> = new ReplaySubject<any[]>(1)

  public parkingpassVehicleMakeFilter: FormControl = new FormControl()

  public filteredMakes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1)

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>()

  constructor(private http: HttpClient,
              private formBuilder: FormBuilder,
              public vehiclesService: VehicleService,
              private translateService: TranslateService,
              private matDialog: MatDialog,
              private el: ElementRef) { }

  protected filterModels() {

    if (!this.vehicleModels) return

    // get the search keyword
    let search = this.parkingpassVehicleModelFilter.value

    if (!search) {
      this.filteredModels.next(this.vehicleModels.slice())
      return
    } else
      search = search.toLowerCase()

    // filter the models
    this.filteredModels.next(
      this.vehicleModels.filter(model => model.model_name.toLowerCase().indexOf(search) > -1)
    )

  }

  protected filterMakes() {

    if (!this.vehicleMakes) return

    // get the search keyword
    let search = this.parkingpassVehicleMakeFilter.value

    if (!search) {
      this.filteredMakes.next(this.vehicleMakes.slice())
      return
    } else
      search = search.toLowerCase()

    // filter the make
    this.filteredMakes.next(
      this.vehicleMakes.filter(make => make.make_name.toLowerCase().indexOf(search) > -1)
    )

  }

  public startInsuranceUploader() {
    this.vehicleInsuranceFileUploaderInput.nativeElement.click()
  }

  public startRegistrationUploader() {
    this.vehicleRegistrationFileUploaderInput.nativeElement.click()
  }

  public startLicenseUploader() {
    this.vehicleDriversLicenseFileUploaderInput.nativeElement.click()
  }

  public scrollToEl(el){
    if(el === undefined || el.nativeElement === undefined) return
    el.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  public uploadDefault(files, vehicleDocumentType: string): void {

    if(this.uploadingVehicleDocument) return

    const fileListLength = files.length

    if (fileListLength === 0) {

      switch(vehicleDocumentType){

        case 'registration':

          this.translateService.get('VEHICLE_INFO.REGISTRATION_REQUIRED').subscribe(
            resp => {
              this.parkingpassVehicleRegistrationMediaMessage = resp
            }
          )
          this.scrollToEl(this.parkingpassVehicleRegistration)
          break

        case 'insurance':
          this.translateService.stream('VEHICLE_INFO.INSURANCE_REQUIRED').subscribe(
            resp => {
              this.parkingpassVehicleInsuranceMediaMessage = resp
            }
          )
          this.scrollToEl(this.parkingpassVehicleInsurance)
          break

        case 'license':
          this.translateService.stream('VEHICLE_INFO.DRIVERS_LICENSE_REQUIRED').subscribe(
            resp => {
              this.parkingpassVehicleDriversLicenseMediaMessage = resp
            }
          )
          this.scrollToEl(this.parkingpassVehicleDriversLicense)
          break

      }

      return

    } else if (fileListLength > 1) {

      switch(vehicleDocumentType){
        case 'registration':
          this.translateService.stream('VEHICLE_INFO.REGISTRATION_TOO_LONG_FILE_LENGTH').subscribe(
            resp => {
              this.parkingpassVehicleRegistrationMediaMessage = resp
            }
          )
          this.scrollToEl(this.parkingpassVehicleRegistration)
          break
        case 'insurance':
          this.translateService.stream('VEHICLE_INFO.INSURANCE_TOO_LONG_FILE_LENGTH').subscribe(
            resp => {
              this.parkingpassVehicleInsuranceMediaMessage = resp
            }
          )
          this.scrollToEl(this.parkingpassVehicleInsurance)
          break
        case 'license':
          this.translateService.stream('VEHICLE_INFO.LICENSE_TOO_LONG_FILE_LENGTH').subscribe(
            resp => {
              this.parkingpassVehicleDriversLicenseMediaMessage = resp
            }
          )
          this.scrollToEl(this.parkingpassVehicleDriversLicense)
          break
      }
      return

    }

    let vehicleDocument
    let uploadSize = 0

    for (let i = 0; i < fileListLength; i++) {

      vehicleDocument = files[i] as File

      if( vehicleDocument.type !== 'image/jpeg' &&
          vehicleDocument.type !== 'image/gif' &&
          vehicleDocument.type !== 'image/png' &&
          vehicleDocument.type !== 'application/pdf' ){

        let fileTypeMsg = `${this.translateService.instant('VEHICLE_INFO.ACCEPTED_FILE_TYPES')} .pdf, .jpeg, .jpg, .png, & .gif`

        switch(vehicleDocumentType){
          case 'registration':
            this.parkingpassVehicleRegistrationMediaMessage = fileTypeMsg
            this.scrollToEl(this.parkingpassVehicleRegistration)
            break
          case 'insurance':
            this.parkingpassVehicleInsuranceMediaMessage = fileTypeMsg
            this.scrollToEl(this.parkingpassVehicleInsurance)
            break
          case 'license':
            this.parkingpassVehicleDriversLicenseMediaMessage = fileTypeMsg
            this.scrollToEl(this.parkingpassVehicleDriversLicense)
            break
        }

        return

      }

      uploadSize += vehicleDocument.size

      if (uploadSize > DEFAULT_MAX_UPLOAD_SIZE) {

        switch(vehicleDocumentType){
          case 'registration':
            this.parkingpassVehicleRegistrationMediaMessage = this.translateService.instant('VEHICLE_INFO.MAX_FILE_UPLOAD')
            this.scrollToEl(this.parkingpassVehicleRegistration)
            break
          case 'insurance':
            this.parkingpassVehicleInsuranceMediaMessage = this.translateService.instant('VEHICLE_INFO.MAX_FILE_UPLOAD')
            this.scrollToEl(this.parkingpassVehicleInsurance)
            break
          case 'license':
            this.parkingpassVehicleDriversLicenseMediaMessage = this.translateService.instant('VEHICLE_INFO.MAX_FILE_UPLOAD')
            this.scrollToEl(this.parkingpassVehicleDriversLicense)
            break
        }

        return

      }

    }

    let reader = new FileReader()
    reader.readAsDataURL(files[0])

    switch(vehicleDocumentType){

      case 'registration':
        this.vehicleRegistrationFileType = vehicleDocument.type
        reader.onload = (_event) => {
          this.vehicleRegistrationFilePreview = reader.result
          this.scrollToEl(this.vehicleRegistrationFileDisplayTitle)
        }
        this.vehicleRegistrationUploadingMessage =  this.translateService.instant('LOADING')
        break

      case 'insurance':
        this.vehicleInsuranceFileType = vehicleDocument.type
        reader.onload = (_event) => {
          this.vehicleInsuranceFilePreview = reader.result
          this.scrollToEl(this.vehicleInsuranceFileDisplayTitle)
        }
        this.vehicleInsuranceUploadingMessage = this.translateService.instant('LOADING')
        break

      case 'license':
        this.vehicleLicenseFileType = vehicleDocument.type
        reader.onload = (_event) => {
          this.vehicleDriversLicenseFilePreview = reader.result
          this.scrollToEl(this.vehicleDriversLicenseFileDisplayTitle)
        }
        this.vehicleDriversLicenseUploadingMessage = this.translateService.instant('LOADING')
        break

    }

    const formData = new FormData()

    formData.append('unique_user_directory', this.uniqueUserDirectory)
    formData.append('property_uuid', this.propertyInfo.property_uuid)
    formData.append('system', this.propertyInfo.system)
    formData.append('vehicle_document_type', vehicleDocumentType)
    formData.append('vehicle_document', vehicleDocument, vehicleDocument.name)
    formData.append('api_key', environment.api_key)

    switch(vehicleDocumentType){
      case 'registration':
        this.parkingpassVehicleRegistrationMediaMessage = ""
        break
      case 'insurance':
        this.parkingpassVehicleInsuranceMediaMessage = ""
        break
      case 'license':
        this.parkingpassVehicleDriversLicenseMediaMessage = ""
        break
    }

    this.http.post(REGISTRATION_UPLOAD_API_URL, formData, {reportProgress: true, observe: 'events'}).pipe(
      retryWithBackOff(1000)
    ).subscribe(
      event => {

        if (event.type === HttpEventType.UploadProgress)
          this.mediaUploadProgress = Math.round(100 * event.loaded / event.total)
        else if (event.type === HttpEventType.Response)
          this.registrationUploadFinished(event.body)

      },
      error => {

        let errorString = null;

        if ( typeof error === 'string' ) {
          errorString = error;
        }

        let sentryContext: any = {
          errorName: 'Vehicle Document Upload Error',
          vehicleDocument: vehicleDocumentType,
          vehicleDocumentFileType: vehicleDocument.type,
          vehicleDocumentFileSize: formatBytes(vehicleDocument.size, 2),
          error: errorString
        }

        sentryContext.error = new VehicleDocumentUploadError(sentryContext.errorName)

        logErrorMessage(3, error, sentryContext, this.matDialog)

      }
    )

    return

  }

  private registrationUploadFinished(resp: any): void{

    if(resp.success){

      const dataPath = decodeURIComponent(resp.data.path)

      switch(resp.data.vehicle_document_type){

        case 'registration':
          this.vehicleRegistrationFile = dataPath
          this.vehicleRegistrationUploadingMessage = null
          this.vehicleInfoForm.get('parkingpassRegistrationProof').setValue(this.vehicleRegistrationFile)
          this.registrationUpdated = true
          break

        case 'insurance':
          this.vehicleInsuranceFile = dataPath
          this.vehicleInsuranceUploadingMessage = null
          this.vehicleInfoForm.get('parkingpassInsuranceProof').setValue(this.vehicleInsuranceFile)
          this.insuranceUpdated = true
          break

        case 'license':
          this.vehicleDriversLicenseFile = dataPath
          this.vehicleDriversLicenseUploadingMessage = null
          this.vehicleInfoForm.get('parkingpassDriversLicense').setValue(this.vehicleDriversLicenseFile)
          this.driversLicenseUpdated = true
          break

      }

      this.uniqueUserDirectory = resp.data.unique_user_directory

    }

  }

  public clickDatePicker(picker: any): void{
    picker.nativeElement.click()
  }

  get i() { return this.vehicleInfoForm.controls }

  get parkingpassVehicleType() { return this.vehicleInfoForm.get('parkingpassVehicleType').value }
  get parkingpassVehicleMake() { return this.vehicleInfoForm.get('parkingpassVehicleMake').value }
  get parkingpassVehicleModel() { return this.vehicleInfoForm.get('parkingpassVehicleModel').value }
  get parkingpassVehicleYear() { return this.vehicleInfoForm.get('parkingpassVehicleYear').value }
  get parkingpassVehicleColor() { return this.vehicleInfoForm.get('parkingpassVehicleColor').value }
  get parkingpassVehicleVin() { return this.vehicleInfoForm.get('parkingpassVehicleVin').value }
  get parkingpassVehicleVinConfirm() { return this.vehicleInfoForm.get('parkingpassVehicleVinConfirm').value }
  get parkingpassVehicleLicensePlate() { return this.vehicleInfoForm.get('parkingpassVehicleLicensePlate').value }
  get parkingpassVehicleLicensePlateConfirm() { return this.vehicleInfoForm.get('parkingpassVehicleLicensePlateConfirm').value }
  get parkingpassVehicleState() { return this.vehicleInfoForm.get('parkingpassVehicleState').value }
  get parkingpassRegistrationProof() { return this.vehicleInfoForm.get('parkingpassRegistrationProof').value }
  get parkingpassInsuranceProof() { return this.vehicleInfoForm.get('parkingpassInsuranceProof').value }
  get parkingpassDriversLicense() { return this.vehicleInfoForm.get('parkingpassDriversLicense').value }
  get parkingpassVehicleRegistrationExpDate() { return this.vehicleInfoForm.get('parkingpassVehicleRegistrationExpDate').value }
  get parkingpassVehicleInsuranceExpDate() { return this.vehicleInfoForm.get('parkingpassVehicleInsuranceExpDate').value }
  get parkingPassPrimaryVehicle() { return this.vehicleInfoForm.get('parkingPassPrimaryVehicle').value }

  public resetVehicleDropdowns(): void{

    this.vehicleLoading.emit(true)

    if(this.parkingpassVehicleType === 'car/truck'){

      this.vehicleYears = vehicleYearList()
      this.showVehicleYears = true
      this.vehicleLoading.emit(false)

      if(this.vehicleProvided){

        this.vehicleInfoForm.get('parkingpassVehicleYear').setValue(parseInt(this.vehicleInfoLoggedIn.year))
        this.getVehicleMakes()

      }

    } else if(this.parkingpassVehicleType === 'motorcycle/scooter'){

      this.vehiclesService.getVehicleMakes('motorcycle/scooter').subscribe(

        resp => {

          this.vehicleMakes = resp.data

          // load the initial make list
          this.filteredMakes.next(this.vehicleMakes.slice())

          // listen for search field value changes
          this.parkingpassVehicleMakeFilter.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterMakes()
          })

          this.showVehicleMakes = true
          this.showVehicleModels = true
          this.vehicleLoading.emit(false)

          if(this.vehicleProvided){
            this.vehicleInfoForm.get('parkingpassVehicleMake').setValue(this.vehicleInfoLoggedIn.make)
            this.vehicleInfoForm.get('parkingpassVehicleModel').setValue(this.vehicleInfoLoggedIn.model)
          }

        },

        err => {

          let sentryContext: any = {
            errorName: 'Get Vehicle Makes Error',
            serverResponse: err,
            requestRetries: 3,
            errorMessage: this.translateService.instant('VEHICLE_SERVER_ERRORS.GET_MAKES_ERROR'),
            vehicleType: 'motorcycle/scooter'
          }

          sentryContext.error = new VehicleMakesError(sentryContext.errorName)

          logErrorMessage(3, err, sentryContext, this.matDialog)

        }

      )

      this.vehicleInfoForm.get('parkingpassVehicleModel').setValue('')

    }

  }

  public getVehicleMakes(): void{

    this.vehicleLoading.emit(true)

    this.vehicleInfoForm.get('parkingpassVehicleMake').reset()

    const vehicleType = this.parkingpassVehicleType

    this.vehiclesService.getVehicleMakes(vehicleType).subscribe(

      resp => {

        this.vehicleMakes = resp.data

        // load the initial make list
        this.filteredMakes.next(this.vehicleMakes.slice())

        // listen for search field value changes
        this.parkingpassVehicleMakeFilter.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterMakes()
        })

        this.showVehicleMakes = true
        this.vehicleLoading.emit(false)

        if(this.vehicleProvided){
          this.vehicleInfoForm.get('parkingpassVehicleMake').setValue(this.vehicleInfoLoggedIn.make)
          this.getVehicleModels()
        }

      },

      err => {

        let sentryContext: any = {
          errorName: 'Get Vehicle Makes Error',
          serverResponse: err,
          requestRetries: 3,
          errorMessage: this.translateService.instant('VEHICLE_SERVER_ERRORS.GET_MAKES_ERROR'),
          vehicleType: 'car/truck'
        }

        sentryContext.error = new VehicleMakesError(sentryContext.errorName)

        logErrorMessage(3, err, sentryContext, this.matDialog)

      }

    )

  }

  public getVehicleModels(): void{

    this.showVehicleModels = true

    if(this.vehicleProvided){
      this.vehicleInfoForm.get('parkingpassVehicleModel').setValue(this.vehicleInfoLoggedIn.model)
      this.vehicleInfoForm.get('parkingpassVehicleColor').setValue(this.vehicleInfoLoggedIn.color)
    }

    /*
    this.vehicleLoading.emit(true)

    if(this.parkingpassVehicleType == 'motorcycle/scooter'){
      this.vehicleLoading.emit(false)
      return
    }

    this.vehicleInfoForm.get('parkingpassVehicleModel').reset()

    const make_id: any = this.vehicleMakes.find(
      vehicle => {
        if(vehicle.make_name === this.parkingpassVehicleMake)
          return vehicle
      }
    ).make_id

    const year: number = this.parkingpassVehicleYear

    this.vehiclesService.getVehicleModels(make_id, year).subscribe(

      resp =>{

        this.vehicleModels = convertKeysToLowerCase(resp.data)

        // load the initial model list
        this.filteredModels.next(this.vehicleModels.slice())

        // listen for search field value changes
        this.parkingpassVehicleModelFilter.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterModels()
        })

        this.showVehicleModels = true
        this.vehicleLoading.emit(false)

        if(this.vehicleProvided){
          this.vehicleInfoForm.get('parkingpassVehicleModel').setValue(this.vehicleInfoLoggedIn.model)
          this.vehicleInfoForm.get('parkingpassVehicleColor').setValue(this.vehicleInfoLoggedIn.color)
        }

      }

    )
    */
  }

  public vehicleFields(formControlName: string): any{

    let style = { 'display' : 'none' }

    switch(formControlName){

      case 'parkingpassVehicleYear':

        if(this.parkingpassVehicleType !== 'motorcycle/scooter'){

          const validators = [Validators.required]

          this.vehicleInfoForm.get('parkingpassVehicleYear').setValidators(validators)

          style = { 'display' : 'inline-block' }

        } else if(this.parkingpassVehicleType === 'motorcycle/scooter'){

          const validators = []
          this.vehicleInfoForm.get('parkingpassVehicleYear').setValidators(validators)

        }

        break

      case 'parkingpassVehicleMake':

        if((this.parkingpassVehicleType === 'motorcycle/scooter' ||
            this.parkingpassVehicleType === 'car/truck')){

          const validators = [Validators.required]
          this.vehicleInfoForm.get('parkingpassVehicleMake').setValidators(validators)

          if((this.parkingpassVehicleType === 'car/truck' &&
              this.parkingpassVehicleYear !== null) ||
              this.parkingpassVehicleType == 'motorcycle/scooter'){

            style = { 'display' : 'inline-block' }

          }

        }

        break

      case 'parkingpassVehicleModel':

        if(this.parkingpassVehicleType !== 'motorcycle/scooter' &&
          this.parkingpassVehicleYear !== null &&
          this.parkingpassVehicleMake !== null){

          const validators = [Validators.required]
          this.vehicleInfoForm.get('parkingpassVehicleModel').setValidators(validators)

          style = { 'display' : 'inline-block' }

        } else if(this.parkingpassVehicleType === 'motorcycle/scooter'){

          const validators = [Validators.required]
          this.vehicleInfoForm.get('parkingpassVehicleModel').setValidators(validators)

          style = { 'display' : 'inline-block' }

        }

        break

      case 'parkingpassVehicleColor':

        if(this.parkingpassVehicleType !== 'motorcycle/scooter' &&
          this.parkingpassVehicleYear !== null &&
          this.parkingpassVehicleMake !== null &&
          this.parkingpassVehicleModel !== null){

          const validators = [Validators.required]

          this.vehicleInfoForm.get('parkingpassVehicleColor').setValidators(validators)

          style = { 'display' : 'inline-block'}

        } else if(this.parkingpassVehicleType === 'motorcycle/scooter'){

          const validators = []
          this.vehicleInfoForm.get('parkingpassVehicleColor').setValidators(validators)

        }

        break

    }

    this.vehicleInfoForm.get(formControlName).updateValueAndValidity()

    return style

  }

  public initForm(): void{

    const vehicleLicensePlateValidators = [Validators.required]
    const vehicleLicensePlateConfirmValidators = [Validators.required]
    const vehicleLicenseVinValidators = [Validators.required, Validators.minLength(6), Validators.maxLength(6)]
    const vehicleLicenseconfirmVinValidators = [Validators.required]
    const vehicleStateValidators = [Validators.required]
    const vehicleTypeValidators = [Validators.required]

    let vehicleRegistrationProofValidators
    if(this.propertyInfo.documents.vehicle_registration)
      vehicleRegistrationProofValidators = [Validators.required]
    else
      vehicleRegistrationProofValidators = []

    let vehicleRegistrationExpDateValidators
    if(this.propertyInfo.documents.vehicle_registration_expiration)
      vehicleRegistrationExpDateValidators = [Validators.required]
    else
      vehicleRegistrationExpDateValidators = []

    let vehicleInsuranceProofValidators
    if(this.propertyInfo.documents.proof_of_insurance)
      vehicleInsuranceProofValidators = [Validators.required]
    else
      vehicleInsuranceProofValidators = []

    let vehicleInsuranceExpDateValidators
    if(this.propertyInfo.documents.proof_of_insurance_expiration)
      vehicleInsuranceExpDateValidators = [Validators.required]
    else
      vehicleInsuranceExpDateValidators = []

    let vehicleDriversLicenseProofValidators
    if(this.propertyInfo.documents.drivers_license)
      vehicleDriversLicenseProofValidators = [Validators.required]
    else
      vehicleDriversLicenseProofValidators = []

    let vehiclePrimaryVehiclesValidators
    if(this.propertyInfo.settings.primary_vehicles_enabled)
      vehiclePrimaryVehiclesValidators = [Validators.required]
    else
      vehiclePrimaryVehiclesValidators = []


    let vehicleUuidValidators
    let alreadySetTag

    if(this.vehicleProvided){
      vehicleUuidValidators = [Validators.required]
      alreadySetTag = this.vehicleInfoLoggedIn.tag.toUpperCase()
    } else {
      vehicleUuidValidators = []
      alreadySetTag = null
    }

    this.vehicleInfoForm = this.formBuilder.group({

      parkingpassVehicleLicensePlate: ['',
        vehicleLicensePlateValidators,
        [
          LpMustNotBeBannedInProperty.valid(this.vehiclesService, this.propertyInfo.system, this.propertyInfo.location),
          LpMustBeUniqueInProperty.valid(this.vehiclesService, this.propertyInfo.system, this.propertyInfo.location, alreadySetTag),
        ]
      ],
      parkingpassVehicleLicensePlateConfirm: ['', vehicleLicensePlateConfirmValidators],
      parkingpassVehicleVin: ['', vehicleLicenseVinValidators],
      parkingpassVehicleVinConfirm: ['', vehicleLicenseconfirmVinValidators],
      parkingpassVehicleState: ['', vehicleStateValidators],
      parkingpassVehicleType: ['', vehicleTypeValidators],
      parkingpassVehicleMake: [],
      parkingpassRegistrationProof: ['', vehicleRegistrationProofValidators],
      parkingpassInsuranceProof: ['', vehicleInsuranceProofValidators],
      parkingpassDriversLicense: ['', vehicleDriversLicenseProofValidators],
      parkingpassVehicleModel: [],
      parkingpassVehicleYear: [],
      parkingpassVehicleColor: [],
      parkingpassVehicleRegistrationExpDate: ['', vehicleRegistrationExpDateValidators],
      parkingpassVehicleInsuranceExpDate: ['', vehicleInsuranceExpDateValidators],
      parkingPassPrimaryVehicle: ['', vehiclePrimaryVehiclesValidators],
      parkingpassVehicleUuid: ['', vehicleUuidValidators]

    }, {
      validators : [
        MustMatch('parkingpassVehicleLicensePlate', 'parkingpassVehicleLicensePlateConfirm'),
        MustNotMatchLpVin('parkingpassVehicleLicensePlate', 'parkingpassVehicleVin'),
        MustMatch('parkingpassVehicleVin', 'parkingpassVehicleVinConfirm'),
        LpMustNotBeBannedInProperty.validate(this.vehiclesService, 'parkingpassVehicleLicensePlate', this.propertyInfo.system, this.propertyInfo.location),
        LpMustBeUniqueInProperty.validate(this.vehiclesService, 'parkingpassVehicleLicensePlate', this.propertyInfo.system, this.propertyInfo.location, alreadySetTag)
      ]
    })

    if(this.vehicleProvided){

      this.vehicleInfoForm.get('parkingpassVehicleLicensePlate').setValue(this.vehicleInfoLoggedIn.tag.toUpperCase())
      this.vehicleInfoForm.get('parkingpassVehicleLicensePlateConfirm').setValue(this.vehicleInfoLoggedIn.tag.toUpperCase())

      this.vehicleInfoForm.get('parkingpassVehicleVin').setValue(this.vehicleInfoLoggedIn.vin_number)
      this.vehicleInfoForm.get('parkingpassVehicleVinConfirm').setValue(this.vehicleInfoLoggedIn.vin_number)

      this.vehicleInfoForm.get('parkingpassVehicleState').setValue(this.vehicleInfoLoggedIn.vehicle_state)

      this.vehicleInfoForm.get('parkingpassVehicleType').setValue(this.vehicleInfoLoggedIn.vehicle_type)

      this.resetVehicleDropdowns()

      this.vehicleInfoForm.get('parkingpassRegistrationProof').setValue(this.vehicleInfoLoggedIn.vehiclereg)
      this.vehicleInfoForm.get('parkingpassInsuranceProof').setValue(this.vehicleInfoLoggedIn.insuranceproof)
      this.vehicleInfoForm.get('parkingpassDriversLicense').setValue(this.vehicleInfoLoggedIn.drivinglicense)

      this.vehicleInfoForm.get('parkingpassVehicleRegistrationExpDate').setValue(this.vehicleInfoLoggedIn.vehiclereg_expiration)
      this.vehicleInfoForm.get('parkingpassVehicleInsuranceExpDate').setValue(this.vehicleInfoLoggedIn.insuranceproof_expiration)

      this.vehicleInfoForm.get('parkingpassVehicleUuid').setValue(this.vehicleInfoLoggedIn.uuid)

    } else
      this.vehicleInfoForm.get('parkingPassPrimaryVehicle').setValue('Secondary')

  }

  public updatePrimaryVehicles(){
    this.primaryVehicleChange.emit({'vehicleIndex': this.vehicleIndex})
  }

  public datePickerOpen(event: KeyboardEvent, datePickerElement){

    if(event.code !== "Tab" && event.code !== "ShiftLeft")
      datePickerElement.open()

  }

  public checkForFormFieldDisplay(field: any){

    if(field)
      return { 'display' : 'inline-block' }
    else
      return { 'display' : 'none' }

  }

  public vinInfo(): void{

    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = true
    dialogConfig.panelClass = "vinPlateInfoPanel"

    let dialogRef = this.matDialog.open(
      VinInfoComponent,
      dialogConfig
    )

  }

  public plateInfo(): void{

    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = true
    dialogConfig.panelClass = "vinPlateInfoPanel"

    let dialogRef = this.matDialog.open(
      LicensePlateInfoComponent,
      dialogConfig
    )

  }

  public scrollToFirstInvalidControl() {

    let firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "mat-error"
    );

    if(firstInvalidControl === null){
      firstInvalidControl = this.el.nativeElement.querySelector(
        "form .ng-invalid"
      );
    }

    if(firstInvalidControl === null) return

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });

  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 100;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  public maxLengthThis(formControlName, int){

    let max = int //The maxlength you want

    let inputControl = this.vehicleInfoForm.get(formControlName)

    if(inputControl.value === null) return

    if(inputControl.value.length > max)
      inputControl.setValue(inputControl.value.substring(0, max));

  }

  ngOnInit() {

    this.isLoggedIn = localStorage.getItem('parkingpass_loggedIn')

    if(this.isLoggedIn == '1'){

      this.vehicleInfoLoggedIn = JSON.parse(localStorage.getItem('parkingpass_vehicleList'))[this.vehicleIndex]

      if(this.vehicleInfoLoggedIn !== undefined){
        this.uuid = this.vehicleInfoLoggedIn.uuid
        this.vehicleProvided = true
      }

    }

    this.initForm()

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


}
