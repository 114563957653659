import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { SignUpComponent } from './parkingpass/logged-out/sign-up/sign-up.component'
import { LogInComponent } from './parkingpass/logged-out/log-in/log-in.component'
import { HomeComponent } from './home/home.component'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { HttpInterceptorService } from './helpers/http-interceptor/http-interceptor.service'
import { LoginGuardServiceService } from './services/route-services/login-guard-service.service'
import { LanguageRouterComponent } from './language-router/language-router.component'
import { systemFromPath } from './helpers/system.helper'


export const routes: Routes = [
  
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LogInComponent },
  
  { path: 'token-login/:card/:system/:userUId/:token', component: LogInComponent },

  { path: 'register', component: SignUpComponent },
  { path: 'en-us/register/:keyword', component: SignUpComponent },


  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule', canActivate: [LoginGuardServiceService] },

  { path: ':uknownRoute', component: LanguageRouterComponent },  
  { path: "**", redirectTo: appropiateRedirect(window.location.pathname) }

]

function appropiateRedirect(currentPath: string): string{

  if(currentPath.indexOf('en-us') > -1 ||
    currentPath.indexOf('pt-pt') > -1 ||
    currentPath.indexOf('es-mx') > -1 ||
    currentPath.indexOf('ht-ht') > -1){
      let route = currentPath.split('/')
      return '/' + route[2]
  }

  let acutalPath = []

  if(currentPath.indexOf('?') > -1){
    acutalPath = currentPath.split('?')
    currentPath = acutalPath[0]
    acutalPath[1] = `?${acutalPath[1]}`
  }

  if(currentPath.indexOf('/token-login') > -1){
    localStorage.setItem('parkingpass_system', systemFromPath(currentPath))
    return currentPath;
  }

  switch(currentPath){
    case '/home':
    case '/login':
    case '/register':
    case '/dashboard':
    case '/password':
    case '/confirm-email':
      if(acutalPath.length > 0)
        return currentPath + acutalPath[1]
      else
        return currentPath
    default:
      return '/home'
  }

}

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }
  ]
})

export class AppRoutingModule { }
