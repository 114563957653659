import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFormatPipe, TimeFormatPipe } from './date-format.pipe';
import { NumberFormatPipe } from './number-format.pipe';

@NgModule({
  declarations: [
    DateFormatPipe,
    TimeFormatPipe,
    NumberFormatPipe
  ],
  imports: [
    CommonModule
  ],
  exports : [
    DateFormatPipe,
    TimeFormatPipe,
    NumberFormatPipe
  ]
})
export class ParkingPassPipesModule { }
