import { Directive,ElementRef, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
 selector: '[vehicleModel]',
 providers: [NgModel]
})
export class VehicleModelDirective {

    constructor(public ref: ElementRef) { }

    @HostListener('input', ['$event']) lettersNumbersAndSpacesOnly(event) {

        let regex = /^[a-zA-Z0-9\s]*$/
        let replaceRegex = /[^a-zA-Z0-9\s]+/
        let str = event.target.value

        // allow letters and spaces only.
        if(!regex.test(event.key)){
            str = str.replace(replaceRegex, "") 
            this.ref.nativeElement.value = str
            event.preventDefault()
        }

    }

    @HostListener('input', ['$event']) replaceInvalidCharacters(event) {

        let replaceRegex = /[^a-zA-Z0-9\s]+/
        let str = event.target.value.replace(replaceRegex, "")
        event.target.value = str

    }

}