import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const modifiedReqOne = this.handleBodyIn(req)
    const modifiedReqTwo = modifiedReqOne.clone({
      withCredentials: false,
    });
    
    return next.handle(modifiedReqTwo);

  }

  handleBodyIn(req:HttpRequest<any>) {

    let locale = localStorage.getItem("LOCALIZE_DEFAULT_LANGUAGE")
    let system = localStorage.getItem('parkingpass_system')

    if(locale == null) locale = 'en-us'

    if (req.method.toLowerCase() === 'post') {
      if (req.body instanceof FormData) {
        req =  req.clone({
          body: req.body.append('locale', locale)
        })
      } else {
        const foo:any = {}; 
        foo['locale'] = locale;
        foo['system'] = system;
        req =  req.clone({
          body: {...req.body, ...foo}
        })
      }            
    } 

    if (req.method.toLowerCase() === 'get') {
      req = req.clone({
        params: req.params.set('locale', locale)
      });
    } 
    return req;    
  }

}