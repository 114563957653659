import { FormGroup } from '@angular/forms';
import { COUNTRY_LIST } from 'src/app/lists/country.list'

// custom validator to check that two fields match
export function ValidateCountry(controlName: string) {
    return (formGroup: FormGroup) => {

        const control = formGroup.controls[controlName];

        if (control.errors && !control.errors.invalid) {
            // return if another validator has already found an error on the control
            return;
        }

        let found = false

        for(let i = 0; i < COUNTRY_LIST.length; i++){
            if(found === true) break
            if(COUNTRY_LIST[i].abbreviation === control.value){
                found = true
            }
        }

        if (found === false)
            control.setErrors({ invalid: true });
        else
            control.setErrors(null);

    }
}
