import { FormGroup } from '@angular/forms'

// custom validator to check that two fields match
export function MustNotMatchNames(firstNameControlName: string, lastNameControlName: string, 
                                    residentFirstName: string, residentLastName: string) {

    return (formGroup: FormGroup) => {

        const ownerFirstName = formGroup.controls[firstNameControlName]
        const ownerLastName = formGroup.controls[lastNameControlName]

        // return if another validator has already found an error on the name
        if (ownerFirstName.errors && !ownerFirstName.errors.invalidInput) { return }

        // set error on matchingControl if validation fails
        if (ownerFirstName.value === residentFirstName && ownerLastName.value === residentLastName) {
            ownerFirstName.setErrors({ mustNotMatch : true })
        } else {
            ownerFirstName.setErrors(null)
        }

    }

}
