import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-license-plate-info',
  templateUrl: './license-plate-info.component.html',
  styleUrls: ['./license-plate-info.component.css']
})
export class LicensePlateInfoComponent implements OnInit {

  public vinPlateHelperImage: string
  public vinPlateHelperImageMobile: string
  public vinPlateHelperImageMobileTwo: string

  constructor(private dialogRef: MatDialogRef<LicensePlateInfoComponent>, @Inject(MAT_DIALOG_DATA) dat, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.vinPlateHelperImage = this.translateService.instant('VEHICLE_INFO.VIN_PLATE_HELPER')
    this.vinPlateHelperImageMobile = this.translateService.instant('VEHICLE_INFO.VIN_PLATE_HELPER_MOBILE')
    this.vinPlateHelperImageMobileTwo = this.translateService.instant('VEHICLE_INFO.VIN_PLATE_HELPER_MOBILE_1')
  }

  close() {
    this.dialogRef.close()
  }

}
