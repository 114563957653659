import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms-main',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsMainComponent implements OnInit {

  public lang: string
  public systemDomain: string
  public system

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.lang = this.translateService.currentLang
    this.translateService.onLangChange.subscribe(
      resp =>{
        this.lang = resp.lang 
      }
    )
  }

}
