import { Directive,ElementRef, HostListener, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, NgControl } from '@angular/forms';

@Directive({
 selector: '[whiteSpace]'
})
export class WhiteSpaceDirective {

    constructor(public ref: ElementRef, private control: NgControl) { }

    @HostListener('focusout', ['$event']) trimWhiteSpaces(event) {

        let str = event.target.value.trim()
        this.control.control.setValue(str)

    }

}