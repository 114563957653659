import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-property-keyword-info',
  templateUrl: './property-keyword-info.component.html',
  styleUrls: ['./property-keyword-info.component.css']
})
export class PropertyKeywordInfoComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<PropertyKeywordInfoComponent>, @Inject(MAT_DIALOG_DATA) dat) {}

  close() {
    this.dialogRef.close()
  }

  ngOnInit(): void {}

}
