<app-loading-screen *ngIf="loading"></app-loading-screen>

<div class='card-title'>
  {{ 'KEYWORD.INSTRUCTIONS_LOG_IN' | translate }}                       
</div>

<form [formGroup]="keywordForm">
  
  <div>

    <div *ngIf="submitted && f.parkingpassKeyword.errors" class="signUpBoxInstructions">

      <mat-error *ngIf="f.parkingpassKeyword.errors.required">
        {{ 'KEYWORD.KEYWORD_REQUIRED' | translate }}
      </mat-error>                       

    </div>
    
      <mat-form-field>

        <input matInput 
                type='text'
                formControlName="parkingpassKeyword"
                autocomplete=off
                placeholder="{{ 'KEYWORD.INPUT_PLACEHOLDER' | translate }}"
                propertyKeyword
                uppercase />

      </mat-form-field>

      <mat-error class='parkingpass-long-error' *ngIf="f.parkingpassKeyword.errors && f.parkingpassKeyword.errors.invalid">
        {{ 'KEYWORD.KEYWORD_INVALID' | translate }}
      </mat-error> 
      
      <mat-error class='parkingpass-long-success' *ngIf="f.parkingpassKeyword.errors && f.parkingpassKeyword.errors.isValid">
        {{ 'KEYWORD.KEYWORD_INVALID' | translate }}
      </mat-error>   

      <br/>

      <button class='text-primary parkingpass-help-info' (click)="enableInfo()" tabindex="0">
        {{ 'KEYWORD.WHATS_THIS' | translate }}<fa-icon class='fa-info' [icon]="faInfoCircle"></fa-icon>
      </button>

    </div>

    <br/><br/>

    <button class='btn btn-primary' (click)="getKeywordPropertyName()" tabindex="0">
      {{ 'KEYWORD.GET_SYSTEM' | translate }}
    </button>

</form>

<br/><br/>

<div class='card-title' *ngIf="(propertyName !== undefined && propertyName !== null) && !f.parkingpassKeyword.errors">
  {{ 'KEYWORD.PROPERTY_NAME' | translate }}: {{ propertyName }}                 
</div>