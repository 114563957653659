import { Component, OnInit, ViewChild} from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'


@Component({
  selector: 'app-menu-logged-out',
  templateUrl: './menu-logged-out.component.html',
  styleUrls: ['./sign-up/sign-up.component.css', '../menu.component.css', './menu-logged-out.component.css']
})
export class MenuLoggedOutComponent implements OnInit {

  @ViewChild('parkingpassMainMenu') parkingpassMainMenu

  public locale

  constructor(private router: Router,              
              private translateService: TranslateService) {
                translateService.setDefaultLang('en-us');
              }

  public initLogin(): void{

    this.router.navigate([`${this.translateService.currentLang}/login`])
    
  }

  public initSignUp(): void{

    this.router.navigate([`${this.translateService.currentLang}/register`])

  }

  ngOnInit(): void {}

  ngAfterViewInit(){}

}