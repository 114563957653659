<app-loading-screen *ngIf="loading"></app-loading-screen>

<div class='parkingpass-logo' #logo (click)="navigateHome()" *ngIf="isLoggedIn != '1'"></div>

<div class='signUpBoxHolder animated fadeInUp' #vc_parkingpass_sign_up_box *ngIf="registrationBox">

    <div #scrollToTop></div>

    <div class="card-title" style="text-align: left;" *ngIf="isLoggedIn == '1'">{{ 'EDIT_YOUR_APPLICATION' | translate }}</div>

    <div class="card text-center signUpBox" #vc_parkingpass_sign_up_box_inner>

        <div class="card-header">

            <ul class="nav nav-tabs card-header-tabs">

                <li class="nav-item" tabindex="0">
                    <a [ngClass]="tabStyle(this.languageCard)" (click)="goToCard(this.languageCard)">{{ 'TABS.LANGUAGE' | translate }}</a>
                </li>

                <li class="nav-item" tabindex="1">
                    <a [ngClass]="tabStyle(this.keywordCard)" (click)="goToCard(this.keywordCard)">{{ 'TABS.KEYWORD' | translate }}</a>
                </li>

                <li class="nav-item" tabindex="2">
                    <a [ngClass]="tabStyle(this.residentCard)" (click)="goToCard(this.residentCard)">{{ 'TABS.RESIDENT_INFO' | translate }}</a>
                </li>

                <li class="nav-item" tabindex="3">
                    <a [ngClass]="tabStyle(this.propertyCard)" (click)="goToCard(this.propertyCard)">{{ 'TABS.PROPERTY_INFO' | translate }}</a>
                </li>

                <li class="nav-item" tabindex="4">
                  <a [ngClass]="tabStyle(this.vehicleCountCard)" (click)="goToCard(this.vehicleCountCard)">{{ 'TABS.VEHICLE_COUNT' | translate }}</a>
                </li>

                <li class="nav-item" tabindex="5">
                    <a [ngClass]="tabStyle(this.vehiclesCard)" (click)="goToCard(this.vehiclesCard)">{{ 'TABS.ADD_VEHICLES' | translate }}</a>
                </li>

                <li class="nav-item" tabindex="6">
                    <a [ngClass]="tabStyle(this.reviewCard)" (click)="goToCard(this.reviewCard)">{{ 'TABS.REVIEW' | translate }}</a>
                </li>

                <li class="nav-item" tabindex="7">
                    <a [ngClass]="tabStyle(this.termsCard)" (click)="goToCard(this.termsCard)">{{ 'TABS.TERMS' | translate }}</a>
                </li>

                <!-- hide parking policy tab when user is logged in -->
                <li *ngIf='isLoggedIn == 1 ; else show_parking_policy '></li>
                <!-- show parking policy tab when user is not logged in -->
                <ng-template #show_parking_policy>
                    <li class="nav-item" tabindex="8">
                        <a [ngClass]="tabStyle(this.parkingPolicyCard)" (click)="goToCard(this.parkingPolicyCard)">{{ 'TABS.PARKING_POLICY' | translate }}</a>
                    </li>
                </ng-template>

            </ul>

            <div class="card-header-tabs-mobile" *ngIf="cardList && cardList[currentCardIndex] && cardList[currentCardIndex].cardTitle">
              {{ cardList[currentCardIndex].cardTitle | translate }}
            </div>

        </div>

        <div class="card-body">

            <div class='card-title' id="sign_up_general_instructions" #parkingpassSignUpIssues></div>

            <div class='tab-pan animated fadeIn' id='language' *ngIf='languageCard.open'>

                <div class='card-title'>
                    {{ 'LANG.WELCOME' | translate }}
                </div>

                <div class='lang-select'>

                    <button type='button' class='btn btn-primary' (click)="selectLocale(lang_en)">Continue in English</button>

                    <button type='button' class='btn btn-primary' (click)="selectLocale(lang_es)">Continuar en Español</button>

                    <button type='button' class='btn btn-primary' (click)="selectLocale(lang_pt)">Continue em Português</button>

                    <button type='button' class='btn btn-primary' (click)="selectLocale(lang_hat)">Kontinye an Kreyol</button>

                </div>

            </div>

            <div class='tab-pan animated fadeIn' id='keyword' [ngStyle]="cardStyle(keywordCard)">

                <app-property-keyword #propertyKeywordCard [keywordForm]="keywordCard.form" (loading)="loadingToggle($event)"></app-property-keyword>

            </div>

            <div class='tab-pan animated fadeIn' id='resident' [ngStyle]="cardStyle(residentCard)">

                <app-resident-info #residentInfoCard></app-resident-info>

            </div>

            <div class='tab-pan animated fadeIn' id='property' [ngStyle]="cardStyle(propertyCard)">

                <app-property-info #propertyInfoCard (ownerFormEvt)="ownerFormEvt()" (loading)="loadingToggle($event)"></app-property-info>

            </div>

            <div class='tab-pan animated fadeIn' id='vehicleCount' [ngStyle]="cardStyle(vehicleCountCard)">

              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th scope="col">{{ 'VEHICLE_INFO.ALLOWED_IN' | translate }}</th>
                    <th scope="col">{{ 'VEHICLE_INFO.REMAINING_VEHICLES' | translate }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th scope="row">
                      <span *ngIf="maxVehiclesPerUnit == 9999;">{{ 'VEHICLE_INFO.NO_LIMIT' | translate }}</span>
                      <span *ngIf="maxVehiclesPerUnit != 9999;">{{ maxVehiclesPerUnit }}</span>
                    </th>

                    <th scope="row">
                      <span *ngIf="maxVehiclesPerUnit == 9999;">{{ 'VEHICLE_INFO.NO_LIMIT' | translate }}</span>
                      <span *ngIf="maxVehiclesPerUnit != 9999;">{{ remainingVehiclesInUnit }}</span>
                    </th>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class='card-title' #parkingpassVehiclesTop *ngIf="remainingVehiclesInUnit === 0 ;" style="color: #721c24;background-color: #f8d7da;border-color: #f5c6cb;position: relative;padding: 0.75rem 1.25rem;margin-bottom: 1rem;border-radius: 0.25rem;">
                {{ 'VEHICLE_INFO.NO_REMAINING_VEHICLES_IN_UNIT_TEXT_MSG' | translate }}
              </div>

            </div>

            <div class='tab-pan animated fadeIn' id='vehicles' [ngStyle]="cardStyle(vehiclesCard)">

                <form [formGroup]="vehicleInCommunityForm">

                    <div class='parkingpass-formFieldWrapper' style="position: relative; display: block; margin: 0 auto; float: unset;">

                        <mat-label>
                            {{ 'VEHICLE_INFO.VEHICLE_IN_COMMUNITY' | translate }}
                        </mat-label>

                        <mat-radio-group class="parkingpass-singup-resident-type"
                                            aria-label="Select an option"
                                            formControlName="parkingpassVehicleInCommunity"
                                            (change)="vehicleInCommunity()">

                            <mat-radio-button value="Yes" (click)="openDialog()">{{ 'GENERAL.YES' | translate }}</mat-radio-button>
                            <mat-radio-button value="No" [disabled]="disableRadioBtn">{{ 'GENERAL.NO' | translate }}</mat-radio-button>

                        </mat-radio-group>


                        <div *ngIf="i.parkingpassVehicleInCommunity.touched && i.parkingpassVehicleInCommunity.errors">

                            <mat-error *ngIf="i.parkingpassVehicleInCommunity.errors.required">
                                {{ 'VEHICLE_INFO.VEHICLE_IN_COMMUNITY_REQUIRED' | translate }}
                            </mat-error>

                            <mat-error *ngIf="i.parkingpassVehicleInCommunity.errors.invalid">
                                {{ 'VEHICLE_INFO.VEHICLE_IN_COMMUNITY_INVALID' | translate }}
                            </mat-error>

                        </div>

                    </div>

                </form>

                <div class='card-title' #parkingpassVehiclesTop *ngIf="parkingpassVehicleInCommunity == 'Yes' ;">

                  <h5 style="text-decoration: underline;margin-top: 20px;">
                    {{ 'VEHICLE_INFO.CARD_TITLE' | translate }}
                  </h5>
                </div>

                <mat-error *ngIf="vehicleLimitReached">
                    {{ 'VEHICLE_INFO.LIMIT_REACHED' | translate }}
                </mat-error>

                <div #vehicleFormWrap [ngStyle]="parkingPassCommunityVehicles()">

                    <mat-error *ngIf="duplicateVehicleTags">
                        {{ 'VEHICLE_INFO.DUPLICATE_TAGS' | translate }}
                    </mat-error>

                    <!-- For vehicles errors -> No need to show this message , this is unnecessary -->
                    <!-- <mat-error *ngIf="errorsInVehicle">
                        {{ 'VEHICLE_INFO.ERRORS_IN_VEHICLES' | translate }}
                    </mat-error> -->

                    <div class='parkingpass-vehicle-form-wrap' *ngFor="let vehicle of vehicles">

                        <h5 class='parkingpass-vehicleWrapTitle'>{{ 'VEHICLE_INFO.VEHICLE' | translate }} #{{ vehicles.indexOf(vehicle) + 1 }}</h5>

                        <div>
                            <app-vehicles-info #vehicleInfoCard
                                                [propertyInfo]="propertyInfo"
                                                [vehicleIndex]="vehicles.indexOf(vehicle)"
                                                (vehicleLoading)="loadingToggle($event)"
                                                (primaryVehicleChange)="primaryVehicleChange($event)"
                                                ></app-vehicles-info>
                        </div>

                        <button class='btn parkingpass-remove-vehicle' (click)="removeVehicle(vehicle)" *ngIf="disableRemoveVehicleBtn == false">
                            {{ 'VEHICLE_INFO.REMOVE_VEHICLE' | translate }}
                        </button>

                    </div>

                </div>

                <button class="btn btn-secondary parkingpass-addVehicleButton"
                        *ngIf="parkingpassVehicleInCommunity == 'Yes'
                        && remainingVehiclesInUnit > 0
                        && vehicles.length <= maxVehiclesPerUnit"
                        (click)="addVehicle()">

                    <fa-icon class="fa-plus" [icon]="faPlus"></fa-icon>
                    {{ 'VEHICLE_INFO.ADD_A_VEHICLE' | translate }}

                </button>

            </div>

            <div class='tab-pan animated fadeIn' id='review' [ngStyle]="cardStyle(reviewCard)">

                <app-review #appReview (goToCard)="goToCardIndex($event)"></app-review>

                <button class='btn btn-secondary' *ngIf='reviewCard.open' (click)="nextCard()">
                    {{ 'REVIEW.ACCEPT' | translate }}
                </button>

            </div>

            <div class='tab-pan animated fadeIn' id='terms' [ngStyle]="cardStyle(termsCard)">

                <app-terms #appTerms (termsSigned)="termsSigned($event)"></app-terms>

                <mat-error class="parkingpass-register-info" *ngIf="signatureIssue">{{ 'TERMS.SIGNATURE_MATCH' | translate }}</mat-error>

                <!-- show sign and finish button when user logged in -->
                <button class='btn btn-secondary' *ngIf='termsCard.open && isLoggedIn == 1 ; else show_text_next ' (click)="nextCard()">
                    {{ 'BUTTONS.FINISH' | translate }}
                </button>

                <!-- show next button when user is not logged in -->
                <ng-template #show_text_next>
                    <button class='btn btn-secondary' *ngIf='termsCard.open' (click)="nextCard()">
                        {{ 'BUTTONS.NEXT' | translate }}
                    </button>
                </ng-template>

            </div>

            <div class='tab-pan animated fadeIn' id='parking_policy' [ngStyle]="cardStyle(parkingPolicyCard)">

                <app-parking-policy #appParkingPolicyTerms (parkingPolicySigned)="parkingPolicySigned($event)" (parkingPolicyLoading)="loadingToggle($event)"></app-parking-policy>

                <mat-error class="parkingpass-register-info" *ngIf="signatureIssue">{{ 'TERMS.SIGNATURE_MATCH' | translate }}</mat-error>

            </div>

        </div>

    </div>

    <button [ngClass]="navigatorButton('back')"
            *ngIf="languageCard.open !== true"
            (click)="prevCard()"
            tabindex="0">
        <fa-icon class='fa-angle-left' [icon]="faAngleLeft"></fa-icon>{{ 'BUTTONS.BACK' | translate }}
    </button>

    <button [ngClass]="navigatorButton('next')"
        *ngIf="languageCard.open !== true
                && reviewCard.open !== true
                && termsCard.open !== true
                && vehiclesCard.open !== true
                && (displayNextButton || propertyCard.open)
                && (displayNextButtonOverride || propertyCard.open)"
        (click)="nextCard()"
        tabindex="0">
    <span *ngIf="!this.termsCard.open">{{ 'BUTTONS.NEXT' | translate }}</span>
    <fa-icon class='fa-angle-right' [icon]="faAngleRight"></fa-icon>
</button>

    <button [ngClass]="navigatorButton('next')"
            *ngIf="vehiclesCard.open === true
                      && parkingpassVehicleInCommunity"
            (click)="nextCard()"
            tabindex="0">
      <span *ngIf="!this.termsCard.open">{{ 'BUTTONS.NEXT' | translate }}</span>
      <fa-icon class='fa-angle-right' [icon]="faAngleRight"></fa-icon>
    </button>
</div><!-- END OF SIGN UP BOX -->

<div class='signUpBoxHolder' *ngIf="successfulRegistration">

    <fa-icon class="fa-check-circle" [icon]="faCheckCircle"></fa-icon>

    <h1>{{ 'SIGN_UP.THANK_YOU' | translate }}</h1>

    <p>
        {{ 'SIGN_UP.SUCCESS_INFO' | translate }}
    </p>

    <p>
        {{ 'SIGN_UP.SUCCESS' | translate }} <a href="{{ systemDomain }}/login">{{ systemDomain }}/login</a>
    </p>

</div>

<div class='signUpBoxHolder' *ngIf="successfulApplicationEdit">

    <fa-icon class="fa-check-circle" [icon]="faCheckCircle"></fa-icon>

    <h1>{{ 'SIGN_UP.THANK_YOU' | translate }}</h1>

    <p>
        {{ 'EDIT_APPLICATION.SUCCESS_INFO' | translate }}
    </p>

    <p>
        {{ 'EDIT_APPLICATION.SUCCESS' | translate }} <a href="{{ systemDomain }}/login">{{ systemDomain }}/login</a>
    </p>

</div>
