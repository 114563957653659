import { Component, Inject } from '@angular/core'
import * as $ from 'jquery';
import { DOCUMENT, PlatformLocation } from '@angular/common'
import { systemTemplate } from './helpers/system.helper'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { MatDialog } from '@angular/material/dialog';
import { PopUpComponent } from './pop-up/pop-up.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  title = 'parkingpass'

  constructor(@Inject(DOCUMENT) private document, 
              private router: Router,
              private translateService: TranslateService,
              private route: ActivatedRoute,
              private dialogRef : MatDialog) {}
 

  public switchTemplate(system: string){

    systemTemplate(document, system)

  }

  ngOnInit(){

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    
    let system = urlParams.get('system')

    if(system == 'undefined' || system == null){
      system = localStorage.getItem('parkingpass_system')
    }

    this.switchTemplate(system)

    const isLoggedIn = localStorage.getItem("parkingpass_loggedIn")

    if (isLoggedIn == "1") this.router.navigate([`${this.translateService.currentLang}/dashboard`])
    
  }
  
}