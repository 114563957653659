import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastHelperComponent } from './toast-helper/toast-helper.component';
import { LoadingScreenComponent } from './loading-helper/loading-screen/loading-screen.component';
import { OnScrollDirective } from '../directives/on-scroll.directive';
import { StopClickPropagationDirective } from '../directives/stop-click-propagation.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerComponent, PdfViewerModule } from 'ng2-pdf-viewer'
import { UppercaseDirective } from '../directives/uppercase.directive';
import { LicensePlateDirective } from '../directives/license-plate.directive';
import { VinDirective } from '../directives/vin.directive';
import { TitleCaseDirective } from '../directives/title-case.directive';
import { PersonNameDirective } from '../directives/person-name.directive';
import { WhiteSpaceDirective } from '../directives/white-space.directive';
import { PropertyKeywordDirective } from '../directives/property-keyword.directive';
import { VehicleModelDirective } from '../directives/vehicle-model.directive';
import { ErrorHandlerComponent } from './error-handler/error-handler.component';

@NgModule({
  declarations: [
    ToastHelperComponent,
    LoadingScreenComponent,
    OnScrollDirective,
    StopClickPropagationDirective,
    UppercaseDirective,
    LicensePlateDirective,
    VinDirective,
    TitleCaseDirective,
    PersonNameDirective,
    WhiteSpaceDirective,
    PropertyKeywordDirective,
    VehicleModelDirective,
    ErrorHandlerComponent,
  ],
  imports : [
    CommonModule, 
    FontAwesomeModule,
    TranslateModule,
    PdfViewerModule
  ],
  exports : [
    ToastHelperComponent, 
    LoadingScreenComponent, 
    OnScrollDirective, 
    StopClickPropagationDirective,
    PdfViewerComponent,
    UppercaseDirective,
    LicensePlateDirective,
    VinDirective,
    TitleCaseDirective,
    PersonNameDirective,
    WhiteSpaceDirective,
    PropertyKeywordDirective,
    VehicleModelDirective
  ]
})

export class HelperModule { }