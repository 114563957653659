import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-language-router',
  templateUrl: './language-router.component.html',
  styleUrls: ['./language-router.component.css']
})
export class LanguageRouterComponent implements OnInit {

  public uknownRoute: string
  
  constructor(private router: Router) { }

  ngOnInit() {}
  
}
