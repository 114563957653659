export const STATE_LIST: Array<any> = [
    {
        name: "US-Alabama",
        abbreviation: "US-AL",
        id: 1
    },
    {
        name: "US-Alaska",
        abbreviation: "US-AK",
        id: 2
    },
    {
        name: "US-Arizona",
        abbreviation: "US-AZ",
        id: 3
    },
    {
        name: "US-Arkansas",
        abbreviation: "US-AR",
        id: 4
    },
    {
        name: "US-California",
        abbreviation: "US-CA",
        id: 5
    },
    {
        name: "US-Colorado",
        abbreviation: "US-CO",
        id: 6
    },
    {
        name: "US-Connecticut",
        abbreviation: "US-CT",
        id: 7
    },
    {
      name: "US-District of Columbia",
      abbreviation: "US-DC",
      id: 64
    },
    {
        name: "US-Delaware",
        abbreviation: "US-DE",
        id: 8
    },
    {
        name: "US-Florida",
        abbreviation: "US-FL",
        id: 9
    },
    {
        name: "US-Georgia",
        abbreviation: "US-GA",
        id: 10
    },
    {
        name: "US-Hawaii",
        abbreviation: "US-HI",
        id: 11
    },
    {
        name: "US-Idaho",
        abbreviation: "US-ID",
        id: 12
    },
    {
        name: "US-Illinois",
        abbreviation: "US-IL",
        id: 13
    },
    {
        name: "US-Indiana",
        abbreviation: "US-IN",
        id: 14
    },
    {
        name: "US-Iowa",
        abbreviation: "US-IA",
        id: 15
    },
    {
        name: "US-Kansas",
        abbreviation: "US-KS",
        id: 16
    },
    {
        name: "US-Kentucky",
        abbreviation: "US-KY",
        id: 17
    },
    {
        name: "US-Louisiana",
        abbreviation: "US-LA",
        id: 18
    },
    {
        name: "US-Maine",
        abbreviation: "US-ME",
        id: 19
    },
    {
        name: "US-Maryland",
        abbreviation: "US-MD",
        id: 20
    },
    {
        name: "US-Massachusetts",
        abbreviation: "US-MA",
        id: 21
    },
    {
        name: "US-Michigan",
        abbreviation: "US-MI",
        id: 22
    },
    {
        name: "US-Minnesota",
        abbreviation: "US-MN",
        id: 23
    },
    {
        name: "US-Mississippi",
        abbreviation: "US-MS",
        id: 24
    },
    {
        name: "US-Missouri",
        abbreviation: "US-MO",
        id: 25
    },
    {
        name: "US-Montana",
        abbreviation: "US-MT",
        id: 26
    },
    {
        name: "US-Nebraska",
        abbreviation: "US-NE",
        id: 27
    },
    {
        name: "US-Nevada",
        abbreviation: "US-NV",
        id: 28
    },
    {
        name: "US-New Hampshire",
        abbreviation: "US-NH",
        id: 29
    },
    {
        name: "US-New Jersey",
        abbreviation: "US-NJ",
        id: 30
    },
    {
        name: "US-New Mexico",
        abbreviation: "US-NM",
        id: 31
    },
    {
        name: "US-New York",
        abbreviation: "US-NY",
        id: 32
    },
    {
        name: "US-North Carolina",
        abbreviation: "US-NC",
        id: 33
    },
    {
        name: "US-North Dakota",
        abbreviation: "US-ND",
        id: 34
    },
    {
        name: "US-Ohio",
        abbreviation: "US-OH",
        id: 35
    },
    {
        name: "US-Oklahoma",
        abbreviation: "US-OK",
        id: 36
    },
    {
        name: "US-Oregon",
        abbreviation: "US-OR",
        id: 37
    },
    {
        name: "US-Pennsylvania",
        abbreviation: "US-PA",
        id: 38
    },
    {
        name: "US-Rhode Island",
        abbreviation: "US-RI",
        id: 39
    },
    {
        name: "US-South Carolina",
        abbreviation: "US-SC",
        id: 40
    },
    {
        name: "US-South Dakota",
        abbreviation: "US-SD",
        id: 41
    },
    {
        name: "US-Tennessee",
        abbreviation: "US-TN",
        id: 42
    },
    {
        name: "US-Texas",
        abbreviation: "US-TX",
        id: 43
    },
    {
        name: "US-Utah",
        abbreviation: "US-UT",
        id: 44
    },
    {
        name: "US-Vermont",
        abbreviation: "US-VT",
        id: 45
    },
    {
        name: "US-Virginia",
        abbreviation: "US-VA",
        id: 46
    },
    {
        name: "US-Washington",
        abbreviation: "US-WA",
        id: 47
    },
    {
        name: "US-West Virginia",
        abbreviation: "US-WV",
        id: 48
    },
    {
        name: "US-Wisconsin",
        abbreviation: "US-WI",
        id: 49
    },
    {
        name: "US-Wyoming",
        abbreviation: "US-WY",
        id: 50
    },
    {
      name: "CA-Alberta",
      abbreviation: "CA-AB",
      id: 51
    },
    {
      name: "CA-British Columbia",
      abbreviation: "CA-BC",
      id: 52
    },
    {
      name: "CA-Manitoba",
      abbreviation: "CA-MB",
      id: 53
    },
    {
      name: "CA-New Brunswick",
      abbreviation: "CA-NB",
      id: 54
    },
    {
      name: "CA-Newfoundland and Labrador",
      abbreviation: "CA-NL",
      id: 55
    },
    {
      name: "CA-Nova Scotia",
      abbreviation: "CA-NS",
      id: 56
    },
    {
      name: "CA-Ontario",
      abbreviation: "CA-ON",
      id: 57
    },
    {
      name: "CA-Prince Edward Island",
      abbreviation: "CA-PE",
      id: 58
    },
    {
      name: "CA-Quebec",
      abbreviation: "CA-QC",
      id: 59
    },
    {
      name: "CA-Saskatchewan",
      abbreviation: "CA-SK",
      id: 60
    },
    {
      name: "CA-Northwest Territories",
      abbreviation: "CA-NT",
      id: 61
    },
    {
      name: "CA-Nunavut",
      abbreviation: "CA-NU",
      id: 62
    },
    {
      name: "CA-Yukon",
      abbreviation: "CA-YT",
      id: 63
    },
  {
    name: "MX-Aguascalientes",
    abbreviation: "MX-AG",
    id: 65
  },
  {
    name: "MX-Baja California",
    abbreviation: "MX-BN",
    id: 66
  },
  {
    name: "MX-Baja California Sur",
    abbreviation: "MX-BS",
    id: 67
  },
  {
    name: "MX-Campeche",
    abbreviation: "MX-CP",
    id: 68
  },
  {
    name: "MX-Chiapas",
    abbreviation: "MX-CS",
    id: 69
  },
  {
    name: "MX-Chihuahua",
    abbreviation: "MX-CI",
    id: 70
  },
  {
    name: "MX-Coahuila",
    abbreviation: "MX-CH",
    id: 71
  },
  {
    name: "MX-Colima",
    abbreviation: "MX-CL",
    id: 72
  },
  {
    name: "MX-Durango",
    abbreviation: "MX-DG",
    id: 73
  },
  {
    name: "MX-Guanajuato",
    abbreviation: "MX-GJ",
    id: 74
  },
  {
    name: "MX-Guerrero",
    abbreviation: "MX-GE",
    id: 75
  },
  {
    name: "MX-Hidalgo",
    abbreviation: "MX-HD",
    id: 76
  },
  {
    name: "MX-Jalisco",
    abbreviation: "MX-JA",
    id: 77
  },
  {
    name: "MX-México",
    abbreviation: "MX-MX",
    id: 78
  },
  {
    name: "MX-Mexico City",
    abbreviation: "MX-DF",
    id: 79
  },
  {
    name: "MX-Michoacán",
    abbreviation: "MX-MC",
    id: 80
  },
  {
    name: "MX-Morelos",
    abbreviation: "MX-MR",
    id: 81
  },
  {
    name: "MX-Nayarit",
    abbreviation: "MX-NA",
    id: 82
  },
  {
    name: "MX-Nuevo León",
    abbreviation: "MX-NL",
    id: 83
  },
  {
    name: "MX-Oaxaca",
    abbreviation: "MX-OA",
    id: 84
  },
  {
    name: "MX-Puebla",
    abbreviation: "MX-PU",
    id: 85
  },
  {
    name: "MX-Querétaro",
    abbreviation: "MX-QE",
    id: 86
  },
  {
    name: "MX-Quintana Roo",
    abbreviation: "MX-QI",
    id: 87
  },
  {
    name: "MX-San Luis Potosí",
    abbreviation: "MX-SL",
    id: 88
  },
  {
    name: "MX-Sinaloa",
    abbreviation: "MX-SI",
    id: 89
  },
  {
    name: "MX-Sonora",
    abbreviation: "MX-SO",
    id: 90
  },
  {
    name: "MX-Tabasco",
    abbreviation: "MX-TB",
    id: 91
  },
  {
    name: "MX-Tamaulipas",
    abbreviation: "MX-TA",
    id: 92
  },
  {
    name: "MX-Tlaxcala",
    abbreviation: "MX-TL",
    id: 93
  },
  {
    name: "MX-Veracruz",
    abbreviation: "MX-VC",
    id: 94
  },
  {
    name: "MX-Yucatán",
    abbreviation: "MX-YU",
    id: 95
  },
  {
    name: "MX-Zacatecas",
    abbreviation: "MX-ZA",
    id: 96
  }
]
