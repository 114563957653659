import { FormGroup } from '@angular/forms'

// custom validator to check that two fields match
export function MustNotMatch(controlName: string, value: string) {

    return (formGroup: FormGroup) => {

        const dontMatchValue = formGroup.controls[controlName]

        // return if another validator has already found an error on the name
        if (dontMatchValue.errors && !dontMatchValue.errors.invalidInput) { return }

        // set error on matchingControl if validation fails
        if (dontMatchValue.value === value) {
            dontMatchValue.setErrors({ mustNotMatch : true })
        } else {
            dontMatchValue.setErrors(null)
        }

    }
    
}
