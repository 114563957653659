export const vehicleYearList = function(): Array<number>{

    const years_needed = 60
    let yearList: Array<number> = []

    var d = new Date();
    var currentYear = d.getFullYear() + 3;

    for(let i = 0; i < years_needed; i++){
        let year = currentYear - i
        yearList.push(year)
    }

    return yearList

}