import { Directive,ElementRef, HostListener } from '@angular/core';

@Directive({
 selector: '[titlecase]'
})
export class TitleCaseDirective {

  constructor(public ref: ElementRef) { }

  @HostListener('input', ['$event']) onInput(event) {

    let str = event.target.value

    let splitStr = str.toLowerCase().split(' ')
    
    for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)  
    }

    this.ref.nativeElement.value = splitStr.join(' ')

  }

}