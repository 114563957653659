import { Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ChangePasswordError } from 'src/app/errors/user-errors/user.errors';
import { logErrorMessage } from 'src/app/helpers/retry.operators';
import { MustMatch } from 'src/app/validators/must-match.validator';
import { MustNotMatchSameForm } from 'src/app/validators/must-not-match-same-form.validator';
import { ValidatePassword } from 'src/app/validators/password.validator';
import { PasswordChangeService } from './password-change.service';

@Component({
  selector: 'app-password-changer',
  templateUrl: './password-changer.component.html',
  styleUrls: ['../resident-info.component.css', './password-changer.component.css']
})

export class PasswordChangerComponent implements OnInit {

  public faEye = faEye
  public faEyeSlash = faEyeSlash

  public passwordChanged = new EventEmitter()

  public changePasswordForm: FormGroup

  public passwordHints: boolean = false

  public submitted: boolean = false
  
  public invalidPassword: boolean = false
  
  public passwordShow: boolean = false

  public loading: boolean = false

  constructor(private dialogRef: MatDialogRef<PasswordChangerComponent>, 
              @Inject(MAT_DIALOG_DATA) dat,
              private passwordChangeService: PasswordChangeService,
              private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private matDialog: MatDialog) {}


  public togglePassword(){

    this.passwordShow = !this.passwordShow

  }

  get h() { return this.changePasswordForm.controls }

  get parkingpassCurrentPassword() { return this.changePasswordForm.get('parkingpassCurrentPassword').value }
  get parkingpassNewPassword() { return this.changePasswordForm.get('parkingpassNewPassword').value }
  get parkingpassNewPasswordConfirmation() { return this.changePasswordForm.get('parkingpassNewPasswordConfirmation').value }

  public changePassword(): void {

    this.loading = true

    const uuid_requesting_login = localStorage.getItem("parkingpass_userId")
    const uuid_to_login = localStorage.getItem("parkingpass_userIdToLogin")
    const v1_token = localStorage.getItem("parkingpass_v1Token")
    const system = localStorage.getItem("parkingpass_system")
    const language = this.translateService.currentLang

    const changePasswordObj = {
      system: system,
      uuid_requesting_login: uuid_requesting_login,
      uuid_to_login: uuid_to_login,
      v1_token: v1_token,
      current_password: this.parkingpassCurrentPassword,
      new_password: this.parkingpassNewPassword,
      new_password_confirm: this.parkingpassNewPasswordConfirmation,
      language: language
    }

    this.passwordChangeService.changePassword(changePasswordObj).subscribe(
      resp => {
        this.changePasswordCallback(resp)
      },
      error => {

        let sentryContext: any = {
          errorName: 'Change Password Error',
          loginToken: v1_token,
          uuid_requesting_login: uuid_requesting_login,
          uuid_to_login: uuid_to_login,
          errorMessage: this.translateService.instant('USER_SERVER_ERRORS.CHANGE_PASSWORD'),
          system: system,
          error: null
        }
    
        sentryContext.error = new ChangePasswordError(sentryContext.errorName)

        this.loading = false

        logErrorMessage(3, error, sentryContext, this.matDialog)

      }
    )

  }

  public resetInvalidPassword(){
    this.invalidPassword = false
  }

  private changePasswordCallback(resp: any): void{
    
    this.loading = false

    if(resp === undefined) return

    if(resp.success){

      this.invalidPassword = false
      this.passwordChanged.emit(null)
      this.close()

    } else {

      if(resp.message === "invalid_cred") this.invalidPassword = true
      
    }

    //console.log("changePasswordCallback", resp)

  }

  public initForm(): void{

    let passwordValidators = [Validators.required]

    this.changePasswordForm = this.formBuilder.group({
      parkingpassCurrentPassword: ['', passwordValidators],
      parkingpassNewPassword: ['', passwordValidators],
      parkingpassNewPasswordConfirmation: ['', passwordValidators]
    }, { 
      validators: [
        ValidatePassword('parkingpassNewPassword'),
        MustMatch('parkingpassNewPassword', 'parkingpassNewPasswordConfirmation'),
        MustNotMatchSameForm('parkingpassCurrentPassword', 'parkingpassNewPassword')
      ]
    })

  }

  close() {
    this.dialogRef.close()
  }

  ngOnInit() {
    this.initForm()
  }

}
