import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-vehicles-full-dialog',
  templateUrl: './vehicles-full-dialog.component.html',
  styleUrls: ['./vehicles-full-dialog.component.css']
})
export class VehiclesFullDialogComponent implements OnInit {

  public unitInfo = {}

  constructor(private dialogRef: MatDialogRef<VehiclesFullDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dat) { }

  public closeDialog(){
    this.dialogRef.close()
  }

  ngOnInit() {

    this.unitInfo = {
      unit: this.dat.unit,
      building: this.dat.building,
      max_vehicles: this. dat.max_vehicles
    }
    
  }

}
