import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ValidatePersonName } from 'src/app/validators/name.validator';
import { MustMatch } from 'src/app/validators/must-match.validator';
import { ValidatePassword } from 'src/app/validators/password.validator';
import { titleCase, removeLastWhiteSpace } from 'src/app/helpers/string-formatting.helper'
import { ValidateUniqueEmail } from 'src/app/validators/sign-up/email-unique.validator';
import { EmailUniqueCheckService } from 'src/app/services/sign-up/email-unique-check.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { faEye, faEyeSlash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { CustomerPinInfoComponent } from './customer-pin-info/customer-pin-info.component';
import { PhoneConfirmationComponent } from '../phone-confirmation/phone-confirmation.component';
import { CaseInsensitiveMustMatch } from 'src/app/validators/sign-up/case-insensitive-must-match.validator';
import { PasswordChangerComponent } from './password-changer/password-changer.component';
import { EmailConfirmationComponent } from '../email-confirmation/email-confirmation.component';
import { MyErrorStateMatcher } from 'src/app/helpers/default.error-matcher';
import { PropertyKeywordService } from 'src/app/services/sign-up/property-keyword.service';
import { passwordHelper } from 'src/app/helpers/password.helper';


@Component({
  selector: 'app-resident-info',
  templateUrl: './resident-info.component.html',
  styleUrls: ['./resident-info.component.css', '../sign-up.component.css']
})
export class ResidentInfoComponent implements OnInit {
  
  @ViewChild('parkingpassSignUpIssues') parkingpassSignUpIssues

  @ViewChild('residentConfirmPassword') residentConfirmPassword
  @ViewChild('residentPassword') residentPassword

  public faEye = faEye
  public faInfo = faInfoCircle
  public faEyeSlash = faEyeSlash

  public residentInfoForm: FormGroup

  public submittingForm: boolean
  public submitted: boolean = false
  
  public emailIsUniqueTimeOut: any = null

  public customerPinSupported: boolean = true 

  public propertyInfo: any = { system: null, questions: { support_pin: null } }

  public phoneConfirmation: boolean = false
  public phoneIsConfirmed: boolean = false
  public language: string

  public alreadyConfirmedNumber: string

  public isLoggedIn: string = '0'

  public passwordHints: boolean = false

  public passwordChanged: boolean = false

  public passwordShow: boolean = false

  public phoneConfirmationOpened: boolean = false

  public dialogRef: any

  public alreadyConfirmedEmail: string = ''
  public emailIsConfirmed: boolean = false
  public emailConfirmation: boolean

  public errorMatcher = new MyErrorStateMatcher()

  constructor(private formBuilder: FormBuilder, 
              private emailUniqueCheckService: EmailUniqueCheckService,
              private propertyInfoService: PropertyKeywordService,
              private matDialog: MatDialog,
              private el: ElementRef) { }

  get f() { return this.residentInfoForm.controls }
    
  get parkingpassFirstName() { return this.residentInfoForm.get('parkingpassFirstName').value }
  get parkingpassLastName() { return this.residentInfoForm.get('parkingpassLastName').value }
  get parkingpassPhoneType() { return this.residentInfoForm.get('parkingpassPhoneType').value }
  get parkingpassPhoneNumber() { return this.residentInfoForm.get('parkingpassPhoneNumber').value }
  get parkingpassEmail() { return this.residentInfoForm.get('parkingpassEmail').value.toLowerCase() }
  get parkingpassEmailConfirm() { return this.residentInfoForm.get('parkingpassEmailConfirm').value.toLowerCase() }
  get parkingpassPassword() { return this.residentInfoForm.get('parkingpassPassword').value }
  get parkingpassConfirm() { return this.residentInfoForm.get('parkingpassConfirm').value }
  get parkingpassCustomerSupportPin() { return this.residentInfoForm.get('parkingpassCustomerSupportPin').value }  
  
  public togglePassword(){
    this.passwordShow = !this.passwordShow
  }

  public formatName(event, formControlName: string): void{

    var regex = /^[a-zA-Z\s]*$/

    // allow letters and whitespaces only.
    if(!regex.test(event.key)) event.preventDefault()

    let str = titleCase(this.residentInfoForm.get(formControlName).value)
    this.residentInfoForm.get(formControlName).setValue(str)

  }

  public formatAfterBlur(formControlName: string){
    let str = removeLastWhiteSpace(this.residentInfoForm.get(formControlName).value)
    this.residentInfoForm.get(formControlName).setValue(str);
  }

  public removeWhiteSpace(key) {
    this.residentInfoForm.get(key).setValue(this.residentInfoForm.get(key).value.trim())
  }

  public initForms(): void{

    const residentInfo = JSON.parse(localStorage.getItem('parkingpass_userInfo'))

    // will set validators for form and take care of animations
    const firstNameValidators = [Validators.required]
    const lastNameValidators = [Validators.required]
    const emailValidators = [Validators.required, Validators.email]
    const phoneValidators = [Validators.required]
    const phoneTypeValidators = [Validators.required]
    const customerSupportPinValidators = [Validators.required, Validators.minLength(4), Validators.maxLength(4)]

    let passwordValidators

    if(this.isLoggedIn == '1'){
      
      passwordValidators = []

      this.residentInfoForm = this.formBuilder.group({
        parkingpassEmail: ['', emailValidators],
        parkingpassEmailConfirm: ['', emailValidators],
        parkingpassPhoneNumber: ['', phoneValidators],
        parkingpassPhoneType: ['', phoneTypeValidators],
        parkingpassFirstName: ['', firstNameValidators],
        parkingpassLastName: ['', lastNameValidators],
        parkingpassConfirm: ['', passwordValidators],
        parkingpassCustomerSupportPin: ['', customerSupportPinValidators]
      }, {
        validators: [
          CaseInsensitiveMustMatch('parkingpassEmail', 'parkingpassEmailConfirm'),
          ValidatePersonName('parkingpassFirstName'),
          ValidatePersonName('parkingpassLastName')
        ]
      })

      this.residentInfoForm.get('parkingpassEmail').setValue(residentInfo.email)
      this.residentInfoForm.get('parkingpassEmailConfirm').setValue(residentInfo.email)
      this.residentInfoForm.get('parkingpassPhoneNumber').setValue(residentInfo.main_contact_number)
      this.residentInfoForm.get('parkingpassPhoneType').setValue(residentInfo.main_contact_number_type)
      this.residentInfoForm.get('parkingpassFirstName').setValue(residentInfo.first_name)
      this.residentInfoForm.get('parkingpassLastName').setValue(residentInfo.last_name)
      this.residentInfoForm.get('parkingpassCustomerSupportPin').setValue(residentInfo.security_pin)

      this.emailIsConfirmed = true
      this.phoneIsConfirmed = true

    } else {

      passwordValidators = [Validators.required]

      this.residentInfoForm = this.formBuilder.group({
        parkingpassEmail: ['', emailValidators],
        parkingpassEmailConfirm: ['', emailValidators],
        parkingpassPhoneNumber: ['', phoneValidators],
        parkingpassPhoneType: [ '', phoneTypeValidators],
        parkingpassFirstName: ['', firstNameValidators],
        parkingpassLastName: ['', lastNameValidators],
        parkingpassPassword: ['', passwordValidators],
        parkingpassConfirm: ['', passwordValidators],
        parkingpassCustomerSupportPin: ['', customerSupportPinValidators]
      }, {
          validators: [
            ValidatePassword('parkingpassPassword'),
            MustMatch('parkingpassPassword', 'parkingpassConfirm'),
            CaseInsensitiveMustMatch('parkingpassEmail', 'parkingpassEmailConfirm'),
            ValidatePersonName('parkingpassFirstName'),
            ValidatePersonName('parkingpassLastName')
          ]
      })

    }

  }

  public togglePasswordHints(): void{
    this.passwordHints = !this.passwordHints
  }

  public addValidators(): void{

    if(this.propertyInfo.questions.support_pin){
      const customerSupportPinValidators = [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      this.residentInfoForm.get('parkingpassCustomerSupportPin').setValidators(customerSupportPinValidators)
    } else
      this.residentInfoForm.get('parkingpassCustomerSupportPin').setValidators([])

    if(this.isLoggedIn == '1'){

      const residentInfo = JSON.parse(localStorage.getItem('parkingpass_userInfo'))
      this.residentInfoForm.get('parkingpassEmail').setAsyncValidators(
        ValidateUniqueEmail.valid(
          this.emailUniqueCheckService, 
          this.propertyInfo.system,
          residentInfo.email
        )
      )

    } else {

      this.residentInfoForm.get('parkingpassEmail').setAsyncValidators(
        ValidateUniqueEmail.valid(
          this.emailUniqueCheckService, 
          this.propertyInfo.system, 
          this.parkingpassEmail
        )
      )

    }

  }

  public emailChanged(){
    this.emailIsConfirmed = false
  }

  public checkIfEmailConfirmed(){

    if(this.isLoggedIn == '1'){

      const residentInfo = JSON.parse(localStorage.getItem('parkingpass_userInfo'))

      if(residentInfo.email == this.parkingpassEmail)
        this.emailIsConfirmed = true
      else
        this.emailIsConfirmed = false

      console.log("email is confirmed", this.emailIsConfirmed)

    } else
      return false

  }

  public checkIfPhoneConfirmed(){

    if(this.isLoggedIn == '1'){

      const residentInfo = JSON.parse(localStorage.getItem('parkingpass_userInfo'))

      if(residentInfo.phone_number == this.parkingpassPhoneNumber)
        this.phoneIsConfirmed = true
      else
        this.phoneIsConfirmed = false

    } else
      return false

  }

  public tryEmailConfirmation(): void{
    
    const emailFormControl = this.residentInfoForm.get('parkingpassEmail')

    if(emailFormControl.valid &&
      (this.alreadyConfirmedEmail !== this.parkingpassEmail || this.emailIsConfirmed == false)){

      this.emailIsConfirmed = false
      this.emailConfirmation = true

      const dialogConfig = new MatDialogConfig()

      dialogConfig.autoFocus = true
      dialogConfig.data = {
        email : this.parkingpassEmail,
        system: this.propertyInfo.system,
        language: this.language,
        propertyId: this.propertyInfo.location,
        firstName: this.parkingpassFirstName
      }

      //console.log("propertyInfo", this.propertyInfo)

      let dialogRef = this.matDialog.open(
        EmailConfirmationComponent,
        dialogConfig
      )
      
      dialogRef.componentInstance.emailAddressChange.subscribe(
        email => {
          this.residentInfoForm.get('parkingpassEmail').setValue(email)
          this.residentInfoForm.get('parkingpassEmailConfirm').setValue(email)
        }
      )

      dialogRef.afterClosed().subscribe(
        resp => {
          this.tryEmailConfirmationCb(resp)
          this.tryPhoneConfirmation()        
        }
      )

    }

  }

  public tryEmailConfirmationCb(resp: any){

    if(resp !== undefined){

      if(resp.emailConfirmed)
        this.emailIsConfirmed = true
      else 
        this.emailIsConfirmed = false
      
    } else 
      this.emailIsConfirmed = false
  
    this.alreadyConfirmedEmail = this.parkingpassEmail

  }

  public tryPhoneConfirmation(): void{

    const phoneFormControl = this.residentInfoForm.get('parkingpassPhoneNumber')
    const phoneType = this.residentInfoForm.get('parkingpassPhoneType').value

    if(phoneFormControl.valid && phoneType === 'mobile' && 
      (this.alreadyConfirmedNumber !== this.parkingpassPhoneNumber || this.phoneIsConfirmed == false)){

      this.phoneIsConfirmed = false
      this.phoneConfirmation = true

      const dialogConfig = new MatDialogConfig()

      dialogConfig.autoFocus = true
      dialogConfig.data = {
        phoneNumber : this.parkingpassPhoneNumber,
        system: this.propertyInfo.system,
        language: this.language
      }

      let dialogRef = this.matDialog.open(
        PhoneConfirmationComponent,
        dialogConfig,
      )      
      
      // event listeners for emits from PhoneConfirmationComponent

      dialogRef.componentInstance.phoneNumberChange.subscribe(
        phoneNumber => {
          this.residentInfoForm.get('parkingpassPhoneNumber').setValue(phoneNumber)
          this.residentInfoForm.get('parkingpassPhoneNumber').updateValueAndValidity()
        }
      )

      dialogRef.componentInstance.changePhone.subscribe(
        x => {
          this.residentInfoForm.get("parkingpassPhoneType").setValue('home')
        }
      )

      dialogRef.afterClosed().subscribe(
        resp => {
          this.tryPhoneConfirmationCb(resp)
        }
      )

    }

  }

  public tryPhoneConfirmationCb(resp: any){

    if(this.parkingpassPhoneType === 'home'){
      this.phoneConfirmation = true
      this.alreadyConfirmedNumber = this.parkingpassPhoneNumber
      return
    }

    if(resp !== undefined){

      if(resp.phoneNumberConfirmed)
        this.phoneIsConfirmed = true
      else 
        this.phoneIsConfirmed = false
      
    } else 
      this.phoneIsConfirmed = false
  
    this.alreadyConfirmedNumber = this.parkingpassPhoneNumber

  }

  public enablePinInfo(): void{

    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = true

    this.matDialog.open(
      CustomerPinInfoComponent,
      dialogConfig
    )

  }

  public togglePasswordChange(): void{

    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = true

    let dialogRef = this.matDialog.open(
      PasswordChangerComponent,
      dialogConfig
    )
    
    dialogRef.componentInstance.passwordChanged.subscribe(
      phoneNumber => {
        this.passwordChanged = true
      }
    )

  }

  public scrollToFirstInvalidControl() {
    
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
    
    if(firstInvalidControl == null) return

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });
    
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 100;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  public getResidentInfoErrors(): boolean{

    let errors = false 

    if(!this.phoneIsConfirmed && this.parkingpassPhoneType == 'mobile'){
      errors = true
      this.tryPhoneConfirmation()
    }

    return errors

  }
  
  public passwordErrors(formControlError: string): any{

    const password = this.parkingpassPassword
    
    return passwordHelper(password, formControlError)

  }

  ngOnInit() {
    this.isLoggedIn = localStorage.getItem('parkingpass_loggedIn')
    this.initForms()
  }

}
