
  import { FormGroup } from '@angular/forms';

  // custom validator to check that two fields match
  export function ValidatePassword(password_control_name: string) {

    return (formGroup: FormGroup) => {

        const password = formGroup.controls[password_control_name];

        // return if another validator has already found an error on the username
        if (password.errors &&
           (!password.errors.oneNumber &&
            !password.errors.oneLowerCase &&
            !password.errors.oneUpperCase &&
            !password.errors.oneSpecialCharacter)
        ) return

        // Password must contain at least one uppercase letter (A-Z)!
        let re = /[A-Z]/
        if (!re.test(password.value)) { 
            password.setErrors({ oneUpperCase: true })
            return
        }    

        // Password must contain at least one lowercase letter (a-z)!
        re = /[a-z]/
        if (!re.test(password.value)){
            password.setErrors({ oneLowerCase: true })
            return 
        }

        // Password must contain at least one number (0-9)!
        re = /[0-9]/
        if (!re.test(password.value)){ 
            password.setErrors({ oneNumber: true })        
            return
        }

        // Password must contain at least one special character (~!@#$%^&*_-+=`|\(){}[]:;"'<>,.?/)!
        re = /(?=.*[~!@#$%^&*_\-+=`|\(){}[\]:;"'<>,\.\?\/])/
        if (!re.test(password.value)){
            password.setErrors({ oneSpecialCharacter: true })
            return    
        }

        //Password must be between 8 - 135 characters long.
        if ((password.value.length < 8) || (password.value.length > 135)) {
            password.setErrors({ wrongLength: true })
            return
        }

    }
}
