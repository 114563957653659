export function systemTemplate(document, system: string){

    let chosenTemplate = 'parkingpass.css'
    let systemName = 'Parking Pass'

    let currSystem

    if(window.location.href.indexOf('system=') > - 1)
      localStorage.setItem('parkingpass_system', null)
    else
      currSystem = localStorage.getItem('parkingpass_system')

    if(currSystem == null || currSystem == undefined)
      localStorage.setItem('parkingpass_system', system)
    else
      system = currSystem

    switch(system){
      case 'ocp':
        chosenTemplate = 'ocp.css'
        systemName = 'OnCall Parking Manager'
        break
      case 'nwr':
        chosenTemplate = 'nwr.css'
        systemName = 'Northwest Recovery'
        break
      case 'sfpa':
        chosenTemplate = 'sfpa.css'
        systemName = 'South Florida Parking Authority'
        break
      case 'sst':
        chosenTemplate = 'sst.css'
        systemName = 'Smart Parking Permit'
        break
      case 'npm':
      case 'parkingpass':
        chosenTemplate = 'parkingpass.css'
        systemName = 'Parking Pass'
        break
      default:
        system = 'ocp'
        chosenTemplate = 'parkingpass.css'
        systemName = 'Parking Pass'
    }

    document.getElementById('parkingTheme').href = `assets/styles/systems/${chosenTemplate}`
    document.title = `${systemName} Registration | ParkingPass.com`

}

export function systemName(system: string){

  let systemName

  switch(system){
    case 'ocp':
      systemName = 'OnCall Parking Manager'
      break
    case 'nwr':
      systemName = 'Northwest Recovery'
      break
    case 'sfpa':
      systemName = 'South Florida Parking Authority'
      break
    case 'sst':
      systemName = 'Smart Parking Permit'
      break
    case 'npm':
    case 'parkingpass':
      systemName = 'ParkingPass.com'
      break
    default:
      systemName = 'OnCall Parking Manager'
  }

  return systemName

}

export function systemLogo(system: string){

  let systemLogo

  switch(systemLogo){
    case 'ocp':
      systemLogo = 'assets/images/logo/ocp/logo.png'
      break
    case 'npm':
      systemLogo = 'assets/images/logo/npm/logo.png'
      break
    case 'nwr':
      systemLogo = 'assets/images/logo/nwr/logo.png'
      break
    case 'sfpa':
      systemLogo = 'assets/images/logo/sfpa/logo.png'
      break
    case 'sst':
      systemLogo = 'assets/images/logo/sst/logo.png'
      break
    case 'parkingpass':
      systemLogo = 'assets/images/logo/parking-pass-new/logo.png'
      break
    default:
      systemLogo = 'assets/images/logo/parking-pass-new/logo.png'
  }

  return systemLogo

}


export function systemDomain(system: string){

  let systemDomain

  switch(system){
    case 'ocp':
      systemDomain = 'https://app.oncallparking.com'
      break
    case 'nwr':
      systemDomain = 'https://www.nwrparking.com'
      break
    case 'sfpa':
      systemDomain = 'https://www.sfpaparking.com'
      break
    case 'sst':
      systemDomain = 'https://www.sstparking.com'
      break
    case 'npm':
    case 'parkingpass':
      systemDomain = 'https://my.parkingpass.com'
      break
  }

  return systemDomain

}


export function systemFromPath(currentPath){

  if(currentPath.indexOf('/ocp/') > -1) return 'ocp'
  if(currentPath.indexOf('/npm/') > -1) return 'npm'
  if(currentPath.indexOf('/nwr/') > -1) return 'nwr'
  if(currentPath.indexOf('/sfpa/') > -1) return 'sfpa'
  if(currentPath.indexOf('/sst/') > -1) return 'sst'

}
