import { FormGroup } from '@angular/forms';
import { STATE_LIST } from 'src/app/lists/state.list'

// custom validator to check that two fields match
export function ValidateState(controlName: string) {
    return (formGroup: FormGroup) => {

        const control = formGroup.controls[controlName];

        if (control.errors && !control.errors.invalid) {
            // return if another validator has already found an error on the control
            return;
        }

        let found = false

        for(let i = 0; i < STATE_LIST.length; i++){
            if(found === true) break
            if(STATE_LIST[i].abbreviation === control.value){
                found = true
            }
        }

        if( control.value.length >= 3 && found === false ){
            found = true
        }

        if (found === false)
            control.setErrors({ invalid: true });
        else
            control.setErrors(null);

    }
}
