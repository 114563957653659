import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { handleError } from 'src/app/helpers/error-helper';
import { environment } from 'src/environments/environment';
import { retryWithBackOff } from 'src/app/helpers/retry.operators';

const PHONE_CONFIRM_SMS_API = `${environment.api_endpoint}/v2/users/validation/phone-number/send-code`
const PHONE_CONFIRM_CALL_API = `${environment.api_endpoint}/v2/users/validation/phone-number/call-and-send-code`
const PHONE_CONFIRM_PIN_API = `${environment.api_endpoint}/v2/users/validation/phone-number/confirm`

@Injectable({
  providedIn: 'root'
})
export class PhoneConfirmationService {

  constructor(private httpClient: HttpClient) {}

  public sendCode(phoneNumber: string, system: string, lang: string): Observable<any>{

    const sendCodeApi = `${PHONE_CONFIRM_SMS_API}`

    const sendCodeObj = {
      api_key: environment.api_key,
      phone_number: phoneNumber,
      system: system,
      lang: lang
    }
    
    return this.httpClient.post<any>(sendCodeApi, sendCodeObj).pipe(
      retryWithBackOff(1000)
    )

  }

  public sendCodeWithCall(phoneNumber: string, system: string, lang: string): Observable<any>{

    const sendCodeApi = `${PHONE_CONFIRM_CALL_API}`

    const sendCodeObj = {
      api_key: environment.api_key,
      phone_number: phoneNumber,
      system: system,
      lang: lang
    }

    return this.httpClient.post<any>(sendCodeApi, sendCodeObj).pipe(
      retryWithBackOff(1000)
    )

  }

  public checkCode(code: string, phoneNumber: string): Observable<any>{

    const sendCodeApi = `${PHONE_CONFIRM_PIN_API}`

    const sendCodeObj = {
      api_key: environment.api_key,
      confirm_code: code,
      phone_number: phoneNumber
    }
    
    return this.httpClient.post<any>(sendCodeApi, sendCodeObj).pipe(
      retryWithBackOff(1000)
    )
  }

}
