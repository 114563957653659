<app-loading-screen *ngIf="loading"></app-loading-screen>

<h2 mat-dialog-title>{{ 'EMAIL_CONFIRMATION.INFO_TITLE' | translate }}</h2>

<div *ngIf="affirmEmail">

  <mat-dialog-content>
  
    <div>
 
      <p>
        {{ 'EMAIL_CONFIRMATION.INFO' | translate }}
      </p>
      
      <form [formGroup]="confirmEmailForm">

        <div class='parkingpass-formFieldWrapper'>
          
          <mat-form-field>
    
            <input matInput type='text' 
                            id='user_phone'
                            (keyup)="changeParentEmail()"
                            placeholder="{{ 'RESIDENT_INFO.EMAIL_PLACEHOLDER' | translate }}"
                            formControlName="parkingpassEmail" />       
              
          </mat-form-field>
        
        </div>
     
      </form>

    </div>
  
  </mat-dialog-content>
  
  <div style="text-align: center;">

    <button class="btn btn-primary parkingpass-nav" (click)="sendCode()">{{ 'EMAIL_CONFIRMATION.SEND_EMAIL' | translate }}</button>

  </div>

</div>

<div *ngIf="confirmEmail">

  <mat-dialog-content>
  
    <div>
 
      <p>
        {{ 'EMAIL_CONFIRMATION.INPUT_CODE' | translate }}
      </p>
 
      <div class='parkingpass-formFieldWrapper'>
      
        <form [formGroup]="pinForm">

          <div *ngIf="f.confirmationCode.touched && f.confirmationCode.errors" class="signUpBoxInstructions">
            
            <mat-error *ngIf="f.confirmationCode.errors.required">
                {{ 'EMAIL_CONFIRMATION.CODE_REQUIRED' | translate }}  
            </mat-error>

            <mat-error *ngIf="f.confirmationCode.errors.invalid">
                {{ 'EMAIL_CONFIRMATION.CODE_INVALID' | translate }}
            </mat-error>

          </div> 

          <mat-form-field>
  
            <input matInput 
                  type="text"
                  formControlName="confirmationCode"
                  placeholder="{{ 'EMAIL_CONFIRMATION.CODE_PLACEHOLDER' | translate }}" 
                  mask="000000"/> 
            
          </mat-form-field>

        </form>
     
      </div>
 
    </div>
  
  </mat-dialog-content>

  <div>

    <button class="btn btn-primary parkingpass-nav parkingpass-confirm-button" (click)="checkCode()">
      {{ 'EMAIL_CONFIRMATION.CONFIRM_BTN' | translate }}
    </button>

  </div>

  <button class='text-primary parkingpass-help-info' (click)="backToFirst()">
    {{ 'EMAIL_CONFIRMATION.CODE_NOT_RECEIVED' | translate }}
  </button>

  <span class='parkingpass-sub-text'>
    <small>{{ 'EMAIL_CONFIRMATION.TRY_AGAIN_WAIT' | translate }}</small>
  </span>

</div>


<div *ngIf="codeCheckTried">

  <div *ngIf="emailConfirmationPassed">
    
    <p>
      {{ 'EMAIL_CONFIRMATION.EMAIL_CONFIRMED' | translate }}
    </p>
    
    <div style="text-align: center;">
      <button class="btn btn-primary parkingpass-nav" (click)="close()">{{ 'EMAIL_CONFIRMATION.DONE' | translate }}</button>
    </div>

  </div>

  <div *ngIf="!emailConfirmationPassed">
    
    <p>
      {{ 'EMAIL_CONFIRMATION.CODE_INVALID' | translate }}
    </p>

    <button class='text-primary parkingpass-help-info' (click)="backToFirst()">
      {{ 'EMAIL_CONFIRMATION.TRY_AGAIN' | translate }}
    </button>

  </div>

</div>