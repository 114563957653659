import { Component, OnInit, ViewChild, ElementRef, Output, Input, EventEmitter, QueryList, ViewChildren } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import * as $ from 'jquery'

import { Observable } from 'rxjs/internal/Observable'
import { of } from 'rxjs'
import { UserService } from 'src/app/services/user.service'
import { KeywordService } from 'src/app/services/keyword.service'

import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { Languages } from 'src/app/enum/languages.enum'
import { faAngleRight, faAngleLeft, faPlus, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { PropertyKeywordComponent } from './property-keyword/property-keyword.component'
import { ResidentInfoComponent } from './resident-info/resident-info.component'
import { PropertyInfoComponent } from './property-info/property-info.component'
import { ReviewComponent } from './review/review.component'
import { TermsComponent } from './terms/terms.component'
import { ParkingPolicyComponent } from './parking-policy/parking-policy.component'
import { PropertyKeywordService } from 'src/app/services/sign-up/property-keyword.service'
import { VehiclesInfoComponent } from './vehicles-info/vehicles-info.component'
import { TranslateService } from '@ngx-translate/core'
import { PlatformLocation } from '@angular/common'
import { getFormValidationErrors } from 'src/app/helpers/form-error-helper'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { VehiclesFullDialogComponent } from './vehicles-full-dialog/vehicles-full-dialog.component'
import { UserLoggedOutComponent } from '../../user-logged-out/user-logged-out.component'
import { logErrorMessage } from 'src/app/helpers/retry.operators'
import { SaveApplicationError, SignUpError } from 'src/app/errors/user-errors/user.errors'
import { SecurityPrivacyComponent } from './security-privacy/security-privacy.component'
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';



@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  @ViewChild('parkingpass_register_info') parkingpass_register_info

  @ViewChild('scrollToTop') scrollToTop

  @ViewChild('vc_account_perks_box') vc_account_perks_box

  @ViewChild('parkingpassSignUpIssues') parkingpassSignUpIssues

  @ViewChild('propertyKeywordCard') propertyKeywordCard: PropertyKeywordComponent
  @ViewChild('residentInfoCard') residentInfoCard: ResidentInfoComponent
  @ViewChild('propertyInfoCard') propertyInfoCard: PropertyInfoComponent

  @ViewChildren('vehicleInfoCard') vehicleComponentList: QueryList<VehiclesInfoComponent>

  @ViewChild('appReview') appReview: ReviewComponent
  @ViewChild('appTerms') appTerms: TermsComponent

  @ViewChild('appParkingPolicyTerms') appParkingPolicyTerms: ParkingPolicyComponent

  @ViewChild('parkingpassVehiclesTop') parkingpassVehiclesTop

  @Output() closeWindow = new EventEmitter()

  @Input() window_obj


  private keywordCardSkipped: boolean = false;

  private isValidKeyword(keyword: string): boolean {
    return keyword && keyword.trim().length > 0;
}

  public lang: string
  public faAngleRight = faAngleRight
  public faAngleLeft = faAngleLeft
  public faPlus = faPlus
  public faCheckCircle = faCheckCircle
  public keyword: string;
  public lang_en = Languages.en
  public lang_es = Languages.es
  public lang_pt = Languages.pt
  public lang_hat = Languages.hat

  public vehicleInCommunityForm: FormGroup

  public signingUp: boolean = false
  public signUpbox: boolean = false
  public submitted: boolean = false

  public signatureIssue: boolean = false

  public loading: boolean = false

  public registrationBox: boolean = true
  public successfulRegistration: boolean = false
  public successfulApplicationEdit: boolean = false

  public propertyInfo: any = null

  public language: string = `${this.lang_en}`

  //Used to display cards on the template
  public languageCard: any = { open: true, cardTitle: 'TABS.LANGUAGE', component: null, form: null }
  public keywordCard: any = { open: false, cardTitle: 'TABS.KEYWORD', component: null, form: null }
  public residentCard: any = { open: false, cardTitle: 'TABS.RESIDENT_INFO', component: null, form: null }
  public propertyCard: any = { open: false, cardTitle: 'TABS.PROPERTY_INFO', component: null, form: null }
  public ownerCard: any = { open: false, cardTitle: 'TABS.PROPERTY_INFO', component: null, form: null }
  public vehicleCountCard: any = { open: false, cardTitle: 'TABS.VEHICLE_COUNT', component_list: null, form: null }
  public vehiclesCard: any = { open: false, cardTitle: 'TABS.ADD_VEHICLES', component_list: null, form: null, vehicles: [] }
  public reviewCard: any = { open: false, cardTitle: 'TABS.REVIEW' }
  public termsCard: any = { open: false, cardTitle: 'TABS.TERMS', component: null }
  public parkingPolicyCard: any = { open: false, cardTitle: 'TABS.PARKING_POLICY', component: null }

  public maxVehiclesPerUnit: number = 0
  public existingVehiclesInUnit: number = 0
  public remainingVehiclesInUnitServer: number = 0
  public remainingVehiclesInUnit: number = 0

  public duplicateVehicleTags: boolean = false
  public vehicleLimitReached: boolean = false
  public isPrimaryVehiclesEnabled: boolean = false;
  public disableRemoveVehicleBtn: boolean = false;
  public disableRadioBtn: boolean = false;
  public getLoggedInUserInfo: any;
  public isGuestAccount: boolean = false;
  public isNextButtonDisabledIfLoggedIn: boolean = false

  public cardList = [
    this.languageCard,
    this.keywordCard,
    this.residentCard,
    this.propertyCard,
    this.vehicleCountCard,
    this.vehiclesCard,
    this.reviewCard,
    this.termsCard,
    this.parkingPolicyCard
  ];

  public currentCardIndex: number = 0

  public vehicles: Array<any> = []

  public vehicleListLoggedIn: Array<any> = []

  public isLoggedIn: string = '0'

  public errorsInVehicle: boolean = false

  public systemDomain: string

  public displayNextButton: boolean = true

  public displayNextButtonOverride: boolean = true

  public previousKeyword: string

  public hasExecuted: boolean = false


  public vehicleDeleteList: Array<any> = []

  constructor(private router: Router,
              private route: ActivatedRoute,
              private signUpService: UserService,
              private propertyKeywordService: PropertyKeywordService,
              private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private location: PlatformLocation,
              private matDialog: MatDialog,
              private userAuthService: UserService,
              private keywordService: KeywordService) {

    this.translateService.setDefaultLang(this.language)

    location.onPopState(() => {

      let route

      if(this.router.url.indexOf('register') > -1)
        route = `/${this.language}/register`
      else
        route = `/${this.language}/dashboard`

      history.pushState(null, null, route)

      this.prevCard()

    })

  }

  scrollTo(el: ElementRef): void{
    $('html, body').animate({ scrollTop: $(el).offset().top }, 'slow')
  }

  public closeWindowX(): void {
    this.closeWindow.emit(this.window_obj)
  }

  public signUpError<T>(operation = 'operation', result?: T){
    return (error: any): Observable<T> => {
      return of(result as T)
    }
  }

  public finishSignUp(): void {

    const signUpBox = document.getElementById('parkingpass_signUpbox')
    signUpBox.style.display = 'none'

    const successsignUp = document.getElementById('success_box')
    successsignUp.style.display = 'none'

  }

  public selectLocale(locale: string): void {

    this.language = locale

    let route

    if(this.router.url.indexOf('register') > -1)
      route = `/${this.language}/register`
    else
      route = `/${this.language}/dashboard`

    history.pushState(null, null, route)

    this.translateService.use(this.language)
    this.router.navigate([route])

    this.residentInfoCard.language = locale

    if (this.keywordCardSkipped) {
      this.currentCardIndex = 2;  // Assume the residentCard index is 2
      this.goToCard(this.cardList[this.currentCardIndex]);
      this.propertyKeywordCard.submitted = true
      this.appReview.keywordForm = this.propertyKeywordCard.keywordForm
      this.getPropertyInfo()
    } else {
      this.goToCard(this.keywordCard);
    }

  }

  public navigateHome(): void {
    let route = `/${this.language}/home`
    this.router.navigate([route])
  }

  public getPropertyInfo(): void{

    const propertyKeyword = this.propertyKeywordCard.keywordForm.get('parkingpassKeyword').value

    this.propertyKeywordService.getPropertyDataset(propertyKeyword).subscribe(
      resp => {
        this.populatePropertyInfo(resp)
      }
    )

  }

  public populatePropertyInfo(httpReponse: any): void{

    //console.log("Property Info:", httpReponse.data)

    if(httpReponse.success){

      this.propertyInfo = httpReponse.data
      this.populateSystemDomain(this.propertyInfo.system)

      //this.propertyInfo.questions.support_pin = true

      this.residentInfoCard.propertyInfo = this.propertyInfo
      this.residentInfoCard.addValidators()

      this.propertyInfoCard.propertyInfo = this.propertyInfo
      this.propertyInfoCard.addValidators()

      this.appReview.rentOrOwn = this.propertyInfo.questions.own_or_rent

    } else
      console.log("populatePropertyInfo", httpReponse)
  }

  public populateSystemDomain(system: string): void{

    switch(system){
      case 'ocp':
        this.systemDomain = 'https://app.oncallparking.com'
        break
      case 'nwr':
        this.systemDomain = 'https://nwrparking.com'
        break
      case 'sfpa':
        this.systemDomain = 'https://www.sfpaparking.com'
        break
      case 'sst':
        this.systemDomain = 'https://www.sstparking.com'
        break
      case 'npm':
      case 'parkingpass':
        this.systemDomain = 'https://my.parkingpass.com'
        break
    }

    this.appTerms.switchSystem(this.systemDomain)

  }

  public goToCard(card: any): void{

    this.cardList.forEach(card => {
        card.open = false;
        card.wasOpened = false;
    });

    this.currentCardIndex = this.cardList.indexOf(card);

    for(let i = 0; i < this.currentCardIndex; i++){
        this.cardList[i].wasOpened = true;
    }

    if ( card ) {
      card.open = true;
    }

    if(this.isLoggedIn == '1') {
        if(this.currentCardIndex == 0 || this.currentCardIndex == 1 || this.currentCardIndex == 2 || this.currentCardIndex == 3) {
            this.isNextButtonDisabledIfLoggedIn = true;
        } else {
            this.isNextButtonDisabledIfLoggedIn = false;
        }
    }

    let hasExecuted = this.hasExecuted;

    if(card === this.vehiclesCard && hasExecuted === false){
        this.communityTypeCheck();
        this.hasExecuted = true;
    }

    if(card === this.vehicleCountCard) {
        this.hasExecuted = false;
    }
}

  public goToCardIndex(cardIndex: number): void{

    let card = this.cardList[cardIndex]
    this.goToCard(card)

  }
  public openDialog(): void {
    this.matDialog.open(
      SecurityPrivacyComponent

    )
  }

  public prevCard(){
    this.displayNextButton = true;
    this.isPrimaryVehiclesEnabled = false;

    if(this.currentCardIndex == 0){
        return;
    } else {
        if(this.currentCardIndex == 4){
            console.log('%c Next Button Override Reset', 'color: orange;');
            this.displayNextButtonOverride = true;
        }

        // Add additional check for this.propertyInfo
        if (this.propertyInfo && typeof this.propertyInfo.community_type !== 'undefined') {
            //move to previous card
            if (this.propertyInfo.community_type === 'Single-Family Home' && this.currentCardIndex === 6) {
                this.currentCardIndex -= 1;
            }
        }

        this.currentCardIndex--;

        // If keywordCard was skipped and we're at the residentCard
        // We go to the languageCard instead of keywordCard
        if(this.keywordCardSkipped && this.cardList[this.currentCardIndex] === this.keywordCard) {
            this.currentCardIndex--;
        }

        this.goToCard(this.cardList[this.currentCardIndex]);
    }
}


  public saveApplication(): void {

    this.loading = true

    const language_info = {
      user_language : this.language,
      language_id : 1
    }

    const keyword_info = {
      property_keyword: this.propertyKeywordCard.parkingpassKeyword,
      system: this.propertyInfoCard.system
    }

    const account_info = {
      first_name: this.residentInfoCard.parkingpassFirstName,
      last_name: this.residentInfoCard.parkingpassLastName,
      phone_type: this.residentInfoCard.parkingpassPhoneType,
      phone_number: this.residentInfoCard.parkingpassPhoneNumber,
      email: this.residentInfoCard.parkingpassEmail,
      email_confirmation: this.residentInfoCard.parkingpassEmailConfirm,
      support_pin: this.residentInfoCard.parkingpassCustomerSupportPin.toString(),
      vehicle_in_community: this.parkingpassVehicleInCommunity,
      device_type: navigator.userAgent,
      uuid: localStorage.getItem('parkingpass_userIdToLogin')
    }

    const property_info = {

      property_id: this.propertyInfo.location,
      property_uuid: this.propertyInfo.property_uuid,
      street_address: this.propertyInfoCard.parkingpassStreetAddress,
      building_number: this.propertyInfoCard.parkingpassBuildingNumber,
      unit_number: this.propertyInfoCard.parkingpassApartmentUnit,
      resident_type: this.propertyInfoCard.parkingpassResidentType,
      owner_info_needed: this.propertyInfoCard.parkingpassOwnerInfoNeeded,
      drivers_license_required: this.propertyInfo.documents.drivers_license,
      proof_of_insurance_required: this.propertyInfo.documents.proof_of_insurance,
      proof_of_insurance_expiration_required: this.propertyInfo.documents.proof_of_insurance_expiration,
      vehicle_registration_required: this.propertyInfo.documents.vehicle_registration,
      vehicle_registration_expiration_required: this.propertyInfo.documents.vehicle_registration_expiration,
      primary_vehicles_enabled: this.propertyInfo.settings.primary_vehicles_enabled

    }

    let owner_info

    if(this.propertyInfoCard.parkingpassResidentType === 'renter' &&
        this.propertyInfoCard.parkingpassOwnerInfoNeeded === 'true'){
      owner_info = {
        first_name : this.propertyInfoCard.parkingpassOwnerFirstName,
        last_name : this.propertyInfoCard.parkingpassOwnerLastName,
        email : this.propertyInfoCard.parkingpassOwnerEmail,
        email_confirmation : this.propertyInfoCard.parkingpassOwnerConfirmEmail,
        phone : this.propertyInfoCard.parkingpassOwnerPhoneNumber,
        address : this.propertyInfoCard.parkingpassOwnerAddress,
        city : this.propertyInfoCard.parkingpassOwnerCity,
        zip_code : this.propertyInfoCard.parkingpassOwnerZipCode,
        country : this.propertyInfoCard.parkingpassOwnerCountry,
        state : this.propertyInfoCard.parkingpassOwnerState
      }
    }

    let vehicle_list = []

    if(this.parkingpassVehicleInCommunity == 'Yes'){

      let vehicleComponentList = this.vehicleComponentList.toArray()

      vehicleComponentList.forEach(vehicle => {

        let addVehicle = {

          license_plate: vehicle.parkingpassVehicleLicensePlate.toUpperCase(),
          license_plate_confirmation: vehicle.parkingpassVehicleLicensePlateConfirm.toUpperCase(),
          vin: vehicle.parkingpassVehicleVin.toUpperCase(),
          vin_confirmation: vehicle.parkingpassVehicleVinConfirm.toUpperCase(),
          state: vehicle.parkingpassVehicleState,
          type: vehicle.parkingpassVehicleType,
          make: vehicle.parkingpassVehicleMake,
          model: vehicle.parkingpassVehicleModel,
          year: vehicle.parkingpassVehicleYear,
          color: vehicle.parkingpassVehicleColor,
          registration_proof: vehicle.parkingpassRegistrationProof,
          registration_exp_date: vehicle.parkingpassVehicleRegistrationExpDate,
          insurance_proof: vehicle.parkingpassInsuranceProof,
          insurance_exp_date: vehicle.parkingpassVehicleInsuranceExpDate,
          drivers_license: vehicle.parkingpassDriversLicense,
          user_hash: vehicle.uniqueUserDirectory,
          uuid: vehicle.uuid,
          registration_proof_updated: vehicle.registrationUpdated,
          insurance_proof_updated: vehicle.insuranceUpdated,
          drivers_license_updated: vehicle.driversLicenseUpdated,
          primary_vehicle : 'Secondary'
        }

        if(this.propertyInfo.settings.primary_vehicles_enabled){
          addVehicle.primary_vehicle = vehicle.parkingPassPrimaryVehicle
        }

        vehicle_list.push(addVehicle)

      });
    }

    const terms_info = {
      terms_fname: this.appTerms.parkingpassFirstName,
      terms_lname: this.appTerms.parkingpassLastName
    }

    const v1_token = localStorage.getItem('parkingpass_v1Token')
    const uuid_requesting_login = localStorage.getItem('parkingpass_userId')
    const uuid_to_login = localStorage.getItem('parkingpass_userIdToLogin')

    const browser =  window.navigator.userAgent
    const referral = this.router.url

    const vehicle_delete_list = this.vehicleDeleteList

    const saveApplicationObj = {
      language_info,
      keyword_info,
      account_info,
      property_info,
      owner_info,
      vehicle_list,
      terms_info,
      v1_token,
      uuid_requesting_login,
      uuid_to_login,
      browser,
      referral,
      vehicle_delete_list
    }

    this.signUpService.saveApplication(saveApplicationObj).subscribe(
      resp => {
        this.saveApplicationCallback(resp)
      },
      error => {

        let sentryContext: any = {
          errorName: 'Save User Application Error',
          language_info,
          keyword_info,
          account_info,
          property_info,
          owner_info,
          vehicle_list,
          terms_info,
          errorMessage: this.translateService.instant('USER_SERVER_ERRORS.SAVE_APPLICATION'),
          error: null
        }

        sentryContext.error = new SaveApplicationError(sentryContext.errorName)

        logErrorMessage(3, error, sentryContext, this.matDialog)

        this.loading = false

      }
    )

  }

  public saveApplicationCallback(httpResponse: any): void{

    //console.log("saveApplicationCallback", httpResponse)

    if(httpResponse === undefined) return

    if(httpResponse.success){

      if(httpResponse.saved_data.message == 'unauthorized'){
        this.userAuthService.logOut()

        const dialogConfig = new MatDialogConfig()

        dialogConfig.autoFocus = true
        dialogConfig.disableClose = true

        let dialogRef = this.matDialog.open(
          UserLoggedOutComponent,
          dialogConfig
        )

        return
      }

      this.registrationBox = false

      let propertyInfo = JSON.stringify(httpResponse.saved_data.property_info)
      let savedUser = JSON.stringify(httpResponse.saved_data.saved_user)
      let userEmail = httpResponse.saved_data.saved_user.email
      let vehicleList = JSON.stringify(httpResponse.saved_data.vehicle_list)
      let ownerInfo = JSON.stringify(httpResponse.saved_data.owner_info)

      localStorage.setItem("parkingpass_userInfo", savedUser)
      localStorage.setItem("parkingpass_userEmail", userEmail)
      localStorage.setItem("parkingpass_propertyInfo", propertyInfo)
      localStorage.setItem("parkingpass_vehicleList", vehicleList)
      localStorage.setItem("parkingpass_ownerInfo", ownerInfo)

      this.successfulApplicationEdit = true

      this.loading = false
      this.signingUp = false

    }

  }

  public submitRegistration(): void {

    this.loading = true

    const language_info = {
      user_language : this.language,
      language_id : 1
    }

    const keyword_info = {
      property_keyword: this.propertyKeywordCard.parkingpassKeyword,
      system: this.propertyInfoCard.system
    }

    const account_info = {
      first_name: this.residentInfoCard.parkingpassFirstName,
      last_name: this.residentInfoCard.parkingpassLastName,
      phone_type: this.residentInfoCard.parkingpassPhoneType,
      phone_number: this.residentInfoCard.parkingpassPhoneNumber,
      email: this.residentInfoCard.parkingpassEmail,
      email_confirmation: this.residentInfoCard.parkingpassEmailConfirm,
      password: this.residentInfoCard.parkingpassPassword,
      password_confirmation: this.residentInfoCard.parkingpassConfirm,
      support_pin: this.residentInfoCard.parkingpassCustomerSupportPin,
      vehicle_in_community: this.parkingpassVehicleInCommunity,
      device_type: navigator.userAgent
    }

    const property_info = {

      property_id: this.propertyInfo.location,
      property_uuid: this.propertyInfo.property_uuid,
      street_address: this.propertyInfoCard.parkingpassStreetAddress,
      building_number: this.propertyInfoCard.parkingpassBuildingNumber,
      unit_number: this.propertyInfoCard.parkingpassApartmentUnit,
      resident_type: this.propertyInfoCard.parkingpassResidentType,
      owner_info_needed: this.propertyInfoCard.parkingpassOwnerInfoNeeded,
      drivers_license_required: this.propertyInfo.documents.drivers_license,
      proof_of_insurance_required: this.propertyInfo.documents.proof_of_insurance,
      proof_of_insurance_expiration_required: this.propertyInfo.documents.proof_of_insurance_expiration,
      vehicle_registration_required: this.propertyInfo.documents.vehicle_registration,
      vehicle_registration_expiration_required: this.propertyInfo.documents.vehicle_registration_expiration,
      primary_vehicles_enabled: this.propertyInfo.settings.primary_vehicles_enabled

    }

    let owner_info

    if(this.propertyInfoCard.parkingpassResidentType === 'renter' &&
        this.propertyInfoCard.parkingpassOwnerInfoNeeded === 'true'){
      owner_info = {
        first_name : this.propertyInfoCard.parkingpassOwnerFirstName,
        last_name : this.propertyInfoCard.parkingpassOwnerLastName,
        email : this.propertyInfoCard.parkingpassOwnerEmail,
        email_confirmation : this.propertyInfoCard.parkingpassOwnerConfirmEmail,
        phone : this.propertyInfoCard.parkingpassOwnerPhoneNumber,
        address : this.propertyInfoCard.parkingpassOwnerAddress,
        city : this.propertyInfoCard.parkingpassOwnerCity,
        zip_code : this.propertyInfoCard.parkingpassOwnerZipCode,
        country : this.propertyInfoCard.parkingpassOwnerCountry,
        state : this.propertyInfoCard.parkingpassOwnerState
      }
    }


    let vehicle_list = []

    if(this.parkingpassVehicleInCommunity == 'Yes'){

      let vehicleComponentList = this.vehicleComponentList.toArray()

      vehicleComponentList.forEach(vehicle => {
        let addVehicle = {
          license_plate: vehicle.parkingpassVehicleLicensePlate.toUpperCase(),
          license_plate_confirmation: vehicle.parkingpassVehicleLicensePlateConfirm.toUpperCase(),
          vin: vehicle.parkingpassVehicleVin.toUpperCase(),
          vin_confirmation: vehicle.parkingpassVehicleVinConfirm.toUpperCase(),
          state: vehicle.parkingpassVehicleState,
          type: vehicle.parkingpassVehicleType,
          make: vehicle.parkingpassVehicleMake,
          model: vehicle.parkingpassVehicleModel,
          year: vehicle.parkingpassVehicleYear,
          color: vehicle.parkingpassVehicleColor,
          registration_proof: vehicle.parkingpassRegistrationProof,
          registration_exp_date: vehicle.parkingpassVehicleRegistrationExpDate,
          insurance_proof: vehicle.parkingpassInsuranceProof,
          insurance_exp_date: vehicle.parkingpassVehicleInsuranceExpDate,
          drivers_license: vehicle.parkingpassDriversLicense,
          user_hash: vehicle.uniqueUserDirectory,
          primary_vehicle : 'Secondary'
        }

        if(this.propertyInfo.settings.primary_vehicles_enabled){
          addVehicle.primary_vehicle = vehicle.parkingPassPrimaryVehicle
        }

        vehicle_list.push(addVehicle)

      });
    }

    const terms_info = {
      terms_fname: this.appTerms.parkingpassFirstName,
      terms_lname: this.appTerms.parkingpassLastName
    }

    const parking_policy_info = {
      is_accept_parking_policy: this.parkingPolicyCard.form.get('acceptParkingTerms').value,
    }

    const registerObj = {
      language_info,
      keyword_info,
      account_info,
      property_info,
      owner_info,
      vehicle_list,
      terms_info,
      parking_policy_info
    }

    this.signUpService.initRegister(registerObj).subscribe(
      resp => {
        this.signUpCallback(resp)
      },
      error => {

        let sentryContext: any = {
          errorName: 'Register User Error',
          language_info,
          keyword_info,
          account_info,
          property_info,
          owner_info,
          vehicle_list,
          terms_info,
          errorMessage: this.translateService.instant('USER_SERVER_ERRORS.REGISTER_USER'),
          error: null
        }

        sentryContext.error = new SignUpError(sentryContext.errorName)

        this.loading = false

        logErrorMessage(3, error, sentryContext, this.matDialog)

      }

    )

  }

  private signUpCallback(httpResponse: any) {

    //console.log("signUpCallback", httpResponse)

    if(httpResponse === undefined) {
      this.loading = false
      return
    }

    if(httpResponse.success){

      this.registrationBox = false

      this.successfulRegistration = true

      this.loading = false
      this.signingUp = false

    }

  }

  public nextCard(){

    this.displayNextButton = true

    if(this.currentCardIndex == (this.cardList.length - 2)){
      //Let's check if signature matches before go to last card

      let accountInfoFirstName = this.residentCard.form.get('parkingpassFirstName').value
      let accountInfoLastName = this.residentCard.form.get('parkingpassLastName').value
      let termsFirstName = this.termsCard.form.get('parkingpassFirstName').value
      let termsLastName = this.termsCard.form.get('parkingpassLastName').value

      this.cardList[this.currentCardIndex].component.submitted = true

      if(accountInfoFirstName == termsFirstName
        && accountInfoLastName == termsLastName){

        //Signature Matched, let's send the form
        this.termsCard.form.get('parkingpassFirstName').setErrors({'noMatch': false})
        this.termsCard.form.get('parkingpassLastName').setErrors({'noMatch': false})

        this.signatureIssue = false

        if(this.isLoggedIn == '1')
          this.saveApplication()
        else
          // this.submitRegistration()
          this.goToCard(
            this.cardList[this.currentCardIndex+1]
          )
          //calling method in parking policy component
          let propertyKeyword = this.propertyKeywordCard.keywordForm.get('parkingpassKeyword').value
          this.appParkingPolicyTerms.getParkingPolicyData(propertyKeyword, this.propertyInfo.system)

      } else {

        if(accountInfoFirstName != termsFirstName)
          this.termsCard.form.get('parkingpassFirstName').setErrors({'noMatch': true})
        else
          this.termsCard.form.get('parkingpassFirstName').setErrors({'noMatch': false})

        if(accountInfoLastName != termsLastName)
          this.termsCard.form.get('parkingpassLastName').setErrors({'noMatch': true})
        else
          this.termsCard.form.get('parkingpassLastName').setErrors({'noMatch': false})

        this.signatureIssue = true

      }

    } else {

      //Let's check the current form for errors before we move to next card.
      let errors = false

      if(this.currentCardIndex === 1){

        this.propertyKeywordCard.submitted = true
        errors = this.propertyKeywordCard.getKeywordFormValidationErrors()

      } else if(this.currentCardIndex === 2){

        this.residentInfoCard.submitted = true

        if(!this.residentInfoCard.emailIsConfirmed &&
            this.residentInfoCard.residentInfoForm.get('parkingpassEmail').valid &&
            this.residentInfoCard.residentInfoForm.get('parkingpassEmailConfirm').valid &&
            this.residentInfoCard.residentInfoForm.get('parkingpassFirstName').valid &&
            this.residentInfoCard.residentInfoForm.get('parkingpassLastName').valid){

          this.residentInfoCard.tryEmailConfirmation()
          return false

        } else if(this.residentInfoCard.residentInfoForm.get('parkingpassEmail').invalid){

          this.residentInfoCard.scrollToFirstInvalidControl()
          return true

        }

        errors = getFormValidationErrors(this.residentInfoCard.residentInfoForm)

        if(errors === false) errors = this.residentInfoCard.getResidentInfoErrors()

        if(errors === true) this.residentInfoCard.scrollToFirstInvalidControl()

      } else if(this.currentCardIndex === 3){

        this.propertyInfoCard.submitted = true

        errors = getFormValidationErrors(this.propertyInfoCard.propertyInfoForm)

        if(errors === false) errors = this.getPropertyErrors()

        if(errors === true) this.propertyInfoCard.scrollToFirstInvalidControl()

        this.nextButtonLogic();

      } else if(this.currentCardIndex === 4){

        // this.nextButtonLogic();

      } else if(this.currentCardIndex === 5){

        this.appReview.vehicles = this.vehicleComponentList.toArray()
        this.appReview.vehiclesInCommunity = this.parkingpassVehicleInCommunity

        errors = this.getVehicleErrors()

        if(errors)
          this.errorsInVehicle = true
        else
          this.errorsInVehicle = false

      }

      if(errors) return

      this.location.pushState(null, null, location.href)

      switch(this.currentCardIndex){
        case 1:
          this.appReview.keywordForm = this.propertyKeywordCard.keywordForm
          this.getPropertyInfo()
          break
        case 2:
          this.appReview.residentForm = this.residentInfoCard.residentInfoForm
          //Set Signature Matched AutoFilled
          this.appTerms.termsForm.get('parkingpassFirstName').setValue(this.residentInfoCard.residentInfoForm .get('parkingpassFirstName').value)
          this.appTerms.termsForm.get('parkingpassLastName').setValue(this.residentInfoCard.residentInfoForm .get('parkingpassLastName').value)
          // end code signature match autofilled
          this.propertyInfoCard.residentForm = this.residentInfoCard.residentInfoForm
          let propertyKeyword = this.propertyKeywordCard.keywordForm.get('parkingpassKeyword').value
          this.propertyInfoCard.getPropertyAddressInfo(propertyKeyword, this.propertyInfo.system)
          break
        case 3:
          this.appReview.propertyInfoForm = this.propertyInfoCard.propertyInfoForm
          this.getVehiclesPerUnitInfo()
          break
        case 5:
          break
      }

      //move to next card
      this.currentCardIndex++

      this.goToCard(
        this.cardList[this.currentCardIndex]
      )

    }

  }

  public communityTypeCheck(): void {
    if (this.propertyInfo.community_type === 'Single-Family Home') {
      this.vehicleInCommunityForm.get('parkingpassVehicleInCommunity').setValue('No');
      this.nextCard();
    }
    console.log(this.propertyInfo)
  }


  public debugFrancoAndTeamIndiaCode(heading, obj) {
    console.log('%c ' + heading, 'color: orange;');

    console.table(obj);
  }

  public nextButtonLogic() {
    if ( this.remainingVehiclesInUnitServer > 0 ) {
      this.displayNextButton = true;
      this.displayNextButtonOverride = true;
    } else {
      this.vehicleLimitReached = true;
      this.displayNextButton = false;
      this.displayNextButtonOverride = false;
    }
  }

  public primaryVehicleChange(vehicle: any){

    let vehicles: Array<VehiclesInfoComponent> = this.vehicleComponentList.toArray()

    for(let i = 0; i < vehicles.length; i++  ){

      if(i !== vehicle.vehicleIndex){
        vehicles[i].vehicleInfoForm.get('parkingPassPrimaryVehicle').setValue("Secondary")
      }

    }

  }

  public getVehiclesPerUnitInfo(){

    this.loading = true

    let addressObj = {
      system: this.propertyInfo.system,
      propertyUuid: this.propertyInfo.property_uuid,
      location: this.propertyInfo.location,
      address: this.propertyInfoCard.parkingpassStreetAddress,
      buildingNumber: this.propertyInfoCard.parkingpassBuildingNumber,
      unitNumber: this.propertyInfoCard.parkingpassApartmentUnit
    }

    this.propertyKeywordService.getVehiclesPerUnit(addressObj).subscribe(
      resp => {
        this.getVehiclesPerUnitInfoCallback(resp)
      }
    )

  }

  public getVehiclesPerUnitInfoCallback(resp: any){

    this.loading = false

    if(resp === undefined) return

    if(resp.success){

      this.vehicleLimitReached = false
      this.existingVehiclesInUnit = resp.data.existing_vehicles_in_unit
      this.remainingVehiclesInUnitServer = resp.data.resident_vehicle_max - this.existingVehiclesInUnit
      this.maxVehiclesPerUnit = resp.data.resident_vehicle_max

      if(this.remainingVehiclesInUnitServer <= 0) {

        this.remainingVehiclesInUnitServer = 0
        this.remainingVehiclesInUnit = 0
        this.vehicles = []
        this.vehicleLimitReached = true;
        this.displayNextButton = false;

      }

      this.propertyInfo.settings.primary_vehicles = false
      this.propertyInfo.settings.primary_vehicles_enabled = false
      this.remainingVehiclesInUnit = this.remainingVehiclesInUnitServer

      if(this.isLoggedIn == '1') this.vehicles = []

      if(this.isLoggedIn == '1'){

        this.vehicleListLoggedIn = JSON.parse(localStorage.getItem('parkingpass_vehicleList'))

        const loggedInPropertyInfo = JSON.parse(localStorage.getItem('parkingpass_userInfo'))

        if( ( loggedInPropertyInfo.address !== this.propertyInfoCard.parkingpassStreetAddress ||
              loggedInPropertyInfo.apart_num !== this.propertyInfoCard.parkingpassApartmentUnit ||
              loggedInPropertyInfo.build_num !== this.propertyInfoCard.parkingpassBuildingNumber) &&
              this.vehicleListLoggedIn.length > this.remainingVehiclesInUnitServer ){

          const dialogConfig = new MatDialogConfig()

          dialogConfig.autoFocus = true

          //console.log("propertyInfo", this.propertyInfo)

          dialogConfig.data = {
            unit : this.propertyInfoCard.parkingpassApartmentUnit,
            building: this.propertyInfoCard.parkingpassBuildingNumber,
            max_vehicles: this.maxVehiclesPerUnit,
          }

          let dialogRef = this.matDialog.open(
            VehiclesFullDialogComponent,
            dialogConfig
          )

          dialogRef.afterClosed().subscribe(
            resp => {
              this.prevCard()
            }
          )

          return

        }

        /*if(this.vehicles.length == this.vehicleListLoggedIn.length) {
          this.calculateVehiclesLeftInUnit()
          return
        }*/

        this.vehicleListLoggedIn.forEach( vehicle => {

          this.addVehicle(false)

        });

        this.displayNextButton = true

      }

      if(this.isLoggedIn == '1'){

          //We need to recalculate the total vehicles allowed if the user has already existing vehicles and if the user has picked a new property.
          const loggedInPropertyInfo = JSON.parse(localStorage.getItem('parkingpass_userInfo'))

          if( loggedInPropertyInfo.address !== this.propertyInfoCard.parkingpassStreetAddress ||
              loggedInPropertyInfo.apart_num !== this.propertyInfoCard.parkingpassApartmentUnit ||
              loggedInPropertyInfo.build_num !== this.propertyInfoCard.parkingpassBuildingNumber ){

            this.remainingVehiclesInUnit = this.remainingVehiclesInUnitServer - this.vehicles.length

          }

      } else {
        this.calculateVehiclesLeftInUnit();
        this.nextButtonLogic();
      }

    } else
      console.log("getVehiclesPerUnitInfoCallback", resp)

  }

  public ownerFormEvt(){
    this.appReview.ownerInfoForm = this.propertyInfoCard.ownerInfoForm
    this.appReview.propertyInfoForm = this.propertyInfoCard.propertyInfoForm
  }

  public getPropertyErrors(): boolean{

    let errors = false

    if(this.propertyInfoCard.parkingpassResidentType == 'renter' &&
        this.propertyInfoCard.parkingpassOwnerInfoNeeded == 'true'){

      this.propertyInfoCard.ownerInfoForm.markAllAsTouched()
      this.propertyInfoCard.ownerInfoForm.updateValueAndValidity()

    }

    if(this.propertyInfoCard.parkingpassResidentType == 'renter' &&
      this.propertyInfoCard.parkingpassOwnerInfoNeeded == 'true' &&
      (this.propertyInfoCard.ownerInfoForm.invalid || this.propertyInfoCard.propertyInfoForm.invalid))
      errors = true

    return errors

  }

  public getVehicleErrors(): boolean{

    if(this.parkingpassVehicleInCommunity == 'No') return false

    let allLicensePlates: Array<string> = []

    let errors = false

    let vehicleComponentList = this.vehicleComponentList.toArray()

    let uploadStatus = []

    vehicleComponentList.forEach(vehicleComponent => {

      if(vehicleComponent.vehicleInsuranceUploadingMessage !== null ||
         vehicleComponent.vehicleDriversLicenseUploadingMessage !== null ||
         vehicleComponent.vehicleRegistrationUploadingMessage !== null){
        uploadStatus.push(true)
      }

      allLicensePlates.push(vehicleComponent.parkingpassVehicleLicensePlate)

    })

    if(uploadStatus.indexOf(true) > -1){
      return true
    }

    let allLicensePlateduplicates = allLicensePlates.filter((item, index) => allLicensePlates.indexOf(item) != index)

    if(allLicensePlateduplicates.length > 0){
      errors = true
      this.duplicateVehicleTags = true
      this.parkingpassVehiclesTop.nativeElement.scrollIntoView({ block: "start" });
      return errors
    } else
      this.duplicateVehicleTags = false

    let currentVehicle: VehiclesInfoComponent = null

    vehicleComponentList.forEach(vehicleComponent => {

      vehicleComponent.submitted = true

      let vehicleForm = vehicleComponent.vehicleInfoForm

      Object.keys(vehicleForm.controls).forEach(key => {

        if(vehicleForm.get(key).status === "PENDING") errors = false

        if(vehicleForm.get(key).status === "INVALID") errors = true

        let controlErrors = vehicleForm.get(key).errors

        if(controlErrors !== null){
          if(currentVehicle === null) currentVehicle = vehicleComponent
          errors = true
        }

      })

      if(errors === true) currentVehicle.scrollToFirstInvalidControl()

    });

    return errors

  }

  public termsSigned(event: Event): void{
    // this.nextCard()
  }

  public parkingPolicySigned(event: Event): void{
    //submit form since we are on last step
    if(this.currentCardIndex == (this.cardList.length - 1)) {
      let acceptParkingTerms = this.parkingPolicyCard.form.get('acceptParkingTerms').value
      if(acceptParkingTerms === false) {
        this.parkingPolicyCard.form.get('acceptParkingTerms').setErrors({'required': true})
        return;
      }
      this.parkingPolicyCard.form.get('acceptParkingTerms').setErrors({'required': false})
      this.submitRegistration();
    }
  }

  public tabStyle(card: any): string{

    if(card.open)
      if(this.lang == 'en-us')
        return 'nav-link active'
      else if(this.lang == 'ht-ht')
        return 'nav-link manage-nav-ht active'
      else
        return 'nav-link manage-nav active'
    else if(card.wasOpened)
      if(this.lang == 'en-us')
        return 'nav-link'
      else if(this.lang == 'ht-ht')
        return 'nav-link manage-nav-ht'
      else
        return 'nav-link manage-nav'
    else
      if(this.lang == 'en-us')
        return 'nav-link disabled'
      else if(this.lang == 'ht-ht')
        return 'nav-link manage-nav-ht disabled'
      else
        return 'nav-link manage-nav disabled'

  }

  public navigatorButton(button: string): string{

    if(button === 'back')
      return 'btn btn-primary parkingpass-nav'
    else if(button === 'next')
      return 'btn btn-primary parkingpass-nav'

  }

  public cardStyle(card: any): any{

    return (card.open) ? {'display' : 'block'} : {'display': 'none'}

  }

  get i() { return this.vehicleInCommunityForm.controls }

  get parkingpassVehicleInCommunity() { return this.vehicleInCommunityForm.get('parkingpassVehicleInCommunity').value }

  public parkingPassCommunityVehicles(): any{

    return (this.parkingpassVehicleInCommunity == 'Yes') ? {'display' : 'block'} : {'display': 'none'}

  }

  public initVehicleForm(): void{

    const vehicleInCommunityValidators = [Validators.required]

    this.vehicleInCommunityForm = this.formBuilder.group({
      parkingpassVehicleInCommunity: ['', vehicleInCommunityValidators]
    })

    if(this.isLoggedIn == '1'){

      if(this.vehicleListLoggedIn.length > 0)
        this.vehicleInCommunityForm.get('parkingpassVehicleInCommunity').setValue('Yes')
      else
        this.vehicleInCommunityForm.get('parkingpassVehicleInCommunity').setValue('No')

    }

  }

  public vehicleInCommunity(): void{

    if (this.parkingpassVehicleInCommunity == 'Yes' && this.remainingVehiclesInUnit > 0) {

      this.vehicleLimitReached = false;

      this.addVehicle();

      this.displayNextButton = true;
      this.displayNextButtonOverride = true;

      return;

    } else if (this.parkingpassVehicleInCommunity == 'Yes' && this.remainingVehiclesInUnit < 1 ) {

      this.vehicleLimitReached = true;

      setTimeout(function(){
        this.vehicleInCommunityForm.get('parkingpassVehicleInCommunity').setValue('No');
      }.bind(this), 400);

      this.displayNextButton = false;
      this.displayNextButtonOverride = false;

      return;

    } else if (this.parkingpassVehicleInCommunity == 'No') {

      this.removeVehicle(0);

      if ( this.remainingVehiclesInUnitServer <= this.maxVehiclesPerUnit && this.vehicles.length < 1 ) {
        this.displayNextButton = true;
        this.displayNextButtonOverride = true;
      } else {
        this.displayNextButton = false;
        this.displayNextButtonOverride = false;
      }

      return;

    } else {

      if ( this.remainingVehiclesInUnitServer <= this.maxVehiclesPerUnit && this.vehicles.length < 1 ) {
        this.displayNextButton = true;
        this.displayNextButtonOverride = true;
      } else {
        this.displayNextButton = false;
        this.displayNextButtonOverride = false;
      }

    }

  }

  public calculateVehiclesLeftInUnit(): void {

    if(this.parkingpassVehicleInCommunity == 'No'){

      this.remainingVehiclesInUnit = this.remainingVehiclesInUnitServer

      if( this.remainingVehiclesInUnitServer == this.maxVehiclesPerUnit ||
          this.remainingVehiclesInUnitServer < this.maxVehiclesPerUnit )
        this.displayNextButton = true
      else
        this.displayNextButton = false

      return

    }

    if(this.vehicles.length > 0 && this.remainingVehiclesInUnitServer > 0 && this.isLoggedIn !== '1') {

      this.remainingVehiclesInUnit = this.remainingVehiclesInUnitServer - this.vehicles.length
      this.displayNextButton = true

    } else {

      if( this.remainingVehiclesInUnitServer == this.maxVehiclesPerUnit ||
          this.remainingVehiclesInUnitServer < this.maxVehiclesPerUnit )
        this.displayNextButton = true
      else
        this.displayNextButton = false

    }

  }

  public addVehicle(updateVehicleCount: boolean = true): void{
    if(this.isLoggedIn == '1' && !updateVehicleCount && JSON.parse(localStorage.getItem('parkingpass_vehicleList')).length == 1) {
      this.disableRemoveVehicleBtn = true
    } else {
      this.disableRemoveVehicleBtn = false
    }
    if(updateVehicleCount){
      this.remainingVehiclesInUnit--

      if(this.remainingVehiclesInUnit < 0) this.remainingVehiclesInUnit = 0
    }

    let vehicle: any = {}

    this.vehicles.push(vehicle)

  }

  public removeVehicle(vehicle: any): void{

    let i = this.vehicles.indexOf(vehicle)

    if(this.isLoggedIn == '1' && vehicle.uuid !== null){

      let currentVehicles = JSON.parse(localStorage.getItem('parkingpass_vehicleList'))

      if(currentVehicles[i] !== undefined){

        let vehicleToDelete = { 'uuid' : currentVehicles[i].uuid }

        this.vehicleDeleteList.push(vehicleToDelete)

        //currentVehicles.splice(i, 1)
        //localStorage.setItem('parkingpass_vehicleList', JSON.stringify(currentVehicles))

      }

    }

    this.vehicles.splice(i, 1)
    this.vehiclesCard.vehicles.splice(i, 1)

    this.remainingVehiclesInUnit++

    if(this.vehicles.length === 0){

      this.vehicleInCommunityForm.get('parkingpassVehicleInCommunity').setValue('No')

      if(this.remainingVehiclesInUnitServer == this.maxVehiclesPerUnit)
        this.displayNextButton = true
      else
        this.displayNextButton = false

    }

    if(this.isLoggedIn == '1' && this.vehicles.length === 1 && this.isGuestAccount === false){
      this.vehicleInCommunityForm.get('parkingpassVehicleInCommunity').setValue('Yes');
      this.disableRemoveVehicleBtn = true;
    }

  }

  public finishRegistration(): void{
    this.nextCard()
  }

  public loadingToggle(loadingState: boolean): void{
    this.loading = loadingState
  }

ngOnInit() {

  this.isLoggedIn = localStorage.getItem('parkingpass_loggedIn')

  if(this.isLoggedIn == '1'){
    this.vehicleListLoggedIn = JSON.parse(localStorage.getItem('parkingpass_vehicleList'))
  }

  this.initVehicleForm()

  //for getting current language
  this.lang = this.translateService.currentLang
  this.translateService.onLangChange.subscribe(
    resp =>{
      this.lang = resp.lang
    }
  )

  //get logged in user account type info
  if(this.isLoggedIn == '1') {
    this.getLoggedInUserInfo = JSON.parse(localStorage.getItem('parkingpass_userInfo'));
    this.isGuestAccount = this.getLoggedInUserInfo.own_vehicle_type == 'No' ? true : false;
    if(this.getLoggedInUserInfo.own_vehicle_type == 'Yes') {
      this.disableRadioBtn = true
    }
  }

  //subscribe to the keyword observable from the KeywordService
  this.keywordService.currentKeyword.subscribe(keyword => {
      this.keyword = keyword;
  });

  this.route.queryParamMap.subscribe(params => {
    const keywordFromURL = params.get('keyword');
    console.log('Keyword:', keywordFromURL);

    // If there's a keyword from URL, validate it
    if (keywordFromURL) {
      this.loading = true;

      this.propertyKeywordService.getPropertyDataset(keywordFromURL).subscribe(res => {
        this.keywordCardSkipped = res.success;
        this.loading = false;
      }, error => {
        // Error handling logic here
        this.keywordCardSkipped = false;
        this.loading = false;
      });

      // Change the keyword in the KeywordService
      this.keywordService.changeKeyword(keywordFromURL);
    }
  });

  // Always start from the language card
  this.goToCard(this.languageCard);


}


  ngAfterViewInit(){

    this.keywordCard.component = this.propertyKeywordCard
    this.keywordCard.form = this.propertyKeywordCard.keywordForm

    this.residentCard.component = this.residentInfoCard
    this.residentCard.form = this.residentInfoCard.residentInfoForm

    this.propertyCard.component = this.propertyInfoCard
    this.propertyCard.form = this.propertyInfoCard.propertyInfoForm
    this.propertyInfoCard.keywordForm = this.keywordCard.form

    this.ownerCard.component = this.propertyInfoCard

    this.vehiclesCard.component_list = this
    this.vehiclesCard.form =  this.vehicleInCommunityForm

    this.termsCard.component = this.appTerms
    this.termsCard.form = this.appTerms.termsForm

    this.parkingPolicyCard.component = this.appParkingPolicyTerms
    this.parkingPolicyCard.form = this.appParkingPolicyTerms.parkingPolicyForm

    if (this.keyword) {
        this.keywordCard.form.get('parkingpassKeyword').setValue(this.keyword);
    }

    if(this.isLoggedIn == '1'){
      this.appReview.keywordForm = this.keywordCard.form
      this.getPropertyInfo()
    }

  }

}
