<app-loading-screen *ngIf="loading"></app-loading-screen>

<h2 mat-dialog-title>{{ 'RESIDENT_INFO.CHANGE_PASSWORD' | translate }}</h2>

<mat-dialog-content>
  
  <div class='parkingpass-passwordInfo'></div>

  <div>

    <form [formGroup]="changePasswordForm">

      <div class='parkingpass-formFieldWrapper'>                           

        <mat-error *ngIf="invalidPassword">
          {{ 'RESIDENT_INFO.INVALID_PASSWORD_CURRENT' | translate }}
        </mat-error>

        <mat-error *ngIf="submitted && h.parkingpassCurrentPassword.errors.required">
          {{ 'RESIDENT_INFO.CURRENT_PASSWORD' | translate }}
        </mat-error> 

        <mat-form-field>
            <input matInput [type]="!passwordShow ? 'password' : 'text'"
                            (keyup)="resetInvalidPassword()"
                            formControlName="parkingpassCurrentPassword"                              
                            id='user_pass_confirm'
                            placeholder="{{ 'RESIDENT_INFO.CURRENT_PASSWORD' | translate }}" />
            <fa-icon class='fa-eye' [icon]="faEye" (click)="togglePassword()" *ngIf="!passwordShow"></fa-icon> 
            <fa-icon class='fa-eye' [icon]="faEyeSlash" (click)="togglePassword()" *ngIf="passwordShow"></fa-icon>                                 
        </mat-form-field>
                                          
      </div>

      <div class='parkingpass-formFieldWrapper'>
          
        <div *ngIf="h.parkingpassNewPassword.dirty && h.parkingpassNewPassword.errors" class="signUpBoxInstructions">
            
            <mat-error *ngIf="h.parkingpassNewPassword.errors.required">
                {{ 'RESIDENT_INFO.PASSWORD_REQUIRED' | translate }}
            </mat-error>

            <mat-error *ngIf="h.parkingpassNewPassword.errors.wrongLength">
                {{ 'RESIDENT_INFO.PASSWORD_LENGTH' | translate }}
            </mat-error>

            <mat-error *ngIf="h.parkingpassNewPassword.errors.oneNumber">
                {{ 'RESIDENT_INFO.PASSWORD_NUMBER' | translate }}
            </mat-error> 

            <mat-error *ngIf="h.parkingpassNewPassword.errors.oneLowerCase">
                {{ 'RESIDENT_INFO.PASSWORD_ONE_LOWERCASE' | translate }}
            </mat-error>  

            <mat-error *ngIf="h.parkingpassNewPassword.errors.oneUpperCase">
                {{ 'RESIDENT_INFO.PASSWORD_UPPERCASE' | translate }}
            </mat-error>
            
            <mat-error *ngIf="h.parkingpassNewPassword.errors.oneSpecialCharacter">
                {{ 'RESIDENT_INFO.PASSWORD_SPECIAL_CHAR' | translate }}
            </mat-error>                

            <mat-error *ngIf="h.parkingpassNewPassword.errors.mustNotMatch">
              {{ 'RESIDENT_INFO.NEW_PASSWORD_MUST_NOT_MATCH' | translate }}
            </mat-error>

        </div>   
    
        <mat-form-field>
            <input matInput [type]="!passwordShow ? 'password' : 'text'"
                            formControlName="parkingpassNewPassword" 
                            id='user_pass_new'
                            placeholder="{{ 'RESIDENT_INFO.PASSWORD_PLACEHOLDER' | translate }}" />
          <fa-icon class='fa-eye' [icon]="faEye" (click)="togglePassword()" *ngIf="!passwordShow"></fa-icon> 
          <fa-icon class='fa-eye' [icon]="faEyeSlash" (click)="togglePassword()" *ngIf="passwordShow"></fa-icon>                                                                 
        </mat-form-field>
        
      </div>

      <div class='parkingpass-formFieldWrapper'>

        <div *ngIf="h.parkingpassNewPasswordConfirmation.touched && h.parkingpassNewPasswordConfirmation.errors" class="signUpBoxInstructions">

          <mat-error *ngIf="h.parkingpassNewPasswordConfirmation.errors.mustMatch">
            {{ 'RESIDENT_INFO.C_PASSWORD_MUST_MATCH' | translate }}
          </mat-error>
          
          <mat-error *ngIf="h.parkingpassNewPasswordConfirmation.errors.required">
            {{ 'RESIDENT_INFO.C_PASSWORD_REQUIRED' | translate }}                
          </mat-error>                              

        </div>                                

        <mat-form-field>
            <input matInput [type]="!passwordShow ? 'password' : 'text'"
                            formControlName="parkingpassNewPasswordConfirmation"                              
                            id='user_pass_confirm'
                            placeholder="{{ 'RESIDENT_INFO.C_PASSWORD_PLACEHOLDER' | translate }}"/> 
            <fa-icon class='fa-eye' [icon]="faEye" (click)="togglePassword()" *ngIf="!passwordShow"></fa-icon> 
            <fa-icon class='fa-eye' [icon]="faEyeSlash" (click)="togglePassword()" *ngIf="passwordShow"></fa-icon>                                                             
        </mat-form-field>
                                          
      </div>

    </form>

  </div>
 
</mat-dialog-content>

<button class="btn btn-primary parkingpass-nav" (click)="changePassword()">
  {{ 'RESIDENT_INFO.CONFIRM_PASS_CHANGE' | translate }}
</button>

<button class="btn btn-primary parkingpass-nav" (click)="close()">
  {{ 'BUTTONS.CLOSE' | translate }}
</button>