import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-logged-out',
  templateUrl: './user-logged-out.component.html',
  styleUrls: ['./user-logged-out.component.css']
})
export class UserLoggedOutComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<UserLoggedOutComponent>, @Inject(MAT_DIALOG_DATA) dat, private router: Router) {}

  public close(){
    this.dialogRef.close()
    this.router.navigate(['log-in'])
  }

  ngOnInit() {}
  
}
