import { AbstractControl, FormGroup } from '@angular/forms';
import { debounce, debounceTime, map,  } from 'rxjs/operators';
import { retryWithBackOff } from 'src/app/helpers/retry.operators';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';

export class LpMustNotBeBannedInProperty {

    static valid(vehicleService: VehicleService, system: string, location: string) {

        return (control: AbstractControl) => {

          return vehicleService.checkIfLpIsBannedInProperty(control.value, system, location).pipe(
            retryWithBackOff(1000),
            map(res => {
              if(res === undefined) return null
              return res.is_not_blacklisted ? null : { blackListedInProperty: true };

          }));

        };

    }
    
    static validate(vehicleService: VehicleService, controlName: string, system: string, location: string) {

        return (formGroup: FormGroup) => {
    
            const control = formGroup.controls[controlName];
    
            if (control.errors && !control.errors.invalid) return;
    
            vehicleService.checkIfLpIsBannedInProperty(control.value, system, location).pipe(
              retryWithBackOff(1000),
              map(res => {
              
              if(res.is_unique){
                control.setErrors(null);
              } else {
                control.setErrors({ blackListedInProperty: true });
              }
  
            }));
        }
  
    }

}