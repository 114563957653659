import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/internal/Observable'
import { retryWithBackOff } from 'src/app/helpers/retry.operators'
import { environment } from 'src/environments/environment'

const PASSWORD_CHANGE_API = `${environment.api_endpoint}/v2/users/auth/change-password`

@Injectable({
  providedIn: 'root'
})
export class PasswordChangeService {

  constructor(private httpClient: HttpClient) {}
  
  public changePassword(changePasswordObj: any): Observable<any>{

    const changePasswordApi = `${PASSWORD_CHANGE_API}`

    const cahngePasswordObj = {
      system: changePasswordObj.system,
      uuid_requesting_login: changePasswordObj.uuid_requesting_login,
      uuid_to_login: changePasswordObj.uuid_to_login,
      v1_token: changePasswordObj.v1_token,
      api_key: environment.api_key,
      current_password: changePasswordObj.current_password,
      password: changePasswordObj.new_password,
      password_confirmation: changePasswordObj.new_password_confirm,
      language: changePasswordObj.language
    }

    return this.httpClient.post<any>(changePasswordApi, cahngePasswordObj).pipe(
      retryWithBackOff(1000)
    )

  }


}
