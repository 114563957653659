import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { MenuComponent } from './menu.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MenuLoggedInRoutingModule } from './logged-in/menu-logged-in-routing.module'
import { MenuLoggedOutRoutingModule } from './logged-out/menu-logged-out-routing.module'
import { VehiclesFullDialogComponent } from './logged-out/sign-up/vehicles-full-dialog/vehicles-full-dialog.component';
import { SecurityPrivacyComponent } from './logged-out/sign-up/security-privacy/security-privacy.component';

@NgModule({
  declarations: [
    MenuComponent,
    VehiclesFullDialogComponent,
    SecurityPrivacyComponent,
  ],
  imports: [
    CommonModule,
    MenuLoggedInRoutingModule,
    MenuLoggedOutRoutingModule,
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,    
  ],
  exports : [
    MenuComponent
  ]
})
export class MenuModule { }
