<div class='parkingpass-logo' #logo></div>

<div class='signUpBoxHolder'>

    <div class="card text-center signUpBox animated fadeInUp">

        <div class="card-body">

            <div class='tab-pan animated fadeIn' id='language'>

                <div class='card-title'>
                    {{ 'SIGN_UP.WELCOME' | translate }}
                </div>

                <div class='lang-select'>

                    <button type='button' tabindex='0' class='btn btn-primary' (click)="initSignUp()">{{ 'BUTTONS.SIGN_UP' | translate }}</button>

                </div>

            </div>

        </div>

    </div>

</div>
