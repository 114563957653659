<app-loading-screen class='parkingpass-loading-screen' *ngIf="loading"></app-loading-screen>

<div class='vehicle-divider'>

    <form [formGroup]="vehicleInfoForm">

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="(i.parkingpassVehicleLicensePlate.touched || submitted) && i.parkingpassVehicleLicensePlate.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassVehicleLicensePlate.errors.required">
                    {{ 'VEHICLE_INFO.VEHICLE_LP_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleLicensePlate.errors.invalid">
                    {{ 'VEHICLE_INFO.VEHICLE_LP_INVALID' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleLicensePlate.errors.blackListedInProperty">
                    {{ 'VEHICLE_INFO.LP_BLACKLISTED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleLicensePlate.errors.mustNotMatchLpVin">
                    {{ 'VEHICLE_INFO.LP_VIN_NOT_MATCH' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleLicensePlate.errors.notUniqueInProperty && !i.parkingpassVehicleLicensePlate.errors.blackListedInProperty">
                    {{ 'VEHICLE_INFO.LP_NOT_UNIQUE' | translate }}
                </mat-error>

            </div>

            <mat-form-field>

                <input matInput type='text' id='vehicle_license_plate'
                                autocomplete="off"
                                uppercase
                                licensePlate
                                maxlength="12"
                                (keyup)="maxLengthThis('parkingpassVehicleLicensePlate', 12)"
                                formControlName="parkingpassVehicleLicensePlate"
                                placeholder="{{ 'VEHICLE_INFO.VEHICLE_LP_PLACEHOLDER' | translate }}"
                                [errorStateMatcher]="errorMatcher" />

            </mat-form-field>

            <fa-icon class="fa-folder infoInput" [icon]="faInfoCircle" (click)="plateInfo()"></fa-icon>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="(i.parkingpassVehicleLicensePlateConfirm.touched || submitted) && i.parkingpassVehicleLicensePlateConfirm.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassVehicleLicensePlateConfirm.errors.required">
                    {{ 'VEHICLE_INFO.VEHICLE_LP_C_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleLicensePlateConfirm.errors.mustMatch">
                    {{ 'VEHICLE_INFO.VEHICLE_LP_C_MUSTMATCH' | translate }}
                </mat-error>

            </div>

            <mat-form-field>
                <input matInput type='text' id='vehicle_license_plate_c'
                                oncontextmenu="return false"
                                uppercase
                                licensePlate
                                maxlength="12"
                                (keyup)="maxLengthThis('parkingpassVehicleLicensePlateConfirm', 12)"
                                onCopy="return false"
                                onDrag="return false"
                                onDrop="return false"
                                onPaste="return false"
                                formControlName="parkingpassVehicleLicensePlateConfirm"
                                placeholder="{{ 'VEHICLE_INFO.VEHICLE_LP_C_PLACEHOLDER' | translate }}"
                                [errorStateMatcher]="errorMatcher" />
            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && i.parkingpassVehicleVin.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassVehicleVin.errors.required">
                    {{ 'VEHICLE_INFO.VEHICLE_VIN_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleVin.errors.invalid">
                    {{ 'VEHICLE_INFO.VEHICLE_VIN_INVALID' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleVin.errors.minlength || i.parkingpassVehicleVin.errors.maxlength">
                    {{ 'VEHICLE_INFO.VEHICLE_VIN_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field>

                <input matInput type='text'
                                id='vehicle_vin'
                                uppercase
                                vin
                                minLength="6"
                                maxLength="6"
                                (keyup)="maxLengthThis('parkingpassVehicleVin', 6)"
                                formControlName="parkingpassVehicleVin"
                                placeholder="{{ 'VEHICLE_INFO.VEHICLE_VIN_PLACEHOLDER' | translate }}"
                                autocomplete="off"
                                [errorStateMatcher]="errorMatcher" />
            </mat-form-field>

            <fa-icon class="fa-folder infoInput" [icon]="faInfoCircle" (click)="vinInfo()"></fa-icon>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && i.parkingpassVehicleVinConfirm.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassVehicleVinConfirm.errors.mustMatch">
                    {{ 'VEHICLE_INFO.VEHICLE_VIN_MUST_MATCH' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleVinConfirm.errors.required">
                    {{ 'VEHICLE_INFO.VEHICLE_VIN_REQUIRED' | translate }}
                </mat-error>

            </div>

            <mat-form-field>
                <input matInput type='text'
                                oncontextmenu="return false"
                                onCopy="return false"
                                onDrag="return false"
                                onDrop="return false"
                                onPaste="return false"
                                uppercase
                                vin
                                minLength="6"
                                maxLength="6"
                                (keyup)="maxLengthThis('parkingpassVehicleVinConfirm', 6)"
                                id='vehicle_vin_confirm'
                                formControlName="parkingpassVehicleVinConfirm"
                                placeholder="{{ 'VEHICLE_INFO.VEHICLE_VIN_C_PLACEHOLDER' | translate }}"
                                [errorStateMatcher]="errorMatcher" />
            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && i.parkingpassVehicleState.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassVehicleState.errors.required">
                    {{ 'VEHICLE_INFO.VEHICLE_STATE_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleState.errors.invalidInput">
                    {{ 'VEHICLE_INFO.VEHICLE_STATE_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field>

                <mat-select placeholder="{{ 'VEHICLE_INFO.VEHICLE_STATE_PLACEHOLDER' | translate }}"
                            id="vehicle_state"
                            formControlName="parkingpassVehicleState"
                            [errorStateMatcher]="errorMatcher">

                    <mat-option *ngFor="let state of stateList" [(value)]="state.id">
                        {{ state.abbreviation }}
                    </mat-option>

                </mat-select>

            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && i.parkingpassVehicleType.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassVehicleType.errors.required">
                    {{ 'VEHICLE_INFO.VEHICLE_TYPE_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleType.errors.invalidInput">
                    {{ 'VEHICLE_INFO.VEHICLE_TYPE_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field>

                <mat-select placeholder="{{ 'VEHICLE_INFO.VEHICLE_TYPE_PLACEHOLDER' | translate }}"
                            id="vehicle_type"
                            (selectionChange)="resetVehicleDropdowns()"
                            formControlName="parkingpassVehicleType"
                            [errorStateMatcher]="errorMatcher">

                    <mat-option value='car/truck'>
                        {{ 'VEHICLE_INFO.VEHICLE_TYPE_CAR' | translate }}
                    </mat-option>

                    <mat-option value='motorcycle/scooter'>
                        {{ 'VEHICLE_INFO.VEHICLE_TYPE_MOTORCYCLE' | translate }}
                    </mat-option>

                </mat-select>

            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper' [ngStyle]="vehicleFields('parkingpassVehicleYear')">

            <div *ngIf="submitted && i.parkingpassVehicleYear.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassVehicleYear.errors.required">
                    {{ 'VEHICLE_INFO.VEHICLE_YEAR_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleYear.errors.invalid">
                    {{ 'VEHICLE_INFO.VEHICLE_YEAR_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field *ngIf="showVehicleYears">

                <mat-select placeholder="{{ 'VEHICLE_INFO.VEHICLE_YEAR_PLACEHOLDER' | translate }}"
                            id="vehicle_year"
                            (selectionChange)="getVehicleMakes()"
                            formControlName="parkingpassVehicleYear"
                            [errorStateMatcher]="errorMatcher">

                    <mat-option *ngFor="let vehicleYear of vehicleYears; let i = index" [(value)]="vehicleYears[i]">
                        {{ vehicleYear }}
                    </mat-option>

                </mat-select>

            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper' [ngStyle]="vehicleFields('parkingpassVehicleMake')">

            <div *ngIf="submitted && i.parkingpassVehicleMake.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassVehicleMake.errors.required">
                    {{ 'VEHICLE_INFO.VEHICLE_MAKE_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleMake.errors.invalidInput">
                    {{ 'VEHICLE_INFO.VEHICLE_MAKE_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field>

                <mat-select placeholder="{{ 'VEHICLE_INFO.VEHICLE_MAKE_PLACEHOLDER' | translate }}"
                            id="vehicle_make"
                            (selectionChange)="getVehicleModels()"
                            formControlName="parkingpassVehicleMake"
                            [errorStateMatcher]="errorMatcher">

                    <mat-option>
                        <ngx-mat-select-search [formControl]="parkingpassVehicleMakeFilter" placeholderLabel="{{ 'VEHICLE_INFO.VEHICLE_MAKE_SEARCH_PLACEHOLDER' | translate }}"></ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let vehicleMake of filteredMakes | async" [(value)]="vehicleMake.make_name">
                        {{ vehicleMake.make_name }}
                    </mat-option>

                </mat-select>

            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper' [ngStyle]="vehicleFields('parkingpassVehicleModel')">

            <div *ngIf="submitted && i.parkingpassVehicleModel.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassVehicleModel.errors.required">
                    {{ 'VEHICLE_INFO.VEHICLE_MODEL_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleModel.errors.invalid">
                    {{ 'VEHICLE_INFO.VEHICLE_MODEL_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field *ngIf="showVehicleModels">

                <input matInput placeholder="{{ 'VEHICLE_INFO.VEHICLE_MODEL_PLACEHOLDER' | translate }}"
                        vehicleModel
                        id="vehicle_model"
                        formControlName="parkingpassVehicleModel"
                        [errorStateMatcher]="errorMatcher"
                        maxlength="45"
                        (keyup)="maxLengthThis('parkingpassVehicleModel', 45)" />

                    <!--<mat-option>
                        <ngx-mat-select-search [formControl]="parkingpassVehicleModelFilter" placeholderLabel="{{ 'VEHICLE_INFO.VEHICLE_MODEL_SEARCH_PLACEHOLDER' | translate }}"></ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let vehicleModel of filteredModels | async" [value]="vehicleModel.model_name">
                        {{ vehicleModel.model_name }}
                    </mat-option>

                </mat-select>-->

            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper' [ngStyle]="vehicleFields('parkingpassVehicleColor')">

            <div *ngIf="submitted && i.parkingpassVehicleColor.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassVehicleColor.errors.required">
                    {{ 'VEHICLE_INFO.VEHICLE_COLOR_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleColor.errors.invalid">
                    {{ 'VEHICLE_INFO.VEHICLE_COLOR_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field>

                <mat-select placeholder="{{ 'VEHICLE_INFO.VEHICLE_COLOR_PLACEHOLDER' | translate }}"
                            id="vehicle_color"
                            formControlName="parkingpassVehicleColor"
                            [errorStateMatcher]="errorMatcher">

                    <mat-option *ngFor="let vehicleColor of vehicleColors; let i = index" [(value)]="vehicleColors[i]">
                        {{ 'COLORS.' + vehicleColor  | translate }}
                    </mat-option>

                </mat-select>

            </mat-form-field>

        </div>

        <div style='position: relative; width: 100%; min-width: 100%;'></div>

        <div class='parkingpass-formFieldWrapper' [ngStyle]="checkForFormFieldDisplay(propertyInfo.documents.vehicle_registration_expiration)">

            <div *ngIf="submitted && i.parkingpassVehicleRegistrationExpDate.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassVehicleRegistrationExpDate.errors.required">
                    {{ 'VEHICLE_INFO.VEHICLE_REGISTRATION_EXP_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleRegistrationExpDate.errors.matDatepickerMin">
                    {{ 'VEHICLE_INFO.VEHICLE_REGISTRATION_EXP_INVALID' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleRegistrationExpDate.errors.invalid">
                    {{ 'VEHICLE_INFO.VEHICLE_REGISTRATION_EXP_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field appearance="fill">

                <mat-label>{{ 'VEHICLE_INFO.VEHICLE_REGISTRATION_EXP_PLACEHOLDER' | translate }}</mat-label>

                <input matInput
                        #registrationExpDatePickerInput
                        [matDatepicker]="registrationExpDatePicker"
                        [min]="minDate"
                        (keydown)="datePickerOpen($event, registrationExpDatePicker)"
                        (click)="registrationExpDatePicker.open()"
                        formControlName="parkingpassVehicleRegistrationExpDate"
                        [errorStateMatcher]="errorMatcher" />

                <mat-datepicker-toggle #registrationExpDatePickerToggle matSuffix [for]="registrationExpDatePicker"></mat-datepicker-toggle>

                <mat-datepicker touchUi #registrationExpDatePicker></mat-datepicker>

            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper' [ngStyle]="checkForFormFieldDisplay(propertyInfo.documents.vehicle_registration)">

            <mat-error class="signUpBoxInstructions" #parkingpassVehicleRegistration>
                {{ parkingpassVehicleRegistrationMediaMessage }}
            </mat-error>

            <div *ngIf="submitted && i.parkingpassRegistrationProof.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassRegistrationProof.errors.required">
                    {{ 'VEHICLE_INFO.REGISTRATION_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassRegistrationProof.errors.invalid">
                    {{ 'VEHICLE_INFO.REGISTRATION_INVALID' | translate }}
                </mat-error>

            </div>

            <input type='text'
                    id='vehicle_registration_proof'
                    formControlName="parkingpassRegistrationProof"
                    [(value)]="vehicleRegistrationFile"
                    hidden/>

            <input type="file"
                class='parkingpass-fileInput'
                #vehicleRegistrationFileUploaderInput
                (change)="uploadDefault(vehicleRegistrationFileUploaderInput.files, 'registration')"
                accept="image/jpeg, image/gif, image/png, application/pdf"
                multiple="false">

            <div class='parkingpass-vehicleFileUploader'
                (click)="startRegistrationUploader()"
                #vehicleRegistrationFileUploader>

                <button class='parkingpass-vehicleFileUpload'>
                    <fa-icon class="fa-folder" [icon]="faFolder"></fa-icon> {{ 'VEHICLE_INFO.REGISTRATION_PLACEHOLDER' | translate }}
                </button>

            </div>

            <mat-hint>{{ 'VEHICLE_INFO.ACCEPTED_FILE_TYPES' | translate }} .pdf, .jpeg, .jpg, .png, & .gif</mat-hint>

            <a class='btn text-primary viewDocumentLink' href="{{vehicleInfoLoggedIn.vehiclereg}}" target="_blank"
                *ngIf="vehicleProvided && vehicleRegistrationFilePreview === undefined">
                <fa-icon class="fa-eye" [icon]="faEye"></fa-icon>
                {{ 'VEHICLE_INFO.CURRENT_REGISTRATION' | translate }}
            </a>

        </div>

        <div *ngIf="vehicleRegistrationFilePreview !== undefined || vehicleRegistrationUploadingMessage !== null" class="fileTitle" #vehicleRegistrationFileDisplayTitle>
            {{ 'VEHICLE_INFO.PREWVIEW_REGISTRATION_PLACEHOLDER' | translate }}
        </div>

        <div class='parkingpass-vehicleFileDisplay'
            *ngIf="(vehicleRegistrationFilePreview !== undefined || vehicleRegistrationUploadingMessage !== null) && vehicleRegistrationFileType !== 'application/pdf'"
            [ngStyle]="{ 'background' : 'url(' + vehicleRegistrationFilePreview + ')' }"
            (click)="startRegistrationUploader()"
            #vehicleRegistrationFileDisplay>

            <div class='parkingpass-centeredMessage' *ngIf="vehicleRegistrationUploadingMessage !== null">
                <fa-icon class="fa-spinner" [icon]="faSpinner" [pulse]="true"></fa-icon> {{ vehicleRegistrationUploadingMessage }}
            </div>

        </div>

        <pdf-viewer *ngIf="(vehicleRegistrationFilePreview !== undefined || vehicleRegistrationUploadingMessage !== null) && vehicleRegistrationFileType === 'application/pdf'"
                    [src]="vehicleRegistrationFilePreview"
                    class="pdfViewer"
                    #vehicleRegistrationFileDisplay>
        </pdf-viewer>

        <div class='parkingpass-formFieldWrapper' [ngStyle]="checkForFormFieldDisplay(propertyInfo.documents.proof_of_insurance_expiration)">

            <div *ngIf="submitted && i.parkingpassVehicleInsuranceExpDate.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassVehicleInsuranceExpDate.errors.required">
                    {{ 'VEHICLE_INFO.VEHICLE_INSURANCE_EXP_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleInsuranceExpDate.errors.matDatepickerMin">
                    {{ 'VEHICLE_INFO.VEHICLE_INSURANCE_EXP_INVALID' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassVehicleInsuranceExpDate.errors.invalid">
                    {{ 'VEHICLE_INFO.VEHICLE_INSURANCE_EXP_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field appearance="fill">

                <mat-label>{{ 'VEHICLE_INFO.VEHICLE_INSURANCE_EXP_PLACEHOLDER' | translate }}</mat-label>

                <input matInput
                    [min]="minDate"
                    [matDatepicker]="insuranceExpirationDatePicker"
                    (keydown)="datePickerOpen($event, insuranceExpirationDatePicker)"
                    (click)="insuranceExpirationDatePicker.open()"
                    formControlName="parkingpassVehicleInsuranceExpDate"
                    [errorStateMatcher]="errorMatcher" />

                <mat-datepicker-toggle #insuranceExpirationDatePickerToggle matSuffix [for]="insuranceExpirationDatePicker"></mat-datepicker-toggle>

                <mat-datepicker touchUi #insuranceExpirationDatePicker></mat-datepicker>

            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper' [ngStyle]="checkForFormFieldDisplay(propertyInfo.documents.proof_of_insurance)">

            <mat-error class="signUpBoxInstructions" #parkingpassVehicleInsurance>
                {{ parkingpassVehicleInsuranceMediaMessage }}
            </mat-error>

            <div *ngIf="submitted && i.parkingpassInsuranceProof.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassInsuranceProof.errors.required">
                    {{ 'VEHICLE_INFO.INSURANCE_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassInsuranceProof.errors.invalid">
                    {{ 'VEHICLE_INFO.INSURANCE_INVALID' | translate }}
                </mat-error>

            </div>

            <input type='text'
                    id='vehicle_insurance_proof'
                    formControlName="parkingpassInsuranceProof"
                    [(value)]="vehicleInsuranceFile"
                    hidden/>

            <input type="file"
                class='parkingpass-fileInput'
                #vehicleInsuranceFileUploaderInput
                (change)="uploadDefault(vehicleInsuranceFileUploaderInput.files, 'insurance')"
                accept="image/jpeg, image/gif, image/png, application/pdf"
                multiple="false">

            <div class='parkingpass-vehicleFileUploader'
                (click)="startInsuranceUploader()"
                #vehicleInsuranceFileUploader>

                <button class='parkingpass-vehicleFileUpload'>
                    <fa-icon class="fa-folder" [icon]="faFolder"></fa-icon> {{ 'VEHICLE_INFO.INSURANCE_PLACEHOLDER' | translate }}
                </button>

            </div>

            <mat-hint>{{ 'VEHICLE_INFO.ACCEPTED_FILE_TYPES' | translate }} .pdf, .jpeg, .jpg, .png, & .gif</mat-hint>

            <a class='btn text-primary viewDocumentLink' href="{{vehicleInfoLoggedIn.insuranceproof}}" target="_blank"
                *ngIf="vehicleProvided && vehicleInsuranceFilePreview === undefined">
                <fa-icon class="fa-eye" [icon]="faEye"></fa-icon>
                {{ 'VEHICLE_INFO.CURRENT_INSURANCE' | translate }}
            </a>

        </div>

        <div *ngIf="vehicleInsuranceFilePreview !== undefined || vehicleInsuranceUploadingMessage !== null" class="fileTitle" #vehicleInsuranceFileDisplayTitle>
            {{ 'VEHICLE_INFO.PREWVIEW_INSURANCE_PLACEHOLDER' | translate }}
        </div>

        <div class='parkingpass-vehicleFileDisplay'
            *ngIf="(vehicleInsuranceFilePreview !== undefined || vehicleInsuranceUploadingMessage !== null) && vehicleInsuranceFileType != 'application/pdf'"
            [ngStyle]="{ 'background' : 'url(' + vehicleInsuranceFilePreview + ')' }"
            (click)="startInsuranceUploader()"
            #vehicleInsuranceFileDisplay>

            <div class='parkingpass-centeredMessage' *ngIf="vehicleInsuranceUploadingMessage !== null">
                <fa-icon class="fa-spinner" [icon]="faSpinner" [pulse]="true"></fa-icon> {{ vehicleInsuranceUploadingMessage }}
            </div>

        </div>

        <pdf-viewer *ngIf="(vehicleInsuranceFilePreview !== undefined || vehicleInsuranceUploadingMessage !== null) && vehicleInsuranceFileType === 'application/pdf'"
                    class="pdfViewer"
                    [src]="vehicleInsuranceFilePreview"
                    #vehicleInsuranceFileDisplay>
        </pdf-viewer>

        <div class='parkingpass-formFieldWrapper' [ngStyle]="checkForFormFieldDisplay(propertyInfo.documents.drivers_license)">

            <mat-error class="signUpBoxInstructions" #parkingpassVehicleDriversLicense>
                {{ parkingpassVehicleDriversLicenseMediaMessage }}
            </mat-error>

            <div *ngIf="submitted && i.parkingpassDriversLicense.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="i.parkingpassDriversLicense.errors.required">
                    {{ 'VEHICLE_INFO.DRIVERS_LICENSE_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="i.parkingpassDriversLicense.errors.invalid">
                    {{ 'VEHICLE_INFO.DRIVERS_LICENSE_INVALID' | translate }}
                </mat-error>

            </div>

            <input type='text'
                    id='vehicle_drivers_license'
                    formControlName="parkingpassDriversLicense"
                    hidden/>

            <input type="file"
                class='parkingpass-fileInput'
                #vehicleDriversLicenseFileUploaderInput
                (change)="uploadDefault(vehicleDriversLicenseFileUploaderInput.files, 'license')"
                accept="image/jpeg, image/gif, image/png, application/pdf"
                multiple="false">

            <div class='parkingpass-vehicleFileUploader'
                (click)="startLicenseUploader()"
                #vehicleDriversLicenseFileUploader>

                <button class='parkingpass-vehicleFileUpload'>
                    <fa-icon class="fa-folder" [icon]="faFolder"></fa-icon> {{ 'VEHICLE_INFO.DRIVERS_LICENSE_PLACEHOLDER' | translate }}
                </button>

            </div>

            <mat-hint>{{ 'VEHICLE_INFO.ACCEPTED_FILE_TYPES' | translate }} .pdf, .jpeg, .jpg, .png, & .gif</mat-hint>

            <a class='btn text-primary viewDocumentLink' href="{{vehicleInfoLoggedIn.drivinglicense}}" target="_blank"
                *ngIf="vehicleProvided && vehicleDriversLicenseFilePreview === undefined">

                <fa-icon class="fa-eye" [icon]="faEye"></fa-icon>
                {{ 'VEHICLE_INFO.CURRENT_LICENSE' | translate }}

            </a>

        </div>

        <div *ngIf="vehicleDriversLicenseFilePreview !== undefined || vehicleDriversLicenseUploadingMessage !== null" class="fileTitle" #vehicleDriversLicenseFileDisplay>
            {{ 'VEHICLE_INFO.PREWVIEW_DRIVERS_LICENSE_PLACEHOLDER' | translate }}
        </div>

        <div class='parkingpass-vehicleFileDisplay'
            *ngIf="(vehicleDriversLicenseFilePreview !== undefined || vehicleDriversLicenseUploadingMessage !== null) && vehicleLicenseFileType !== 'application/pdf'"
            [ngStyle]="{ 'background' : 'url(' + vehicleDriversLicenseFilePreview + ')' }"
            (click)="startLicenseUploader()"
            #vehicleDriversLicenseFileDisplay>

            <div class='parkingpass-centeredMessage' *ngIf="vehicleDriversLicenseUploadingMessage !== null">
                <fa-icon class="fa-spinner" [icon]="faSpinner" [pulse]="true"></fa-icon> {{ vehicleDriversLicenseUploadingMessage }}
            </div>

        </div>

        <pdf-viewer *ngIf="(vehicleDriversLicenseFilePreview !== undefined || vehicleDriversLicenseUploadingMessage !== null) && vehicleLicenseFileType === 'application/pdf'"
                    class="pdfViewer"
                    [src]="vehicleDriversLicenseFilePreview"
                    #vehicleDriversLicenseFileDisplay>
        </pdf-viewer>

        <div class='parkingpass-formFieldWrapper'
            [ngStyle]="checkForFormFieldDisplay(propertyInfo.settings.primary_vehicles_enabled)"
            style="position: relative; display: block; margin: 0 auto; float: unset; text-align: center;">


            <mat-label>
                {{ 'VEHICLE_INFO.PRIMARY_VEHICLE' | translate }}
            </mat-label>

            <mat-radio-group class="parkingpass-singup-resident-type"
                                aria-label="Select an option"
                                (change)="updatePrimaryVehicles()"
                                formControlName="parkingPassPrimaryVehicle">

                <mat-radio-button value="Primary">{{ 'GENERAL.YES' | translate }}</mat-radio-button>
                <mat-radio-button value="Secondary">{{ 'GENERAL.NO' | translate }}</mat-radio-button>

            </mat-radio-group>

            <div *ngIf="submitted && i.parkingPassPrimaryVehicle.errors">

                <mat-error *ngIf="i.parkingPassPrimaryVehicle.errors.required">
                    {{ 'VEHICLE_INFO.PRIMARY_VEHICLE_REQ' | translate }}
                </mat-error>

            </div>

        </div>

        <input type="hidden" formControlName="parkingpassVehicleUuid" />

    </form>

</div>
