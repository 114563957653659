<app-loading-screen *ngIf="loading"></app-loading-screen>

<div class='parkingpass-logo' #logo (click)="navigateHome()"></div>

<div class='signUpBoxHolder'>

  <div class="card text-center signUpBox animated fadeInUp" *ngIf="usingForm">

    <div class="card-body">

      <div class='tab-pan animated fadeIn' *ngIf="systemIsSet">

        <div class='card-title' #parkingpassLogInIssues>
            {{ 'LOG_IN.WELCOME' | translate }}
        </div>

        <form [formGroup]="logInForm" (ngSubmit)="initLogIn()">

          <div>

            <div *ngIf="f.parkingpassUsername.errors !== null">

              <mat-error *ngIf="submitted && (f.parkingpassUsername.errors.required || f.parkingpassUsername.errors.emptyUsername)">
                {{ 'RESIDENT_INFO.EMAIL_REQUIRED' | translate }}
              </mat-error>

              <mat-error *ngIf="submitted && f.parkingpassUsername.errors.email">
                {{ 'RESIDENT_INFO.EMAIL_INVALID' | translate }}
              </mat-error>

            </div>

            <mat-form-field>

              <mat-label>
                E-Mail
              </mat-label>

              <input matInput
                      formControlName="parkingpassUsername"
                      type="text"
                      placeholder="E-Mail Address"
                      autocomplete="email"/>

            </mat-form-field>

          </div>

          <div>

            <div *ngIf="f.parkingpassPassword.dirty && f.parkingpassPassword.errors" class="signUpBoxInstructions">

              <mat-error *ngIf="f.parkingpassPassword.errors.required">
                  {{ 'RESIDENT_INFO.PASSWORD_REQUIRED' | translate }}
              </mat-error>

              <mat-error *ngIf="f.parkingpassPassword.errors.wrongLength || f.parkingpassPassword.errors.oneNumber || f.parkingpassPassword.errors.oneLowerCase ||
                                f.parkingpassPassword.errors.oneUpperCase || f.parkingpassPassword.errors.oneSpecialCharacter">
                  {{ 'LOG_IN.INVALID_PASSWORD' | translate }}
              </mat-error>

            </div>

            <mat-form-field>

              <mat-label>
                Password
              </mat-label>

              <input matInput
                formControlName="parkingpassPassword"
                [type]="!passwordShow ? 'password' : 'text'"
                autocomplete="password"/>
              <fa-icon class='fa-eye' [icon]="faEye" (click)="togglePassword()" *ngIf="!passwordShow"></fa-icon>
              <fa-icon class='fa-eye' [icon]="faEyeSlash" (click)="togglePassword()" *ngIf="passwordShow"></fa-icon>
            </mat-form-field>

          </div>

          <!--<section class="parkingpass-toggle">

            <mat-label>{{ 'BUTTONS.REMEMBER_ME' | translate }}</mat-label>

            <mat-checkbox class="label-margin"
                          color="primary"
                          formControlName="parkingpassRememberMeField"
                          [checked]="parkingpassRememberMe">
            </mat-checkbox>

          </section>-->

          <button class="btn btn-primary">

            {{ 'BUTTONS.LOG_IN' | translate }}

          </button>

        </form>

        <div tabindex='0' class="parkingpass-forgot-pass text-primary"
            (keydown)="goToForgotPassword()"
            (click)="goToForgotPassword()">
            {{ 'BUTTONS.FORGOT_PASSWORD' | translate }}
        </div>

      </div>

      <div class='card-title' *ngIf="!systemIsSet">
        <app-property-keyword-log-in></app-property-keyword-log-in>
      </div>

    </div>

  </div>

</div>
