import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-refunds-main',
  templateUrl: './refunds.component.html',
  styleUrls: ['./refunds.component.css']
})
export class RefundsMainComponent implements OnInit {

  public lang: string
  public systemDomain: string
  public system

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.lang = this.translateService.currentLang
    this.translateService.onLangChange.subscribe(
      resp =>{
        this.lang = resp.lang
      }
    )
  }

}
