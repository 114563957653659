import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function CaseInsensitiveMustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {

        const control = formGroup.controls[controlName]
        const matchingControl = formGroup.controls[matchingControlName]

        if (matchingControl.errors && !matchingControl.errors.mustMatch) return

        // set error on matchingControl if validation fails
        if (control.value.toLowerCase() !== matchingControl.value.toLowerCase())
            matchingControl.setErrors({ mustMatch: true })
        else
            matchingControl.setErrors(null)

    }
}
