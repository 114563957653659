export class VehicleDocumentUploadError extends Error {
    constructor(message) {
        super(message); 
        this.name = "VehicleDocumentUploadError"; 
    }
}

export class VehicleMakesError extends Error {
    constructor(message) {
        super(message); 
        this.name = "VehicleMakesError"; 
    }
}