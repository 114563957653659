import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MenuLoggedOutComponent } from './menu-logged-out.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { LogInComponent } from './log-in/log-in.component'
import { SignUpComponent } from './sign-up/sign-up.component'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatDialogModule } from '@angular/material/dialog'
import { MatRadioModule } from '@angular/material/radio'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { PropertyKeywordComponent } from './sign-up/property-keyword/property-keyword.component'
import { PropertyInfoComponent } from './sign-up/property-info/property-info.component'
import { ResidentInfoComponent } from './sign-up/resident-info/resident-info.component'
import { VehiclesInfoComponent } from './sign-up/vehicles-info/vehicles-info.component'
import { TranslateModule } from '@ngx-translate/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { TermsComponent } from './sign-up/terms/terms.component'
import { ParkingPolicyComponent } from './sign-up/parking-policy/parking-policy.component'
import { ReviewComponent } from './sign-up/review/review.component'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PropertyKeywordInfoComponent } from './sign-up/property-keyword/property-keyword-info/property-keyword-info.component'
import { CustomerPinInfoComponent } from './sign-up/resident-info/customer-pin-info/customer-pin-info.component'
import { PhoneConfirmationComponent } from './sign-up/phone-confirmation/phone-confirmation.component'
import { TermsMainComponent } from 'src/app/terms/terms.component'
import { ParkingPolicyMainComponent } from 'src/app/parking-policy-main/parking-policy-main.component'
import { HelperModule } from 'src/app/helpers/helper.module'
import { routes } from 'src/app/app-routing.module'
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router'
import { PasswordChangerComponent } from './sign-up/resident-info/password-changer/password-changer.component'
import { EmailConfirmationComponent } from './sign-up/email-confirmation/email-confirmation.component'
import { VinInfoComponent } from './sign-up/vehicles-info/vin-info/vin-info.component'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search'
import { LicensePlateInfoComponent } from './sign-up/vehicles-info/license-plate-info/license-plate-info.component'
import { UserLoggedOutComponent } from '../user-logged-out/user-logged-out.component'
import { PropertyKeywordLogInComponent } from './log-in/property-keyword-log-in/property-keyword-log-in.component'
import { SecurityPrivacyComponent } from './sign-up/security-privacy/security-privacy.component'
import {RefundsMainComponent} from '../../refunds/refunds.component';

export const options : Partial<IConfig> | (() => Partial<IConfig>) = null

@NgModule({
  declarations: [
    MenuLoggedOutComponent,
    LogInComponent,
    SignUpComponent,
    PropertyKeywordInfoComponent,
    PropertyKeywordComponent,
    PropertyInfoComponent,
    CustomerPinInfoComponent,
    PasswordChangerComponent,
    ResidentInfoComponent,
    VehiclesInfoComponent,
    TermsComponent,
    ParkingPolicyComponent,
    ReviewComponent,
    PhoneConfirmationComponent,
    EmailConfirmationComponent,
    RefundsMainComponent,
    TermsMainComponent,
    ParkingPolicyMainComponent,
    VinInfoComponent,
    LicensePlateInfoComponent,
    UserLoggedOutComponent,
    PropertyKeywordLogInComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatRadioModule,
    MatDatepickerModule,
    NgxMatSelectSearchModule,
    FontAwesomeModule,
    HelperModule,
    MatCheckboxModule,
    NgxMaskModule.forRoot(options),
    TranslateModule,
    LocalizeRouterModule.forChild(routes),
    RouterModule
  ],
  exports : [
    MenuLoggedOutComponent,
    TranslateModule,
    SignUpComponent,
    PropertyKeywordInfoComponent,
    PropertyKeywordComponent,
    PropertyInfoComponent,
    CustomerPinInfoComponent,
    ResidentInfoComponent,
    VehiclesInfoComponent,
    TermsComponent,
    ParkingPolicyComponent,
    ReviewComponent,
    PhoneConfirmationComponent,
    UserLoggedOutComponent,
    RefundsMainComponent,
    TermsMainComponent,
    ParkingPolicyMainComponent,
    PropertyKeywordLogInComponent
  ],
  entryComponents: [
    PropertyKeywordInfoComponent,
    PhoneConfirmationComponent,
    SecurityPrivacyComponent
  ],
})
export class MenuLoggedOutRoutingModule { }
