<h2 mat-dialog-title>{{ 'DASHBOARD.TIMEOUT_TITLE' | translate }}</h2>

<mat-dialog-content>

   <div>
      {{ 'DASHBOARD.SESSION_TIMEOUT' | translate }}
   </div>

</mat-dialog-content>

<br/><br/>
