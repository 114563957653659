<h2 mat-dialog-title>{{ 'RESIDENT_INFO.CUSTOMER_PIN_PLACEHOLDER' | translate }}</h2>

<mat-dialog-content>
  
   <div>
      {{ 'RESIDENT_INFO.CUSTOMER_PIN_HELPER' | translate }}
   </div>
 
</mat-dialog-content>

<button class="btn btn-primary parkingpass-nav" (click)="close()">
   {{ 'BUTTONS.CLOSE' | translate }}
</button>