import { Directive,ElementRef, HostListener } from '@angular/core';

const MAX_VIN_LENGTH = 6

@Directive({
 selector: '[vin]'
})
export class VinDirective {

    constructor(public ref: ElementRef) { }

    @HostListener('input', ['$event']) onInput(event) {
  
        let regex = /^[a-zA-Z0-9]*$/
        let replaceRegex = /[^a-zA-Z0-9/-]+/
        let str = event.target.value
        
        //shorten the string if it's over length
        if(str.length > MAX_VIN_LENGTH)
            str = str.substring(0, MAX_VIN_LENGTH)

        // allow letters and numbers only.
        if(!regex.test(event.key)){
          str = str.replace(replaceRegex, "") 
          this.ref.nativeElement.value = str
          event.preventDefault()      
        }
    
    }

    @HostListener('input', ['$event']) onBlur(event) {
  
        let replaceRegex = /[^a-zA-Z0-9/-]+/
        let str = event.target.value.replace(replaceRegex, "")
        event.target.value = str.toUpperCase()
    
    }

}