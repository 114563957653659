<div class='card-title'>
  <p>{{ 'TERMS.INSTRUCTIONS' | translate }}</p>
</div>

<p class='terms'>

  <app-terms-main #termsMainComponent></app-terms-main>

</p>

<hr>

<p class='terms'>

  <app-refunds-main #refundsMainComponent></app-refunds-main>

</p>

<p>
  <i>
    {{ 'TERMS.SECOND_INSTRUCTIONS' | translate }}
  </i>
</p>

<form [formGroup]="termsForm">

  <div class='parkingpass-formFieldWrapper'>

    <div *ngIf="submitted && f.parkingpassFirstName.errors" class="signUpBoxInstructions">

        <mat-error *ngIf="f.parkingpassFirstName.errors.required">
            {{ 'TERMS.FIRST_NAME_REQUIRED' | translate }}
        </mat-error>

        <mat-error *ngIf="f.parkingpassFirstName.errors.invalid">
            {{ 'TERMS.FIRST_NAME_INVALID' | translate }}
        </mat-error>

        <mat-error *ngIf="f.parkingpassFirstName.errors.noMatch">
          {{ 'TERMS.FIRST_NAME_NO_MATCH' | translate }}
        </mat-error>

    </div>

    <mat-form-field>
        <input matInput type='text'
                        id='first_name'
                        placeholder="{{ 'TERMS.FIRST_NAME_PLACEHOLDER' | translate }}"
                        (keydown)="formatName($event, 'parkingpassFirstName')"
                        (blur)="formatAfterBlur('parkingpassFirstName')"
                        autocomplete="given-name"
                        formControlName="parkingpassFirstName"
                        [readonly]="true"
                        />
    </mat-form-field>

  </div>

  <div class='parkingpass-formFieldWrapper'>

    <div *ngIf="submitted && f.parkingpassLastName.errors" class="signUpBoxInstructions">

        <mat-error *ngIf="f.parkingpassLastName.errors.required">
            {{ 'TERMS.LAST_NAME_REQUIRED' | translate }}
        </mat-error>

        <mat-error *ngIf="f.parkingpassLastName.errors.invalid">
            {{ 'TERMS.LAST_NAME_INVALID' | translate }}
        </mat-error>

        <mat-error *ngIf="f.parkingpassLastName.errors.noMatch">
          {{ 'TERMS.LAST_NAME_NO_MATCH' | translate }}
        </mat-error>

    </div>

    <mat-form-field>
        <input matInput type='text'
                        id='last_name'
                        placeholder="{{ 'TERMS.LAST_NAME_PLACEHOLDER' | translate }}"
                        (keydown)="formatName($event, 'parkingpassLastName')"
                        (blur)="formatAfterBlur('parkingpassLastName')"
                        autocomplete="family-name"
                        formControlName="parkingpassLastName"
                        [readonly]="true"
                        />
    </mat-form-field>

  </div>

  <!-- <button class='btn btn-secondary' #signAndFinishButton (click)="signTerms()">
      {{ 'BUTTONS.ACCEPT_TERMS' | translate }}
  </button> -->

</form>
