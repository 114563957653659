export class ChangePasswordError extends Error {
    constructor(message) {
        super(message); 
        this.name = "ChangePasswordError"; 
    }
}

export class LogInError extends Error {
    constructor(message) {
        super(message); 
        this.name = "LogInError"; 
    }
}

export class TokenLogInError extends Error {
    constructor(message) {
        super(message); 
        this.name = "TokenLogInError"; 
    }
}

export class SignUpError extends Error {
    constructor(message) {
        super(message); 
        this.name = "SignUpError"; 
    }
}

export class SaveApplicationError extends Error {
    constructor(message) {
        super(message); 
        this.name = "SaveApplicationError"; 
    }
}

export class PhoneConfirmationError extends Error {
    constructor(message) {
        super(message); 
        this.name = "PhoneConfirmationError"; 
    }
}

export class CheckPhoneConfirmationError extends Error {
    constructor(message) {
        super(message); 
        this.name = "CheckPhoneConfirmationError"; 
    }
}

export class EmailConfirmationError extends Error {
    constructor(message) {
        super(message); 
        this.name = "EmailConfirmationError"; 
    }
}

export class EmailValidationError extends Error {
    constructor(message) {
        super(message); 
        this.name = "EmailValidationError"; 
    }
}