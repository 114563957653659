import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { HttpClientModule } from '@angular/common/http'
import { MenuLoggedInComponent } from './menu-logged-in.component'
import { RouterModule } from '@angular/router'
import { NgxMaskModule, IConfig } from 'ngx-mask'

import { HelperModule } from 'src/app/helpers/helper.module'
import { ParkingPassPipesModule } from 'src/app/pipes/parkingpass-pipes.module'
import { MenuLoggedOutRoutingModule } from '../logged-out/menu-logged-out-routing.module'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSidenavModule } from '@angular/material/sidenav'

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { MatDialogModule } from '@angular/material/dialog'
import { AccountWarningsDialogComponent } from './account-warnings-dialog/account-warnings-dialog.component'

export const options : Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [   
    MenuLoggedInComponent,
    AccountWarningsDialogComponent           
  ],
  imports: [  
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    HttpClientModule,
    ParkingPassPipesModule,
    MenuLoggedOutRoutingModule,
    MatToolbarModule,
    MatButtonModule,    
    MatIconModule,
    MatDialogModule,
    MatSidenavModule,
    RouterModule,
    HelperModule,
    FontAwesomeModule,
    NgxMaskModule.forRoot(options)
  ],
  exports: [
    MenuLoggedInComponent,
    AccountWarningsDialogComponent 
  ] 
})
export class MenuLoggedInRoutingModule { }