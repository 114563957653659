import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-security-privacy',
  templateUrl: './security-privacy.component.html',
  styleUrls: ['./security-privacy.component.css']
})
export class SecurityPrivacyComponent implements OnInit {

  ngOnInit() {
  }

  constructor(private dialog: MatDialogRef<SecurityPrivacyComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private translateService: TranslateService,
    private matDialog: MatDialog,
    public MatDialogModule: MatDialogModule) {}

    public closeDialog(): void {
      this.dialog.close(
        
        
      )
    }
}
