export const VEHICLE_COLOR_LIST: Array<string> = [
    "WHITE",
    "SILVER",
    "BLACK",
    "GREY",
    "BLUE",
    "RED",
    "BROWN",
    "GREEN",
    "OTHER"
]