<div class='card-title'>
    {{ 'REVIEW.INSTRUCTIONS' | translate }}
</div>

<div class='card-title parkingpass-changeInfoSectionTitle'>
    {{ 'TABS.KEYWORD' | translate }}
</div>

<div class='parkingpass-form-review' *ngIf="keywordForm">
    
    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'TABS.KEYWORD' | translate }}</mat-label>
        <input matInput tabindex="-1" type="text" readonly [(value)]="keywordForm.get('parkingpassKeyword').value" [ngStyle]="{ 'text-transform' : 'uppercase' }" />

    </div> 

    <button class='btn btn-primary  parkingpass-changeInfo' (click)="goToCardx(1)">{{ 'REVIEW.CHG_PROPERTY_KEYWORD' | translate }}</button>

</div>

<div class='card-title parkingpass-changeInfoSectionTitle'>
    {{ 'TABS.RESIDENT_INFO' | translate }}
</div>

<div class='parkingpass-form-review' *ngIf="residentForm">
    
    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'RESIDENT_INFO.FIRST_NAME_PLACEHOLDER' | translate }}</mat-label>
        <input matInput tabindex="-1" type="text" readonly [(value)]="residentForm.get('parkingpassFirstName').value" />

    </div>

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'RESIDENT_INFO.LAST_NAME_PLACEHOLDER' | translate }}</mat-label>
        <input matInput tabindex="-1" type="text" readonly [(value)]="residentForm.get('parkingpassLastName').value" />

    </div>    

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'RESIDENT_INFO.EMAIL_PLACEHOLDER' | translate }}</mat-label>
        <input matInput tabindex="-1" type="text" readonly [(value)]="residentForm.get('parkingpassEmail').value" />

    </div>

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'RESIDENT_INFO.C_EMAIL_PLACEHOLDER' | translate }}</mat-label>
        <input matInput tabindex="-1" type="text" readonly [(value)]="residentForm.get('parkingpassEmailConfirm').value" />

    </div>  

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'RESIDENT_INFO.PHONE_NUMBER_TYPE' | translate }}</mat-label>
        
        <input matInput tabindex="-1" type="text" readonly [value]="titleCaseThis(residentForm.get('parkingpassPhoneType').value)" />

    </div>

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'RESIDENT_INFO.PHONE_NUMBER_PLACEHOLDER' | translate }}</mat-label>
        <input matInput tabindex="-1" type="text" readonly [(value)]="residentForm.get('parkingpassPhoneNumber').value" />

    </div>  


    <div class='parkingpass-formFieldWrapper' *ngIf="residentForm.get('parkingpassCustomerSupportPin').value.length > 0">

        <mat-label>{{ 'RESIDENT_INFO.CUSTOMER_PIN_PLACEHOLDER' | translate }}</mat-label>
        <input matInput tabindex="-1" type="text" readonly [(value)]="residentForm.get('parkingpassCustomerSupportPin').value" />

    </div>  

    <button class='btn btn-primary parkingpass-changeInfo' (click)="goToCardx(2)">{{ 'REVIEW.CHG_ACCOUNT_INFO' | translate }}</button>

</div>

<div class='card-title parkingpass-changeInfoSectionTitle'>
    {{ 'TABS.PROPERTY_INFO' | translate }}
</div>

<div class='parkingpass-form-review' *ngIf="propertyInfoForm">
    
    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'PROPERTY_INFO.ADDRESS_REVIEW' | translate }}</mat-label>
        <input matInput tabindex="-1" type="text" readonly [(value)]="propertyInfoForm.get('parkingpassStreetAddress').value" />

    </div>

    <div class='parkingpass-formFieldWrapper' *ngIf="propertyInfoForm.get('parkingpassBuildingNumber').value !== 'null'">

        <mat-label>{{ 'PROPERTY_INFO.BUILDING_NUMBER_PLACEHOLDER' | translate }}</mat-label>
        <input matInput tabindex="-1" type="text" readonly [(value)]="propertyInfoForm.get('parkingpassBuildingNumber').value" />

    </div>    

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'PROPERTY_INFO.UNIT_NUMBER_PLACEHOLDER' | translate }}</mat-label>
        <input matInput tabindex="-1" type="text" readonly [(value)]="propertyInfoForm.get('parkingpassApartmentUnit').value" />

    </div>

    <div class='parkingpass-formFieldWrapper'
        *ngIf="(propertyInfoForm.get('parkingpassResidentType').value === 'owner' || propertyInfoForm.get('parkingpassResidentType').value === 'renter') && rentOrOwn">

        <mat-label>{{ 'PROPERTY_INFO.OWN_OR_RENT' | translate }}</mat-label>

        <input matInput tabindex="-1" type="text" readonly *ngIf="propertyInfoForm.get('parkingpassResidentType').value === 'owner'" value="{{ 'PROPERTY_INFO.OWN' | translate }}" />

        <input matInput tabindex="-1" type="text" readonly *ngIf="propertyInfoForm.get('parkingpassResidentType').value === 'renter'" value="{{ 'PROPERTY_INFO.RENT' | translate }}" />

    </div>  

    <button class='btn btn-primary parkingpass-changeInfo' (click)="goToCardx(3)">{{ 'REVIEW.CHG_PROPERTY_INFO' | translate }}</button>

</div>

<div class='card-title parkingpass-changeInfoSectionTitle' *ngIf="ownerInfoForm !== undefined && propertyInfoForm.get('parkingpassResidentType').value == 'renter' && rentOrOwn">
    {{ 'PROPERTY_INFO.OWN_OR_RENT_TITLE' | translate }}
</div>

<div class='parkingpass-form-review' *ngIf="ownerInfoForm !== undefined && propertyInfoForm.get('parkingpassResidentType').value == 'renter' && rentOrOwn">
    
    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'OWNER_INFO.FIRST_NAME_PLACEHOLDER' | translate }}</mat-label>
        <input tabindex="-1" matInput type="text" readonly [(value)]="ownerInfoForm.get('parkingpassOwnerFirstName').value" />

    </div>

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'OWNER_INFO.LAST_NAME_PLACEHOLDER' | translate }}</mat-label>
        <input tabindex="-1" matInput type="text" readonly [(value)]="ownerInfoForm.get('parkingpassOwnerLastName').value" />

    </div>    

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'OWNER_INFO.EMAIL_PLACEHOLDER' | translate }}</mat-label>
        <input tabindex="-1" matInput type="text" readonly [(value)]="ownerInfoForm.get('parkingpassOwnerEmail').value" />

    </div>

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'OWNER_INFO.C_EMAIL_PLACEHOLDER' | translate }}</mat-label>
        <input tabindex="-1" matInput type="text" readonly [(value)]="ownerInfoForm.get('parkingpassOwnerConfirmEmail').value" />

    </div>  

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'OWNER_INFO.PHONE_NUMBER_PLACEHOLDER' | translate }}</mat-label>
        <input tabindex="-1" matInput type="text" readonly [(value)]="ownerInfoForm.get('parkingpassOwnerPhoneNumber').value" />

    </div>

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'OWNER_INFO.OWNER_ADDRESS_PLACEHOLDER' | translate }}</mat-label>
        <input tabindex="-1" matInput type="text" readonly [(value)]="ownerInfoForm.get('parkingpassOwnerAddress').value" />

    </div>

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'OWNER_INFO.OWNER_CITY_PLACEHOLDER' | translate }}</mat-label>
        <input tabindex="-1" matInput type="text" readonly [(value)]="ownerInfoForm.get('parkingpassOwnerCity').value" />

    </div>    

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'OWNER_INFO.OWNER_ZIP_CODE_PLACEHOLDER' | translate }}</mat-label>
        <input tabindex="-1" matInput type="text" readonly [(value)]="ownerInfoForm.get('parkingpassOwnerZipCode').value" />

    </div>  

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'OWNER_INFO.SELECTED_COUNTRY' | translate }}</mat-label>
        <input tabindex="-1" matInput type="text" readonly [(value)]="ownerInfoForm.get('parkingpassOwnerCountry').value" />

    </div>  

    <div class='parkingpass-formFieldWrapper'>

        <mat-label>{{ 'OWNER_INFO.SELECTED_STATE' | translate }}</mat-label>
        <input tabindex="-1" matInput type="text" readonly [(value)]="ownerInfoForm.get('parkingpassOwnerState').value" />

    </div>      

    <button class='btn btn-primary parkingpass-changeInfo' (click)="goToCardx(3)">{{ 'REVIEW.CHG_OWNER_INFO' | translate }}</button>

</div>

<div class='card-title parkingpass-changeInfoSectionTitle' *ngIf="vehicles.length > 0 && vehiclesInCommunity == 'Yes'">
    {{ 'TABS.ADD_VEHICLES' | translate }}
</div>

<div class='parkingpass-form-review formReviewVehicles' *ngIf="vehicles.length > 0 && vehiclesInCommunity == 'Yes'">

    <table class='table'>

        <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{ 'REVIEW.VEHICLE_YEAR' | translate }}</th>
              <th scope="col">{{ 'REVIEW.VEHICLE_MAKE' | translate }}</th>
              <th scope="col">{{ 'REVIEW.VEHICLE_MODEL' | translate }}</th>              
              <th scope="col">{{ 'REVIEW.VEHICLE_COLOR' | translate }}</th>
              <th scope="col">{{ 'REVIEW.VEHICLE_LP' | translate }}</th>
            </tr>
        </thead>
        
        <tbody>
            <tr class='parkingpass-vehicleReview' *ngFor="let vehicle of vehicles; let i = index">
                <td scope="row">{{ i+1 }}</td>
                <td>{{ vehicle.vehicleInfoForm.get('parkingpassVehicleYear').value }}</td>
                <td>{{ vehicle.vehicleInfoForm.get('parkingpassVehicleMake').value }}</td>
                <td>{{ vehicle.vehicleInfoForm.get('parkingpassVehicleModel').value }}</td>                
                <td>{{ vehicle.vehicleInfoForm.get('parkingpassVehicleColor').value }}</td>
                <td>{{ vehicle.vehicleInfoForm.get('parkingpassVehicleLicensePlate').value | uppercase }}</td>
            </tr> 
        </tbody> 
  

    </table>

    <button class='btn btn-primary parkingpass-changeInfo' (click)="goToCardx(4)">{{ 'REVIEW.CHG_VEHICLES_INFO' | translate }}</button>

</div>

<div class='parkingpass-form-review formReviewVehiclesMobile' *ngIf="vehicles.length > 0 && vehiclesInCommunity == 'Yes'">

    <table class='table'>

        <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{ 'REVIEW.VEHICLE_YEAR' | translate }}</th>
              <th scope="col">{{ 'REVIEW.VEHICLE_MAKE' | translate }}</th>
              <th scope="col">{{ 'REVIEW.VEHICLE_LP' | translate }}</th>
            </tr>
        </thead>
        
        <tbody>
            <tr class='parkingpass-vehicleReview' *ngFor="let vehicle of vehicles; let i = index">
                <td scope="row">{{ i+1 }}</td>
                <td>{{ vehicle.vehicleInfoForm.get('parkingpassVehicleYear').value }}</td>
                <td>{{ vehicle.vehicleInfoForm.get('parkingpassVehicleMake').value }}</td>
                <td>{{ vehicle.vehicleInfoForm.get('parkingpassVehicleLicensePlate').value }}</td>
            </tr> 
        </tbody> 
  

    </table>

    <button class='btn btn-primary parkingpass-changeInfo' (click)="goToCardx(4)">{{ 'REVIEW.CHG_VEHICLES_INFO' | translate }}</button>

</div>