import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeywordService {
  private keywordSource = new BehaviorSubject<string | null>(null);
  public currentKeyword = this.keywordSource.asObservable();

  changeKeyword(keyword: string | null) {
    this.keywordSource.next(keyword);
  }
}
