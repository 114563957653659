<app-loading-screen *ngIf="loading"></app-loading-screen>
<p class="terms">
  <app-parking-policy-main
    #ParkingPolicyMainComponent
  ></app-parking-policy-main>
</p>
<form [formGroup]="parkingPolicyForm">
  <div id="parkingpass-parking-policy" class="parkingpass-formFieldWrapper">
    <div
      *ngIf="submitted && f.acceptParkingTerms.errors"
      class="signUpBoxInstructions"
    >
      <mat-error *ngIf="f.acceptParkingTerms.errors.required">
        {{ "PARKING_POLICY.PARKING_POLICY_REQUIRED" | translate }}
      </mat-error>
    </div>

    <div class="form-group form-check row">
      <div class="col-sm-12">
        <input
          type="checkbox"
          formControlName="acceptParkingTerms"
          id="acceptParkingTerms"
        />
        <label for="acceptParkingTerms" class="form-check-label"
          >{{ "PARKING_POLICY.PARKING_POLICY_CHECKBOX_CONTENT" | translate }}
        </label>
      </div>
    </div>
  </div>

  <button class="btn btn-success" #signAndFinishButton (click)="signTerms()">
    {{ "BUTTONS.FINISH" | translate }}
  </button>
</form>
