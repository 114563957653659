import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { handleError } from 'src/app/helpers/error-helper';
import { retryWithBackOff } from 'src/app/helpers/retry.operators';
import { environment } from 'src/environments/environment';

const VEHICLE_YEARS_API = `${environment.api_endpoint}/v2/vehicles/year-make-model/years`
const VEHICLE_MAKES_API = `${environment.api_endpoint}/v2/vehicles/year-make-model/makes`
const VEHICLE_MODELS_API = `${environment.api_endpoint}/v2/vehicles/year-make-model/models`
const VEHICLE_COLORS_API = `${environment.api_endpoint}/v2/vehicles/year-make-model/colors`

const UNIQUE_LP_IN_PROPERTY_API = `${environment.api_endpoint}/v2/vehicles/vehicle/check-if-lp-is-unique-in-property`
const BANNED_LP_IN_PROPERTY_API = `${environment.api_endpoint}/v2/vehicles/vehicle/check-if-lp-is-banned-in-property`

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private http: HttpClient) { }

  public getVehicleYears(): Observable<any>{

    const apiEndpoint = `${VEHICLE_YEARS_API}`

    let body = {
      api_key: environment.api_key
    }

    return this.http.post<any>(apiEndpoint, body).pipe(
      retryWithBackOff(1000),     
    )

  }

  public getVehicleMakes(vehicleTypeName: string): Observable<any>{

    const apiEndpoint = `${VEHICLE_MAKES_API}`

    let body = {
      api_key: environment.api_key,
      vehicle_type_name: vehicleTypeName
    }

    return this.http.post<any>(apiEndpoint, body).pipe(
      retryWithBackOff(1000)   
    )

  }

  public getVehicleModels(makeId: number, year: number): Observable<any>{

    const apiEndpoint = `${VEHICLE_MODELS_API}`

    let body = {
      api_key: environment.api_key,
      make_id: makeId,
      year: year
    }

    return this.http.post<any>(apiEndpoint, body).pipe(
      retryWithBackOff(1000)      
    )

  }

  public getVehicleColors(): Observable<any>{

    const apiEndpoint = `${VEHICLE_COLORS_API}`

    let body = {
      api_key: environment.api_key
    }

    return this.http.post<any>(apiEndpoint, body).pipe(
      retryWithBackOff(1000)     
    )

  }

  public checkIfLpIsUniqueInProperty(licensePlate: string, system: string, location: string): Observable<any>{

    const apiEndpoint = `${UNIQUE_LP_IN_PROPERTY_API}`

    let body = {
      api_key: environment.api_key,
      license_plate: licensePlate,
      system: system,
      location: location
    }

    return this.http.post<any>(apiEndpoint, body).pipe(
      retryWithBackOff(1000)      
    )

  }
  
  public checkIfLpIsBannedInProperty(licensePlate: string, system: string, location: string): Observable<any>{

    const apiEndpoint = `${BANNED_LP_IN_PROPERTY_API}`

    let body = {
      api_key: environment.api_key,
      license_plate: licensePlate,
      system: system,
      location: location
    }

    return this.http.post<any>(apiEndpoint, body).pipe(
      retryWithBackOff(1000)     
    )

  }

}
