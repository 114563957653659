//Async Validation
import { AbstractControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { PropertyError } from 'src/app/errors/property-errors/property.errors';
import { logErrorMessage, retryWithBackOff } from 'src/app/helpers/retry.operators';
import { PropertyKeywordService } from 'src/app/services/sign-up/property-keyword.service';

export class ValidatePropertyKeyword {

    static valid(propertyKeywordService: PropertyKeywordService) {
        return (control: AbstractControl) => {

          return propertyKeywordService.getPropertyDataset(control.value).pipe(
            map(
              res => {   
                
                if(res === undefined) return { invalid : true }  
                                     
                if(res.success) {
                  localStorage.setItem('parkingpass_system', res.data.system)   
                  return null 
                } else 
                 return { invalid: true }

              },
              error => {
                
                let sentryContext: any = {
                  errorName: 'Get Property From Keyword Error',
                  keyword: control.value,
                  errorMessage: 'Get Property From Keyword Error',
                  error: null
                }
            
                sentryContext.error = new PropertyError(sentryContext.errorName)
        
                logErrorMessage(3, error, sentryContext)

              }
            )
          );
        
        };
    }

    static validate(propertyKeywordService: PropertyKeywordService, controlName: string) {

      return (formGroup: FormGroup) => {
  
          const control = formGroup.controls[controlName];

          if(control.value.length < 3) return { invalid: true }

          if (control.errors && !control.errors.invalid) return;
  
          propertyKeywordService.getPropertyDataset(control.value).pipe(            
            map(res => {
            
              (res.is_unique) ? control.setErrors(null) : control.setErrors({ invalid: true })

            }
          ));
      }

  }

}