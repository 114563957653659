import { Component, OnInit, ViewChild } from '@angular/core'
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { MatDialogConfig, MatDialog } from '@angular/material/dialog'
import { ValidatePropertyKeyword } from 'src/app/validators/sign-up/property-keyword.validator'
import { PropertyKeywordService } from 'src/app/services/sign-up/property-keyword.service'
import { Subject } from 'rxjs'
import { Debounce } from 'src/app/helpers/debounce.decorator'
import { titleCase } from 'src/app/helpers/string-formatting.helper'
import { logErrorMessage } from 'src/app/helpers/retry.operators'
import { TranslateService } from '@ngx-translate/core'
import { PropertyError } from 'src/app/errors/property-errors/property.errors'
import { PropertyKeywordInfoComponent } from '../../sign-up/property-keyword/property-keyword-info/property-keyword-info.component'
import { Router } from '@angular/router'

@Component({
  selector: 'app-property-keyword-log-in',
  templateUrl: './property-keyword-log-in.component.html',
  styleUrls: ['./property-keyword-log-in.component.css']
})
export class PropertyKeywordLogInComponent implements OnInit {

  @ViewChild('parkingpassSignUpIssues') parkingpassSignUpIssues

  public faInfoCircle = faInfoCircle

  public keywordForm: FormGroup

  public submittingForm: boolean = false
  public submitted: boolean = false

  public propertyKeywordIsValidTimeOut: any = null

  public propertyName: string

  public subject = new Subject()

  public loading: boolean = false

  constructor(private formBuilder: FormBuilder,
              private matDialog: MatDialog,
              private propertyKeywordService: PropertyKeywordService,
              private translateService: TranslateService,
              private router: Router) { }
              
  get f() { return this.keywordForm.controls }

  get parkingpassKeyword() { return this.keywordForm.get('parkingpassKeyword').value }

  public initForm(): void{

    const propertyInfo = JSON.parse(localStorage.getItem('parkingpass_propertyInfo'))

    const parkingpassKeywordValidators = [Validators.required]

    this.keywordForm = this.formBuilder.group({
      parkingpassKeyword: ['', parkingpassKeywordValidators, ValidatePropertyKeyword.valid(this.propertyKeywordService)]
    }, {
      validators: [
        ValidatePropertyKeyword.validate(this.propertyKeywordService, 'parkingpassKeyword')
      ]
    })

    if(propertyInfo !== null) this.keywordForm.get('parkingpassKeyword').setValue(propertyInfo.keyword)
    
  }

  public enableInfo(): void{

    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = true

    this.matDialog.open(
      PropertyKeywordInfoComponent,
      dialogConfig
    )

  }

  public getKeywordFormValidationErrors(): boolean {

    let errors = true
    let form = this.keywordForm

    Object.keys(form.controls).forEach(key => {

      if(form.get(key).status == 'PENDING') return true

      const controlErrors: ValidationErrors = form.get(key).errors

      if (controlErrors != null || form.get(key).status == 'INVALID')
        errors = true
      else
        errors = false

    })

    return errors

  }

  @Debounce(1000)
  public getKeywordPropertyName(){

    this.loading = true

    if(this.keywordForm.get('parkingpassKeyword').valid){

      this.propertyKeywordService.getPropertyDataset(this.parkingpassKeyword).subscribe(

        resp => {
          
          if(resp.success){
            this.propertyName = titleCase(resp.data.name)
            localStorage.setItem('parkingpass_system', resp.data.system)
            window.location.reload()
          } else
            this.propertyName = null
           

          this.loading = false

        },
        error => {
  
          let sentryContext: any = {
            errorName: 'Get Property From Keyword Error',
            keyword: this.parkingpassKeyword,
            errorMessage: this.translateService.instant('USER_SERVER_ERRORS.PROPERTY_KEYWORD'),
            error: null
          }
      
          sentryContext.error = new PropertyError(sentryContext.errorName)
  
          logErrorMessage(3, error, sentryContext, this.matDialog)
          
          this.loading = false

        }  
      )

    }    

  }

  ngOnInit() {
    
    this.initForm()

  }

}
