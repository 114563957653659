import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { removeLastWhiteSpace, titleCase } from 'src/app/helpers/string-formatting.helper';
import { TermsMainComponent } from 'src/app/terms/terms.component';
import { ValidatePersonName } from 'src/app/validators/name.validator';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css', '../sign-up.component.css']
})
export class TermsComponent implements OnInit {

  @ViewChild('parkingpassSignUpIssues') parkingpassSignUpIssues
  @ViewChild('signAndFinishButton') signAndFinishButton

  @ViewChild('termsMainComponent') termsMainComponent: TermsMainComponent

  public termsForm: FormGroup
  public residentForm: FormGroup = null

  public submittingForm: boolean = false
  public submitted: boolean = false

  public firstName: string
  public lastName: string

  public systemDomain: string

  @Output() termsSigned = new EventEmitter()

  constructor(private formBuilder: FormBuilder) { }

  public termsError<T>(operation = 'operation', result?: T) {

    this.submittingForm = false

    return (error: any): Observable<T> => {

      console.log(error)

      let signUpInstructions = this.parkingpassSignUpIssues.nativeElement

      signUpInstructions.className = 'signUpBoxInstructions parkingpass-error-red'
      signUpInstructions.style.display = 'none'

      signUpInstructions.innerHTML = "There was an error signing-up."

      const error_list = error.error.errors

      if(error_list.password_confirmation){

        let errors: {[k: string]: any} = {};
        error_list.password_confirmation.forEach(error => {
          error[error] = true
        })          
        this.termsForm.get('parkingpassConfirm').setErrors(errors)
        
      }

      this.submittingForm = false

      setTimeout(function() {
        signUpInstructions.style.display = 'block' 
      } , 200)  

      // Let the app keep running by returning an empty result.
      return of(result as T)

    }

  }

  get f() { return this.termsForm.controls }

  get parkingpassFirstName() { return this.termsForm.get('parkingpassFirstName').value }
  get parkingpassLastName() { return this.termsForm.get('parkingpassLastName').value }

  public formatName(event, formControlName: string){

    var regex = /^[a-zA-Z\s-'äöüéèâîôñïç]*$/

    // allow letters and whitespaces only.
    if(!regex.test(event.key)) event.preventDefault()

    let str = titleCase(this.termsForm.get(formControlName).value)
    this.termsForm.get(formControlName).setValue(str)

  }
  
  public formatAfterBlur(formControlName: string){
    let str = removeLastWhiteSpace(this.termsForm.get(formControlName).value)
    this.termsForm.get(formControlName).setValue(str);
  }

  public initForm(): void{

    const firstNameValidators = [Validators.required]
    const lastNameValidators = [Validators.required]

    this.termsForm = this.formBuilder.group({
      parkingpassFirstName: ['', firstNameValidators],
      parkingpassLastName: ['', lastNameValidators]
    }, { validators: [
      ValidatePersonName('parkingpassFirstName'),
      ValidatePersonName('parkingpassLastName')
    ]})

  }

  public signTerms(): void{
    this.termsSigned.emit(null)
  }

  public switchSystem(systemDomain: string){
    this.systemDomain = systemDomain
    this.termsMainComponent.systemDomain = systemDomain
  }

  ngOnInit() {
    this.initForm()
  }

}
