import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-customer-pin-info',
  templateUrl: './customer-pin-info.component.html',
  styleUrls: ['./customer-pin-info.component.css']
})
export class CustomerPinInfoComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<CustomerPinInfoComponent>, @Inject(MAT_DIALOG_DATA) dat) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close()
  }

}
