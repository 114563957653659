<div class="col" *ngIf="lang == 'en-us'">

    <h3>
      Refund Policy
    </h3>
    <p>
        <u>Acceptance of the Refund Policy</u>
    </p>
    <p>
      Since Union Rich Technologies d/b/a ParkingPass.com  ("Company", "we" or "us") is offering non-tangible irrevocable goods, we do not issue refunds once the payment has processed and the parking permit has been sent or issued. As a customer you are responsible for understanding this upon purchasing any item through our site. However, we realize that exceptional circumstance can take place.
    </p>
    <p>
      Therefore, we DO honor requests for the refund on the following reasons:
    </p>
  <ul>
    <li><strong>Advanced Purchases:</strong> In the event that you have purchased a renewal permit in advance, but have decided to move from the property before your active parking permit has expired, we will certainly honor your refund request. In this case we recommend contacting your property management office for assistance. Claims for advanced purchases must be submitted to your property management office in writing within 30 days from the order placing date. Otherwise the parking permit will be considered received/paid and no refund will be provided.</li>
  </ul>
  <p>
    Please note that we do not bear any responsibility and therefore we do not satisfy any refund requests based on termination of our services by your property, property management firm, HOA or Board of Directors, and therefore we do not satisfy any refund requests based on such termination of our services.
  </p>

</div>

<div class="col" *ngIf="lang == 'es-mx'">

  <h3>
    Politica de Reembolso
  </h3>
  <p>
    <u>Aceptación de la Política de Reembolso</u>
  </p>
  <p>
    Dado que Union Rich Technologies dba ParkingPass.com ("Compañía", "nosotros" o "nosotros") ofrece bienes irrevocables no tangibles, no emitimos reembolsos una vez que se ha procesado el pago y se ha enviado o emitido el permiso de estacionamiento. Como cliente, usted es responsable de comprender esto al comprar cualquier artículo a través de nuestro sitio. Sin embargo, nos damos cuenta de que pueden darse circunstancias excepcionales.
  </p>
  <p>
    Por lo tanto, cumplimos con las solicitudes de reembolso por los siguientes motivos:
  </p>
  <ul>
    <li><strong>Compras Anticipadas:</strong> En el caso de que haya comprado un permiso de renovación por adelantado, pero haya decidido mudarse de la propiedad antes de que caduque su permiso de estacionamiento activo, ciertamente cumpliremos con su solicitud de reembolso. En este caso, le recomendamos que se comunique con la oficina de administración de su propiedad para obtener ayuda. Los reclamos por compras anticipadas deben enviarse a la oficina de administración de su propiedad por escrito dentro de los 30 días a partir de la fecha de realización del pedido. De lo contrario, el permiso de estacionamiento se considerará pagado y no se realizará ningún reembolso.</li>
  </ul>
  <p>
    Tenga en cuenta que no asumimos ninguna responsabilidad y, por lo tanto, no satisfacemos ninguna solicitud de reembolso basada en la terminación de nuestros servicios por parte de su propiedad, empresa de administración de propiedades, HOA o Junta Directiva y, por lo tanto, no satisfacemos ninguna solicitud de reembolso basada en tal terminación de nuestros servicios.
  </p>

</div>

<div class="col" *ngIf="lang  == 'ht-ht'">

  <h3>
    Règleman Ranbousman
  </h3>
  <p>
    <u>Akseptasyon Règleman Ranbousman An</u>
  </p>
  <p>
    Depi Union Rich Technologies dba ParkingPass.com ("Konpayi", "nou" oswa "nou") ap ofri machandiz irevokabl ki pa byen mèb, nou pa bay ranbousman yon fwa ke peman an fin trete epi yo voye oswa bay pèmi pakin lan. Kòm yon kliyan ou responsab pou konprann sa a lè w ap achte nenpòt atik atravè sit nou an. Sepandan, nou reyalize ke sikonstans eksepsyonèl ka rive.
  </p>
  <p>
    Se poutèt sa, nou onore demann pou ranbousman an pou rezon sa yo:
  </p>
  <ul>
    <li><strong>Acha Avanse:</strong> Nan ka ou te achte yon pèmi renouvèlman davans, men ou te deside deplase soti nan pwopriyete a anvan pèmi pakin aktif ou a ekspire, nou pral sètènman onore demann ranbousman ou a. Nan ka sa a nou rekòmande pou kontakte biwo jesyon pwopriyete w la pou asistans. Reklamasyon pou acha avanse yo dwe soumèt bay biwo jesyon pwopriyete w la alekri nan 30 jou apati dat pase lòd la. Sinon, yo pral konsidere pèmi pakin lan resevwa peye epi yo pap bay okenn ranbousman.</li>
  </ul>
  <p>
    Tanpri sonje ke nou pa pote okenn responsablite e se poutèt sa nou pa satisfè okenn demann ranbousman ki baze sou revokasyon sèvis nou yo pa pwopriyete w la, konpayi jesyon pwopriyete w la, HOA oswa Konsèy Administrasyon, epi kidonk nou pa satisfè okenn demann ranbousman ki baze sou sa yo. revokasyon sèvis nou yo.
  </p>

</div>

<div class="col" *ngIf="lang  == 'pt-pt'">

  <h3>
    Politica de Reembolso
  </h3>
  <p>
    <u>Aceitação da Política de Reembolso</u>
  </p>
  <p>
    Como a Union Rich Technologies dba ParkingPass.com ("Empresa", "nós" ou "nós") está oferecendo bens irrevogáveis não tangíveis, não emitimos reembolsos após o processamento do pagamento e o envio ou emissão da autorização de estacionamento. Como cliente, você é responsável por entender isso ao comprar qualquer item em nosso site. No entanto, percebemos que circunstâncias excepcionais podem ocorrer.
  </p>
  <p>
    Portanto, honramos os pedidos de reembolso pelos seguintes motivos:
  </p>
  <ul>
    <li><strong>Compras Avançadas:</strong> No caso de você ter adquirido uma autorização de renovação com antecedência, mas decidiu se mudar da propriedade antes de sua autorização de estacionamento ativa expirar, certamente honraremos seu pedido de reembolso. Nesse caso, recomendamos entrar em contato com o escritório de administração de imóveis para obter assistência. Reivindicações para compras antecipadas devem ser enviadas ao seu escritório de administração de imóveis por escrito dentro de 30 dias a partir da data de envio do pedido. Caso contrário, a permissão de estacionamento será considerada recebida paga e nenhum reembolso será fornecido.</li>
  </ul>
  <p>
    Observe que não assumimos nenhuma responsabilidade e, portanto, não atendemos a nenhuma solicitação de reembolso com base no término de nossos serviços por sua propriedade, empresa de administração de propriedade, HOA ou Conselho de Administração e, portanto, não atendemos a nenhuma solicitação de reembolso com base em tal rescisão de nossos serviços.
  </p>

</div>
