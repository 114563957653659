import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retryWithBackOff } from 'src/app/helpers/retry.operators';
import { environment } from 'src/environments/environment';

const EMAIL_API = `${environment.api_endpoint}/v2/users/validation/email`

@Injectable({
  providedIn: 'root'
})
export class EmailUniqueCheckService {

  constructor(private http: HttpClient) { }

  public checkIfEmailUnique(email: string, system: string): Observable<any>{

    const testEmailApi = EMAIL_API
    const emailObj = {
        api_key: environment.api_key,
        system: system,
        user_email: email,
    }

    return this.http.post<any>(testEmailApi, emailObj).pipe(
        retryWithBackOff(1000)
    )

  }

}
