<div class='card-title'>
    {{ 'RESIDENT_INFO.CARD_TITLE' | translate }} 
</div>

<form [formGroup]="residentInfoForm">                                               
    
    <div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && f.parkingpassFirstName.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="f.parkingpassFirstName.errors.required">
                    {{ 'RESIDENT_INFO.FIRST_NAME_REQUIRED' | translate }}  
                </mat-error>
                
                <mat-error *ngIf="f.parkingpassFirstName.errors.invalidInput">
                    {{ 'RESIDENT_INFO.FIRST_NAME_INVALID' | translate }}  
                </mat-error>

            </div>                  

            <mat-form-field>

                <input matInput type='text' 
                                id='user_first_name'                         
                                formControlName="parkingpassFirstName" 
                                placeholder="{{ 'RESIDENT_INFO.FIRST_NAME_PLACEHOLDER' | translate }}"
                                titlecase
                                personName
                                whiteSpace
                                autocomplete="given-name"
                                [errorStateMatcher]="errorMatcher" />   

            </mat-form-field>

        </div>
    
        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && f.parkingpassLastName.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="f.parkingpassLastName.errors.required">
                    {{ 'RESIDENT_INFO.LAST_NAME_REQUIRED' | translate }}
                </mat-error>
                
                <mat-error *ngIf="f.parkingpassLastName.errors.invalidInput">
                    {{ 'RESIDENT_INFO.LAST_NAME_INVALID' | translate }}
                </mat-error>

            </div>                   
                                
            <mat-form-field>

                <input matInput type='text' 
                                id='user_last_name' 
                                formControlName="parkingpassLastName" 
                                placeholder="{{ 'RESIDENT_INFO.LAST_NAME_PLACEHOLDER' | translate }}" 
                                titlecase
                                personName
                                whiteSpace
                                autocomplete="family-name"
                                [errorStateMatcher]="errorMatcher"/> 

            </mat-form-field>
                                                    
        </div>

    </div>

    <div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="f.parkingpassEmail.touched && f.parkingpassEmail.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="f.parkingpassEmail.errors.required">
                    {{ 'RESIDENT_INFO.EMAIL_REQUIRED' | translate }}
                </mat-error>

                <mat-error *ngIf="f.parkingpassEmail.errors.email">
                    {{ 'RESIDENT_INFO.EMAIL_INVALID' | translate }}
                </mat-error>                              
                
                <mat-error *ngIf="f.parkingpassEmail.errors.emailTaken">
                    {{ 'RESIDENT_INFO.EMAIL_NOT_UNIQUE' | translate }}
                </mat-error>                             
                
            </div>
            
            <mat-form-field>

                <input matInput (keyup)="removeWhiteSpace('parkingpassEmail')"
                                (change)="checkIfEmailConfirmed()"
                                type='text' 
                                id='user_email' 
                                formControlName="parkingpassEmail" 
                                placeholder="{{ 'RESIDENT_INFO.EMAIL_PLACEHOLDER' | translate }}" 
                                autocomplete="email"
                                [errorStateMatcher]="errorMatcher"/>

            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && f.parkingpassEmailConfirm.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="f.parkingpassEmailConfirm.errors.required">
                    {{ 'RESIDENT_INFO.C_EMAIL_REQUIRED' | translate }}
                </mat-error>                          
                
                <mat-error *ngIf="f.parkingpassEmailConfirm.errors.email">
                    {{ 'RESIDENT_INFO.C_EMAIL_INVALID' | translate }}
                </mat-error>                             

                <mat-error *ngIf="f.parkingpassEmailConfirm.errors.mustMatch">
                    {{ 'RESIDENT_INFO.C_EMAIL_MUST_MATCH' | translate }}
                </mat-error>

            </div>
        
            <mat-form-field>

                <input matInput (keyup)="removeWhiteSpace('parkingpassEmailConfirm'); emailChanged()" 
                                oncontextmenu="return false"
                                onCopy="return false" 
                                onDrag="return false" 
                                onDrop="return false"
                                onPaste="return false"
                                type='text' 
                                id='user_confirm_email' 
                                formControlName="parkingpassEmailConfirm"
                                autocomplete=off
                                placeholder="{{ 'RESIDENT_INFO.C_EMAIL_PLACEHOLDER' | translate }}"
                                [errorStateMatcher]="errorMatcher" />        

            </mat-form-field>

        </div>

    </div>

    <div>

        <div class='parkingpass-formFieldWrapper'>
        
            <div *ngIf="submitted && f.parkingpassPhoneType.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="f.parkingpassPhoneType.errors.required">
                    {{ 'RESIDENT_INFO.PHONE_NUMBER_TYPE_REQUIRED' | translate }}
                </mat-error>                                                

                <mat-error *ngIf="f.parkingpassPhoneType.errors.invalid">
                    {{ 'RESIDENT_INFO.PHONE_NUMBER_TYPE_INVALID' | translate }}
                </mat-error>

            </div>

            <mat-form-field>
                
                <mat-select id="phone_type"
                            placeholder="{{ 'RESIDENT_INFO.PHONE_NUMBER_TYPE' | translate }}"
                            formControlName="parkingpassPhoneType"
                            [errorStateMatcher]="errorMatcher"
                            required>

                    <mat-option value='mobile'>
                        {{ 'RESIDENT_INFO.PHONE_NUMBER_MOBILE' | translate }}
                    </mat-option> 

                    <mat-option value='home'>
                        {{ 'RESIDENT_INFO.PHONE_NUMBER_HOME' | translate }}
                    </mat-option>

                </mat-select>
            
            </mat-form-field>

        </div>

        <div class='parkingpass-formFieldWrapper'>

            <div *ngIf="submitted && f.parkingpassPhoneNumber.errors" class="signUpBoxInstructions">

                <mat-error *ngIf="f.parkingpassPhoneNumber.errors.required">
                    {{ 'RESIDENT_INFO.PHONE_NUMBER_REQUIRED' | translate }}
                </mat-error>                                                

                <mat-error *ngIf="f.parkingpassPhoneNumber.errors.invalidPhone">
                    {{ 'RESIDENT_INFO.PHONE_NUMBER_INVALID' | translate }}                
                </mat-error> 

                <mat-error *ngIf="f.parkingpassPhoneNumber.errors.notUnique">
                    {{ 'RESIDENT_INFO.PHONE_NUMBER_NOT_UNIQUE' | translate }}                
                </mat-error>   

            </div>  
            
            <mat-form-field>

                <input matInput type='text' 
                                id='user_phone' 
                                mask="(000) 000-0000"
                                (change)="checkIfPhoneConfirmed()"
                                autocomplete=off
                                placeholder="{{ 'RESIDENT_INFO.PHONE_NUMBER_PLACEHOLDER' | translate }}"
                                formControlName="parkingpassPhoneNumber"
                                [errorStateMatcher]="errorMatcher" />

            </mat-form-field>  

        </div>

    </div>
    
    <div *ngIf="this.isLoggedIn !== '1'">

        <div class='parkingpass-formFieldWrapper'>
        
            <div *ngIf="submitted && f.parkingpassPassword.errors" class="signUpBoxInstructions">
                
                <mat-error *ngIf="f.parkingpassPassword.errors.required">
                    {{ 'RESIDENT_INFO.PASSWORD_REQUIRED' | translate }}
                </mat-error>
    
                <mat-error *ngIf="f.parkingpassPassword.errors.wrongLength">
                    {{ 'RESIDENT_INFO.PASSWORD_LENGTH' | translate }}
                </mat-error>
    
                <mat-error *ngIf="f.parkingpassPassword.errors.oneNumber">
                    {{ 'RESIDENT_INFO.PASSWORD_NUMBER' | translate }}
                </mat-error> 
    
                <mat-error *ngIf="f.parkingpassPassword.errors.oneLowerCase">
                    {{ 'RESIDENT_INFO.PASSWORD_ONE_LOWERCASE' | translate }}
                </mat-error>  
    
                <mat-error *ngIf="f.parkingpassPassword.errors.oneUpperCase">
                    {{ 'RESIDENT_INFO.PASSWORD_UPPERCASE' | translate }}
                </mat-error>
                
                <mat-error *ngIf="f.parkingpassPassword.errors.oneSpecialCharacter">
                    {{ 'RESIDENT_INFO.PASSWORD_SPECIAL_CHAR' | translate }}
                </mat-error>                
    
            </div>   
        
            <mat-form-field>
                <input matInput [type]="!passwordShow ? 'password' : 'text'"
                                formControlName="parkingpassPassword" 
                                id='user_pass'
                                placeholder="{{ 'RESIDENT_INFO.PASSWORD_PLACEHOLDER' | translate }}"
                                (focus)="togglePasswordHints()"
                                (blur)="togglePasswordHints()"
                                [errorStateMatcher]="errorMatcher"
                                #residentPassword /> 
                <fa-icon class='fa-eye' [icon]="faEye" (click)="togglePassword()" *ngIf="!passwordShow"></fa-icon> 
                <fa-icon class='fa-eye' [icon]="faEyeSlash" (click)="togglePassword()" *ngIf="passwordShow"></fa-icon>                                  
            </mat-form-field>
            
        </div>
    
        <div class='parkingpass-formFieldWrapper'>
    
            <div *ngIf="submitted && f.parkingpassConfirm.errors" class="signUpBoxInstructions">
    
                <mat-error *ngIf="f.parkingpassConfirm.errors.mustMatch">
                    {{ 'RESIDENT_INFO.C_PASSWORD_MUST_MATCH' | translate }}
                </mat-error>
                
                <mat-error *ngIf="f.parkingpassConfirm.errors.required">
                    {{ 'RESIDENT_INFO.C_PASSWORD_REQUIRED' | translate }}                
                </mat-error>                              
    
            </div>                                
    
            <mat-form-field>
                <input matInput [type]="!passwordShow ? 'password' : 'text'"
                                formControlName="parkingpassConfirm"                              
                                id='user_pass_confirm'
                                placeholder="{{ 'RESIDENT_INFO.C_PASSWORD_PLACEHOLDER' | translate }}"
                                (focus)="togglePasswordHints()"
                                (blur)="togglePasswordHints()"
                                [errorStateMatcher]="errorMatcher" 
                                #residentConfirmPassword />
                <fa-icon class='fa-eye' [icon]="faEye" (click)="togglePassword()" *ngIf="!passwordShow"></fa-icon> 
                <fa-icon class='fa-eye' [icon]="faEyeSlash" (click)="togglePassword()" *ngIf="passwordShow"></fa-icon>                                 
            </mat-form-field>
                                            
        </div>

    </div>

    <div class='parkingpass-passwordInfo' *ngIf="passwordHints">

        <span>{{ 'RESIDENT_INFO.PASSWORD_REQUIREMENTS' | translate }}</span>

        <ul>
            <li [ngStyle]="passwordErrors('oneUpperCase')">{{ 'RESIDENT_INFO.PASSWORD_REQUIREMENTS_0' | translate }}</li>
            <li [ngStyle]="passwordErrors('oneLowerCase')">{{ 'RESIDENT_INFO.PASSWORD_REQUIREMENTS_1' | translate }}</li>
            <li [ngStyle]="passwordErrors('oneNumber')">{{ 'RESIDENT_INFO.PASSWORD_REQUIREMENTS_2' | translate }}</li>
            <li [ngStyle]="passwordErrors('oneSpecialCharacter')">{{ 'RESIDENT_INFO.PASSWORD_REQUIREMENTS_3' | translate }}</li>
            <li [ngStyle]="passwordErrors('wrongLength')">{{ 'RESIDENT_INFO.PASSWORD_REQUIREMENTS_4' | translate }}</li>
        </ul>
        
    </div>

    <div class='parkingpass-formFieldWrapper customerPin' *ngIf="propertyInfo !== undefined && propertyInfo.questions.support_pin">

        <div *ngIf="submitted && f.parkingpassCustomerSupportPin.errors" class="signUpBoxInstructions">
            
            <mat-error *ngIf="f.parkingpassCustomerSupportPin.errors.required">
                {{ 'RESIDENT_INFO.CUSTOMER_PIN_REQUIRED' | translate }}  
            </mat-error>

            <mat-error *ngIf="f.parkingpassCustomerSupportPin.errors.maxLength || f.parkingpassCustomerSupportPin.errors.minLength">
                {{ 'RESIDENT_INFO.CUSTOMER_PIN_WRONG_LENGTH' | translate }}
            </mat-error>

        </div>   
    
        <mat-form-field>
            <input matInput type='text' 
                    formControlName="parkingpassCustomerSupportPin"
                    mask="0000" 
                    id='user_pin' 
                    placeholder="{{ 'RESIDENT_INFO.CUSTOMER_PIN_PLACEHOLDER' | translate }}"
                    [errorStateMatcher]="errorMatcher" />                                    
        </mat-form-field>
        
        <br/>

        <button class='text-primary parkingpass-help-info' (click)="enablePinInfo()">
            {{ 'KEYWORD.WHATS_THIS' | translate }}<fa-icon class='fa-info' [icon]="faInfo"></fa-icon>
        </button>

    </div>
    
    <button class='text-primary parkingpass-help-info' *ngIf="isLoggedIn == '1'" (click)="togglePasswordChange()">
        {{ 'RESIDENT_INFO.CHANGE_PASSWORD' | translate  }}
    </button>

    <div class='parkingpass-help-info passwordChanged' *ngIf="passwordChanged">
        {{ 'RESIDENT_INFO.PASSWORD_CHANGED' | translate  }}
    </div>
    
</form>
