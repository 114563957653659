import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { handleError } from 'src/app/helpers/error-helper';
import { environment } from 'src/environments/environment';
import { retryWithBackOff } from 'src/app/helpers/retry.operators';

const SEND_EMAIL_CODE_API = `${environment.api_endpoint}/v2/users/validation/email/send-code`
const EMAIL_CONFIRM_PIN_API = `${environment.api_endpoint}/v2/users/validation/email/check-confirm`

@Injectable({
  providedIn: 'root'
})
export class EmailConfirmationService {

  constructor(private httpClient: HttpClient) {}

  public sendCode(firstName:string, email: string, system: string, lang: string, property_id: number): Observable<any>{

    const sendCodeApi = `${SEND_EMAIL_CODE_API}`

    const sendCodeObj = {
      api_key: environment.api_key,
      first_name: firstName,
      email: email,
      system: system,
      lang: lang,
      property_id: property_id
    }

    return this.httpClient.post<any>(sendCodeApi, sendCodeObj).pipe(
      retryWithBackOff(1000)
    )

  }

  public checkCode(code: string, email: string): Observable<any>{

    const sendCodeApi = `${EMAIL_CONFIRM_PIN_API}`

    const sendCodeObj = {
      api_key: environment.api_key,
      confirm_code: code,
      email: email      
    }
    
    return this.httpClient.post<any>(sendCodeApi, sendCodeObj).pipe(
      retryWithBackOff(1000)
    )

  }

}
