import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CheckPhoneConfirmationError, PhoneConfirmationError } from 'src/app/errors/user-errors/user.errors';
import { logErrorMessage } from 'src/app/helpers/retry.operators';
import { PhoneConfirmationService } from 'src/app/services/sign-up/phone-confirmation/phone-confirmation.service';


@Component({
  selector: 'app-phone-confirmation',
  templateUrl: './phone-confirmation.component.html',
  styleUrls: ['../sign-up.component.css', './phone-confirmation.component.css']
})
export class PhoneConfirmationComponent implements OnInit {

  public changePhone = new EventEmitter();

  public buttonDisabled: boolean = false;

  public phoneNumberChange = new EventEmitter()

  public providedPhoneNumber: string
  public system: string
  public language: string

  public phoneNumberConfirmationPassed: boolean = false

  public affirmPhoneNumber: boolean = true
  public confirmPhoneNumber: boolean = false
  public codeCheckTried: boolean = false

  public confirmPhoneForm: FormGroup
  public pinForm: FormGroup

  public loading: boolean = false

  

  constructor(private dialogRef: MatDialogRef<PhoneConfirmationComponent>,
              @Inject(MAT_DIALOG_DATA) private dat,
              private phoneConfirmationService: PhoneConfirmationService,
              private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private matDialog: MatDialog) {}  

  public sendCodeWithCall(): void{

    if(this.loading) return

    const phoneNumber = this.parkingpassPhoneNumber
    const system = this.system
    const lang = this.language

    this.phoneConfirmationService.sendCodeWithCall(phoneNumber, system, lang).subscribe(
      resp =>{
        this.sendCodeCallback(resp)
      },
      error => {

        let sentryContext: any = {
          errorName: 'Phone Call Confirmation Error',
          phoneNumber: phoneNumber,
          lang: this.language,
          errorMessage: this.translateService.instant('USER_SERVER_ERRORS.PHONE_NUMBER_CONFIRMATION'),
          system: system,
          error: null
        }
    
        sentryContext.error = new PhoneConfirmationError(sentryContext.errorName)

        this.loading = false

        logErrorMessage(3, error, sentryContext, this.matDialog)

      }      
    )

  }

  public sendCode(): void{

    if(this.loading) return

    this.confirmPhoneForm.get('parkingpassPhoneNumber').setErrors(null)

    const phoneNumber = this.parkingpassPhoneNumber
    const system = this.system
    const lang = this.language

    this.phoneConfirmationService.sendCode(phoneNumber, system, lang).subscribe(
      resp =>{
        this.sendCodeCallback(resp)
      },
      error => {

        let sentryContext: any = {
          errorName: 'Phone Confirmation Error',
          phoneNumber: phoneNumber,
          lang: this.language,
          errorMessage: this.translateService.instant('USER_SERVER_ERRORS.PHONE_NUMBER_CONFIRMATION'),
          system: system,
          error: null
        }
    
        sentryContext.error = new PhoneConfirmationError(sentryContext.errorName)

        this.loading = false

        logErrorMessage(3, error, sentryContext, this.matDialog)

      }
    )

  }

  public sendCodeCallback(httpResponse: any){

    if(httpResponse === undefined) return

    if(httpResponse.success){

      switch(httpResponse.status){

        case 'confirmation.sent':
          this.initPinForm()

          this.affirmPhoneNumber = false
          this.confirmPhoneNumber = true          
          break
          
        case 'confirmation.verified':
          this.codeCheckTried = true
          this.phoneNumberConfirmationPassed = true
          this.affirmPhoneNumber = false
          this.confirmPhoneNumber = false
          break

        case 'phoneNumber.unavailable':
          this.confirmPhoneForm.get('parkingpassPhoneNumber').setErrors({ unavailable : true })
          break

        case 'phoneNumber.invalid':
          this.confirmPhoneForm.get('parkingpassPhoneNumber').setErrors({ invalid : true })
          break
                 
      }

    } else
      console.log("sendCodeCallback", httpResponse)

    this.loading = false

  }

  public checkCode(): void{

    this.phoneConfirmationService.checkCode(this.confirmationCode, this.parkingpassPhoneNumber).subscribe(
      resp =>{
        this.checkCodeCallback(resp)
      },
      error => {

        let sentryContext: any = {
          errorName: 'Check Phone Code Confirmation Error',
          phoneNumber: this.parkingpassPhoneNumber,
          confirmationCode: this.confirmationCode,
          errorMessage: this.translateService.instant('USER_SERVER_ERRORS.PHONE_NUMBER_CONFIRMATION'),
          error: null
        }
    
        sentryContext.error = new CheckPhoneConfirmationError(sentryContext.errorName)

        this.loading = false

        logErrorMessage(3, error, sentryContext, this.matDialog)

      }

    )

  }

  public checkCodeCallback(httpResponse: any): void{

    this.confirmPhoneNumber = false
    this.codeCheckTried = true
    
    if(httpResponse === undefined) return

    if(httpResponse.success){

      this.phoneNumberConfirmationPassed = true  

      const parentObj: any = {
        phoneNumberConfirmed : this.phoneNumberConfirmationPassed
      }
  
      setTimeout(function(){
        this.dialogRef.close(parentObj)
      }.bind(this, parentObj), 3000)

    } else {
    
      this.phoneNumberConfirmationPassed = false

    }

  }

  public changeParentPhone(): void{
    this.phoneNumberChange.emit(this.parkingpassPhoneNumber)
  }

  get d() { return this.confirmPhoneForm.controls }

  get parkingpassPhoneNumber() { return this.confirmPhoneForm.get('parkingpassPhoneNumber').value }  

  public initPhoneNumberForm(): void{

    const phoneValidators = [Validators.required]

    this.confirmPhoneForm = this.formBuilder.group({
      parkingpassPhoneNumber: ['', phoneValidators]
    })

    this.confirmPhoneForm.get('parkingpassPhoneNumber').setValue(this.providedPhoneNumber)

  }

  get f() { return this.pinForm.controls }

  get confirmationCode() { return this.pinForm.get('confirmationCode').value }  

  public initPinForm(): void{

    const codeValidators = [Validators.required]

    this.pinForm = this.formBuilder.group({
      confirmationCode: ['', codeValidators]
    })

  }

  public backToFirst(): void{

    this.affirmPhoneNumber = true
    this.pinForm.reset()
    this.confirmPhoneNumber = false
    this.codeCheckTried = false
    
  }

  public close(): void {
    const parentObj: any = {
      phoneNumberConfirmed : this.phoneNumberConfirmationPassed
    }
    this.dialogRef.close(parentObj)
  }

  public optOutClose(): void {
    const parentObj: any = {
      phoneNumberConfirmed : this.phoneNumberConfirmationPassed
    }
    this.dialogRef.close(parentObj)
  }

  ngOnInit(): void {    

    this.providedPhoneNumber = this.dat.phoneNumber 
    this.system = this.dat.system   
    this.language = this.dat.language

    this.initPhoneNumberForm()

  }

  public optOut(): void {
    this.close()
    this.changePhone.emit(this)
    }

}
