import { Injectable } from '@angular/core'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { UserService } from '../user.service'

@Injectable({
  providedIn: 'root'
})
export class LoginGuardServiceService {

  constructor(private router: Router,
              private userAuthService: UserService,
              private translateService: TranslateService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {  

      const response = await this.userAuthService.checkIfLoggedIn()  
  
      if (response.message == 'authorized') {

        return true
      
      } else {

        let system = localStorage.getItem('parkingpass_system')

        localStorage.clear()

        localStorage.setItem('parkingpass_system', system)

        let language = this.translateService.currentLang

        this.router.navigate([`${language}/home`])
        
        return false

      }
      
    }

}
