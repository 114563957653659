import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { Observable, of } from 'rxjs'
import { MustMatch } from 'src/app/validators/must-match.validator'
import { ValidatePersonName } from 'src/app/validators/name.validator'
import { ValidatePropertyAddress } from 'src/app/validators/sign-up/property-address.validator'
import { ValidatePropertyBuildingNumber } from 'src/app/validators/sign-up/property-building-number.validator'
import { ValidatePropertyResidentType } from 'src/app/validators/sign-up/property-resident-type.validator'
import { ValidatePropertyUnit } from 'src/app/validators/sign-up/property-unit.validator'
import { STATE_LIST } from 'src/app/lists/state.list'
import { COUNTRY_LIST } from 'src/app/lists/country.list'
import { ValidateState } from 'src/app/validators/sign-up/state.validator'
import { ValidateCountry } from 'src/app/validators/sign-up/country.validators'
import { removeLastWhiteSpace, titleCase } from 'src/app/helpers/string-formatting.helper'
import { PropertyKeywordService } from 'src/app/services/sign-up/property-keyword.service'
import { MustNotMatchNames } from 'src/app/validators/sign-up/must-not-match-name.validator'
import { MustNotMatch } from 'src/app/validators/sign-up/must-not-match.validator'
import { MyErrorStateMatcher } from 'src/app/helpers/default.error-matcher'
import { handleError } from 'src/app/helpers/error-helper'
import { logErrorMessage } from 'src/app/helpers/retry.operators'
import { PropertyError } from 'src/app/errors/property-errors/property.errors'
import { TranslateService } from '@ngx-translate/core'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-property-info',
  templateUrl: './property-info.component.html',
  styleUrls: ['./property-info.component.css', '../sign-up.component.css']
})
export class PropertyInfoComponent implements OnInit {

  @Output() ownerFormEvt = new EventEmitter()
  @Output() loading = new EventEmitter()

  @Input() keywordForm: FormGroup

  @ViewChild('parkingpassSignUpIssues') parkingpassSignUpIssues

  public countrySelected: string

  public prevCard = new EventEmitter()

  public propertyInfoForm: FormGroup
  public ownerInfoForm: FormGroup
  public residentForm: FormGroup

  public submittingForm: boolean = false
  public submitted: boolean = false

  public state_list: Array<any> = STATE_LIST
  public country_list = COUNTRY_LIST

  public propertyInfo: any

  public showAddress: boolean = false
  public showBuildingNumber: boolean = false
  public showUnitNumber: boolean = false

  public propertyAddressList: Array<any>
  public buildingNumberList: Array<any>
  public unitNumberList: Array<any>

  public keyword: string
  public system: string

  public isLoggedIn: string = '0'

  public propertyInfoLoggedIn: any

  public canInitialize: boolean = false

  public errorMatcher = new MyErrorStateMatcher()

  constructor(private formBuilder: FormBuilder,
              private propertyInfoService: PropertyKeywordService,
              private el: ElementRef,
              private translateService: TranslateService,
              private matDialog: MatDialog) { }


  public formatAfterBlur(formControlName: string){
    let str = removeLastWhiteSpace(this.ownerInfoForm.get(formControlName).value)
    this.ownerInfoForm.get(formControlName).setValue(str)
  }

  get g() { return this.propertyInfoForm.controls }

  get parkingpassStreetAddress() { return this.propertyInfoForm.get('parkingpassStreetAddress').value }
  get parkingpassBuildingNumber() { return this.propertyInfoForm.get('parkingpassBuildingNumber').value }
  get parkingpassApartmentUnit() { return this.propertyInfoForm.get('parkingpassApartmentUnit').value }
  get parkingpassCity() { return this.propertyInfoForm.get('parkingpassCity').value }
  get parkingpassState() { return this.propertyInfoForm.get('parkingpassState').value }
  get parkingpassZipCode() { return this.propertyInfoForm.get('parkingpassZipCode').value }
  get parkingpassResidentType() { return this.propertyInfoForm.get('parkingpassResidentType').value }
  get parkingpassOwnerInfoNeeded() { return this.propertyInfoForm.get('parkingpassOwnerInfoNeeded').value }

  get h() { return this.ownerInfoForm.controls }

  get parkingpassOwnerFirstName() { return this.ownerInfoForm.get('parkingpassOwnerFirstName').value }
  get parkingpassOwnerLastName() { return this.ownerInfoForm.get('parkingpassOwnerLastName').value }
  get parkingpassOwnerEmail() { return this.ownerInfoForm.get('parkingpassOwnerEmail').value.toLowerCase() }
  get parkingpassOwnerConfirmEmail() { return this.ownerInfoForm.get('parkingpassOwnerConfirmEmail').value.toLowerCase() }
  get parkingpassOwnerPhoneNumber() { return this.ownerInfoForm.get('parkingpassOwnerPhoneNumber').value }
  get parkingpassOwnerAddress() { return this.ownerInfoForm.get('parkingpassOwnerAddress').value }
  get parkingpassOwnerCity() { return this.ownerInfoForm.get('parkingpassOwnerCity').value }
  get parkingpassOwnerZipCode() { return this.ownerInfoForm.get('parkingpassOwnerZipCode').value  }
  get parkingpassOwnerCountry() { return this.ownerInfoForm.get('parkingpassOwnerCountry').value }
  get parkingpassOwnerState() { return this.ownerInfoForm.get('parkingpassOwnerState').value }

  public residentTypeChange(): void{

    if(this.parkingpassResidentType === 'renter'){

      const firstNameValidators = [Validators.required]
      const lastNameValidators = [Validators.required]
      const emailValidators = [Validators.required, Validators.email]
      const confirmEmailValidators = [Validators.required]
      const ownerPhoneNumberValidators = [Validators.required]
      const addressValidators = [Validators.required]
      const cityValidators = [Validators.required]
      const countryValidators = [Validators.required]
      const zipCodeValidators = [Validators.required]
      const stateValidators = [Validators.required]

      //the resident doesn't own the property. Let's initiate the required fields.
      this.ownerInfoForm = this.formBuilder.group({
        parkingpassOwnerFirstName: ['', firstNameValidators],
        parkingpassOwnerLastName: ['', lastNameValidators],
        parkingpassOwnerEmail: ['', emailValidators],
        parkingpassOwnerConfirmEmail: ['', confirmEmailValidators],
        parkingpassOwnerPhoneNumber: ['', ownerPhoneNumberValidators],
        parkingpassOwnerAddress: ['', addressValidators],
        parkingpassOwnerCity: ['', cityValidators],
        parkingpassOwnerCountry: ['', countryValidators],
        parkingpassOwnerZipCode: ['', zipCodeValidators],
        parkingpassOwnerState: ['', stateValidators],
      },{
        validators: [
          ValidatePersonName('parkingpassOwnerFirstName'),
          ValidatePersonName('parkingpassOwnerLastName'),
          MustMatch('parkingpassOwnerEmail', 'parkingpassOwnerConfirmEmail'),
          ValidateState('parkingpassOwnerState'),
          ValidateCountry('parkingpassOwnerCountry'),
          MustNotMatchNames('parkingpassOwnerFirstName', 'parkingpassOwnerLastName',
                             this.residentForm.get('parkingpassFirstName').value, this.residentForm.get('parkingpassLastName').value),
          MustNotMatch('parkingpassOwnerEmail', this.residentForm.get('parkingpassEmail').value),
          MustNotMatch('parkingpassOwnerPhoneNumber', this.residentForm.get('parkingpassPhoneNumber').value),
        ]
      })

      this.ownerInfoForm.get('parkingpassOwnerCountry').setValue('US')

      if(this.propertyInfo.questions.own_or_rent){
        this.propertyInfoForm.get('parkingpassOwnerInfoNeeded').setValue("true")
      } else {
        this.propertyInfoForm.get('parkingpassOwnerInfoNeeded').setValue("false")
      }

      this.ownerFormEvt.emit()

      this.canInitialize = true

      let ownerInfo = JSON.parse(localStorage.getItem('parkingpass_ownerInfo'))

      if(ownerInfo !== null && ownerInfo !== undefined){

        this.ownerInfoForm.get('parkingpassOwnerFirstName').setValue(ownerInfo.first_name)
        this.ownerInfoForm.get('parkingpassOwnerLastName').setValue( ownerInfo.last_name)
        this.ownerInfoForm.get('parkingpassOwnerEmail').setValue(ownerInfo.email)
        this.ownerInfoForm.get('parkingpassOwnerConfirmEmail').setValue(ownerInfo.email)
        this.ownerInfoForm.get('parkingpassOwnerPhoneNumber').setValue(ownerInfo.phone)
        this.ownerInfoForm.get('parkingpassOwnerAddress').setValue(ownerInfo.address)
        this.ownerInfoForm.get('parkingpassOwnerCity').setValue(ownerInfo.city)
        this.ownerInfoForm.get('parkingpassOwnerCountry').setValue(ownerInfo.country)
        this.ownerInfoForm.get('parkingpassOwnerZipCode').setValue(ownerInfo.zip_code)
        this.ownerInfoForm.get('parkingpassOwnerState').setValue(ownerInfo.state)

      }

    } else {

      //the resident owns the property let's get rid of the required fields.
      this.ownerInfoForm = this.formBuilder.group({
        parkingpassOwnerFirstName: [],
        parkingpassOwnerLastName: [],
        parkingpassOwnerEmail: [],
        parkingpassOwnerConfirmEmail: [],
        parkingpassOwnerPhoneNumber: [],
        parkingpassOwnerAddress: [],
        parkingpassOwnerCity: [],
        parkingpassOwnerCountry: [],
        parkingpassOwnerZipCode: [],
        parkingpassOwnerState: [],
      },{
        validators: []
      })

      this.propertyInfoForm.get('parkingpassOwnerInfoNeeded').setValue("false")

      this.canInitialize = true

    }

  }

  public addValidators(){

    if(this.propertyInfo.questions.own_or_rent){
      this.initForm()
    } else {

      const streetAddressValidators = []
      const buildingNumberValidators = []
      const apartmentUnitValidators = []
      const ownerInfoNeededValidators = [Validators.required]

      this.propertyInfoForm = this.formBuilder.group({
        parkingpassStreetAddress: ['', streetAddressValidators],
        parkingpassBuildingNumber: ['', buildingNumberValidators],
        parkingpassApartmentUnit: ['', apartmentUnitValidators],
        parkingpassOwnerInfoNeeded: ['', ownerInfoNeededValidators],
        parkingpassResidentType: ['']
      }, {
        validators: [
          ValidatePropertyAddress('parkingpassStreetAddress'),
          ValidatePropertyBuildingNumber('parkingpassBuildingNumber'),
          ValidatePropertyUnit('parkingpassApartmentUnit')
        ]
      })

    }

    if ( this.propertyInfo.community_type === 'Manufactured Homes' ) {

      this.propertyInfoForm.get('parkingpassResidentType').setValue('owner')
      this.propertyInfoForm.get('parkingpassOwnerInfoNeeded').setValue('false')

    } else if ( !this.propertyInfo.questions.own_or_rent ){

      this.propertyInfoForm.get('parkingpassResidentType').setValue('renter')
      this.propertyInfoForm.get('parkingpassOwnerInfoNeeded').setValue('false')

    } else {

      if(this.parkingpassResidentType == 'owner')
        this.propertyInfoForm.get('parkingpassOwnerInfoNeeded').setValue('false')
      else
        this.propertyInfoForm.get('parkingpassOwnerInfoNeeded').setValue('true')

    }

  }

  public getPropertyAddressInfo(keyword: string, system: string){

    if(this.isLoggedIn != '1')
      if(keyword === this.keyword) return

    this.keyword = keyword
    this.system = system

    this.loading.emit(true)

    this.propertyInfoService.getPropertyAddressInfo(keyword, system).subscribe(
      resp => {
        this.populatePropertyAddressInfo(resp)
      },
      error => {

        let sentryContext: any = {
          errorName: 'Get Address Info Error',
          keyword: keyword,
          system: system,
          errorMessage: this.translateService.instant('USER_SERVER_ERRORS.ADDRESS_INFORMATION'),
          error: null
        }

        sentryContext.error = new PropertyError(sentryContext.errorName)

        logErrorMessage(3, error, sentryContext, this.matDialog)

        this.loading.emit(false)

      }
    )

  }

  public populatePropertyAddressInfo(httpResponse: any): void{

    if(httpResponse.success){

      if (httpResponse.count > 0) {
        this.propertyAddressList = httpResponse.data
        this.showAddress = true
      } else {
        this.showAddress = false
        this.getBuildingNumbers()
      }

      if(this.isLoggedIn == '1'){
        this.propertyInfoForm.get('parkingpassStreetAddress').setValue(this.propertyInfoLoggedIn.address)
      }

    } else
      console.log("populatePropertyAddressInfo", httpResponse)

    this.loading.emit(false)

  }


  public getBuildingNumbers(): void{

    this.loading.emit(true)

    const keyword = this.keyword
    const system = this.system
    const address = this.parkingpassStreetAddress

    this.propertyInfoService.getBuildingNumbers(keyword, system, address).subscribe(
      resp => {
        this.populateBuildingNumbers(resp)
      },
      error => {

        let sentryContext: any = {
          errorName: 'Get Building Numbers Error',
          keyword: keyword,
          system: system,
          address: address,
          errorMessage: this.translateService.instant('USER_SERVER_ERRORS.BUILDING_NUMBER_INFORMATION'),
          error: null
        }

        sentryContext.error = new PropertyError(sentryContext.errorName)

        logErrorMessage(3, error, sentryContext, this.matDialog)

        this.loading.emit(false)

      }
    )

  }

  public populateBuildingNumbers(httpResponse: any): void{

    this.showBuildingNumber = false
    this.showUnitNumber = false

    if(httpResponse.success){

      this.buildingNumberList = httpResponse.data

      if (httpResponse.count > 0) {
        this.showBuildingNumber = true
      } else {
        this.showBuildingNumber = false
        this.getUnitNumbers()
      }

      if(this.isLoggedIn == '1'){

        this.propertyInfoForm.get('parkingpassBuildingNumber').setValue(this.propertyInfoLoggedIn.build_num)

      } else if(this.buildingNumberList.length == 1) {

        this.propertyInfoForm.get('parkingpassBuildingNumber').setValue(this.buildingNumberList[0].building_no)

      }

      this.residentTypeChange()

    } else
      console.log("populateBuildingNumbers", httpResponse)

    this.loading.emit(false)

  }

  public getUnitNumbers(): void{

    this.loading.emit(true)

    const keyword = this.keyword
    const system = this.system
    const address = this.parkingpassStreetAddress
    const building = this.parkingpassBuildingNumber

    this.propertyInfoService.getUnitNumbers(keyword, system, address, building).subscribe(
      resp => {
        this.populateUnitNumbers(resp)
      },
      error => {

        let sentryContext: any = {
          errorName: 'Get Unit Numbers Error',
          keyword: keyword,
          system: system,
          address: address,
          building: building,
          errorMessage: this.translateService.instant('USER_SERVER_ERRORS.UNIT_NUMBER_INFORMATION'),
          error: null
        }

        sentryContext.error = new PropertyError(sentryContext.errorName)

        logErrorMessage(3, error, sentryContext, this.matDialog)

        this.loading.emit(false)

      }
    )

  }

  public changeCountry(): void {
    console.log(this.countrySelected)
    }

  public populateUnitNumbers(httpResponse: any): void{

    if(httpResponse == undefined) {
      this.showUnitNumber = false
      return
    }

    if(httpResponse.success){

      this.unitNumberList = httpResponse.data
      if (httpResponse.count > 0) {
        this.showUnitNumber = true
      } else {
        this.showUnitNumber = false
      }

      if(this.isLoggedIn == '1')
        this.propertyInfoForm.get('parkingpassApartmentUnit').setValue(this.propertyInfoLoggedIn.apart_num)
      else if(this.unitNumberList.length == 1)
        this.propertyInfoForm.get('parkingpassApartmentUnit').setValue(this.unitNumberList[0].unit_number)
    }

    this.loading.emit(false)

  }

  public initForm(): void{

    const streetAddressValidators = []
    const buildingNumberValidators = []
    const apartmentUnitValidators = []
    const residentTypeValidators = [Validators.required]
    const ownerInfoNeededValidators = [Validators.required]

    this.propertyInfoForm = this.formBuilder.group({
      parkingpassStreetAddress: ['', streetAddressValidators],
      parkingpassBuildingNumber: ['', buildingNumberValidators],
      parkingpassApartmentUnit: ['', apartmentUnitValidators],
      parkingpassOwnerInfoNeeded: ['', ownerInfoNeededValidators],
      parkingpassResidentType: ['', residentTypeValidators]
    }, {
      validators: [
        ValidatePropertyAddress('parkingpassStreetAddress'),
        ValidatePropertyBuildingNumber('parkingpassBuildingNumber'),
        ValidatePropertyUnit('parkingpassApartmentUnit'),
        ValidatePropertyResidentType('parkingpassResidentType')
      ]
    })

    if(this.isLoggedIn == '1'){

      this.propertyInfoForm.get('parkingpassResidentType').setValue(this.propertyInfoLoggedIn.usertype.toLowerCase())

      if(this.parkingpassResidentType == 'owner')
        this.propertyInfoForm.get('parkingpassOwnerInfoNeeded').setValue("false")
      else if(this.parkingpassResidentType == 'renter')
        this.propertyInfoForm.get('parkingpassOwnerInfoNeeded').setValue("true")


    }

  }

  public removeWhiteSpace(key) {
    this.ownerInfoForm.get(key).setValue(this.ownerInfoForm.get(key).value.trim())
  }

  public scrollToFirstInvalidControl() {

    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    if(firstInvalidControl == null) return

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: "smooth"
    });

  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 100;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  ngOnInit() {

    this.isLoggedIn = localStorage.getItem('parkingpass_loggedIn')

    if(this.isLoggedIn == '1')
      this.propertyInfoLoggedIn = JSON.parse(localStorage.getItem('parkingpass_userInfo'))

    this.initForm()

  }
}
